import axios from "axios";
import { getItem } from "../../utility/LocalStorageControl";
import Cookies from "js-cookie";
import { ADMIN_URL, API_ENDPOINT, SOCKET_URL_ENDPOINT } from "../Constant";


// DEFINE API BASE URL
// staging url
// const API_ENDPOINT = `https://api.xik.io/api/`;
// const SOCKET_URL_ENDPOINT = "https://api.xik.io/";

// production url
// const API_ENDPOINT = `https://api.atz.ai/api/`;
// const SOCKET_URL_ENDPOINT = "https://api.atz.ai/";
const browserLanguageCode = navigator.language || navigator.userLanguage;

const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
  language_code: getItem("apiLanguageSelection")
    ? getItem("apiLanguageSelection")
    : browserLanguageCode?.includes("-")
      ? browserLanguageCode?.split("-")[0]
      : browserLanguageCode,
  time_zone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}, uploadPercentage) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (uploadPercentage) {
          uploadPercentage(percentage);
        }
      },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}, optionalHeader = {}, uploadPercentage) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (uploadPercentage) {
          uploadPercentage(percentage);
        }
      },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }
}

client.interceptors.request.use((config) => {
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    if (!response.data.error) {
      return response;
    } else if (response.data.error && response.data.statusCode === 200) {
      return response;
    }
  },
  (error) => {
    const { response } = error;
    const originalRequest = error.response;
    if (response) {
      if (response.status === 500 || response.status === 400) {
        return originalRequest;
      } else if (response.status === 401) {
        localStorage.clear();
        Cookies.remove("logedIn");
        Cookies.remove("username")
        window.location.replace(
          `${ADMIN_URL}/login`
        );
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  }
);

export { DataService, API_ENDPOINT, SOCKET_URL_ENDPOINT };
