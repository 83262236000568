import React, { useState } from "react";
import styles from "./profileSetting.module.css";
import { Row, Col, Avatar, Tabs } from "antd";
import Profile from "./Profile/Profile";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import BusinessDetail from "./BusinessDetail/BusinessDetail";
import BioLinkDetail from "./BiolinkDetail/BioLinkDetail";
import { useSelector } from "react-redux";
import { getItem } from "../../../utility/LocalStorageControl";

function ProfileSetting() {
  const { t } = useTranslation();
  const userDetail = getItem("userDetails")
  const [selectedMenu, setSelectedMenu] = useState("profile");

  return (
    <div className={styles.accountMainContainer}>
      <h1>{t("Profile Setting")}</h1>
      <div className={styles.tabContainer}>
        <Row gutter={25}>
          <Col
            xxl={6}
            xl={6}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className={styles.profileMenuContainer}
          >
            <Row gutter={16}>
              <Col span={24}>
                <div className={styles.profileImage}>
                  {userDetail?.user_image !== "" ? (
                    <Avatar
                      className={styles.profileImageBox}
                      src={userDetail?.user_image}
                    />
                  ) : (
                    <Avatar
                      className={styles.profileImageAvatar}
                      icon={<FeatherIcon icon="user" size={50} />}
                    />
                  )}
                </div>
                <h2 className={styles.profileUserName}>
                  {userDetail?.name
                    ? userDetail?.name?.charAt(0).toUpperCase() +
                    userDetail?.name?.slice(1)
                    : ""}
                </h2>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xxl={24} xl={24} lg={24} md={0} sm={0} xs={0}>
                <div
                  className={
                    selectedMenu == "profile"
                      ? `${styles.activeProfileMenu}`
                      : `${styles.profileMenu}`
                  }
                  onClick={() => {
                    setSelectedMenu("profile");
                  }}
                >
                  {t("profile")}
                </div>

                <div
                  className={
                    selectedMenu == "business"
                      ? `${styles.activeProfileMenu}`
                      : `${styles.profileMenu}`
                  }
                  onClick={() => {
                    setSelectedMenu("business");
                  }}
                >
                  {t("Business Details")}
                </div>
                <div
                  className={
                    selectedMenu == "bioLink_detail"
                      ? `${styles.activeProfileMenu}`
                      : `${styles.profileMenu}`
                  }
                  onClick={() => {
                    setSelectedMenu("bioLink_detail");
                  }}
                >
                  {t("BioLink Detail")}
                </div>
              </Col>
              <Col xxl={0} xl={0} lg={0} md={24} sm={24} xs={24}>
                <Tabs
                  onChange={(e) => {
                    setSelectedMenu(e);
                  }}
                  size="large"
                  className="settingPageTab"
                  // centered={true}
                  type="card"
                  items={[
                    {
                      label: t("profile"),
                      key: "profile",
                    },
                    {
                      label: t("Business Details"),
                      key: "business",
                    },
                    {
                      label: t("BioLink Detail"),
                      key: "bioLink_detail",
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col xxl={18} xl={18} lg={16} md={24} sm={24} xs={24}>
            <div className={styles.profileEditContainer}>
              {selectedMenu == "profile" ? (
                <Profile />
              ) : selectedMenu == "business" ? (
                <BusinessDetail />
              ) : selectedMenu == "bioLink_detail" ? (
                <BioLinkDetail />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ProfileSetting;
