import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Input, Button, Spin, message, Divider, Skeleton, Space, Switch } from "antd";
import SignInCommonLogo from "./SignInCommonLogo";
import { InputOTP } from "antd-input-otp";
import {
  loginEmail,
  otpResend,
  otpVarification,
} from "../../redux/authentication/action";
import {
  getItem,
  removeItem,
  setItem,
} from "../../utility/LocalStorageControl";
import styles from "./SignIn.module.css";
import { el } from "date-fns/locale";

// DEFINE MAIN FUNCTION
const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [otpForm] = Form.useForm();
  const [otpLoading, setOTPLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showOtpVarification, setShowOtpVarification] = useState(
    getItem("ShowOtpVarification")
  );
  const [otpVarificationEmail, setOtpVarificationEmail] = useState(
    getItem("OtpVarificationEmail") ? getItem("OtpVarificationEmail") : ""
  );
  const [countryCode, setCountryCode] = useState('');
  const [autoOtpStatus, setAutoOtpStatus] = useState(true);
  const [timer, setTimer] = useState(getItem("timer"));
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const { t, i18n } = useTranslation();
  const [otpVal, setOtpVal] = useState([]);
  const browserLanguageCode = navigator.language || navigator.userLanguage;
  const [loading, setLoading] = useState(false)
  const [isApiCallInProgress, setApiCallInProgress] = useState(false);

  useEffect(() => {
    setLoading(true)
    // if (countryCode == '' || getItem('country_details')?.country_code) {
    countryApi()
    // }
  }, []);

  const countryApi = async () => {
    await axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        setCountryCode(response.data.country_code);
        setItem("userGeoLocation", response?.data)
        setItem("country_details", {
          country_code: response.data.country_code,
          country_name: response.data.country_name,
        });
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.info(`your country code error :---> ${error}`)
        console.error("country details error ", error);
      });
  }

  useEffect(() => {
    if (timer) {
      setItem("timer", timer.toString());
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    setItem(
      "apiLanguageSelection",
      browserLanguageCode?.includes("-")
        ? browserLanguageCode?.split("-")[0]
        : browserLanguageCode
    );
    i18n.changeLanguage(
      browserLanguageCode?.includes("-")
        ? browserLanguageCode?.split("-")[0]
        : browserLanguageCode
    );
  }, []);

  const onOtpSubmit = async (otpPayload) => {
    setOTPLoading(true);
    let payload = {
      email: otpVarificationEmail,
      otp: otpPayload.otp.join(""),
    };
    const response = await dispatch(otpVarification(payload));
    if (response.status) {
      if (response.submitted_status) {
        setItem(
          "apiLanguageSelection",
          response?.data?.language_code
            ? response?.data?.language_code
            : getItem("apiLanguageSelection")
        );
        i18n.changeLanguage(
          response?.data?.language_code
            ? response?.data?.language_code
            : getItem("apiLanguageSelection")
        );
        if (response?.data?.username !== '' &&
          response?.data?.username !== undefined &&
          response?.data?.username !== null) {
          window.location.replace("/");
        } else {
          message.warning('There is some problem Please Enter new Otp.')
        }
      } else {
        navigate(`/accountVarification`);
      }
      removeItem("ShowOtpVarification");
      removeItem("OtpVarificationEmail");
      setOTPLoading(false);
      setAutoOtpStatus(true);
      otpForm.resetFields();
      onOTPChange([]);
    } else {
      if (response.optFalse) {
        setAutoOtpStatus(false);
        setTimer(0);
        setOTPLoading(false);
        otpForm.resetFields();
        onOTPChange([]);
        setOtpVal([]);
      } else {
        setTimer(0);
        setOTPLoading(false);
        setAutoOtpStatus(true);
        onOTPChange([]);
        setOtpVal([]);
      }
    }
  };

  const onEmailSubmit = async (emailPayload) => {
    if (isApiCallInProgress) {
      // If an API call is in progress, not trigger again
      return;
    }
    setEmailLoading(true);
    setApiCallInProgress(true);


    try {
      if (emailPayload && countryCode !== null && countryCode !== undefined && countryCode !== '') {
        const payloadObj = {
          email: emailPayload.email,
          country_iso_code: countryCode !== null && countryCode !== undefined && countryCode !== ''
            ? countryCode : 'SE',
        };
        const result = await dispatch(loginEmail(payloadObj));
        if (result) {
          setOtpVarificationEmail(emailPayload.email);
          setShowOtpVarification(true);
          setItem("ShowOtpVarification", true);
          setTimer(60);
          setAutoOtpStatus(true);
          setItem("OtpVarificationEmail", emailPayload.email);
          setEmailLoading(false);
          form.resetFields();
        } else {
          setEmailLoading(false);
        }
      } else {
        setEmailLoading(false);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during loginEmail API call:", error);
    } finally {
      setApiCallInProgress(false); // Reset after the API call is complete
    }


  };

  const onOTPChange = (value) => {
    if (autoOtpStatus) {
      if (value.filter((item) => item.length !== 0)?.length == 6) {
        onOtpSubmit({ otp: value });
        setOtpVal(value);
      }
    }
  };

  const onOTPResend = async () => {
    onOTPChange([]);
    setOtpVal([]);
    if (!isSendingOTP && timer === 0) {
      setOTPLoading(true);
      let payload = {
        email: otpVarificationEmail,
      };
      const result = await dispatch(otpResend(payload));
      if (result) {
        setOTPLoading(false);
        setTimer(60);
        setAutoOtpStatus(false);
        setIsSendingOTP(true);
        otpForm.resetFields();
        setTimeout(() => {
          setIsSendingOTP(false);
        }, 2000);
        onOTPChange([]);
        setOtpVal([]);
      } else {
        setOTPLoading(false);
        onOTPChange([]);
        setOtpVal([]);
      }
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <Row justify={"center"}>
      {showOtpVarification ? (
        <Col xxl={6} xl={8} lg={10} md={12} sm={14} xs={24}>
          <div className={styles.signInContainer}>
            <div className={styles.logoHeader}>
              <SignInCommonLogo
                welcomeText={t("OTP Verification")}
                subText={t(
                  "Please enter your verification code sent to your email"
                )}
                emailId={otpVarificationEmail}
              />
            </div>
            <div>
              <div className={styles.loginFonContainer}>
                <Form
                  layout="vertical"
                  name="basic"
                  autoComplete="nope"
                  onFinish={(value) => onOtpSubmit(value)}
                  className={styles.loginForm}
                  form={otpForm}
                >
                  <Form.Item
                    name="otp"
                    validateTrigger={["onSubmit"]}
                    rules={[
                      {
                        required: true,
                        message: t("Please enter OTP!"),
                      },
                      {
                        validator: (back, value) => {
                          if (
                            value &&
                            value?.filter((item) => item?.length !== 0)
                              .length === 6
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Please enter a 6-digit OTP!");
                        },
                      },
                    ]}
                  >
                    <InputOTP
                      onChange={(value) => {
                        onOTPChange(value);
                      }}
                      value={otpVal}
                      autoFocus
                      inputType="numeric"
                      inputMode="numeric"
                      length={6}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.formSubmitBtn}
                    block
                    style={{
                      backgroundColor: otpLoading
                        ? "rgb(201 101 165)"
                        : "#E60C8F",
                      color: "white",
                    }}
                    disabled={otpLoading}
                  >
                    {otpLoading ? (
                      <Spin size="default" className="buttonLoader" />
                    ) : (
                      t("Submit")
                    )}
                  </Button>
                </Form>
              </div>

              {timer === 0 ? (
                <p className={styles.forgotPassText}>
                  <NavLink onClick={onOTPResend}>{t("Resend OTP")}</NavLink>
                </p>
              ) : (
                <>
                  <p>
                    00:{timer?.toString()?.length == 1 ? "0" : ""}
                    {timer} {t("Resend OTP")}
                  </p>{" "}
                </>
              )}

              <p className={styles.forgotPassText}>
                <NavLink
                  onClick={() => {
                    setShowOtpVarification(false);
                    removeItem("ShowOtpVarification");
                    removeItem("OtpVarificationEmail");
                    setTimer(0);
                  }}
                >
                  {t("Back to Login")}
                </NavLink>
              </p>
            </div>
          </div>
        </Col>
      ) : (
        <Col xxl={6} xl={8} lg={10} md={12} sm={14} xs={24}>
          <div className={styles.signInContainer}>
            {
              loading ? (
                <>
                  <Skeleton.Image active={true} />
                  <br />
                  <Space>
                    <Skeleton.Input
                      active={true}
                      size='default'
                      style={{ marginTop: '20px' }}
                    />
                  </Space>
                  <br />

                  <Skeleton.Input
                    active={true}
                    size='default'
                    block={true}
                    style={{ marginTop: '50px' }}
                  />
                  <Skeleton.Input
                    active={true}
                    size='default'
                    block={true}
                    style={{ marginTop: '20px' }} />

                </>
              )
                :
                (<>
                  <div className={styles.logoHeader}>
                    <SignInCommonLogo welcomeText={t("Welcome to ATZ !")} />
                  </div>
                  <div>
                    <div className={styles.loginFonContainer}>
                      <Form
                        layout="vertical"
                        name="basic"
                        autoComplete="nope"
                        form={form}
                        onFinish={(value) => onEmailSubmit(value)}
                        className={styles.loginForm}
                      >
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("Please enter your email address!"),
                            },
                            {
                              type: "email",
                              message: t("Please enter valid email address!"),
                            },
                          ]}
                        >
                          <Input size="large" placeholder={t("email")} />
                        </Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className={styles.formSubmitBtn}
                          block
                          style={{
                            backgroundColor: emailLoading
                              ? "rgb(201 101 165)"
                              : "#E60C8F",
                            color: "white",
                          }}
                          disabled={emailLoading}
                        >
                          {emailLoading ? (
                            <Spin size="default" className="buttonLoader" />
                          ) : (
                            t("Sign In")
                          )}
                        </Button>
                      </Form>
                    </div>
                  </div>
                </>
                )
            }
          </div>
        </Col>
      )}
    </Row>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default SignIn;
