import React, { useState, useRef, useEffect } from "react";
import styles from "./AddInteraction.module.css";
import {
  Form,
  Input,
  Button,
  message,
  Spin,
  Progress,
  Modal,
  Row,
  Col,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import webCam from "../../../../assets/Images/web-camera-svgrepo-com.svg";
import upload from "../../../../assets/Images/upload-svgrepo-com.svg";
import recordBtn from "../../../../assets/Images/video-svgrepo-com.svg";
import recordActiveBtn from "../../../../assets/Images/active-video-btn.svg";
import stopBtn from "../../../../assets/Images/stop-circle-svgrepo-com.svg";
import audio from "../../../../assets/Images/microphone1-svgrepo-com.svg";
import messageImg from "../../../../assets/Images/chat-svgrepo-com.svg";
import { getItem, setItem } from "../../../../utility/LocalStorageControl";
import { createInteraction } from "../../../../redux/Interaction/action";
import AudioRecord from "./AudioRecord";
import { useTranslation } from "react-i18next";
import VideoRecordImg from "../../../../assets/Images/video-camera-film-svgrepo-com.svg";
import CKEditorMsg from "../../ckEditor/CKEditorMsg";

const videoColor = {
  "0%": "#E60C8F",
  "100%": "#E60C8F",
};

function CreateContent() {
  const { t } = useTranslation();
  const useDetail = getItem("userDetails");
  const dispatch = useDispatch();
  const [formDetail, setFormDetail] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const videoRefone = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [streams, setStreams] = useState(null);
  const [contentType, setContentType] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [videoBlob, setVideoBlob] = useState(null);
  const [recording, setRecording] = useState(false);
  const [videoFileData, setVideoFileData] = useState(null);
  const [IsRodcording, setIsRodcording] = useState(false);
  const inputFile = useRef(null);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [filePreview, setFilePreview] = useState([]);
  const [disabledStop, setDisabledStop] = useState(true);
  const [disabledRecord, setDisabledRecord] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioBlob, setAudioBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timerCount, setTimerCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [count, setCount] = useState(-1);
  const [timer, setTimer] = useState();
  const [selectedCommentType, setSelectedCommentType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countPercentage, setCountPercentage] = useState(0);

  useEffect(() => {
    if (location && location?.state && location?.state?.formData) {
      setFormDetail(location && location?.state && location?.state?.formData);
    }
  }, []);

  const getVideoPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      setSelectedCommentType("video");
      setProgressCount(0);
      setCount(3);
    } catch (err) {
      if (err == "NotAllowedError: Permission denied") {
        setSelectedCommentType("PermissionBlocked");
        message.warning(t("Please enable camera & microphone permissions"));
      } else {
        message.error(t("Requested device not found"));
      }
    }
  };

  const startRecording = async () => {
    try {
      setIsRodcording(true);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (videoRefone.current) {
        videoRefone.current.srcObject = stream;
      }
      setProgressCount(0);
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        setVideoBlob(URL.createObjectURL(blob));
        setVideoFileData(blob);
      };
      mediaRecorderRef.current.start();
      setRecording(true);
      setStreams(stream);
      setDisabledRecord(true);
      setProgressCount(0);
      setDisabledStop(false);
      setRecordingStatus("recording");
    } catch (error) {
      console.error("Error accessing camera and microphone:", error);
    }
  };

  const stopRecording = async () => {
    setRecordingStatus("inactive");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      if (streams) {
        streams.getTracks().forEach((track) => track.stop());
      }
    }
    setIsRodcording(false);
    setRecording(false);
    setVideoBlob(null);
    setProgressCount(0);
    setVideoFileData(null);
    setDisabledStop(true);
    setDisabledRecord(false);
  };

  const cancelRecording = () => {
    stopRecording();
    setIsRodcording(false);
    setVideoBlob(null);
    setVideoFileData(null);
    setDisabledStop(true);
    setDisabledRecord(false);
    setIsModalOpen(false);
    setRecording(false);
    setCountPercentage(0);
    setProgressCount(0);
    setRecordingStatus("inactive");
  };

  const uploadPercentage = (val) => {
    setProgressCount(val);
  };

  // for image/ file upload
  const onChangeFile = (event) => {
    event.stopPropagation();
    setProgressCount(0);
    event.preventDefault();
    if (event.target.files && event.target.files.length) {
      if (
        event.target.files[0]["type"]?.split("/")[0] == "image" ||
        event.target.files[0]["type"]?.split("/")[0] == "video" ||
        event.target.files[0]["type"]?.split("/")[0] == "audio"
      ) {
        if (event.target.files[0]["type"]?.split("/")[0] == "image") {
          if (
            event.target.files[0]["type"]?.split("/")[1] == "png" ||
            event.target.files[0]["type"]?.split("/")[1] == "jpeg" ||
            event.target.files[0]["type"]?.split("/")[1] == "jpg"
          ) {
            setFileName(event.target.files[0]);
            setFile(event.target.files[0]);
            setContentType("image_upload");
            setFilePreview(
              <div className={styles.previewImgContainer}>
                <img
                  crossOrigin="anonymous"
                  alt=""
                  src={URL.createObjectURL(event.target.files[0])}
                />
              </div>
            );
          } else {
            message.error(t("Please select jpg or png image"));
          }
        } else if (event.target.files[0]["type"]?.split("/")[0] == "video") {
          setFileName(event.target.files[0]);
          setFile(event.target.files[0]);
          setContentType("video_upload");
          setFilePreview(
            <div className={styles.previewImgContainer}>
              <video
                playsInline
                autoPlay
                preload="true"
                src={URL.createObjectURL(event.target.files[0])}
              />
            </div>
          );
        } else if (event.target.files[0]["type"]?.split("/")[0] == "audio") {
          setFileName(event.target.files[0]);
          setFile(event.target.files[0]);
          setContentType("audio_upload");
          setFilePreview(
            <div className={styles.uploadAudioContainer}>
              <audio
                alt=""
                controls
                src={URL.createObjectURL(event.target.files[0])}
              />
            </div>
          );
        }
      } else {
        message.error(t("Please select image or video or audio"));
      }
    }
  };

  const removeImg = () => {
    setFile([]);
    setFilePreview([]);
    setFileName("");
    setProgressCount(0);
  };

  const removeAudio = () => {
    //
  };

  const audioFile = (file) => {
    setAudioBlob(file);
    setProgressCount(0);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const form_data = new FormData();
    form_data.append("business_id", useDetail?._id);
    form_data.append("title", formDetail?.title ? formDetail?.title : "");
    form_data.append(
      "description",
      formDetail?.description ? formDetail?.description : ""
    );
    form_data.append("is_viewers_reply", formDetail?.is_viewers_reply);
    form_data.append("content_type", contentType);
    form_data.append(
      "short_url",
      formDetail?.short_url ? formDetail?.short_url : ""
    );
    form_data.append("cta_link_enable", formDetail?.cta_link_enable);
    if (formDetail?.cta_links) {
      form_data.append("cta_links", JSON.stringify(formDetail?.cta_links));
    }
    if (
      (contentType == "video_upload" ||
        contentType == "image_upload" ||
        contentType == "audio_upload") &&
      file
    ) {
      form_data.append("upload_content", file);
    } else if (contentType == "text") {
      form_data.append("upload_content", textMessage);
    } else if (contentType == "audio_record") {
      form_data.append("upload_content", audioBlob);
    } else if (contentType == "video_record" && videoBlob) {
      setIsRodcording(false);
      form_data.append("upload_content", videoFileData);
    } else {
      message.warning(t("Please add content"));
    }
    const newInteraction = await dispatch(
      createInteraction(form_data, uploadPercentage)
    );
    if (newInteraction) {
      setLoading(false);
      setVideoFileData(null);
      navigate("/interaction");
      setContentType("");
      setFile();
      setFileName("");
      setTextMessage("");
      setProgressCount(0);
      uploadSuccessFully();
      setCountPercentage(0);
    }
  };
  const uploadSuccessFully = () => {
    setProgressCount(0);
    navigate("/interaction");
  };

  // count down
  useEffect(() => {
    if (count > -1) {
      const countdownInterval = setInterval(() => {
        setCount(count - 1);
      }, 1500);
      if (count == 4) {
        setCountPercentage(0);
      } else if (count == 3) {
        setCountPercentage(33);
      } else if (count == 2) {
        setCountPercentage(66);
      } else if (count == 1) {
        setCountPercentage(100);
      }
      if (count == 0) {
        startCountdown();
      }
      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [count]);

  const startCountdown = () => {
    startRecording();
  };

  useEffect(() => {
    if (timer) {
      setItem("timer", timer.toString());
      const interval = setInterval(() => {
        if (timer > -1) {
          setTimer(timer - 1);
        }
      }, 1000);
    }
  }, [timer]);

  // delete file modal
  const handleCancel = () => {
    setIsModalOpen(false);
    setProgressCount(0);
    setCountPercentage(0);
  };

  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  // onChange={(value) => {
  //   setTextMessage(value.target.value);
  // }}

  const handleEditorChange = (value) => {
    setTextMessage(value.target.value);
  };

  return (
    <>
      <div className={styles.createVideoContainer} ref={ref}>
        <div
          className={styles.backBtn}
          onClick={() => navigate("/add-interaction")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="rgb(206 19 119 / 54%)"
            height="inherit"
            width="inherit"
            style={{
              objectFit: "cover",
            }}
            version="1.1"
            id="Layer_1"
            viewBox="0 0 512 512"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <g>
                  {" "}
                  <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>
        </div>

        <div className={styles.componentBox}>
          <div
            className={styles.createMainContainer}
            style={{
              minHeight:
                width < 992 ? (contentType == "" ? "70vh" : "0vh") : "70vh",
              height:
                width < 992 ? (contentType !== "" ? "80px" : "auto") : "auto",
            }}
          >
            <h2>{t("How would you like to create this step?")}</h2>
            <Form
              layout="vertical"
              autoComplete="nope"
              form={form}
              className={styles.createVideoForm}
            >
              <div className={styles.uploadOptionsContainer}>
                <div className={styles.uploadOptionCardGrid}>
                  {width < 992 ? (
                    contentType == "" ? (
                      <>
                        {" "}
                        <div
                          className={
                            contentType == "" || contentType == "video_record"
                              ? `${styles.uploadCard}`
                              : `${styles.disableUploadCard}`
                          }
                          onClick={() => {
                            setContentType("video_record");
                          }}
                        >
                          <div
                            className={
                              contentType == "" || contentType == "video_record"
                                ? `${styles.optionImgContainer}`
                                : `${styles.disableImgContainer}`
                            }
                          >
                            <div className={styles.optionImg}>
                              <img src={webCam} />
                            </div>
                          </div>
                          <p>{t("Video Recording")}</p>
                        </div>
                        <div
                          className={
                            contentType == "" ||
                              contentType == "image_upload" ||
                              contentType == "audio_upload" ||
                              contentType == "video_upload"
                              ? `${styles.uploadCard}`
                              : `${styles.disableUploadCard}`
                          }
                          onClick={() => {
                            setContentType("image_upload");
                          }}
                        >
                          <div
                            className={
                              contentType == "" ||
                                contentType == "image_upload" ||
                                contentType == "audio_upload" ||
                                contentType == "video_upload"
                                ? `${styles.optionImgContainer}`
                                : `${styles.disableImgContainer}`
                            }
                          >
                            <div className={styles.optionImg}>
                              <img src={upload} />
                            </div>
                          </div>
                          <p>{t("Upload")}</p>
                        </div>
                        <div
                          className={
                            contentType == "" || contentType == "audio_record"
                              ? `${styles.uploadCard}`
                              : `${styles.disableUploadCard}`
                          }
                          onClick={() => {
                            setContentType("audio_record");
                            setTimerCount(-1);
                          }}
                        >
                          <div
                            className={
                              contentType == "" || contentType == "audio_record"
                                ? `${styles.optionImgContainer}`
                                : `${styles.disableImgContainer}`
                            }
                          >
                            <div className={styles.optionImg}>
                              <img src={audio} />
                            </div>
                          </div>
                          <p>{t("Audio Recording")}</p>
                        </div>
                        <div
                          className={
                            contentType == "" || contentType == "text"
                              ? `${styles.uploadCard}`
                              : `${styles.disableUploadCard}`
                          }
                          onClick={() => {
                            setContentType("text");
                          }}
                        >
                          <div
                            className={
                              contentType == "" || contentType == "text"
                                ? `${styles.optionImgContainer}`
                                : `${styles.disableImgContainer}`
                            }
                          >
                            <div className={styles.optionImg}>
                              <img src={messageImg} />
                            </div>
                          </div>
                          <p>{t("Message")}</p>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    <>
                      {" "}
                      <div
                        className={
                          contentType == "" || contentType == "video_record"
                            ? `${styles.uploadCard}`
                            : `${styles.disableUploadCard}`
                        }
                        onClick={() => {
                          setContentType("video_record");
                        }}
                      >
                        <div
                          className={
                            contentType == "" || contentType == "video_record"
                              ? `${styles.optionImgContainer}`
                              : `${styles.disableImgContainer}`
                          }
                        >
                          <div className={styles.optionImg}>
                            <img src={webCam} />
                          </div>
                        </div>
                        <p>{t("Video Recording")}</p>
                      </div>
                      <div
                        className={
                          contentType == "" ||
                            contentType == "image_upload" ||
                            contentType == "audio_upload" ||
                            contentType == "video_upload"
                            ? `${styles.uploadCard}`
                            : `${styles.disableUploadCard}`
                        }
                        onClick={() => {
                          setContentType("image_upload");
                        }}
                      >
                        <div
                          className={
                            contentType == "" ||
                              contentType == "image_upload" ||
                              contentType == "audio_upload" ||
                              contentType == "video_upload"
                              ? `${styles.optionImgContainer}`
                              : `${styles.disableImgContainer}`
                          }
                        >
                          <div className={styles.optionImg}>
                            <img src={upload} />
                          </div>
                        </div>
                        <p>{t("Upload")}</p>
                      </div>
                      <div
                        className={
                          contentType == "" || contentType == "audio_record"
                            ? `${styles.uploadCard}`
                            : `${styles.disableUploadCard}`
                        }
                        onClick={() => {
                          setContentType("audio_record");
                          setTimerCount(-1);
                        }}
                      >
                        <div
                          className={
                            contentType == "" || contentType == "audio_record"
                              ? `${styles.optionImgContainer}`
                              : `${styles.disableImgContainer}`
                          }
                        >
                          <div className={styles.optionImg}>
                            <img src={audio} />
                          </div>
                        </div>
                        <p>{t("Audio Recording")}</p>
                      </div>
                      <div
                        className={
                          contentType == "" || contentType == "text"
                            ? `${styles.uploadCard}`
                            : `${styles.disableUploadCard}`
                        }
                        onClick={() => {
                          setContentType("text");
                        }}
                      >
                        <div
                          className={
                            contentType == "" || contentType == "text"
                              ? `${styles.optionImgContainer}`
                              : `${styles.disableImgContainer}`
                          }
                        >
                          <div className={styles.optionImg}>
                            <img src={messageImg} />
                          </div>
                        </div>
                        <p>{t("Message")}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
          </div>

          <div
            className={styles.RecorderContentPreviewVox}
            style={{
              display:
                width < 992 ? (contentType == "" ? "none" : "block") : "block",
            }}
          >
            {contentType == "video_record" ? (
              <>
                {selectedCommentType == "PermissionBlocked" ? (
                  <div className={styles.permissionBlockedContainer}>
                    <div className={styles.HeaderInteractionContainer}>
                      <div
                        className={styles.cancelTextDiv}
                        onClick={() => {
                          setSelectedCommentType("");
                        }}
                      >
                        <FeatherIcon
                          icon="x-circle"
                          style={{ color: "#631deb" }}
                          size={20}
                        />
                      </div>
                    </div>
                    <div className={styles.blockedPermissionInfo}>
                      <h3>
                        {t("Please enable camera")} &amp;{" "}
                        {t("microphone permissions")}
                      </h3>
                      <p className={styles.message_one}>
                        {t("(You may have accidentally said no)")}
                      </p>
                      <h4>{t("How to fix?")}</h4>
                      <p className={styles.fixedMessage}>
                        {t(
                          "Click the 🔒lock icon in your browser's address bar, set the correct permissions and then refresh this page."
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {videoBlob && !recording ? (
                      <>
                        <div className={styles.videoDiv}>
                          <video src={videoBlob} playsInline autoPlay />
                        </div>
                      </>
                    ) : IsRodcording ? (
                      <>
                        <div className={styles.videoDiv}>
                          <video ref={videoRefone} autoPlay playsInline></video>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.videoRecordFirstDiv}>
                          <div className={styles.videoRecordImg}>
                            <img src={VideoRecordImg}></img>
                          </div>
                          {count > 0 && recordingStatus == "inactive" ? (
                            <div className={styles.videoCountDownContainer}>
                              <Progress
                                type="circle"
                                size={150}
                                className={styles.recordingCountDownProgress}
                                percent={countPercentage}
                                strokeColor={videoColor}
                                format={() => {
                                  return countPercentage <= 33 ? (
                                    <p className={"videoCountNum"}>3</p>
                                  ) : countPercentage <= 66 ? (
                                    <p className={"videoCountNum"}>2</p>
                                  ) : countPercentage <= 99 ? (
                                    <p className={"videoCountNum"}>1</p>
                                  ) : (
                                    <p className={"videoCountNum"}>1</p>
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}

                    <div className={styles.recordingGroupBtn}>
                      {recording && recordingStatus == "recording" ? (
                        <>
                          <button
                            id="record"
                            onClick={startRecording}
                            disabled={disabledRecord}
                            className={styles.activeBtn}
                          >
                            <div className={styles.btnIcon}>
                              <img src={recordActiveBtn} />
                            </div>
                            <span>{t("Recording")}</span>
                          </button>
                          <button
                            id="stopRecord"
                            onClick={stopRecording}
                            disabled={disabledStop}
                            className={styles.PermissionBtn}
                          >
                            <div className={styles.btnIcon}>
                              <img src={stopBtn} />
                            </div>
                            <span>{t("Stop")}</span>
                          </button>
                        </>
                      ) : recordingStatus == "inactive" && videoBlob == null ? (
                        <>
                          <button
                            id="record"
                            onClick={getVideoPermission}
                            disabled={disabledRecord}
                            className={styles.PermissionBtn}
                          >
                            <div className={styles.btnIcon}>
                              <img src={recordBtn} />
                            </div>
                            <span>{t("Start Recording")}</span>
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {videoBlob && !recording ? (
                      <div className={styles.removeRecording}>
                        <FeatherIcon
                          icon="trash"
                          style={{ cursor: "pointer" }}
                          size={20}
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            ) : contentType == "image_upload" ||
              contentType == "audio_upload" ||
              contentType == "video_upload" ? (
              <div className={styles.imageDiv}>
                {filePreview?.length == 0 ? (
                  <div
                    className={styles.uploadFileContainer}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <FeatherIcon icon="plus" size={30} />
                    <p> {t("choose file")}</p>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={onChangeFile.bind(this)}
                    />
                  </div>
                ) : (
                  filePreview
                )}
                {fileName.length !== 0 && (
                  <div className={styles.fileInfo}>
                    <p>
                      {fileName?.name.length > 70
                        ? fileName?.name.slice(0, 70) +
                        "..." +
                        fileName?.name.split(".").pop()
                        : fileName?.name}
                    </p>
                    <div className={styles.removeUploadFile}>
                      <FeatherIcon
                        style={{ cursor: "pointer" }}
                        className={styles.removeContent}
                        icon="trash"
                        size={20}
                        onClick={removeImg}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : contentType == "audio_record" ? (
              <div className={styles.audioDiv}>
                <AudioRecord
                  removeAudio={removeAudio}
                  audioFile={audioFile}
                  timerCount={timerCount}
                />
              </div>
            ) : contentType == "text" ? (
              // <div className={styles.audioDiv}>
              //   <Form layout="vertical" autoComplete="nope" form={form}>
              //     <Form.Item name="message">
              //       <TextArea
              //         className={styles.messageInputFields}
              //         placeholder={t("enter_your_text") + "..."}
              //         bordered={false}
              //         onChange={(value) => {
              //           setTextMessage(value.target.value);
              //         }}
              //       />
              //     </Form.Item>
              //   </Form>
              // </div>
              <div className="CKEditorDiv">
                <CKEditorMsg
                  isConfig={true}
                  onChange={(data) => {
                    setTextMessage(data);
                  }}
                />
              </div>
            ) : (
              <>
                {width < 992 ? (
                  contentType == "" ? (
                    ""
                  ) : (
                    ""
                  )
                ) : (
                  <div className={styles.defaultContent}>
                    <h3>{t("No Option selected")}</h3>
                  </div>
                )}
              </>
            )}

            {progressCount > 0 && (
              <div className={styles.progressCircle}>
                <Progress
                  type="circle"
                  percent={progressCount}
                  strokeColor="#E60C8F"
                />
              </div>
            )}

            <div className={styles.blankInteractionFooter}></div>
            {contentType !== "" ? (
              <div className={styles.createBtnContainer}>
                <Button
                  className={styles.cancelBtn}
                  onClick={() => {
                    setTextMessage("");
                    setContentType("");
                    setFilePreview([]);
                    setFile();
                    setFileName("");
                    setAudioBlob(null);
                    form.resetFields();
                    setRecording(false);
                    setCountPercentage(0);
                    setRecordingStatus("inactive");
                    setVideoBlob(null);
                  }}
                  disabled={loading}
                >
                  <div className={styles.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button
                  className={
                    textMessage !== "" ||
                      filePreview.length !== 0 ||
                      videoBlob !== null ||
                      audioBlob !== null
                      ? `${styles.createBtn}`
                      : `${styles.disableUpload}`
                  }
                  htmlType="submit"
                  onClick={() => {
                    if (progressCount > 0) {
                    } else {
                      handleSubmit();
                    }
                  }}
                  disabled={
                    textMessage !== "" ||
                      filePreview.length !== 0 ||
                      videoBlob !== null ||
                      audioBlob !== null
                      ? false
                      : true
                  }
                >
                  <div className={styles.buttonContent}>
                    {loading ? (
                      <Spin size="small" className="whiteSpin" />
                    ) : (
                      t("Upload")
                    )}
                  </div>
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>

          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            centered={true}
            footer={false}
            width={500}
          >
            <div className={styles.signOutConfirm}>
              <h3>{t("Are your sure you wants to delete this recording")}?</h3>
              <div className={styles.modalBtnContainer}>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={24} xxl={12}>
                    <Button
                      className={styles.premiumBtn}
                      onClick={cancelRecording}
                    >
                      <div className={styles.buttonContent}>{t("Yes")}</div>
                    </Button>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24} xxl={12}>
                    <div className={styles.premiumBtnContainer}>
                      <Button
                        className={styles.premiumBtn}
                        onClick={handleCancel}
                      >
                        <div className={styles.buttonContent}>{t("No")}</div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default CreateContent;
