import React from "react";
import styles from "./userBooking.module.css";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Calendars from "./calendar/Calendar";

function UsersBooking() {
  const { t } = useTranslation();

  return (
    <div className={styles.calendarDetailContainer}>
      <div className={styles.calendarHeader}>
        <h1>{t("User Booking List")}</h1>
      </div>
      <Row>
        <Col span={24}>
          <div className={styles.CalendarContainer}>
            <Calendars />
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default UsersBooking;
