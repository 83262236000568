// ALL API END POINTS OBJECT SET-UP
const API = {
  // DEFINE AUTHENTICATION API ROUTE
  auth: {
    login: "/users/register",
    verifyOtp: "/users/verify-user",
    validUserName: "/users/isuserexist/",
    resendOTP: "/users/resend-otp",
    accountVarificationUserName: "users/update_details/",
    check_pwd: "/check_pwd",
    change_pwd: "/change_pwd",
    updateLanguage: "/ministra/updateSyncStatus",
    verify_adult_password: "/ministra/verifyAdultPassword",
    update_adult_password: "/ministra/updateAdultPassword",
    create_adult_password: "/ministra/createAdultPassword",
    forgetPassword: "/forget_pwd",
    resetPassword: "/reset_pwd",
    profile: "/profile",
    countryList: "/users/countryList",
  },
  dashboard: {
    allMedia: "/ministra/dashboard",
    mediaFetched: "/MEDIA_FETCHED",
  },
  integration: {
    allList: "/interaction/list",
    detail: "interaction/details",
    create: "/interaction/create",
    update: "/interaction/update/",
    delete: "/interaction/delete/",
    reactionList: "/interaction_response/list",
    myreactionList: "/interaction_response/user_replies",
    reply: "/interaction_response/interaction_reply/",
    responseDetail: "/interaction_response/details",
    getShortURL: "/shortUrl/checkUrl/",
  },
  endUserInteraction: {
    details: "/interaction/interaction_details/",
    create: "/interaction_response/create",
    reaction: "/interaction_response/interaction_reaction/",
    ResponseChatDetail: "/interaction_response/user_business_responses",
  },
  profile: {
    update: "/users/update_details/",
    getUserDetail: "/users/user_details/",
  },
  subscription: {
    list: "business_subscription/subscription_list?country=",
    purchaseForIndia:
      "business_subscription/purchase_business_subscription_for_india",
  },
  businessUser: {
    businessDetail: "business_user/business_user_details",
    updateBusinessUser: "business_user/business_user_detail_update",
  },
  qrPayment: {
    qrList: "qr_payment/list",
    qrDetail: "qr_payment/details/",
    createQrPayment: "qr_payment/create",
    resetQrPayment: "qr_payment/reset_qr/",
    qrPaymentHistory: "qr_payment_history/list",
    allPaymentHistory: "stripe_transaction/list",
    editQrPayment: "qr_payment/update/",
    getReceipt: "stripe_transaction/receipt/",
    deleteQr: "qr_payment/delete/",
  },
  queue: {
    typeQueue: "/typeQueue",
    queueList: "/queue/list",
    queueRegister: "/queue/register",
    queueDelete: "/queue/delete/",
    queueUpdate: "/queue/update/",
    getPeopleQueue: "/queueUsers/list/",
    getQueueDetails: "/queue/get/",
    joinQueue: "/queueUsers/join-queue/",
    doneQueue: "/queueUsers/requestDone/",
    rejoinQueue: "/queueUsers/all-user/",
    queueReview: "/smartQueueReviews/create",
    editQuestion: "/queueUsers/update-join-queue/",
    sendMessaga: "/whatsapp/sendMessage",
    getQrCode: "/whatsapp/getQRCode",
    viewReview: "/smartQueueReviews/list/",
  },
  myBooking: {
    list: "booking_purchase/list_filters",
    details: "booking_purchase/details/",
    checkInBooking: "booking_purchase/check-in-booking",
    refundRequest: "booking_purchase/refund_request_business/"
  },
  Booking: {
    bookingList: "booking/list-pagination",
    bookingDetail: "booking/details/",
    createBooking: "booking/create",
    editBooking: "booking/update/",
    categoryList: "category_subCategory/list",
    getByDetailFilter: "booking/get_by_date",
    addSlot: "booking/add_slot",
    editDeleteSlot: "booking/time_slot_update_delete",
    deleteBooking: "booking/delete/",
    cancelBookingAdminSide: "booking_purchase/cancel_purchase/",
    refundPurchase: "booking_purchase/refund-purchase-amount",
    endPackage: "booking_purchase/cancel_booking",
    cancelBookingUserSide: "booking_purchase/user_cancel_request/"
  },
  usersBooking: {
    usersBookingList: 'booking_purchase/list',
    usersBookingDetail: 'booking_purchase/details/',
  }
};

// EXPORT API ROUTES OBJECT DEFINE
export { API };
