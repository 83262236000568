import React, { useState, useRef } from "react";
import styles from "./SignUp.module.css";
import { Form, Input, Button, Divider, Avatar, message, Spin } from "antd";
import { useDispatch } from "react-redux";
import { updateBusinessAccountDetail } from "../../redux/business/action";
import { getItem, removeItem } from "../../utility/LocalStorageControl";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useTranslation } from "react-i18next";
import { updateReduxUserNavigateDetails } from "../../redux/authentication/action";
import Cookies from "js-cookie";

function BusinessDetailForm() {
  const { t } = useTranslation();
  const userDetail = getItem("userDetails");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [accountDetailForm, setAccountDetailForm] = useState(false);
  const [previewMedia, setPreviewMedia] = useState([]);
  const [img, setImg] = useState([]);
  const inputFile = useRef(null);
  const [vatId, setVatId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [orgNum, setOrgNum] = useState("");
  const [registrationNum, setRegistrationNum] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [loginLoading, setLoginLoading] = useState(false)
  const handleSubmit = async (value) => {
    setLoginLoading(true)
    let form_Data = new FormData();
    if (img !== "") {
      form_Data.append("business_image", img);
    }
    if (businessPhone) {
      form_Data.append("phone_number", businessPhone.toString());
    }
    form_Data.append("registration_number", registrationNum);
    form_Data.append("username", userDetail?.username);
    form_Data.append("name", userDetail?.name);
    form_Data.append("email", userDetail?.email);
    form_Data.append("companyName", companyName);
    form_Data.append("organizationNumber", orgNum);
    form_Data.append("VATId", vatId);
    form_Data.append("user_id", userDetail?._id);
    const response = await dispatch(updateBusinessAccountDetail(form_Data));
    if (!response?.data?.data?.error) {
      form.resetFields();
      Cookies.set("logedIn", true);
      window.location.replace("/");
      setLoginLoading(false)
      // await dispatch(updateReduxUserNavigateDetails(getItem("userDetails")));
    }
  };

  const changeHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files[0]) {
      if (e.target.files[0]["type"]?.split("/")[0] == "image") {
        setImg(e.target.files[0]);
        setPreviewMedia(
          <div className={styles.previewImgContainer}>
            <img
              crossOrigin="anonymous"
              alt=""
              src={URL.createObjectURL(e.target.files[0])}
            />
          </div>
        );
      } else {
        message.error(t("please select image"));
      }
    }
  };

  const onSkipPage = async () => {
    // await dispatch(updateReduxUserNavigateDetails(getItem("userDetails")))
    Cookies.set("logedIn", true);
    removeItem("AccountDetailsFilled");
    window.location.replace("/");

  }

  return (
    <Form
      layout="vertical"
      name="basic"
      autoComplete="nope"
      colon={true}
      form={form}
    >
      {
        loginLoading ?
          <div className={styles.loaderBox}>
            <Spin size='large' />
          </div>
          :
          (accountDetailForm == true ? (
            <>
              <div
                className={styles.skipBtn}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <span
                  onClick={() => {
                    setAccountDetailForm(false);
                    form.resetFields();
                  }}
                >
                  {" "}
                  {t("Back")}
                </span>
                <span
                  onClick={() => {
                    onSkipPage();
                  }}
                >
                  {t("Skip")}
                </span>
              </div>
              <div className={styles.userImg}>
                {Object.keys(previewMedia).length !== 0 ? (
                  previewMedia
                ) : (
                  <Avatar
                    size={130}
                    icon={<FeatherIcon icon="image" size={40} />}
                  />
                )}
                <div
                  className={styles.editImg}
                  onClick={() => {
                    inputFile.current.click();
                  }}
                >
                  {Object.keys(previewMedia).length !== 0 ? (
                    <FeatherIcon icon="edit" size={18} />
                  ) : (
                    <FeatherIcon icon="plus" size={18} />
                  )}
                </div>
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={changeHandler.bind(this)}
              />
              <Form.Item label={t("Company Name")} name="companyName">
                <Input
                  placeholder={t("Company Name")}
                  className={styles.userNameInput}
                  style={{ height: "50px" }}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item label={t("mobile number")} name="businessMobile">
                <Input
                  style={{ height: "52px" }}
                  type="tel"
                  className={styles.userNameInput}
                  maxLength={10}
                  placeholder={t("mobile number")}
                  value={businessPhone}
                  onChange={(e) => {
                    setBusinessPhone(e.target.value.slice(0, 10));
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "." || event.key === "-" || event.key == "e") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label={t("Registration Number")}
                name="Registration Number"
              >
                <Input
                  placeholder={t("Registration Number")}
                  className={styles.userNameInput}
                  style={{ height: "50px" }}
                  onChange={(e) => {
                    setRegistrationNum(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label={t("organization number")} name="organizationNumber">
                <Input
                  className={styles.userNameInput}
                  style={{ height: "52px" }}
                  type="tel"
                  placeholder={t("organization number")}
                  onChange={(e) => {
                    setOrgNum(e.target.value);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "." || event.key === "-" || event.key == "e") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label={t("vat id")} name="VATId">
                <Input
                  className={styles.userNameInput}
                  style={{ height: "52px" }}
                  placeholder={t("vat id")}
                  onChange={(e) => {
                    setVatId(e.target.value);
                  }}
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className={styles.formSubmitBtn}
                onClick={(e) => {
                  if (loginLoading == false) {
                    handleSubmit();
                  } else {
                    e.preventDefault()
                  }
                }}
                block
              >
                {loginLoading ?
                  <Spin size='small' />
                  : t("Submit")
                }
              </Button>
            </>
          ) : (
            <>
              <div
                className={styles.skipBtn}
                onClick={() => {
                  onSkipPage();
                }}
                style={{
                  justifyContent: "end",
                }}
              >
                {t("Skip")}
              </div>
              <div className={styles.addAccountOptionContainer}>
                <div
                  className={styles.accountTypeBtn}
                  onClick={() => {
                    window.open(getItem("userDetails")?.stripe_connect_url);
                  }}
                >
                  {t("Connect stripe account")}
                </div>
                <Divider>Or</Divider>
                <div
                  className={styles.accountTypeBtn}
                  style={{ marginTop: "30px" }}
                  onClick={() => setAccountDetailForm(true)}
                >
                  {t("Business Account")}
                </div>
              </div>
            </>
          ))
      }

    </Form>
  );
}

export default BusinessDetailForm;
