import React from "react";
import "./ListShowModal.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
const ListShowModal = ({ selectedBookingId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="listingContainer">
        <div className="listingDivHeader">
          <span>{t("Title")}</span>
          <span>{t("Status")}</span>
          <span>{t("Time")}</span>
          <span>{t("Action")}</span>
        </div>
        <div className="listingDivContent">
          {selectedBookingId?.map((value, index) => {
            return (
              <div key={index} className="listingDiv">

                <Tooltip placement="top"
                  title={value?.title}
                >
                  <span>{value?.title.length > 20 ? value?.title.slice(0, 20) + '...' : value?.title}</span>
                </Tooltip>
                <span>
                  {
                    value?.label == "upcoming" ? "Upcoming"
                      : value?.label == "expired" ? "Expired"
                        : value?.label == "active" ? "Active"
                          : value?.label == "refund_request" ? "Refund Request"
                            : value?.label == "cancelled" ? "cancelled"
                              : value?.label == "cancel_request" ? "Cancelled Request"
                                : value?.label == "refunded" ? "Refunded"
                                  : value?.label == "completed" ? "Completed"
                                    : value?.label
                  }
                </span>
                <span>{value?.booked_time_slot}</span>
                <span>
                  <div
                    onClick={() => {
                      navigate(`/calendar-detail/${value?._id}`, {
                        replace: true,
                        state: {
                          pathname: location?.pathname,
                        },
                      });
                    }}
                  >
                    <FeatherIcon icon={"eye"} size={18} /> Details
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ListShowModal;
