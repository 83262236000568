import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEng from "./components/languages/en_translation.json";
import translationSwe from "./components/languages/sv_translation.json";
import translationHin from "./components/languages/hn_translation.json";
import translationGu from "./components/languages/gu_translation.json";
import translationaf from "./components/languages/translation_af.json";
import translationar from "./components/languages/translation_ar.json";
import translationbe from "./components/languages/translation_be.json";
import translationbg from "./components/languages/translation_bg.json";
import translationcs from "./components/languages/translation_cs.json";
import translationca from "./components/languages/translation_ca.json";
import translationda from "./components/languages/translation_da.json";
import translationde from "./components/languages/translation_de.json";
import translationel from "./components/languages/translation_el.json";
import translationeo from "./components/languages/translation_eo.json";
import translationes from "./components/languages/translation_es.json";
import translationet from "./components/languages/translation_et.json";
import translationfa from "./components/languages/translation_fa.json";
import translationfi from "./components/languages/translation_fi.json";
import translationfr from "./components/languages/translation_fr.json";
import translationgl from "./components/languages/translation_gl.json";
import translationhe from "./components/languages/translation_he.json";
import translationhr from "./components/languages/translation_hr.json";
import translationhu from "./components/languages/translation_hu.json";
import translationhy from "./components/languages/translation_hy.json";
import translationid from "./components/languages/translation_id.json";
import translationis from "./components/languages/translation_is.json";
import translationit from "./components/languages/translation_it.json";
import translationja from "./components/languages/translation_ja.json";
import translationko from "./components/languages/translation_ko.json";
import translationmi from "./components/languages/translation_mi.json";
import translationnl from "./components/languages/translation_nl.json";
import translationpa from "./components/languages/translation_pa.json";
import translationpl from "./components/languages/translation_pl.json";
import translationpt from "./components/languages/translation_pt.json";
import translationro from "./components/languages/translation_ro.json";
import translationru from "./components/languages/translation_ru.json";
import translationsa from "./components/languages/translation_sa.json";
import translationsv from "./components/languages/translation_sv.json";
import translationta from "./components/languages/translation_ta.json";
import translationte from "./components/languages/translation_te.json";
import translationth from "./components/languages/translation_th.json";
import translationtl from "./components/languages/translation_tl.json";
import translationtr from "./components/languages/translation_tr.json";
import translationur from "./components/languages/translation_ur.json";
import translationvi from "./components/languages/translation_vi.json";
import translationzh from "./components/languages/translation_zh.json";

const storedLang = localStorage.getItem("apiLanguageSelection");
const browserLanguageCode = navigator.language || navigator.userLanguage;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: storedLang,
    fallbackLng: browserLanguageCode, // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        translations: translationEng,
      },
      hi: {
        translations: translationHin,
      },
      gu: {
        translations: translationGu,
      },
      ar: {
        translations: translationar,
      },
      af: {
        translations: translationaf,
      },
      be: {
        translations: translationbe,
      },
      bg: {
        translations: translationbg,
      },
      cs: {
        translations: translationcs,
      },
      ca: {
        translations: translationca,
      },
      da: {
        translations: translationda,
      },
      de: {
        translations: translationde,
      },
      el: {
        translations: translationel,
      },
      eo: {
        translations: translationeo,
      },
      es: {
        translations: translationes,
      },
      et: {
        translations: translationet,
      },
      fa: {
        translations: translationfa,
      },
      fi: {
        translations: translationfi,
      },
      fr: {
        translations: translationfr,
      },
      gl: {
        translations: translationgl,
      },
      he: {
        translations: translationhe,
      },
      hr: {
        translations: translationhr,
      },
      hu: {
        translations: translationhu,
      },
      hy: {
        translations: translationhy,
      },
      id: {
        translations: translationid,
      },
      is: {
        translations: translationis,
      },
      it: {
        translations: translationit,
      },
      ja: {
        translations: translationja,
      },
      ko: {
        translations: translationko,
      },
      mi: {
        translations: translationmi,
      },
      nl: {
        translations: translationnl,
      },
      pa: {
        translations: translationpa,
      },
      pl: {
        translations: translationpl,
      },
      pt: {
        translations: translationpt,
      },
      ro: {
        translations: translationro,
      },
      ru: {
        translations: translationru,
      },
      sa: {
        translations: translationsa,
      },
      sv: {
        translations: translationsv,
      },
      ta: {
        translations: translationta,
      },
      te: {
        translations: translationte,
      },
      th: {
        translations: translationth,
      },
      tl: {
        translations: translationtl,
      },
      tr: {
        translations: translationtr,
      },
      ur: {
        translations: translationur,
      },
      vi: {
        translations: translationvi,
      },
      zh: {
        translations: translationzh,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    storedLang,
  });

i18n.changeLanguage(storedLang);

export default i18n;
