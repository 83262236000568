import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import {
  Button,
  Calendar,
  Card,
  Col,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CalenDar from "react-calendar";
import moment from "moment";
import { useDispatch } from "react-redux";
import { BlockViewCalendarWrapper } from "../Style";
import "./style.css";
import ListShowModal from "./ListShowModal";
import { useTranslation } from "react-i18next";
import { getUsersBookingList } from "../../../../../redux/userBooking/action";

const MonthCalendar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookingList, setBookingList] = useState([]);

  useEffect(() => {
    userBookingListData(
      moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD"),
      moment(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ).format("YYYY-MM-DD")
      , ''
    );
  }, []);

  const userBookingListData = async (start_date, end_date, status) => {
    const dataList = await dispatch(getUsersBookingList(start_date, end_date, status));
    if (dataList) {
      let output = [];
      dataList?.data?.map((item) => {
        const bookingId = item.booking_id._id;
        const _id = item._id;
        let bookedTimeSlot = item.booked_time_slot;
        const bookingTitle = item.booking_id.title;
        const bookingStatus = item.status;
        let startDate = moment(item.start_date).format("MM/DD/YYYY");
        let endDate = moment(item.end_date).format("MM/DD/YYYY");

        if (bookedTimeSlot.length) {
          bookedTimeSlot = bookedTimeSlot[0].split("-");
        } else {
          bookedTimeSlot = ["", ""];
        }
        let checkValueExist = output.findIndex(
          (e) =>
            e.bookingId == bookingId &&
            e.date[0] == startDate &&
            e.date[1] == endDate
        );
        if (checkValueExist >= 0) {
          output[checkValueExist].count++;
          output[checkValueExist].data.push({
            _id: _id,
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            start_date: item.start_date,
            end_date: item.end_date,
            booked_time_slot:
              bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                ? `${moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                  "HH:mm"
                )}-${moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                  "HH:mm"
                )}`
                : "-",
          });
        } else {
          output.push({
            date: [startDate, endDate],
            time:
              bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                ? [
                  moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                    "HH:mm"
                  ),
                  moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                    "HH:mm"
                  ),
                ]
                : ["", ""],
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            count: 1,
            data: [
              {
                _id: _id,
                title: bookingTitle,
                label: bookingStatus,
                bookingId: bookingId,
                start_date: item.start_date,
                end_date: item.end_date,
                booked_time_slot:
                  bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                    ? `${moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                      "HH:mm"
                    )}-${moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                      "HH:mm"
                    )}`
                    : "-",
              },
            ],
          });
        }
      });
      // console.log("Month Calendar Data", output);
      setBookingList(output);
    }
  };

  const [state, setState] = useState({
    date: new Date(),
    container: null,
    currentLabel: moment().format("MMMM YYYY"),
    width: 0,
    defaultValue: moment().format("YYYY-MM-DD"),
  });

  const { container, currentLabel, width } = state;
  const getInput = useRef();

  function getListData(value) {
    if (bookingList && bookingList?.length > 0) {
      if (bookingList && bookingList?.length > 0) {
        let listData;
        const calenderData = [];
        bookingList?.map((event) => {
          if (moment(event.date[0]).format("MMMM YYYY") === currentLabel) {
            const { count, date, label, time, title, _id, type, data } = event;
            const a = moment(moment(event.date[1]).format("DD MMMM YYYY"));
            const b = moment(moment(event.date[0]).format("DD MMMM YYYY"));
            const totalDays = a.diff(b, "days");
            switch (value.format("DD MMMM")) {
              case moment(event.date[0]).format("DD MMMM"):
                calenderData.push({
                  count,
                  date,
                  label,
                  time,
                  title,
                  _id,
                  type,
                  data,
                });
                listData = calenderData;
                break;
              default:
            }
          }
          return listData;
        });
        return listData || [];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item, i) => (
          <li
            key={i}
            ref={getInput}
            onClick={() => {
              setShowDetailsModal(true);
              setSelectedBookingId(item?.data);
            }}
          >
            <Tooltip
              title={`${item?.label == "upcoming" ? "Upcoming"
                : item?.label == "expired" ? "Expired"
                  : item?.label == "active" ? "Active"
                    : item?.label == "refund_request" ? "Refund Request"
                      : item?.label == "cancelled" ? "cancelled"
                        : item?.label == "cancel_request" ? "Cancelled Request"
                          : item?.label == "refunded" ? "Refunded"
                            : item?.label == "completed" ? "Completed"
                              : item?.label} ${t("Booking")}`}
              placement="top"
            >
              <Link
                style={{ width: width * (item.totalDays + 1) }}
                className={item.label}
                to="#"
              >
                {`${item.title.length > 20
                  ? item.title.slice(0, 20) + "..."
                  : item.title} (${item.count})`}
              </Link>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Card>
      <Modal
        footer={null}
        type="primary"
        title={<span>{t("Selected Booking List")}</span>}
        open={showDetailsModal}
        onCancel={() => {
          setShowDetailsModal(false);
          setSelectedBookingId("");
        }}
        width={800}
        centered
      >
        <ListShowModal selectedBookingId={selectedBookingId} />
      </Modal>
      <BlockViewCalendarWrapper className="table-responsive">
        <Calendar
          headerRender={({ value, onChange }) => {
            return (
              <>
                <div className="calendar-header">
                  <div className="calendar-header__left">
                    <Button
                      onClick={() => {
                        navigate(`/users-booking-list/today`, {
                          replace: true,
                        });
                      }}
                      className="btn-today"
                      type="white"
                      outlined
                    >
                      <NavLink replace={true} to="/users-booking-list/today">
                        {t("Today")}
                      </NavLink>
                    </Button>
                    <CalenDar
                      onClickMonth={() => {
                        container.classList.remove("show");
                      }}
                      onActiveStartDateChange={({ activeStartDate }) => {
                        setState({
                          ...state,
                          currentLabel:
                            moment(activeStartDate).format("MMMM YYYY"),
                        });
                        userBookingListData(
                          moment(activeStartDate)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          moment(activeStartDate)
                            .endOf("month")
                            .format("YYYY-MM-DD"),
                          ''
                        );
                        onChange(
                          value.clone().month(moment(activeStartDate).month())
                        );
                      }}
                      next2Label={null}
                      prev2Label={null}
                      nextLabel={<FeatherIcon icon="chevron-right" size={22} />}
                      prevLabel={<FeatherIcon icon="chevron-left" size={22} />}
                      value={state.date}
                      navigationLabel={() =>
                        `${t(moment(currentLabel).format("MMMM"))} ${t(
                          moment(currentLabel).format("YYYY")
                        )}`
                      }
                    />
                  </div>
                  <div className="calendar-header__right">
                    <ul>
                      <li>
                        <NavLink replace={true} to="/users-booking-list/day">
                          {t("Day")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink replace={true} to="/users-booking-list/week">
                          {t("Week")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink replace={true} to="/users-booking-list/month">
                          {t("Month")}
                        </NavLink>
                      </li>
                    </ul>
                    <NavLink
                      replace={true}
                      className="schedule-list"
                      to="/users-booking-list/schedule"
                    >
                      <FeatherIcon icon="list" />
                      {t("Schedule")}
                    </NavLink>
                  </div>
                </div>
              </>
            );
          }}
          cellRender={dateCellRender}
        />
      </BlockViewCalendarWrapper>
    </Card>
  );
};

export default MonthCalendar;
