import React, { useEffect, useState, useRef } from "react";
import styles from "./Reactions.module.css";
import {
  Avatar,
  Col,
  message,
  Pagination,
  Row,
  Skeleton,
  Tooltip,
  Divider,
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ChatModule from "./chatModule/ChatModule";
import { UserOutlined } from "@ant-design/icons";
import { chatResponse_time, time_ago } from "../../../utility/ComanFunctions";
import { allMyReactionsList } from "../../../redux/InteractionReactions/action";

// allMyReactionsList
function ReactionsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allList, setAllList] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [responseId, setResponseId] = useState("");

  useEffect(() => {
    fetchList(currentPage, dataPerPage);
  }, [currentPage, dataPerPage]);

  const fetchList = async (pageNum, limit) => {
    setLoading(true);
    const params = {
      page: pageNum,
      limit: limit,
    };
    const resp = await dispatch(allMyReactionsList(params));
    if (resp && resp?.data) {
      setLoading(false);
      setAllList(resp?.data);
      setPaginationObj(resp?.pagination);
      resp?.data.map((data, i) => {
        data.response_time = chatResponse_time(data.created_at);
      });
    } else message.error(resp?.data?.message);
  };

  // pagination
  const onDataChange = (current, pageSize) => {
    setCurrentPage(current, pageSize);
    setDataPerPage(pageSize);
  };

  const showChatModule = (boolean, val) => {
    setIsChatOpen(boolean);
    setResponseData(val);
    setResponseId(val._id);
  };

  // group messages by day
  const GroupedDayMessagesArr = groupMessagesByDay(allList);
  function groupMessagesByDay(messages) {
    const grouped = [];
    messages.forEach((message) => {
      const day = new Date(message.created_at).toLocaleDateString();
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(message);
    });
    return grouped;
  }

  return (
    <div className={styles.responsesContainer}>
      {loading ? (
        <>
          <Skeleton.Input
            active={true}
            size={"large"}
            block={false}
            className="skeletonInputMain"
            style={{ marginBottom: "20px" }}
          />
          {new Array(5).fill("").map((_, i) => {
            return (
              <>
                <div className={styles.interactionCard}>
                  <Skeleton
                    avatar
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <h2 className={styles.HeaderTitle}>
            <span>{t("My Reactions")}</span>
          </h2>
          {allList && allList.length > 0 ? (
            <>
              <div className={styles.parentDiv}>
                <div
                  className={
                    isChatOpen == false
                      ? styles.interactionCardContainer
                      : styles.FullWidthContainer
                  }
                >
                  <div className={styles.interactionCardContainer}>
                    {Object.keys(GroupedDayMessagesArr).map((day) => {
                      return (
                        <div key={day}>
                          <Divider
                            orientation="left"
                            plain
                            orientationMargin={10}
                          >
                            <p className={styles.dividerDayText}>
                              {moment(day, 'DD/MM/YYYY').format("MMM DD, YYYY")}
                            </p>
                          </Divider>
                          <Row gutter={25}>
                            {GroupedDayMessagesArr[day].map((data, i) => {
                              data.response_time = time_ago(data.created_at);
                              return (
                                <>
                                  <Col span={24} key={i}>
                                    <div
                                      className={styles.customUserResponseCard}
                                    >
                                      <div className={styles.userThumbNail}>
                                        <div
                                          className={
                                            styles.customUserResponseCardImageTitle
                                          }
                                        >
                                          {data?.business_id?.user_image !==
                                            "" ? (
                                            <Avatar
                                              className={styles.userAvatar}
                                              size={35}
                                              src={data?.business_id?.user_image}
                                            />
                                          ) : (
                                            <Avatar
                                              size={35}
                                              icon={<UserOutlined />}
                                            />
                                          )}
                                          <div className={styles.headerTimeBox}>
                                            <div
                                              className={styles.responseTiming}
                                            >
                                              <Tooltip
                                                placement="top"
                                                title={
                                                  data?.business_id?.username !==
                                                    ""
                                                    ? data?.business_id?.username
                                                    : data?.business_id?.email
                                                }
                                              >
                                                <h3>
                                                  {data?.business_id?.username !==
                                                    ""
                                                    ? data?.business_id?.username
                                                      .length > 15
                                                      ? data?.business_id?.username.slice(
                                                        0,
                                                        15
                                                      ) + "..."
                                                      : data?.business_id
                                                        ?.username
                                                    : data?.business_id?.email
                                                      .length > 15
                                                      ? data?.business_id?.email.slice(
                                                        0,
                                                        15
                                                      ) + "..."
                                                      : data?.business_id?.email}
                                                </h3>
                                              </Tooltip>
                                              <div
                                                className={
                                                  styles.customUserResponseTime
                                                }
                                              >
                                                <span>{data.response_time}</span>
                                              </div>
                                            </div>

                                            <div
                                              className={
                                                styles.customUserResponseDate
                                              }
                                            >
                                              <span>
                                                {moment(data.created_at).format(
                                                  "hh:mm a"
                                                )}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={styles.viewActionIcon}
                                          onClick={() => {
                                            showChatModule(true, data);
                                          }}
                                        >
                                          <FeatherIcon
                                            icon="message-square"
                                            size={16}
                                          />
                                        </div>
                                      </div>
                                      <Row>
                                        <Col span={24}>
                                          <div
                                            className={
                                              styles.customUserResponseTitle
                                            }
                                          >
                                            {data?.interaction_id?.title !== ""
                                              ? data?.interaction_id?.title
                                              : ""}
                                          </div>
                                        </Col>
                                        <Col span={24}>
                                          <div
                                            className={
                                              styles.customUserResponseDescription
                                            }
                                          >
                                            {data?.interaction_id?.description !==
                                              ""
                                              ? data?.interaction_id?.description
                                                .length > 50
                                                ? data?.interaction_id?.description.slice(
                                                  0,
                                                  50
                                                ) + "..."
                                                : data?.interaction_id
                                                  ?.description
                                              : ""}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
                {isChatOpen == true && (
                  <ChatModule
                    responseData={responseData}
                    responseId={responseId}
                    showChatModule={showChatModule}
                    pageNum={currentPage}
                    limit={dataPerPage}
                    fetchList={fetchList}
                  />
                )}
              </div>
              <div className={styles.paginationComponent}>
                <Pagination
                  total={paginationObj?.total_counts}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={dataPerPage}
                  defaultCurrent={currentPage}
                  onChange={onDataChange}
                  className="interactionPagination"
                />
              </div>
            </>
          ) : (
            <p>{t("No Response Data")}</p>
          )}
        </>
      )}
    </div>
  );
}

export default ReactionsList;
