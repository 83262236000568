import React, { useState, useLayoutEffect, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Button, Card, Modal, Tooltip } from "antd";
import "./style.css";
import { eventVisible } from "../../../../../redux/calendar/actionCreator";
import ListShowModal from "./ListShowModal";
import { useTranslation } from "react-i18next";
import { getUsersBookingList } from "../../../../../redux/userBooking/action";

const WeekCalendar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [calenderDates, setCalenderDates] = useState([]);
  useEffect(() => {
    userBookingListData(
      moment().startOf("week").format("YYYY-MM-DD"),
      moment().endOf("week").format("YYYY-MM-DD"),
      ''
    );
    generateDateArray(
      moment().startOf("week").format("YYYY-MM-DD"),
      moment().endOf("week").format("YYYY-MM-DD"),
      ''
    );
  }, []);

  const userBookingListData = async (start_date, end_date, status) => {
    // console.log(
    //   "🚀 ~ file: Week.js:29 ~ userBookingListData ~ start_date, end_date:",
    //   start_date,
    //   end_date
    // );
    const dataList = await dispatch(getUsersBookingList(start_date, end_date, status));
    if (dataList) {
      let output = [];
      let filterArray = dataList?.data?.filter(
        (e) =>
          e.booking_id &&
          e.booking_id.timing &&
          ["hour", "Hour"].includes(e.booking_id.timing)
      );
      filterArray.map((item) => {
        const bookingId = item.booking_id._id;
        const _id = item._id;
        let bookedTimeSlot = item.booked_time_slot;
        const bookingTitle = item.booking_id.title;
        const bookingStatus = item.status;
        let startDate = moment(item.start_date).format("MM/DD/YYYY");
        let endDate = moment(item.end_date).format("MM/DD/YYYY");

        if (bookedTimeSlot.length) {
          bookedTimeSlot = bookedTimeSlot[0].split("-");
        } else {
          bookedTimeSlot = ["", ""];
        }
        let checkValueExist = output.findIndex(
          (e) =>
            e.bookingId == bookingId &&
            e.date[0] === startDate &&
            e.date[1] == endDate &&
            e.timeSlotString == item.booked_time_slot[0]
        );
        if (checkValueExist >= 0) {
          output[checkValueExist].count++;
          output[checkValueExist].data.push({
            _id: _id,
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            start_date: item.start_date,
            end_date: item.end_date,
            booked_time_slot: `${moment(
              moment(bookedTimeSlot[0], "hh:mm")
            ).format("HH:mm")}-${moment(
              moment(bookedTimeSlot[1], "hh:mm")
            ).format("HH:mm")}`,
          });
        } else {
          output.push({
            date: [startDate, endDate],
            time: [
              moment(moment(bookedTimeSlot[0], "hh:mm")).format("HH:mm"),
              moment(moment(bookedTimeSlot[1], "hh:mm")).format("HH:mm"),
            ],
            title: bookingTitle,
            label: bookingStatus,
            timeSlotString: item.booked_time_slot[0],
            bookingId: bookingId,
            count: 1,
            data: [
              {
                _id: _id,
                title: bookingTitle,
                label: bookingStatus,
                bookingId: bookingId,
                start_date: item.start_date,
                end_date: item.end_date,
                booked_time_slot: `${moment(
                  moment(bookedTimeSlot[0], "hh:mm")
                ).format("HH:mm")}-${moment(
                  moment(bookedTimeSlot[1], "hh:mm")
                ).format("HH:mm")}`,
              },
            ],
          });
        }
      });
      // console.log("Week Calendar Data", output);
      setBookingList(output);
    }
  };

  const [state, setState] = useState({
    currentWeek: moment().week(),
    maxWeek: moment("12-31-2090", "MM-DD-YYYY").isoWeek(),
    minWeek: 1,
    year: moment().year(),
    defaultValue: moment().format("YYYY-MM-DD"),
  });

  const { currentWeek, maxWeek, minWeek, defaultValue, year } = state;

  //DEFINE USE LAYOUT EFFECT THEME IMPLEMENTED
  useLayoutEffect(() => {
    const calenderDom = document.querySelectorAll(
      ".ant-picker-calendar-date-content"
    );
    calenderDom.forEach((element) => {
      element.addEventListener("click", (e) => {
        if (e.target.classList[0] === "ant-picker-calendar-date-content") {
          const getDate = moment(e.currentTarget.getAttribute("title")).format(
            "YYYY-MM-DD"
          );
          setState({
            defaultValue: getDate,
            currentWeek,
            maxWeek,
            minWeek,
            year,
          });
          dispatch(eventVisible(true));
        }
      });
    });
    setState({ currentWeek, maxWeek, minWeek, defaultValue, year });
  }, [currentWeek, maxWeek, minWeek, defaultValue, year, dispatch]);

  const onIncrement = () => {
    if (currentWeek < maxWeek) {
      generateDateArray(
        moment()
          .year(year)
          .isoWeek(currentWeek + 1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year)
          .isoWeek(currentWeek + 1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
      userBookingListData(
        moment()
          .year(year)
          .isoWeek(currentWeek + 1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year)
          .isoWeek(currentWeek + 1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        ''
      );
      setState({
        ...state,
        currentWeek: currentWeek + 1,
      });
    } else {
      generateDateArray(
        moment()
          .year(year + 1)
          .isoWeek(1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year + 1)
          .isoWeek(1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
      userBookingListData(
        moment()
          .year(year + 1)
          .isoWeek(1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year + 1)
          .isoWeek(1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        ''
      );
      setState({
        ...state,
        year: year + 1,
        currentWeek: 1,
      });
    }
  };

  const onDecrement = () => {
    if (currentWeek > minWeek) {
      generateDateArray(
        moment()
          .year(year)
          .isoWeek(currentWeek - 1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year)
          .isoWeek(currentWeek - 1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
      userBookingListData(
        moment()
          .year(year)
          .isoWeek(currentWeek - 1)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year)
          .isoWeek(currentWeek - 1)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        ''
      );
      setState({
        ...state,
        currentWeek: currentWeek - 1,
      });
    } else {
      generateDateArray(
        moment()
          .year(year - 1)
          .isoWeek(maxWeek)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year - 1)
          .isoWeek(maxWeek)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
      userBookingListData(
        moment()
          .year(year - 1)
          .isoWeek(maxWeek)
          .startOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .year(year - 1)
          .isoWeek(maxWeek)
          .endOf("isoWeek")
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        ''
      );
      setState({
        ...state,
        year: year - 1,
        currentWeek: maxWeek,
      });
    }
  };

  function generateDateArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate, "day")) {
      dateArray.push(currentDate.format("MM/DD/YYYY"));
      currentDate.add(1, "day");
    }
    // console.log("dateArray", dateArray);
    setCalenderDates(dateArray);
  }

  const eventTimes = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];
  // console.log(
  //   "calendar show dates , ",
  //   moment(calenderDates[0]).format("MMM DD")
  // );
  return (
    <Card>
      <Modal
        footer={null}
        type="primary"
        title={<span>{t("Selected Booking List")}</span>}
        open={showDetailsModal}
        onCancel={() => {
          setShowDetailsModal(false);
          setSelectedBookingId("");
        }}
        width={800}
        centered
      >
        <ListShowModal selectedBookingId={selectedBookingId} />
      </Modal>
      <div className="calendar-header">
        <div className="calendar-header__left">
          <Button
            onClick={() => {
              navigate(`/users-booking-list/today`, {
                replace: true,
              });
            }}
            className="btn-today"
            size="small"
            type="white"
            outlined
          >
            <NavLink replace={true} to="/users-booking-list/today">
              {t("Today")}
            </NavLink>
          </Button>
          <div className="calender-head__navigation">
            <Button
              onClick={onDecrement}
              className="btn-navigate"
              type="white"
              outlined
            >
              <FeatherIcon icon="chevron-left" size={22} />
            </Button>
            <span className="date-label">
              {`${t(moment(calenderDates[0]).format("MMMM"))} ${t(
                moment(calenderDates[0]).format("DD")
              )} - ${t(moment(calenderDates[6]).format("MMMM"))} ${t(
                moment(calenderDates[6]).format("DD")
              )}
             , ${t(year)}`}
            </span>
            <Button
              onClick={onIncrement}
              className="btn-navigate"
              type="white"
              outlined
            >
              <FeatherIcon icon="chevron-right" size={22} />
            </Button>
          </div>
        </div>
        <div className="calendar-header__right">
          <ul>
            <li>
              <NavLink replace={true} to="/users-booking-list/day">
                {t("Day")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/week">
                {t("Week")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/month">
                {t("Month")}
              </NavLink>
            </li>
          </ul>
          <NavLink
            replace={true}
            className="schedule-list"
            to="/users-booking-list/schedule"
          >
            <FeatherIcon icon="list" />
            {t("Schedule")}
          </NavLink>
        </div>
      </div>
      <table className="table-event event-week table-responsive" width="100%">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {calenderDates?.map((day, index) => {
              return (
                <th key={index}>
                  <p className="week-dayName">
                    {t(moment(day).format("dddd"))}
                  </p>
                  <p
                    className={
                      moment().format("MM/DD/YYYY") === day
                        ? "week-date primary"
                        : "week-date deactivate"
                    }
                  >
                    {t(moment(day).format("DD"))}{" "}
                    {t(moment(day).format("MMMM"))}
                  </p>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {eventTimes.map((time, index) => {
            return (
              <tr key={index}>
                <td>{t(time)}</td>
                {calenderDates?.map((day, i) => {
                  return (
                    <td
                      key={i}
                      className={`ant-picker-calendar-date-content`}
                      style={{
                        backgroundColor:
                          moment().format("h A") === time &&
                            moment().format("MM/DD/YYYY") ===
                            moment(day).format("MM/DD/YYYY")
                            ? "rgb(227 99 168 / 16%)"
                            : "white",
                        borderTop:
                          moment().format("h A") === time &&
                            moment().format("MM/DD/YYYY") ===
                            moment(day).format("MM/DD/YYYY")
                            ? "3px solid rgb(227 99 168)"
                            : "none",
                      }}
                      title={day}
                    >
                      {bookingList?.map(
                        (event) =>
                          moment(day).format("M/D/YYYY") ===
                          moment(event.date[0]).format("M/D/YYYY") &&
                          time ===
                          moment(event.time[0], "h:mm a").format("h A") && (
                            <>
                              <Tooltip
                                title={`${event?.label == "upcoming" ? "Upcoming"
                                  : event?.label == "expired" ? "Expired"
                                    : event?.label == "active" ? "Active"
                                      : event?.label == "refund_request" ? "Refund Request"
                                        : event?.label == "cancelled" ? "cancelled"
                                          : event?.label == "cancel_request" ? "Cancelled Request"
                                            : event?.label == "refunded" ? "Refunded"
                                              : event?.label == "completed" ? "Completed"
                                                : event?.label} ${t("Booking")}`}
                                placement="top"
                              >
                                <Link
                                  to="#"
                                  className={`${event.label} day-event-item`}
                                  onClick={() => {
                                    setShowDetailsModal(true);
                                    setSelectedBookingId(event?.data);
                                  }}
                                >
                                  <span className="event-title">
                                    {`${event.title.length > 20
                                      ? event.title.slice(0, 20) + "..."
                                      : event.title} (${event.count})`}
                                  </span>
                                  <span>{`${event.time[0]} - ${event.time[1]}`}</span>
                                </Link>
                              </Tooltip>
                            </>
                          )
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default WeekCalendar;
