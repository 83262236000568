import React from "react";
import styles from "./Termsandpolicy.module.css";
import logo from "../../assets/Images/ATZ2.svg";
import { useNavigate } from "react-router-dom";

const Privacy = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.mainContainer}>
            <div className={styles.privacyMainContainer}>
                <div
                    className={styles.backBtn}
                    onClick={() => navigate("/sign-up")}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="rgb(206 19 119 / 54%)"
                        height="inherit"
                        width="inherit"
                        style={{
                            objectFit: "cover",
                        }}
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 512 512"
                    >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                        <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                                {" "}
                                <g>
                                    {" "}
                                    <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                                </g>{" "}
                            </g>{" "}
                        </g>
                    </svg>
                </div>
                <div className={styles.atzLogoBox}>
                    <div
                        className={styles.logoBox}
                        onClick={() => {
                            navigate("/sign-up");
                        }}
                    >
                        <img src={logo} />
                    </div>
                    <h1 className={styles.mainHeading}>Your Privacy</h1>
                </div>


                <div className={styles.paragraphsText}>
                    <h3 className={styles.welcomeText}>Welcome to ATZ.ai!</h3>
                    <p>
                        Please read Privacy Policy
                    </p>


                    <div className={styles.contentBox}>
                        <h3 className={styles.subHeadingText}>Reservation of Rights</h3>
                        <p>
                            We reserve the right to request that you remove all links or any particular link to our Website.
                            You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and itâ€™s
                            linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                        </p>
                    </div>
                    <div className={styles.contentBox}>
                        <h3 className={styles.subHeadingText}>Removal of links from our website</h3>
                        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to
                            remove links but we are not obligated to or so or to respond to you directly.
                        </p>
                        <p>
                            We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website
                            remains available or that the material on the website is kept up to date.
                        </p>
                    </div>


                    <div className={styles.contentBox}>
                        <h3 className={styles.subHeadingText}>Disclaimer
                        </h3>

                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our
                            website and the use of this website. Nothing in this disclaimer will:<br />
                            <ul className={styles.listText}>
                                <li>
                                    limit or exclude our or your liability for death or personal injury;
                                </li>
                                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>

                            </ul>
                        </p>
                        <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer:<br />
                            (a) are subject to the preceding paragraph; and<br />
                            (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
                        </p>

                        <p>
                            As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                        </p>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default Privacy;
