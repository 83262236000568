import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import "./style.css";
import { Card, Modal, Tooltip } from "antd";
import ListShowModal from "./ListShowModal";
import { useTranslation } from "react-i18next";
import { getUsersBookingList } from "../../../../../redux/userBooking/action";
import { useDispatch } from "react-redux";

const TodayCalendar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookingList, setBookingList] = useState([]);

  useEffect(() => {
    userBookingListData(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
      ''
    );
  }, []);

  const userBookingListData = async (start_date, end_date, status) => {
    const dataList = await dispatch(getUsersBookingList(start_date, end_date, status));
    if (dataList) {
      let output = [];

      let filterArray = dataList?.data?.filter(
        (e) =>
          e.booking_id &&
          e.booking_id.timing &&
          ["hour", "Hour"].includes(e.booking_id.timing)
      );
      filterArray.map((item) => {
        const bookingId = item.booking_id._id;
        const _id = item._id;
        let bookedTimeSlot = item.booked_time_slot;
        const bookingTitle = item.booking_id.title;
        const bookingStatus = item.status;
        let startDate = moment(item.start_date).format("MM/DD/YYYY");
        let endDate = moment(item.end_date).format("MM/DD/YYYY");

        if (bookedTimeSlot.length) {
          bookedTimeSlot = bookedTimeSlot[0].split("-");
        } else {
          bookedTimeSlot = ["", ""];
        }
        let checkValueExist = output.findIndex(
          (e) =>
            e.bookingId == bookingId &&
            e.date[0] === startDate &&
            e.date[1] == endDate &&
            e.timeSlotString == item.booked_time_slot[0]
        );
        if (checkValueExist >= 0) {
          output[checkValueExist].count++;
          output[checkValueExist].data.push({
            _id: _id,
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            start_date: item.start_date,
            end_date: item.end_date,
            booked_time_slot: `${moment(
              moment(bookedTimeSlot[0], "hh:mm")
            ).format("HH:mm")}-${moment(
              moment(bookedTimeSlot[1], "hh:mm")
            ).format("HH:mm")}`,
          });
        } else {
          output.push({
            date: [startDate, endDate],
            time: [
              moment(moment(bookedTimeSlot[0], "hh:mm")).format("HH:mm"),
              moment(moment(bookedTimeSlot[1], "hh:mm")).format("HH:mm"),
            ],
            title: bookingTitle,
            label: bookingStatus,
            timeSlotString: item.booked_time_slot[0],
            bookingId: bookingId,
            count: 1,
            data: [
              {
                _id: _id,
                title: bookingTitle,
                label: bookingStatus,
                bookingId: bookingId,
                start_date: item.start_date,
                end_date: item.end_date,
                booked_time_slot: `${moment(
                  moment(bookedTimeSlot[0], "hh:mm")
                ).format("HH:mm")}-${moment(
                  moment(bookedTimeSlot[1], "hh:mm")
                ).format("HH:mm")}`,
              },
            ],
          });
        }
      });
      // console.log("Today Calendar Data", output);
      setBookingList(output);
    }
  };

  const eventTimes = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];

  return (
    <Card>
      <Modal
        footer={null}
        type="primary"
        title={<span>{t("Selected Booking List")}</span>}
        open={showDetailsModal}
        onCancel={() => {
          setShowDetailsModal(false);
          setSelectedBookingId("");
        }}
        width={800}
        centered
      >
        <ListShowModal selectedBookingId={selectedBookingId} />
      </Modal>
      <div className="calendar-header">
        <div className="calendar-header__left"></div>
        <div className="calendar-header__right">
          <ul>
            <li>
              <NavLink replace={true} to="/users-booking-list/day">
                {t("Day")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/week">
                {t("Week")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/month">
                {t("Month")}
              </NavLink>
            </li>
          </ul>
          <NavLink
            replace={true}
            className="schedule-list"
            to="/users-booking-list/schedule"
          >
            <FeatherIcon icon="list" />
            {t("Schedule")}
          </NavLink>
        </div>
      </div>
      <table className="table-event" width="100%">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>
              <p>{t(moment(moment().format("YYYY-MM-DD")).format("dddd"))}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {eventTimes.map((time, key) => {
            return (
              <tr key={key + 1}>
                <td style={{ width: "60px" }}>{t(time)}</td>
                <td
                  className={`ant-picker-calendar-date-content ${moment().format("h A") === time ? "current-data" : null
                    }`}
                >
                  {moment().format("h A") === time ? (
                    <span className="currentTime secondary" />
                  ) : null}

                  {bookingList?.map(
                    (event) =>
                      moment(moment().format("YYYY-MM-DD")).format(
                        "MM/DD/YYYY"
                      ) === event.date[0] &&
                      time ===
                      moment(event.time[0], "h:mm a").format("h A") && (
                        <Tooltip
                          title={`${event?.label == "upcoming" ? "Upcoming"
                            : event?.label == "expired" ? "Expired"
                              : event?.label == "active" ? "Active"
                                : event?.label == "refund_request" ? "Refund Request"
                                  : event?.label == "cancelled" ? "cancelled"
                                    : event?.label == "cancel_request" ? "Cancelled Request"
                                      : event?.label == "refunded" ? "Refunded"
                                        : event?.label == "completed" ? "Completed"
                                          : event?.label} ${t("Booking")}`}
                          placement="top"
                        >
                          <Link
                            to="#"
                            className={`${event.label} day-event-item`}
                            onClick={() => {
                              setShowDetailsModal(true);
                              setSelectedBookingId(event?.data);
                            }}
                          >
                            <span className="event-title">
                              {`${event.title.length > 20
                                ? event.title.slice(0, 20) + "..."
                                : event.title} (${event.count})`}
                            </span>
                            <span>{`${event.time[0]} - ${event.time[1]}`}</span>
                          </Link>
                        </Tooltip>
                      )
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default TodayCalendar;
