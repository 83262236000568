import React, { useEffect, useState } from "react";
import { Row, Col, Button, Divider, Empty, Modal, Spin } from "antd";
import styles from "./Subscription.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubScriptionList,
  purchaseSubScription,
} from "../../../redux/subscription/action";
import { getItem } from "../../../utility/LocalStorageControl";
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Subscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [listLoading, setListLoading] = useState(true);
  const [createPurchaseLoading, setCreatePurchaseLoading] = useState(false);
  const [subScriptionConfirmModal, setSubScriptionConfirmModal] =
    useState(false);
  const [subScriptionDetails, setSubScriptionDetails] = useState({});

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setListLoading(true);
    let response = await dispatch(getSubScriptionList());
    if (response) {
      setListLoading(false);
    } else {
      setListLoading(true);
      setTimeout(() => {
        setListLoading(false);
      }, 1000);
    }
  };

  let listOfSubscription = useSelector(
    (state) => state.subscription.subScriptionList
  );
  // console.log(
  //   "🚀 ~ file: Subscription.js:24 ~ Subscription ~ listOfSubscription:",
  //   listOfSubscription
  // );

  const onSubScriptionClick = (value) => {
    setSubScriptionDetails(value);
    setSubScriptionConfirmModal(true);
  };

  const onSubScriptionConfirm = async () => {
    setCreatePurchaseLoading(true);
    let payload = {
      country: getItem("userDetails")?.countryObject?.countryName,
      subscription_id: subScriptionDetails?._id,
      user_id: getItem("userDetails")._id,
      is_business_subscription: true,
      customer: getItem("userDetails")?.stripe_customer_code,
      stripe_customer_code: getItem("userDetails")?.stripe_customer_code,
      price: Number(subScriptionDetails?.price),
      is_live: true,
    };
    let response = await dispatch(purchaseSubScription(payload));
    if (response) {
      setCreatePurchaseLoading(false);
      setSubScriptionDetails({});
      setSubScriptionConfirmModal(false);
    } else {
      setCreatePurchaseLoading(true);
      setTimeout(() => {
        setCreatePurchaseLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <Modal
        width={570}
        centered
        open={subScriptionConfirmModal}
        onCancel={() => {
          setSubScriptionDetails({});
          setSubScriptionConfirmModal(false);
        }}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <div className={styles.subcriptionConfirmModalTitle}>
              <span>
                {t("Are you sure you want to purchase this subscription?")}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div
              className={styles.subcriptionConfirmModalButtonDiv}
              onClick={() => {
                setSubScriptionDetails({});
                setSubScriptionConfirmModal(false);
              }}
            >
              <Button className={styles.subcriptionConfirmModalButton}>
                {t("Cancel")}
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.subcriptionConfirmModalButtonDiv}>
              <Button
                className={`${styles.subcriptionConfirmModalButton} subSubscriptionLoader`}
                onClick={() => {
                  onSubScriptionConfirm();
                }}
                disabled={createPurchaseLoading}
              >
                {createPurchaseLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                          fill: "white",
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  t("Confirm")
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>

      <div
        className={styles.subscriptionContainer}
        style={{ height: listLoading ? "calc(100% - 130px)" : "" }}
      >
        <h2 className={styles.HeaderTitle}>
          <span>{t("Our Subscription Plans")}</span>
        </h2>
        <p className={styles.pagedDescription}>
          {t(
            "Subscribe to get more benefits and unlimited access of our latest App Features."
          )}
        </p>
        {listLoading ? (
          <div className={styles.subScriptionNoDataFoundDiv}>
            <Spin />
          </div>
        ) : (
          <>
            <div className="subscriptionCardBox">
              <Row gutter={25}>
                {listOfSubscription && listOfSubscription.length > 0 ? (
                  listOfSubscription.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={24}
                          xs={24}
                          style={{ marginTop: "28px" }}
                        >
                          <div
                            className={styles.SubscriptionCard}
                            style={{
                              background: "#f3f3f3",
                            }}
                          >
                            <div className={styles.cardNamePriceDiv}>
                              <p className={styles.price}>
                                <div>
                                  <span className={styles.currency}>
                                    {value?.countryObject?.symbol}
                                  </span>
                                  <span className={styles.number}>
                                    {value?.price}
                                  </span>
                                  <span className={styles.type}>
                                    <span>/</span> {value?.timing}
                                  </span>
                                </div>
                                {value?.is_purchase ? (
                                  <div className={styles.activeSubScription}>
                                    {t("Active")}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </p>
                              <p className={styles.name}>{value?.name}</p>
                            </div>
                            {value?.features?.map((item, index) => (
                              <>
                                <div
                                  key={index}
                                  className={styles.cardNamePriceDiv}
                                >
                                  <div className={styles.featuresDiv}>
                                    <span>
                                      {" "}
                                      <FeatherIcon icon="check" size={20} />
                                    </span>
                                    <span>{item}</span>
                                  </div>
                                </div>
                                {index > 1 && (
                                  <div className={styles.DividerDiv}>
                                    <Divider
                                      style={{
                                        margin: "0",
                                        background: "#80808078",
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ))}
                            {value?.is_purchase ? (
                              <div className={styles.btnContainer}>
                                <Button
                                  size="default"
                                  type="secondary"
                                  className={styles.ActiveBtn}
                                  onClick={() => {
                                    onSubScriptionClick(value);
                                  }}
                                  disabled={true}
                                  style={{
                                    cursor: "no-drop",
                                  }}
                                >
                                  {t("Subscribed")}
                                </Button>
                              </div>
                            ) : (
                              <div className={styles.btnContainer}>
                                <Button
                                  size="default"
                                  type="secondary"
                                  className={styles.premiumBtn}
                                  onClick={() => {
                                    onSubScriptionClick(value);
                                  }}
                                  disabled={value?.is_purchase}
                                  style={{
                                    cursor: value?.is_purchase
                                      ? "no-drop"
                                      : "pointer",
                                  }}
                                >
                                  {t("Subscribe")}
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <div className={styles.subScriptionNoDataFound}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Subscription;
