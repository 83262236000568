import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// all interaction List API
export const getQrPaymentsList = () => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(`${API.qrPayment.qrList}`);
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// all interaction List API
export const getQrPaymentsDetail = (id) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(`${API.qrPayment.qrDetail}${id}`);
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// create QR PAYMENT API CALL
export const createQrPaymentApi = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.qrPayment.createQrPayment,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// resetQrPayment QR PAYMENT API CALL
export const resetQrPayment = (payload, qrID) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.qrPayment.resetQrPayment}${qrID}`,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// qr payment history List API
export const getQrPaymentsHistory = (businessId) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.qrPayment.qrPaymentHistory}?business_id=${businessId.business_id}&qr_payment_id=${businessId?.qr_payment_id}&page=${businessId.page}&limit=${businessId.limit}&`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// all payment history List API
export const getPaymentsHistoryList = (param) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.qrPayment.allPaymentHistory}?type=${param.type}&startOf=${param?.startOf}&endOf=${param.endOf}&page=${param.page}&limit=${param.limit}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// ?edit qr qrDetail
export const editQrDetailApi = (payload, qrID) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.qrPayment.editQrPayment}${qrID}`,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// ?edit qr qrDetail
export const viewReceiptApi = (ID) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.qrPayment.getReceipt}${ID}`
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};



// delete interaction API CALL
export const deleteQRPayment = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.qrPayment.deleteQr + id);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
