import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.css";
import {
  Avatar,
  Button,
  Col,
  Row,
  message,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Pagination,
  Skeleton,
  Tooltip,
  Progress,
  Card,
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  allInteractionList,
  deleteINteraction,
  updateInteraction,
  getCheckShortUrl,
} from "../../../redux/Interaction/action";
import audioImg from "../../../assets/Images/headphone-svgrepo-com.svg";
import videoImg from "../../../assets/Images/video-camera-svgrepo-com.svg";
import moment from "moment";
import { getItem } from "../../../utility/LocalStorageControl";
import { useTranslation } from "react-i18next";
import { CloseOutlined, QrcodeOutlined } from "@ant-design/icons";
import CKEditorMsg from "../ckEditor/CKEditorMsg";
import { WEBSITE_URL } from "../../../config/Constant";

function Interaction() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [file, setFile] = useState([]);
  const useDetail = getItem("userDetails");
  const [allList, setAllList] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listId, setListId] = useState("");
  const [collectDetail, setCollectDetail] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [contentType, setContentType] = useState("");
  const [contentFileType, setContentFileType] = useState("");
  const [defaultContentType, setDefaultContentType] = useState("");
  const [fileName, setFileName] = useState("");
  const [filePreview, setFilePreview] = useState([]);
  const [defaultContent, setDefaultContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [canUserReply, setCanUserReply] = useState(false);
  const [CTASwitch, setCTASwitch] = useState(false);
  const [shortUrl, setShortUrl] = useState("");
  const [defaultShortUrl, setDefaultShortUrl] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const [validUrlStatus, setValidUrlNameStatus] = useState(true);
  const [urlLoading, setUrlLoading] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [progressCount, setProgressCount] = useState(0);
  const [addedFields, setAddedFields] = useState(1);

  useEffect(() => {
    fetchList(currentPage, dataPerPage);
  }, []);

  const uploadPercentage = (val) => {
    setProgressCount(val);
  };

  const fetchList = async (pageNum, limit) => {
    setLoading(true);
    const params = {
      page: pageNum,
      limit: limit,
    };
    setProgressCount(0);
    const resp = await dispatch(allInteractionList(params));
    if (resp && resp?.data) {
      setLoading(false);
      setAllList(resp?.data);
      setPaginationObj(resp?.pagination);
    } else message.error(resp?.data?.message);
  };

  const handleOk = async () => {
    const deleteResp = await dispatch(deleteINteraction(listId));
    if (deleteResp == true) {
      setIsModalOpen(false);
      fetchList(currentPage, dataPerPage);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // edit modal
  const showEditModal = (data) => {
    setDefaultContentType("");
    setEditModalOpen(true);
    setProgressCount(0);
    setShortUrl(data?.short_url?.short_code);
    setDefaultShortUrl(data?.short_url?.short_code);

    setDefaultContent(data.upload_content);
    setDefaultContentType(
      data?.content_type == "video_record"
        ? "video_upload"
        : data?.content_type == "audio_record"
          ? "audio_upload"
          : data?.content_type
    );
    setContentType(
      data?.content_type == "video_record"
        ? "video_upload"
        : data?.content_type == "audio_record"
          ? "audio_upload"
          : data?.content_type
    );
    form.setFieldsValue({
      title: data?.title,
      description: data?.description,
      is_viewers_reply: setCanUserReply(data?.is_viewers_reply),
      upload_content: data?.upload_content,
      content_type:
        data?.content_type == "video_record"
          ? "video_upload"
          : data?.content_type == "audio_record"
            ? "audio_upload"
            : data?.content_type,
      message: data?.upload_content,
      short_url: data?.short_url?.short_code,
      cta_link_enable: setCTASwitch(data?.cta_link_enable),
      cta_links:
        data?.cta_link_enable == true
          ? data?.cta_links
          : [{ title: "", links: "" }],
    });
  };

  const handleEditCancel = (e) => {
    form.resetFields();
    setSubmitClicked(false);
    setEditModalOpen(false);
    setFileName("");
    setFile([]);
    setFilePreview([]);
    setShortUrl("");
    setProgressCount(0);
  };

  const onChange = (checked) => {
    if (checked == true) {
      if (CTASwitch == false) {
        setCanUserReply(true);
      } else {
        setCTASwitch(false);
        setCanUserReply(true);
      }
    } else {
      setCanUserReply(false);
    }
  };

  const onChangeCTALink = (checked) => {
    if (checked == true) {
      if (canUserReply == false) {
        setCTASwitch(true);
      } else {
        setCanUserReply(false);
        setCTASwitch(true);
      }
    } else {
      setCTASwitch(false);
    }
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (
      event.target.files[0]["type"]?.split("/")[0] == "image" ||
      event.target.files[0]["type"]?.split("/")[0] == "video" ||
      event.target.files[0]["type"]?.split("/")[0] == "audio"
    ) {
      if (event.target.files[0]["type"]?.split("/")[0] == "image") {
        if (
          event.target.files[0]["type"]?.split("/")[1] == "png" ||
          event.target.files[0]["type"]?.split("/")[1] == "jpeg" ||
          event.target.files[0]["type"]?.split("/")[1] == "jpg"
        ) {
          setFileName(event.target.files[0]);
          setFile(event.target.files[0]);
          setContentFileType("image_upload");

          setContentType("image_upload");
          form.setFieldsValue({
            content_type: "image_upload",
          });
          setFilePreview(
            <div className={styles.previewImgContainer}>
              <img
                crossOrigin="anonymous"
                alt=""
                src={URL.createObjectURL(event.target.files[0])}
              />
            </div>
          );
        } else {
          message.error(t("Please select jpg or png image"));
        }
      } else if (event.target.files[0]["type"]?.split("/")[0] == "video") {
        setFileName(event.target.files[0]);
        setContentFileType("video_upload");
        setFile(event.target.files[0]);
        setContentType("video_upload");
        form.setFieldsValue({
          content_type: "video_upload",
        });
        setFilePreview(
          <div className={styles.previewImgContainer}>
            <video
              src={URL.createObjectURL(event.target.files[0])}
              controls
              autoPlay
            />
          </div>
        );
      } else {
        setFileName(event.target.files[0]);
        setFile(event.target.files[0]);
        setContentFileType("audio_upload");

        setContentType("audio_upload");
        form.setFieldsValue({
          content_type: "audio_upload",
        });
        setFilePreview(
          <div className={styles.previewImgContainer}>
            <audio
              src={URL.createObjectURL(event.target.files[0])}
              controls
              autoPlay
            />
          </div>
        );
      }
    }
  };

  const handleSubmit = async (val) => {
    if (shortUrl !== "") {
      setSubmitClicked(true);
      const form_data = new FormData();
      form_data.append("business_id", useDetail?._id);
      form_data.append("title", val?.title);
      form_data.append("description", val?.description);
      form_data.append("is_viewers_reply", canUserReply);
      form_data.append("cta_link_enable", CTASwitch);
      form_data.append("content_type", val.content_type);
      form_data.append("short_url", val.short_url);
      if (CTASwitch == true) {
        form_data.append("cta_links", JSON.stringify(val?.cta_links));
      }
      if (
        contentType == "video_upload" ||
        contentType == "video_record" ||
        contentType == "image_upload" ||
        contentType == "audio_upload" ||
        contentType == "audio_record"
      ) {
        if (file.length !== 0 || (file !== "" && fileName !== "")) {
          form_data.append("upload_content", file);
        }
      } else if (contentType == "text") {
        form_data.append("upload_content", val?.message);
      } else {
        message.warning("Please add content");
      }
      // for (const pair of form_data.entries()) {
      //   console.log("🚀 ~ file: Interaction.js:304 ~ handleSubmit ~ pair:", pair);
      // }
      const editInteraction = await dispatch(
        updateInteraction(form_data, listId, uploadPercentage)
      );
      if (editInteraction) {
        setSubmitClicked(false);
        form.resetFields();
        fetchList(currentPage, dataPerPage);
        setEditModalOpen(false);
        setContentType("");
        setDefaultContentType("");
        setFileName("");
        setFile([]);
        setFilePreview([]);
        setShortUrl("");
        setProgressCount(0);
      }
    }
  };

  // pagination
  const onDataChange = (current, pageSize) => {
    setCurrentPage(current, pageSize);
    setDataPerPage(pageSize);
    fetchList(current, pageSize);
  };

  // URL VALIDATION
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  const onchangeShortUrl = (val) => {
    if (val == defaultShortUrl) {
      setUrlLoading(false);
      setShortUrl(val.replace(/\s/g, "").toLowerCase());
      setSubmitStatus(false);
    } else {
      setUrlLoading(true);
      setShortUrl(val.replace(/\s/g, "").toLowerCase());
      setSubmitStatus(val == "" ? true : false);
    }
  };
  const onValidUrlCheck = async (value) => {
    if (value == "" || value == defaultShortUrl) {
      setUrlLoading(false);
    } else {
      const response = await dispatch(getCheckShortUrl(value));
      if (response) {
        setUrlLoading(false);
        setValidUrlNameStatus(true);
      } else {
        setUrlLoading(false);
        setValidUrlNameStatus(false);
      }
    }
  };

  return (
    <div className={styles.interactionContainer}>
      {loading ? (
        <>
          <Skeleton.Input
            active={true}
            size={"large"}
            block={false}
            className="skeletonInputMain"
            style={{ marginBottom: "20px" }}
          />
          {new Array(5).fill("").map((_, i) => {
            return (
              <>
                <div className={styles.interactionCard}>
                  <Skeleton.Image
                    active={true}
                    style={{ width: "100px", height: "100px" }}
                  />
                  <Skeleton
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className={styles.interactionHeader}>
            <h1>{t("Interaction List")}</h1>
            <div className={styles.premiumBtnContainer}>
              <Button
                className={styles.premiumBtn}
                onClick={() => navigate("/add-interaction")}
              >
                <div className={styles.buttonContent}>
                  {t("New Interaction")}
                </div>
              </Button>
            </div>
          </div>

          <div className={styles.interactionCardContainer}>
            {allList && allList.length == 0
              ? t("No Interaction Created")
              : allList &&
              allList.map((data, i) => {
                return (
                  <div className={styles.interactionCard}>
                    <div
                      className={styles.interactionThumbnail}
                      onClick={() =>
                        navigate(`/interaction-detail/${data._id}`)
                      }
                    >
                      <Tooltip
                        title={
                          data?.content_type == "video_upload"
                            ? "Video Uploaded"
                            : data?.content_type == "video_record"
                              ? "Video Recorded"
                              : data?.content_type == "image_upload"
                                ? "Image Uploaded"
                                : data?.content_type == "audio_upload"
                                  ? "Audio Uploaded"
                                  : data?.content_type == "audio_record"
                                    ? "Audio Recorded"
                                    : "Text"
                        }
                        placement="top"
                      >
                        {data?.content_type == "video_upload" ||
                          data?.content_type == "video_record" ? (
                          <img
                            src={
                              data?.thumbnail_image == ""
                                ? videoImg
                                : data?.thumbnail_image
                            }
                            className={styles.headPhoneIcon}
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                          ></img>
                        ) : data?.content_type == "image_upload" ? (
                          <img
                            src={data?.upload_content}
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                          ></img>
                        ) : data?.content_type == "audio_upload" ||
                          data?.content_type == "audio_record" ? (
                          <img
                            src={audioImg}
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                            className={styles.headPhoneIcon}
                          ></img>
                        ) : data?.content_type == "text" ? (
                          <Avatar
                            className={styles.textAvatar}
                            shape="square"
                          >
                            <p>TXT</p>
                          </Avatar>
                        ) : (
                          <Avatar
                            className={styles.textAvatar}
                            shape="square"
                          >
                            !
                          </Avatar>
                        )}
                      </Tooltip>
                    </div>
                    <div className={styles.interactionInfoBox}>
                      <div className={styles.interactionDetail}>
                        <div className={styles.cardInfoContainer}>
                          <h4
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                          >
                            {data?.title !== "" &&
                              data?.title !== undefined &&
                              data?.title !== null
                              ? data?.title
                              : "-"}{" "}
                          </h4>
                          <p
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                            className={styles.descriptionText}
                          >
                            {data?.description !== "" &&
                              data?.description !== undefined &&
                              data?.description !== null
                              ? data?.description
                              : "-"}
                          </p>
                          <div className={styles.reactionContainer}>
                            <div className={styles.reactionBox}>
                              <FeatherIcon icon="thumbs-up" size={24} />
                              <p>{data?.like_count}</p>
                            </div>
                            <div className={styles.reactionBox}>
                              <FeatherIcon icon="thumbs-down" size={24} />
                              <p>{data?.dislike_count}</p>
                            </div>
                            <div className={styles.reactionBox}>
                              <FeatherIcon icon="eye" size={24} />
                              <p>{data?.view_count}</p>
                            </div>
                            <div className={styles.reactionBox}>
                              <FeatherIcon icon="message-circle" size={24} />
                              <p>{data?.reply_count}</p>
                            </div>
                          </div>
                          <p
                            onClick={() => {
                              navigate(`/interaction-detail/${data._id}`);
                            }}
                            className={styles.dateInfoText}
                          >
                            {moment(
                              data.created_at,
                              "YYYY-MM-DDTHH:mm:ss.SSSSZ"
                            ).format("MMM DD, YYYY HH:mm")}
                          </p>
                        </div>
                      </div>

                      <div
                        className={styles.iconBox}
                        onClick={() => {
                          setShowAction(!showAction);
                          setListId(data._id);
                        }}
                      >
                        <FeatherIcon icon="more-vertical" size={20} />
                      </div>
                      {showAction == true && listId == data._id ? (
                        <div className={styles.actionContainer}>
                          <Tooltip title="Delete" placement="top">
                            <div
                              className={styles.deleteActionIcon}
                              onClick={() => {
                                setShowAction(false);
                                setIsModalOpen(true);
                                setListId(data._id);
                              }}
                            >
                              <FeatherIcon icon="trash-2" size={20} />
                            </div>
                          </Tooltip>
                          <Tooltip title="View Detail" placement="top">
                            <div
                              className={styles.viewActionIcon}
                              onClick={() => {
                                navigate(`/interaction-detail/${data._id}`);
                                setShowAction(false);
                              }}
                            >
                              <FeatherIcon icon="eye" size={20} />
                            </div>
                          </Tooltip>
                          <Tooltip title="Edit Interaction" placement="top">
                            <div
                              className={styles.editActionIcon}
                              onClick={() => {
                                showEditModal(data);
                                setShowAction(false);
                              }}
                            >
                              <FeatherIcon icon="edit" size={20} />
                            </div>
                          </Tooltip>
                          <Tooltip title="View QR Code" placement="top">
                            <div
                              className={styles.viewActionIcon}
                              onClick={() => {
                                setShowAction(false);
                                setQrModalOpen(true);
                                setPreviewContent(data?.short_url?.qr_code);
                              }}
                            >
                              <QrcodeOutlined />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {allList && allList.length > 0 && (
            <div className={styles.paginationComponent}>
              <Pagination
                total={paginationObj?.total_counts}
                showSizeChanger
                pageSizeOptions={[10, 20, 50, 100]}
                defaultPageSize={dataPerPage}
                defaultCurrent={currentPage}
                onChange={onDataChange}
                className="interactionPagination"
              />
            </div>
          )}

          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            centered={true}
            footer={false}
            width={600}
          >
            <div className={styles.signOutConfirm}>
              <h3>
                {t("Are your sure You wants to Delete this Interaction?")}
              </h3>

              <div className={styles.modalBtnContainer}>
                <Row>
                  <Col span={12}>
                    <Button className={styles.cancelBtn} onClick={handleCancel}>
                      <div className={styles.buttonContent}>{t("Cancel")}</div>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <div className={styles.premiumBtnContainer}>
                      <Button className={styles.premiumBtn} onClick={handleOk}>
                        <div className={styles.buttonContent}>
                          {t("Confirm")}
                        </div>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>

          <Modal
            width={600}
            title={t("Edit Interaction")}
            open={editModalOpen}
            onCancel={handleEditCancel}
            footer={null}
          >
            <Form
              layout="vertical"
              name="basic"
              autoComplete="nope"
              onFinish={(value) => handleSubmit(value)}
              className={styles.interactionForm}
              form={form}
              initialValues={{ cta_links: [{ title: "", links: "" }] }}
            >
              {defaultContentType == contentType ? (
                <Form.Item name="upload_content">
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={onChangeFile.bind(this)}
                  />
                  <div className={styles.editContentLabel}>
                    <p>{t("Edit Content")}</p>
                    <FeatherIcon
                      icon="edit"
                      size={18}
                      onClick={() => {
                        inputFile.current.click();
                      }}
                    />
                  </div>
                </Form.Item>
              ) : (
                <div style={{ height: "15px" }}></div>
              )}

              {filePreview.length == 0 ? (
                <>
                  {contentType == "image_upload" ? (
                    defaultContentType == contentType ? (
                      <div className={styles.defaultEditImgDiv}>
                        <img src={defaultContent}></img>
                      </div>
                    ) : (
                      <>
                        <div className={styles.uploadIconeDiv}>
                          <div
                            className={styles.iconeDiv}
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          >
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onChangeFile.bind(this)}
                            />
                            <FeatherIcon icon="upload" size={20} />
                          </div>
                          <p>{t("Select your file")}</p>{" "}
                        </div>
                      </>
                    )
                  ) : contentType == "video_upload" ||
                    contentType == "video_record" ? (
                    defaultContentType == contentType ? (
                      <div className={styles.defaultEditImgDiv}>
                        <video src={defaultContent} controls autoPlay />
                      </div>
                    ) : (
                      <>
                        <div className={styles.uploadIconeDiv}>
                          <div
                            className={styles.iconeDiv}
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          >
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onChangeFile.bind(this)}
                            />
                            <FeatherIcon icon="upload" size={20} />
                          </div>
                          <p>{t("Select your file")}</p>{" "}
                        </div>
                      </>
                    )
                  ) : contentType == "audio_upload" ||
                    contentType == "audio_record" ? (
                    defaultContentType == contentType ? (
                      <div className={styles.defaultEditAudioDiv}>
                        <audio src={defaultContent} controls autoPlay />
                      </div>
                    ) : (
                      <>
                        <div className={styles.uploadIconeDiv}>
                          <div
                            className={styles.iconeDiv}
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          >
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onChangeFile.bind(this)}
                            />
                            <FeatherIcon icon="upload" size={20} />
                          </div>
                          <p>{t("Select your file")}</p>{" "}
                        </div>
                      </>
                    )
                  ) : (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t("Please enter your content"),
                        },
                      ]}
                      label={t("Text")}
                      name="message"
                    >
                      <CKEditorMsg
                        className="CKEditorDiv"
                        isConfig={true}
                        data={
                          defaultContentType == contentType
                            ? defaultContent
                            : ""
                        }
                        onChange={(data) => {
                          setDefaultContent(data);
                        }}
                      />
                    </Form.Item>
                  )}
                </>
              ) : contentType == "text" ? (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t("Please enter your content"),
                    },
                  ]}
                  label={t("text")}
                  name="message"
                >
                  <CKEditorMsg
                    className="CKEditorDiv"
                    isConfig={true}
                    data={
                      defaultContentType == contentType ? defaultContent : ""
                    }
                    onChange={(data) => {
                      setDefaultContent(data);
                    }}
                  />
                </Form.Item>
              ) : contentFileType == contentType ? (
                filePreview
              ) : (
                <div className={styles.uploadIconeDiv}>
                  <div
                    className={styles.iconeDiv}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      onChange={onChangeFile.bind(this)}
                    />
                    <FeatherIcon icon="upload" size={20} />
                  </div>
                  <p>{t("Select your file")}</p>{" "}
                </div>
              )}
              {fileName.length !== 0 && (
                <div className={styles.fileInfo}>
                  <p>
                    {fileName?.name.length > 70
                      ? fileName?.name.slice(0, 70) +
                      "..." +
                      fileName?.name.split(".").pop()
                      : fileName?.name}
                  </p>
                </div>
              )}

              <Form.Item
                name="short_url"
                label={
                  <span>
                    <span
                      style={{
                        color: "red",
                        marginRight: "2px",
                        display:
                          submitStatus && shortUrl == "" && !validUrlStatus
                            ? "none"
                            : "contents",
                      }}
                    >
                      *{" "}
                    </span>
                    {t("Short URL")}
                  </span>
                }
                className={styles.inputMain}
                hasFeedback
                validateStatus={
                  submitStatus
                    ? shortUrl == "" && validUrlStatus
                      ? "error"
                      : ""
                    : shortUrl == ""
                      ? ""
                      : urlLoading
                        ? "validating"
                        : validUrlStatus
                          ? "success"
                          : "error"
                }
                help={
                  submitStatus
                    ? shortUrl == "" && validUrlStatus
                      ? t("Please enter url!")
                      : ""
                    : shortUrl == ""
                      ? ""
                      : urlLoading
                        ? ""
                        : validUrlStatus
                          ? ""
                          : t("This short Url is not available.")
                }
                rules={[
                  {
                    required:
                      submitStatus && shortUrl == "" && !validUrlStatus
                        ? true
                        : false,
                    message: t("Please Enter short url"),
                  },
                  {
                    pattern: new RegExp(
                      /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                    ),
                    message: t(
                      "URL can not contain whitespace or upper case letters"
                    ),
                  },
                ]}
              >
                <Input
                  className={styles.profileInputFiled}
                  style={{ padding: "0px 8px" }}
                  prefix={
                    <>
                      <b className={styles.backslash}>{WEBSITE_URL}</b>
                    </>
                  }
                  defaultValue={shortUrl}
                  onChange={(e) => {
                    onchangeShortUrl(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Space") event.preventDefault();
                  }}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toLocaleLowerCase())
                  }
                  onPaste={(e) => {
                    e.preventDefault()
                  }}
                  placeholder={t("Enter url")}
                  disabled={false}
                  autoFocus={true}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Please select content type"),
                  },
                ]}
                name="content_type"
                label={t("Content Type")}
              >
                <Select
                  size="large"
                  style={{ height: "50px", }}
                  className="profileCountrySelectBox"
                  onChange={(value) => {
                    setContentType(value);
                  }}
                >
                  {[
                    {
                      value: "image_upload",
                      label: t("Image Upload"),
                    },
                    {
                      value: "video_upload",
                      label: t("Video Upload"),
                    },
                    {
                      value: "audio_upload",
                      label: t("Audio Upload"),
                    },
                    {
                      value: "text",
                      label: t("Text"),
                    },
                  ].map((option) => (
                    <Select.Option
                      style={{ color: "black", backgroundColor: "white" }}
                      key={option.value}
                      value={option.value}
                      onClick={() => {
                        setContentType(option.value);
                      }}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Title")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter title!"),
                  },
                ]}
                name="title"
              >
                <Input size="large" placeholder={t("Enter title")} />
              </Form.Item>
              <Form.Item label={t("Description")} name="description">
                <Input size="large" placeholder={t("Enter description")} />
              </Form.Item>
              <Row>
                <Col xl={12} xxl={12} lg={12} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="is_viewers_reply"
                    className="interactionSwitch"
                  >
                    <p className={styles.switchLabelText}>
                      {t("Can User Reply on Interaction")}
                      <FeatherIcon icon="help-circle" size={20} />
                    </p>
                    <Switch
                      checked={canUserReply}
                      onChange={onChange}
                      className="interactionSwitch"
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} xxl={12} lg={12} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="cta_link_enable"
                    className="interactionSwitch"
                  >
                    <p className={styles.switchLabelText}>
                      {t("Add CTA Link")}
                    </p>
                    <Switch
                      onChange={onChangeCTALink}
                      checked={CTASwitch}
                      className="interactionSwitch"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {CTASwitch ? (
                <Form.List name="cta_links">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, i) => (
                        <Card
                          size="small"
                          className={styles.ExtraCTACard}
                          title={`CTA ${field.name + 1}`}
                          key={field.key}
                          extra={
                            <CloseOutlined
                              style={{
                                display: i == 0 ? "none" : "block",
                              }}
                              onClick={() => {
                                remove(field.name);
                                setAddedFields(addedFields - 1);
                                setCollectDetail(!collectDetail);
                              }}
                            />
                          }
                        >
                          <>
                            <Form.Item
                              label={t("Title")}
                              name={[field.name, "title"]}
                              className={styles.CTALinkFormItem}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter a title"),
                                },
                                {
                                  max: 25,
                                  message: t("Length should be less than 25"),
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("Enter Title")}
                              />
                            </Form.Item>
                            <Form.Item
                              label={t("Link")}
                              name={[field.name, "links"]}
                              className={styles.CTALinkFormItem}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter link"),
                                },
                                {
                                  type: "url",
                                  message: t("Please enter valid url."),
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("Enter Link")}
                              />
                            </Form.Item>
                          </>
                        </Card>
                      ))}
                      {addedFields < 3 && (
                        <Button
                          type="dashed"
                          className={styles.ExtraCTACard}
                          onClick={() => {
                            add();
                            setAddedFields(addedFields + 1);
                          }}
                          block
                        >
                          + {t("Add Item")}
                        </Button>
                      )}
                    </div>
                  )}
                </Form.List>
              ) : (
                ""
              )}
              {progressCount > 0 && (
                <div className={styles.progressCircle}>
                  <Progress
                    type="circle"
                    percent={progressCount}
                    strokeColor="#E60C8F"
                  />
                </div>
              )}
              <div className={styles.createBtnContainer}>
                <Button className={styles.cancelBtn} onClick={handleEditCancel}>
                  <div className={styles.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button
                  className={styles.createBtn}
                  disabled={progressCount > 0 ? true : false}
                  loading={progressCount > 0 ? true : false}
                  onClick={() => {
                    !submitClicked && form.submit();
                  }}
                >
                  <div className={styles.buttonContent}>{t("Submit")}</div>
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            open={qrModalOpen}
            footer={null}
            centered={true}
            onCancel={() => {
              setQrModalOpen(false);
              setPreviewContent("");
            }}
            width={400}
            className="interactionPreviewModal"
          >
            <div className={styles.modalQrPreviewContent}>
              <div className={styles.QrPreviewImg}>
                <img src={previewContent}></img>
              </div>
              <p>
                {t("Scan this qr code to View this Interaction's web view")}
              </p>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Interaction;
