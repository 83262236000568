import React, { useEffect, useState, useRef } from "react";
import styles from "./Dasboard.module.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Line, DualAxes } from "@ant-design/plots";
import { allInteractionList } from "../../../redux/Interaction/action";
import { Avatar, Col, Row, message, Skeleton, Empty, Button } from "antd";
import { getItem } from "../../../utility/LocalStorageControl";
import nodataImg from "../../../assets/Images/emptyFolder.gif";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allList, setAllList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getItem("access_token") !== null && getItem("access_token") !== undefined && getItem("access_token") !== '') {
      fetchList();
    }
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const params = {
      page: 1,
      limit: 4,
    };
    const resp = await dispatch(allInteractionList(params));
    if (resp && resp?.data) {
      setLoading(false);
      setAllList(resp?.data);
    } else {
      message.error(resp?.data?.message)
      setLoading(false);
    };
  };

  const config = {
    data: [
      {
        Date: "2010-01",
        scales: 500,
      },
      {
        Date: "2010-02",
        scales: 400,
      },
      {
        Date: "2010-03",
        scales: 1800,
      },
      {
        Date: "2010-04",
        scales: 2000,
      },
      {
        Date: "2010-05",
        scales: 300,
      },
      {
        Date: "2010-06",
        scales: 400,
      },
      {
        Date: "2010-07",
        scales: 200,
      },
    ],
    padding: "auto",
    xField: "Date",
    yField: "scales",
    height: 210,
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };

  const configTwo = {
    data: [
      {
        year: "1991",
        value: 1,
      },
      {
        year: "1992",
        value: 4,
      },
      {
        year: "1993",
        value: 3.5,
      },
      {
        year: "1994",
        value: 5,
      },
    ],
    xField: "year",
    height: 210,
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
          height: "210px",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  const configThree = {
    data: [
      {
        Date: "2010-01",
        scales: 200,
      },
      {
        Date: "2010-02",
        scales: 500,
      },
      {
        Date: "2010-03",
        scales: 300,
      },
      {
        Date: "2010-04",
        scales: 2000,
      },
      {
        Date: "2010-05",
        scales: 1800,
      },
      {
        Date: "2010-06",
        scales: 400,
      },
      {
        Date: "2010-07",
        scales: 500,
      },
    ],
    padding: "auto",
    xField: "Date",
    height: 210,
    yField: "scales",
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "median"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "text",
        position: ["min", "median"],
        content: "ATZ",
        offsetY: -4,
        style: {
          textBaseline: "bottom",
          height: "210px",
        },
      },
      {
        type: "line",
        start: ["min", "median"],
        end: ["max", "median"],
        style: {
          stroke: "#F4664A",
          lineDash: [2, 2],
          height: "210px",
        },
      },
    ],
  };

  const configFour = {
    data: [
      [
        {
          year: "1991",
          value: 3,
          count: 10,
        },
        {
          year: "1992",
          value: 4,
          count: 4,
        },
        {
          year: "1993",
          value: 3.5,
          count: 5,
        },
        {
          year: "1994",
          value: 5,
          count: 5,
        },
        {
          year: "1995",
          value: 4.9,
          count: 4.9,
        },
      ],
      [
        {
          year: "1991",
          value: 3,
          count: 10,
        },
        {
          year: "1992",
          value: 4,
          count: 4,
        },
        {
          year: "1993",
          value: 3.5,
          count: 5,
        },
        {
          year: "1994",
          value: 5,
          count: 5,
        },
        {
          year: "1995",
          value: 4.9,
          count: 4.9,
        },
      ],
    ],
    xField: "year",
    yField: ["value", "count"],
    height: 210,
    geometryOptions: [
      {
        geometry: "line",
        color: "rgb(227 99 168)",
      },
      {
        geometry: "line",
        color: "#5AD8A6",
      },
    ],
  };

  return (
    <div className={styles.DashboardContainer}>
      <div className={styles.journeyContainer1}>
        {getItem("userDetails")?.name !== "" ? (
          <h2 className={styles.interactionTitle}>
            <span style={{ color: "black", fontSize: "24px" }}>
              {t("Welcome")} , {getItem("userDetails")?.name}
            </span>
          </h2>
        ) : (
          <h2 className={styles.interactionTitle}>
            <span style={{ color: "black", fontSize: "24px" }}>
              {t("Welcome")} , {getItem("userDetails")?.userName}
            </span>
          </h2>
        )}
        {loading ? (
          <>
            <Skeleton.Input
              active={true}
              size={"large"}
              block={false}
              className="skeletonInputMain"
              style={{ marginBottom: "20px" }}
            />
            <Row gutter={[20, 20]}>
              {new Array(3).fill("").map((_, i) => {
                return (
                  <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24} key={i}>
                    <div className={styles.journeyCard}>
                      <div className={styles.skeletonImgBox}>
                        <Skeleton.Image
                          active={true}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                      <Skeleton />
                      <div>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          style={{ marginTop: "10px" }}
                        />
                        <Skeleton.Button
                          active={true}
                          size="large"
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : (
          <>
            <h2 className={styles.interactionTitle}>
              <span>{t("Latest Interactions")}</span>
              {allList && allList.length > 0 && (
                <span
                  className={styles.viewAllDashboard}
                  onClick={() => navigate("/interaction")}
                >
                  {t("View all")}
                </span>
              )}
            </h2>
            <Row gutter={[20, 20]}>
              {allList && allList.length > 0 ? (
                allList &&
                allList.map((detail, i) => {
                  return (
                    <Col xxl={6} key={i} xl={6} lg={12} md={12} sm={24} xs={24}>
                      <div className={styles.journeyCard}>
                        <div
                          className={styles.interactionCard}
                          onClick={() => {
                            navigate(`/interaction-detail/${detail._id}`);
                          }}
                        >
                          <div
                            className={styles.viewInteractionIcon}
                            onClick={() => {
                              navigate(`/interaction-detail/${detail._id}`);
                            }}
                          >
                            <FeatherIcon icon="eye" size={20} />
                          </div>
                          {detail?.content_type == "video_upload" ||
                            detail?.content_type == "video_record" ? (
                            <div className={styles.ThumbnailBox}>
                              <video
                                src={detail?.upload_content}
                                playsInline
                                autoPlay
                                muted
                                preload="true"
                              ></video>
                              {/* <VideoJS videoUrl={detail?.upload_content} /> */}
                            </div>
                          ) : detail?.content_type == "image_upload" ? (
                            <div className={styles.ThumbnailBox}>
                              <img src={detail?.upload_content}></img>
                            </div>
                          ) : detail?.content_type == "audio_upload" ||
                            detail?.content_type == "audio_record" ? (
                            <div className={styles.audioContainerBox}>
                              <audio controls>
                                <source
                                  src={detail?.upload_content}
                                  type="audio/mpeg"
                                />
                              </audio>
                            </div>
                          ) : detail?.content_type == "text" ? (
                            <div className={styles.textContentBox}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: detail?.upload_content,
                                }}
                              />
                            </div>
                          ) : (
                            <div className={styles.ThumbnailBox}>
                              <Avatar
                                className={styles.textAvatar}
                                shape="square"
                              >
                                !
                              </Avatar>
                            </div>
                          )}
                        </div>
                        <div className={styles.performanceView}>
                          <h3
                            onClick={() => {
                              navigate(`/interaction-detail/${detail._id}`);
                            }}
                          >
                            {detail?.title}
                          </h3>
                          <Row>
                            <Col
                              xxl={6}
                              xl={12}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className={styles.reactionsCol}
                            >
                              {" "}
                              <FeatherIcon icon="thumbs-up" size={20} />{" "}
                              <p>{detail?.like_count}</p>
                            </Col>
                            <Col
                              xxl={6}
                              xl={12}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className={styles.reactionsCol}
                            >
                              {" "}
                              <FeatherIcon icon="thumbs-down" size={20} />{" "}
                              <p>{detail?.dislike_count}</p>
                            </Col>
                            <Col
                              xxl={6}
                              xl={12}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className={styles.reactionsCol}
                            >
                              {" "}
                              <FeatherIcon icon="eye" size={20} />{" "}
                              <p>{detail?.view_count}</p>
                            </Col>
                            <Col
                              xxl={6}
                              xl={12}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className={styles.reactionsCol}
                            >
                              {" "}
                              <FeatherIcon
                                icon="message-circle"
                                size={20}
                              />{" "}
                              <p>{detail?.reply_count}</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <>
                  <div className={styles.NoInteractionCard}>
                    <Row justify="center">
                      <Col xxl={10} xl={10} lg={12} md={12} sm={24} xs={24}>
                        <div className={styles.noDataContent}>
                          <div className={styles.noDataText}>
                            <h3>{t("You don't have any interaction yet")}</h3>
                            <p>
                              {t(
                                "Start creating your interaction to personally connect and build business relation with your audience"
                              )}
                            </p>
                          </div>
                          <div className={styles.premiumBtnContainer}>
                            <Button
                              className={styles.premiumBtn}
                              onClick={() => navigate("/add-interaction")}
                            >
                              <div className={styles.buttonContent}>
                                {t("Create Your First Interaction")}
                              </div>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xxl={10} xl={10} lg={12} md={12} sm={24} xs={24}>
                        <div className={styles.noDataIMG}>
                          <img src={nodataImg} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Row>
          </>
        )}
      </div>
      <h2 className={styles.interactionTitle}>
        <span>{t("Explore your metrics")}</span>
      </h2>

      <Row gutter={[20, 20]}>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className={styles.journeyCard} style={{ height: "394px" }}>
            <div className={styles.performanceView}>
              <div className={styles.activityCard}>
                <div className={styles.iconBox}>
                  <FeatherIcon icon="link" size={20} />
                </div>
                <div className={styles.activityContent}>
                  <p>{t("Link in Bio")}</p>
                  <p className={styles.subText}>
                    {t("Your LIB had 26 page views")}
                  </p>
                </div>
              </div>
              <Row gutter={25}>
                <Col span={24} className={styles.reactionsCol}>
                  <span
                    className={styles.iconeDescTitle}
                    style={{ color: "green" }}
                  >
                    {t("+ 1200% from prior 7 days")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className={styles.interactionCard} style={{ padding: "12px" }}>
              <Line {...config} />
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className={styles.journeyCard} style={{ height: "394px" }}>
            <div className={styles.performanceView}>
              <div className={styles.activityCard}>
                <div className={styles.iconBox}>
                  <FeatherIcon icon="twitch" size={20} />
                </div>
                <div className={styles.activityContent}>
                  <p>{t("Interaction")}</p>
                  <p className={styles.subText}>
                    {t("Your Interaction had 80")}
                  </p>
                </div>
              </div>
              <Row gutter={25}>
                <Col span={24} className={styles.reactionsCol}>
                  <span className={styles.iconeDescTitle}>
                    {t("+ 0% from prior 77 days")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className={styles.interactionCard} style={{ padding: "12px" }}>
              <Line {...configTwo} />
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className={styles.journeyCard} style={{ height: "394px" }}>
            <div className={styles.performanceView}>
              <div className={styles.activityCard}>
                <div className={styles.iconBox}>
                  <FeatherIcon icon="message-square" size={20} />
                </div>
                <div className={styles.activityContent}>
                  <p>{t("Reactions")}</p>
                  <p className={styles.subText}>{t("Your Reactions had 0")}</p>
                </div>
              </div>
              <Row gutter={25}>
                <Col span={24} className={styles.reactionsCol}>
                  <span className={styles.iconeDescTitle}>
                    {t("+ 800% from prior 7 days")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className={styles.interactionCard} style={{ padding: "12px" }}>
              <Line {...configThree} />
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className={styles.journeyCard} style={{ height: "394px" }}>
            <div className={styles.performanceView}>
              <div className={styles.activityCard}>
                <div className={styles.iconBox}>
                  <FeatherIcon icon="message-circle" size={20} />
                </div>
                <div className={styles.activityContent}>
                  <p>{t("My Response")}</p>
                  <p className={styles.subText}>
                    {t("Response had 23 counts")}
                  </p>
                </div>
              </div>
              <Row gutter={25}>
                <Col span={24} className={styles.reactionsCol}>
                  <span className={styles.iconeDescTitle}>
                    {t("+ 1200% from prior 53 days")}
                  </span>
                </Col>
              </Row>
            </div>
            <div className={styles.interactionCard} style={{ padding: "12px" }}>
              <DualAxes {...configFour} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
