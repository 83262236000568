import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// all interaction List API
export const allInteractionList = (params) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.integration.allList}/?page=${params?.page}&limit=${params?.limit}`
      );
      if (!listResp.data.error) {
        // message.success(listResp.data.message);
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// interaction detail API
export const interactionDetail = (id) => {
  return async (dispatch) => {
    try {
      const detailResp = await DataService.get(
        `${API.integration.detail}/${id}`
      );
      if (!detailResp.data.error) {
        return detailResp?.data;
      } else {
        message.error(detailResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// create interactionAPI CALL
export const createInteraction = (payload, uploadPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.integration.create,
        payload,
        {
          "Content-Type": "multipart/form-data",
        },
        uploadPercentage
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// update interaction API CALL
export const updateInteraction = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.integration.update}${id}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// delete interaction API CALL
export const deleteINteraction = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.integration.delete + id);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// replay to reaction api call
export const sendReplyToReaction = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.integration.reply + id,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

//  interaction reactions List API
export const interactionReactionsList = (params) => {
  // interaction_id=${id}&page=${params?.page}&limit=${params?.limit}`
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.integration.reactionList}?interaction_id=${params?.id}&page=${params?.page}&limit=${params?.limit}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

//  all reactions List API
export const allMyReactionsList = (params) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.integration.myreactionList}?page=${params?.page}&limit=${params?.limit}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// user reaction detail api
export const responseDetailApi = (id) => {
  return async (dispatch) => {
    try {
      const detailResp = await DataService.get(
        `${API.integration.responseDetail}/${id}`
      );
      if (!detailResp.data.error) {
        return detailResp?.data;
      } else {
        message.error(detailResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
