import { message } from "antd";
import { API } from "../../config/api/Index";
import { DataService } from "../../config/dataService/DataService";

export const getQueueList = (page, limit) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.queue.queueList + "?page=" + page + "&limit=" + limit
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const registerQueue = (payload, uploadPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.queue.queueRegister,
        payload,
        {},
        uploadPercentage
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const deleteQueue = (params) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.queue.queueDelete + params);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};

export const updateQueue = (params, payload, uploadPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.queue.queueUpdate + params,
        payload,
        {},
        uploadPercentage
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const getQueueDetails = (params) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.queue.getQueueDetails + params
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const joinQueue = (params, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.queue.joinQueue + params,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const queueDone = (params, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.queue.doneQueue + params,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const rejoinQueue = (params) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.queue.rejoinQueue + "?queueId=" + params
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const reviewQueue = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.queue.queueReview, payload);
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};

export const getPeopleOfQueue = (params) => {
  return async (dispatch) => {
    let queryString = "?";
    Object.keys(params).map((value) => {
      return (queryString += value + "=" + params[value] + "&");
    });
    try {
      const response = await DataService.get(
        API.queue.getPeopleQueue + queryString
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};

export const editQueueQuestion = (params, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.queue.editQuestion + params,
        payload
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const viewQueueReview = (params) => {
  let queryString = "?";
  Object.keys(params).map((value) => {
    return (queryString += value + "=" + params[value] + "&");
  });
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.queue.viewReview + queryString
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};

export const sendMessageOnWA = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.queue.sendMessaga, payload);
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
export const getQRCodeWA = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.queue.getQrCode);
      if (!response.data.error) {
        return response.data.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("error", err);
      return false;
    }
  };
};
