import Cookies from "js-cookie";
import { API } from "../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  login: Cookies.get("logedIn") == true || Cookies.get("logedIn") == "true" ? true : false,
  loading: false,
  userDetails: {},
  countryList: [],
};

//DEFINE AUTH REDUCER FUNCTION
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case API.auth.verifyOtp:
      return {
        ...state,
        login: action.login,
        userDetails: action.userDetails,
        loading: false,
      };
    case API.auth.countryList:
      return {
        ...state,
        countryList: action.countryList,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default AuthReducer;
