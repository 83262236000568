import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import "./SubscriptionMessage.css";
import Aos from "aos";
import { t } from "i18next";
import { ADMIN_URL } from "../../config/Constant";

const SubscriptionMessage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="stripPaymentDiv">
      <img
        src={require("../../assets/Images/ATZ2.svg")}
        onClick={() => {
          navigate("/");
        }}
      />
      <span>
        {window.location.href.split("?").pop().includes("success=true")
          ? t("Congratulations!")
          : t("Cancelled!")}{" "}
      </span>
      <span>
        {t("Your Payment Has Been")}{" "}
        {window.location.href.split("?").pop().includes("success=true")
          ? t("Successfully Processed!")
          : t("Cancelled!")}
      </span>
      <Button
        type="primary"
        onClick={() => {
          window.location.replace(
            `${ADMIN_URL}login`
            // API_ENDPOINT == "https://api.atz.ai/api/"
            //   ? "https://app.atz.ai/login"
            //   : "https://staging.atz.ai/login"
          );
        }}
        className="notFoundIntercaionButton"
      >
        {t("Go back")}
      </Button>
    </div>
  );
};

export default SubscriptionMessage;
