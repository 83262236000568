import React from "react";
import styles from "./SignIn.module.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Images/ATZ2.svg";
const SignInCommonLogo = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={styles.logoBox}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} />
      </div>
      <div className={styles.welcomeText}>
        <h2>{props.welcomeText}</h2>
        <div className={styles.signInText}>
          {props.subText ? <p>{props.subText}</p> : ""}
          {props.navigationText ? (
            <p className={styles.signUpText}>
              <NavLink to={props.navigationLink}>
                {props.navigationText}
              </NavLink>
            </p>
          ) : (
            ""
          )}
        </div>
        <p className={styles.emailIdText}>{props.emailId}</p>
      </div>
    </>
  );
};

export default SignInCommonLogo;
