import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// all booking List API
export const getMyBookingList = (params) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.myBooking.list}?is_expired=${params.is_expired}&type=${params.type}&page=${params?.page}&limit=${params?.limit}`
      );
      if (!listResp.data.error) {
        return listResp?.data.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// all booking List API
export const getMyBookingDetail = (id) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(API.myBooking.details + id);
      if (!listResp.data.error) {
        return listResp?.data?.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// check in booking
export const checkInBookingApi = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.myBooking.checkInBooking, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// refund_request_business (user side )
export const refundRequestApi = (purchase_id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.myBooking.refundRequest + purchase_id, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};