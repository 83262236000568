import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Card, Col, Modal, Row, Input, message, Dropdown, Space, Tooltip } from "antd";
import "./style.css";
import ListShowModal from "./ListShowModal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getUsersBookingList } from "../../../../../redux/userBooking/action";
import { el } from "date-fns/locale";
import { FilterTwoTone, FilterFilled, RetweetOutlined } from "@ant-design/icons";
import styled from "styled-components";

const { Search } = Input;
const ScheduleCalendar = () => {
  const [state, setState] = useState({
    currentMonth: 0,
    defaultValue: moment().format("YYYY-MM-DD"),
  });
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataList = useRef();
  const [searchList, setSearchList] = useState([]);
  const uniqueDate = [];
  const [filterStatus, setFilterStatus] = useState("");
  const [filterProduct, setFilterProduct] = useState(false);

  useLayoutEffect(() => {
    if (dataList.current.querySelector("tr") === null) {
      document.querySelector(".emptyData").style.display = "flex";
    } else {
      document.querySelector(".emptyData").style.display = "none";
    }
  });

  useEffect(() => {
    userBookingListData(
      moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD"),
      moment(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ).format("YYYY-MM-DD")
      , ''
    );
  }, []);

  const userBookingListData = async (start_date, end_date, status) => {
    const dataList = await dispatch(getUsersBookingList(start_date, end_date, status));
    if (dataList == false && status !== '') {
      message.error('No booking data found for this status.', 5)
    }
    if (dataList) {
      let output = [];
      dataList?.data?.map((item) => {
        const bookingId = item.booking_id._id;
        const _id = item._id;
        let bookedTimeSlot = item.booked_time_slot;
        const bookingTitle = item.booking_id.title;
        const bookingStatus = item.status;
        let startDate = moment(item.start_date).format("MM/DD/YYYY");
        let endDate = moment(item.end_date).format("MM/DD/YYYY");

        if (bookedTimeSlot.length) {
          bookedTimeSlot = bookedTimeSlot[0].split("-");
        } else {
          bookedTimeSlot = ["", ""];
        }
        let checkValueExist = output.findIndex(
          (e) =>
            e.bookingId == bookingId &&
            e.date[0] == startDate &&
            e.date[1] == endDate
        );
        if (checkValueExist >= 0) {
          output[checkValueExist].count++;
          output[checkValueExist].data.push({
            _id: _id,
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            start_date: item.start_date,
            end_date: item.end_date,
            booked_time_slot:
              bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                ? `${moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                  "HH:mm"
                )}-${moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                  "HH:mm"
                )}`
                : "-",
          });
        } else {
          output.push({
            date: [startDate, endDate],
            time:
              bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                ? [
                  moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                    "HH:mm"
                  ),
                  moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                    "HH:mm"
                  ),
                ]
                : ["", ""],
            title: bookingTitle,
            label: bookingStatus,
            bookingId: bookingId,
            count: 1,
            data: [
              {
                _id: _id,
                title: bookingTitle,
                label: bookingStatus,
                bookingId: bookingId,
                start_date: item.start_date,
                end_date: item.end_date,
                booked_time_slot:
                  bookedTimeSlot[0] != "" && bookedTimeSlot[1] != ""
                    ? `${moment(moment(bookedTimeSlot[0], "hh:mm")).format(
                      "HH:mm"
                    )}-${moment(moment(bookedTimeSlot[1], "hh:mm")).format(
                      "HH:mm"
                    )}`
                    : "-",
              },
            ],
          });
        }
      });
      // console.log("Schedule Calendar Data", output);
      setBookingList(output);
    }
  };

  const { currentMonth } = state;

  const onIncrement = () => {
    userBookingListData(
      moment()
        .add(currentMonth + 1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment()
        .add(currentMonth + 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD")
      , ''
    );
    return setState({
      ...state,
      currentMonth: currentMonth + 1,
    });
  };

  const onDecrement = () => {
    userBookingListData(
      moment()
        .add(currentMonth - 1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment()
        .add(currentMonth - 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD")
      , ''
    );
    setState({
      ...state,
      currentMonth: currentMonth - 1,
    });
  };


  const searchFilterData = (status) => {
    userBookingListData(moment(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ).format("YYYY-MM-DD"),
      moment(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ).format("YYYY-MM-DD")
      , status
    )
  }

  const items = [
    {
      key: "upcoming",
      label: (
        <div
          onClick={() => {
            searchFilterData("upcoming");
            setFilterProduct(true);
          }}
        >
          <span>Upcoming</span>
        </div>
      ),
    },
    {
      key: "expired",
      label: (
        <div
          onClick={() => {
            searchFilterData("expired");
            setFilterProduct(true);
          }}
        >
          <span>Expired</span>
        </div>
      ),
    },
    {
      key: "active",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("active");
            setFilterProduct(true);
          }}
        >
          <span>Active</span>
        </div>
      ),
    },
    {
      key: "refund_request",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("refund_request");
            setFilterProduct(true);
          }}
        >
          <span>Refund Request</span>
        </div>
      ),
    },
    {
      key: "cancelled",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("cancelled");
            setFilterProduct(true);
          }}
        >
          <span>Cancelled</span>
        </div>
      ),
    },
    {
      key: "cancel_request",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("cancel_request");
            setFilterProduct(true);
          }}
        >
          <span>Cancelled Request</span>
        </div>
      ),
    },
    {
      key: "refunded",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("refunded");
            setFilterProduct(true);
          }}
        >
          <span>Refunded</span>
        </div>
      ),
    },
    {
      key: "completed",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("completed");
            setFilterProduct(true);
          }}
        >
          <span>Completed</span>
        </div>
      ),
    },
    {
      key: "reset",
      label: (
        <div
          className="filterListItem"
          onClick={() => {
            searchFilterData("");
            setFilterProduct(true);
          }}
        >
          <span><RetweetOutlined /> Reset</span>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Modal
        footer={null}
        type="primary"
        title={<span>{t("Selected Booking List")}</span>}
        open={showDetailsModal}
        onCancel={() => {
          setShowDetailsModal(false);
          setSelectedBookingId("");
        }}
        width={800}
        centered
      >
        <ListShowModal selectedBookingId={selectedBookingId} />
      </Modal>
      <div className="calendar-header">
        <div className="calendar-header__left">
          <Button
            onClick={() => {
              navigate(`/users-booking-list/today`, {
                replace: true,
              });
            }}
            className="btn-today"
            type="white"
            outlined
          >
            <NavLink replace={true} to="/users-booking-list/today">
              {t("Today")}
            </NavLink>
          </Button>
          <div className="calender-head__navigation">
            <Button
              onClick={onDecrement}
              className="btn-navigate"
              type="white"
              outlined
            >
              <FeatherIcon icon="chevron-left" size={22} />
            </Button>
            <span className="date-label">
              {`${t(moment().add(currentMonth, "month").format("MMMM"))} ${t(
                moment().add(currentMonth, "month").format("YYYY")
              )} - 
                  ${t(
                moment()
                  .add(currentMonth + 1, "month")
                  .format("MMMM")
              )} ${t(
                moment()
                  .add(currentMonth + 1, "month")
                  .format("YYYY")
              )}
                  `}
            </span>
            <Button
              onClick={onIncrement}
              className="btn-navigate"
              type="white"
              outlined
            >
              <FeatherIcon icon="chevron-right" size={22} />
            </Button>
          </div>
        </div>
        <div className="calendar-header__right">
          <ul>
            <li>
              <NavLink replace={true} to="/users-booking-list/day">
                {t("Day")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/week">
                {t("Week")}
              </NavLink>
            </li>
            <li>
              <NavLink replace={true} to="/users-booking-list/month">
                {t("Month")}
              </NavLink>
            </li>
          </ul>
          <NavLink
            replace={true}
            className="schedule-list"
            to="/users-booking-list/schedule"
          >
            <FeatherIcon icon="list" />
            {t("Schedule")}
          </NavLink>
          <Tooltip title='Filter data by Booking Status' placement='top'>
            <Dropdown
              menu={{
                items,
                selectable: true,
                defaultSelectedKeys: ["reset"],
              }}
              trigger={['click']}
            >
              {filterProduct == true ? (
                <FilterTwoTone
                  className='filterIcon'
                  twoToneColor="#d50c0c"
                  style={{ fontSize: '26px' }}
                  size={30}
                />
              ) : (
                <FilterFilled className='filterIcon' size={30} style={{ fontSize: '26px' }} />
              )}
            </Dropdown>
          </Tooltip>
        </div>
      </div>

      {/* <div className='searchBoxContainer'>
        <Search
          className="bookingSearch"
          placeholder="Search booking by Id"
          onSearch={(value, _e, info) => searchFilterData(value)}
          enterButton
          allowClear
          size='large'
        />


      </div> */}
      <div className="emptyData">{t("There is No Event Available")}</div>
      <table className="table-event schedule-event" width="100%">
        <tbody ref={dataList}>
          {
            searchList && searchList.length > 0 ?
              searchList && searchList?.map((event) => {
                uniqueDate.push(event.date[0]);
                return false;
              })
              :
              bookingList?.map((event) => {
                uniqueDate.push(event.date[0]);
                return false;
              })
          }
          {[...new Set(uniqueDate)].map((date, index) => {
            return (
              moment(date).format("MM") >=
              moment().add(currentMonth, "month").format("MM") &&
              moment(date).format("MM") <=
              moment()
                .add(currentMonth + 1, "month")
                .format("MM") && (
                <tr key={index + 1}>
                  <td className="schedule-time">
                    <span className="schedule-date">
                      {moment(date).format("DD MMM")}
                    </span>
                    <span className="schedule-date-name">
                      {moment(date).format("ddd")}
                    </span>
                  </td>
                  <td className="schedule-time-data">
                    {bookingList
                      .filter((item) => item.date[0] === date)
                      .map((item, ind) => (
                        <Row
                          key={ind + 1}
                          onClick={() => {
                            setShowDetailsModal(true);
                            setSelectedBookingId(item?.data);
                          }}
                        >
                          <Col xxl={4} xl={8} md={10} sm={24} xs={24}>
                            <span className={`bullet ${item.label}`} />
                            <span className="event-title">
                              {`${item.time[0]} - ${item.time[1]}`}
                            </span>
                          </Col>
                          <Col xxl={4} xl={8} md={6} sm={24} xs={24}>
                            <span className="schedule-time">
                              {" "}
                              {item?.label == "upcoming" ? "Upcoming"
                                : item?.label == "expired" ? "Expired"
                                  : item?.label == "active" ? "Active"
                                    : item?.label == "refund_request" ? "Refund Request"
                                      : item?.label == "cancelled" ? "cancelled"
                                        : item?.label == "cancel_request" ? "Cancelled Request"
                                          : item?.label == "refunded" ? "Refunded"
                                            : item?.label == "completed" ? "Completed"
                                              : item?.label} {t("Booking")}
                            </span>
                          </Col>
                          <Col xxl={16} xl={8} md={8} sm={24} xs={24}>
                            <span className="event-title">
                              {" "}
                              {`${item.title} (${item.count})`}
                            </span>
                          </Col>
                        </Row>
                      ))}
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default ScheduleCalendar;
