import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";
import { getItem } from "../../utility/LocalStorageControl";

// update interaction API CALL
export const updateProfile = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.profile.update}${id}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return { status: true, data: response.data.data };
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      message.error(err);
      return { status: false };
    }
  };
};

//get user DEtail api
export const getUserDetailData = (username) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.profile.getUserDetail}${username}?country_iso_code=${
          getItem("country_details") && getItem("country_details")?.country_code
        }`
      );
      if (!response.data.error) {
        // message.success(response.data.message);
        return { status: true, data: response.data.data };
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      message.error(err);
      return { status: false };
    }
  };
};
