import React, { useState, useEffect, useRef } from "react";
import styles from "./Profile.module.css";
import {
  Avatar,
  Button,
  Row,
  Col,
  Form,
  Input,
  Spin,
  message,
  Select,
  Modal,
  Space,
} from "antd";
import { getItem } from "../../../../utility/LocalStorageControl";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  updateProfile,
  getUserDetailData,
} from "../../../../redux/profileSetting/action";
import { setItem } from "../../../../utility/LocalStorageControl";
import { useTranslation } from "react-i18next";
import {
  checkValidUserName,
  countryList,
  updateReduxUserDetails,
} from "../../../../redux/authentication/action";
import i18next from "i18next";
import { LANGUAGES, WEBSITE_URL } from "../../../../config/Constant";
import Cookies from "js-cookie";

function Profile() {
  const { t } = useTranslation();
  const [previewMedia, setPreviewMedia] = useState([]);
  const [img, setImg] = useState([]);
  const [id, setId] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const use_Detail = getItem("userDetails");
  const [userImg, setUserImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [formSetValueDetail, setFormSetValueDetail] = useState({});
  const [countryListLoading, setCountryListLoading] = useState(true);
  const [countryDataList, setCountryDataList] = useState([]);
  const [countryNameList, setCountryNameList] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const inputFile = useRef(null);
  const [shortUrl, setShortUrl] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [editUrlModal, setEditUrlModal] = useState(false);
  const [userNameLoading, setUserNameLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const [urlForm] = Form.useForm();
  const [useDetail, setUseDetail] = useState({});
  const [validUserNameStatus, setValidUserNameStatus] = useState(true);
  const [name, setName] = useState(use_Detail?.name);
  const [selectedLangauge, setSelectedLangauge] = useState(
    getItem("apiLanguageSelection")
  );
  const [countryIsoList, setCountryIsoList] = useState([]);

  useEffect(() => {
    userDetailData(use_Detail?.username);
    getCountryList();
  }, []);

  // DEFINE USEEFFECT FOR SEARCH CHANNEL DEBOUNCE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUserNameCheck(userName);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [userName]);

  const onValidUserNameCheck = async (value) => {
    if (use_Detail?.username == value || value == "") {
      setUserNameLoading(false);
    } else {
      const response = await dispatch(checkValidUserName(value));
      if (response) {
        setSubmitButtonDisabled(false);
        setUserNameLoading(false);
        setValidUserNameStatus(true);
      } else {
        setSubmitButtonDisabled(true);
        setUserNameLoading(false);
        setValidUserNameStatus(false);
      }
    }
  };

  const getCountryList = async () => {
    setCountryListLoading(true);
    let response = await dispatch(countryList());
    if (response.status) {
      if (response.data.length > 0) {
        setCountryListLoading(false);
        setCountryDataList(response.data);
        if (
          response.data.find(
            (item) =>
              item.country.name === use_Detail?.countryObject?.countryName
          )
        ) {
          setCountryValue(
            response.data.find(
              (item) =>
                item.country.name === use_Detail?.countryObject?.countryName
            )?.country?.name
          );
        } else {
          setCountryValue("");
        }
        setCountryIsoList(
          response.data.map(function (item) {
            return {
              label: item?.country?.alpha2Code,
              value: item?.country?.name,
            };
          })
        );
        setCountryNameList(
          response.data.map(function (item) {
            return {
              label: item?.country?.name,
              value: item?.country?.name,
            };
          })
        );
      }
    } else {
      setCountryListLoading(true);
    }
  };

  const userDetailData = async (val) => {
    setLoading(true);
    let username = val && val ? val : Cookies.get("username")

    let response = await dispatch(getUserDetailData(username));
    if (response) {
      setUseDetail(response?.data);
      setId(response?.data?._id);
      setUserImg(response?.data?.user_image);
      setLoading(false);
      setSubmitButtonDisabled(
        response?.data?.language_code == selectedLangauge
      );
      setFormSetValueDetail({
        language_code: response?.data?.language_code
          ? response?.data?.language_code
          : selectedLangauge,
        name: response?.data?.name,
        phone_number: response?.data?.mobile,
        language: response?.data?.countryObject?.countryName,
        new_username: response?.data?.username,
      });
      form.setFieldsValue({
        language_code: response?.data?.language_code
          ? response?.data?.language_code
          : selectedLangauge,
        email: response?.data?.email,
        language: response?.data?.countryObject?.countryName,
        name: response?.data?.name,
        phone_number: response?.data?.mobile,
        new_username: response?.data?.username,
      });
    }
  };

  const handleSubmit = async (value) => {
    if (validUserNameStatus == true && userNameLoading == false) {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("name", name);
      if (mobileNumber) {
        form_data.append("phone_number", mobileNumber.toString());
      }
      if (img !== "") {
        form_data.append("user_image", img);
      }
      form_data.append(
        "country_iso_code",
        countryValue == ""
          ? useDetail?.countryObject?.iso
          : countryDataList.find((item) => item.country.name === countryValue)
            ?.country?.alpha2Code
      );
      setItem("country_details", {
        country_code:
          countryValue == ""
            ? useDetail?.countryObject?.iso
            : countryDataList.find((item) => item.country.name === countryValue)
              ?.country?.alpha2Code,
        country_name:
          countryValue == ""
            ? useDetail?.countryObject?.countryName
            : countryDataList.find((item) => item.country.name === countryValue)
              ?.country?.name,
      });

      form_data.append("language_code", selectedLangauge);
      const userInfo = await dispatch(updateProfile(form_data, id));
      if (userInfo.status == true) {
        setLoading(false);
        setSubmitButtonDisabled(true);
        setUseDetail(userInfo?.data);
        userDetailData(userInfo?.data?.username);
        setItem("userDetails", userInfo?.data);
        await dispatch(updateReduxUserDetails(userInfo?.data));
      } else {
        setSubmitButtonDisabled(false);
        setLoading(false);
      }
    }
  };

  const changeHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files[0]) {
      if (e.target.files[0]["type"]?.split("/")[0] == "image") {
        setImg(e.target.files[0]);
        setPreviewMedia(
          <div className={styles.previewImgContainer}>
            <img
              crossOrigin="anonymous"
              alt=""
              src={URL.createObjectURL(e.target.files[0])}
            />
          </div>
        );
      } else {
        message.error(t("please select image"));
      }
    }
  };

  const confirmEditUrl = async (val) => {
    const form_data = new FormData();
    form_data.append("short_url", val.edit_short_url);

    const editShortUrl = await dispatch(updateProfile(form_data, id));
    if (editShortUrl) {
      setEditUrlModal(false);
      setShortUrl("");
      userDetailData();
      setItem("userDetails", editShortUrl?.data);
    }
  };

  const handleCancel = () => {
    setEditUrlModal(false);
    setShortUrl("");
  };

  const onCheckFormChangeValue = (value) => {
    let getFiledValues = {
      language_code: value == "" ? selectedLangauge : value,
      name: form.getFieldsValue()?.name,
      phone_number: form.getFieldsValue()?.phone_number,
    };
    let defaultValue = {
      language_code: formSetValueDetail?.language_code,
      name: formSetValueDetail?.name,
      phone_number: formSetValueDetail?.phone_number,
    };
    setSubmitButtonDisabled(
      JSON.stringify(getFiledValues) === JSON.stringify(defaultValue)
    );
  };

  const handleMenuClick = (e) => {
    setItem("apiLanguageSelection", e);
    i18next.changeLanguage(e);
    setSelectedLangauge(e);
  };

  return (
    <div className={styles.profileContainer}>
      {loading ? (
        <div className={styles.loadArbOX}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            layout="vertical"
            autoComplete="off"
            className="userInfoForm"
            onFinish={(value) => handleSubmit(value)}
            form={form}
            onValuesChange={(value) => {
              onCheckFormChangeValue("");
            }}
          >
            <Row>
              <Col xxl={6} xl={6} lg={24} md={6} sm={24} xs={24}>
                <div className={styles.userImg}>
                  {Object.keys(previewMedia).length !== 0 ? (
                    previewMedia
                  ) : userImg !== "" ? (
                    <div className={styles.previewImgContainer}>
                      <img alt="" src={userImg} />
                    </div>
                  ) : (
                    <Avatar
                      size={130}
                      icon={<FeatherIcon icon="user" size={40} />}
                    />
                  )}
                  <div
                    className={styles.editImg}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <FeatherIcon icon="edit" size={18} />
                  </div>
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={changeHandler.bind(this)}
                />
              </Col>
              <Col xxl={18} xl={18} lg={24} md={18} sm={24} xs={24}>
                <div className={styles.profileInfo}>
                  <Row gutter={25}>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name="new_username"
                        label={t("username")}
                        className={styles.inputMain}
                      >
                        <div className={styles.inputContainer}>
                          <Input
                            disabled={true}
                            className={styles.profileEmailInputFiled}
                            placeholder={t("Enter your user Name")}
                            defaultValue={useDetail?.username}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="name"
                        label={t("Name")}
                        rules={[
                          {
                            required: true,
                            message: t("Please Enter name!"),
                          },
                        ]}
                        className={styles.inputMain}
                      >
                        <div className={styles.inputContainer}>
                          <Input
                            className={styles.profileInputFiled}
                            placeholder={t("Enter name")}
                            onKeyDown={(event) => {
                              if (event.code === "Space")
                                event.preventDefault();
                            }}
                            defaultValue={useDetail?.name}
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                            disabled={false}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label={t("mobile number")}
                        name="phone_number"
                        className={styles.inputMain}
                      >
                        <Space direction="vertical" size="middle">
                          <Space.Compact>
                            <Select
                              className="profileCountrySelectBox"
                              defaultValue={
                                useDetail?.countryObject?.countryName
                              }
                              value={countryValue}
                              bordered="false"
                              size="large"
                              showSearch
                              style={{
                                width: "30%",
                                height: "46px",
                                textAlign: "center",
                              }}
                              loading={countryListLoading}
                              placeholder={t("Select country code")}
                              optionFilterProp="children"
                              required
                              onChange={(value) => {
                                setSubmitButtonDisabled(
                                  formSetValueDetail?.language == value
                                );
                                setCountryValue(value);
                              }}
                              filterOption={(input, option) =>
                                (option?.value ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={countryIsoList}
                            />
                            <Input
                              style={{
                                width: "70%",
                              }}
                              className={styles.profileInputFiled}
                              disabled={false}
                              type="tel"
                              maxLength={10}
                              placeholder={
                                useDetail?.mobile !== ""
                                  ? t("mobile number")
                                  : t("Add mobile number")
                              }
                              defaultValue={
                                mobileNumber !== null
                                  ? mobileNumber
                                  : useDetail?.mobile !== ""
                                    ? useDetail?.mobile
                                    : "-"
                              }
                              onChange={(e) => {
                                setMobileNumber(e.target.value.slice(0, 10));
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                );
                              }}
                              onKeyDown={(event) => {
                                if (
                                  event.key == "." ||
                                  event.key === "-" ||
                                  event.key == "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Space.Compact>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      {" "}
                      <Form.Item
                        name={"language_code"}
                        label={"Language"}
                        className={styles.inputMain}
                        style={{
                          marginBottom: "18px",
                        }}
                      >
                        <div className={styles.inputContainer}>
                          <Select
                            className="profileCountrySelectBox"
                            defaultValue={getItem("apiLanguageSelection")}
                            bordered="false"
                            size="large"
                            showSearch
                            style={{ height: "46px", textAlign: "center" }}
                            placeholder={t("Select your language")}
                            optionFilterProp="children"
                            onChange={(value) => {
                              onCheckFormChangeValue(value);
                              handleMenuClick(value);
                            }}
                            value={selectedLangauge}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={LANGUAGES}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      {" "}
                      <Form.Item
                        name="language"
                        label={t("Country")}
                        className={styles.inputMain}
                        style={{
                          marginBottom: "18px",
                        }}
                      >
                        <div className={styles.inputContainer}>
                          <Select
                            className="profileCountrySelectBox"
                            defaultValue={useDetail?.countryObject?.countryName}
                            value={countryValue}
                            bordered="false"
                            size="large"
                            showSearch
                            style={{ height: "46px", textAlign: "center" }}
                            loading={countryListLoading}
                            placeholder={t("Select your country")}
                            optionFilterProp="children"
                            required
                            onChange={(value) => {
                              setSubmitButtonDisabled(
                                formSetValueDetail?.language == value
                              );
                              setCountryValue(value);
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countryNameList}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name="email"
                        label={t("Email")}
                        className={styles.inputMain}
                      >
                        <div className={styles.inputContainer}>
                          <Input
                            disabled={true}
                            className={styles.profileEmailInputFiled}
                            placeholder={t("Enter your mail Id")}
                            defaultValue={useDetail?.email}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}></Col>
              <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
                <div className={styles.createBtnContainer}>
                  <Button
                    className={styles.createBtn}
                    onClick={() => {
                      if (use_Detail?.userName == name) {
                        form.submit();
                      } else {
                        handleSubmit();
                      }
                    }}
                    style={{
                      cursor: submitButtonDisabled ? "no-drop" : "pointer",
                      background: submitButtonDisabled
                        ? "#ff8ace"
                        : "rgb(223 71 153)",
                      color: !submitButtonDisabled
                        ? "white"
                        : "rgb(251 251 251 / 69%)",
                    }}
                    disabled={submitButtonDisabled}
                  >
                    <div className={styles.buttonContent}>{t("Submit")}</div>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <Modal
            title={t("Edit Short URL")}
            centered={true}
            open={editUrlModal}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              onFinish={(value) => confirmEditUrl(value)}
              form={urlForm}
              layout="vertical"
              className={styles.urlModal}
            >
              <Form.Item
                name="Edit Short URL"
                rules={[
                  {
                    required: true,
                    message: t("Please Enter url!"),
                  },
                  {
                    pattern: new RegExp(
                      /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                    ),
                    message: t(
                      "URL can not contain whitespace or upper case letters"
                    ),
                  },
                ]}
                label={t("Short URL")}
                className={styles.inputMain}
              >
                <Input
                  className={styles.profileInputFiled}
                  size="large"
                  prefix={
                    <>
                      <b className={styles.backslash}>{WEBSITE_URL}</b>
                    </>
                  }
                  defaultValue={shortUrl}
                  onChange={(value) => {
                    setShortUrl(value.target.value);
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                  }}
                  placeholder={t("Enter url")}
                  disabled={false}
                  autoFocus={true}
                />
              </Form.Item>

              <div className={styles.createBtnContainer}>
                <Button className={styles.cancelBtn} onClick={handleCancel}>
                  <div className={styles.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button
                  className={styles.createBtn}
                  onClick={urlForm.submit}
                  style={{
                    cursor: shortUrl == "" ? "no-drop" : "pointer",
                    background: shortUrl == "" ? "#ff8ace" : "rgb(223 71 153)",
                    color: shortUrl == "" ? "rgb(251 251 251 / 69%)" : "white",
                  }}
                  disabled={shortUrl == "" ? true : false}
                >
                  <div className={styles.buttonContent}>{t("Submit")}</div>
                </Button>
              </div>
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Profile;
