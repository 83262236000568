import React, { useState, useEffect, useRef } from "react";
import style from "./BookingDetail.module.css";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    Row,
    QRCode,
    Divider,
    Select,
    Modal,
    Form,
    Input,
    Switch,
    Radio,
    Skeleton,
    Tooltip,
    message,
    DatePicker,
    TimePicker,
    InputNumber,
    Checkbox,
    Table,
    Pagination,
    Spin,
    Empty,
    Calendar,
    theme,
    Popover,
    Descriptions,
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import { getItem } from "../../../../utility/LocalStorageControl";
import { useNavigate, useParams } from "react-router-dom";
// import moment from "moment";
import { getBookingDetail } from "../../../../redux/booking/action";
import { createSlotApi, updateTimeSlotApi, getCategoryList, getByDetailAndFilter } from "../../../../redux/booking/action";
import dayjs from "dayjs";
import { format, addDays, parse, eachDayOfInterval } from "date-fns";
import { DateRange, DayPicker, DayClickEventHandler } from "react-day-picker";
import { QrcodeOutlined, EyeOutlined, ClockCircleOutlined } from "@ant-design/icons";
import "react-day-picker/dist/style.css";
import { el } from "date-fns/locale";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import moment from "moment-timezone";
import { WEBSITE_URL } from "../../../../config/Constant";

const { RangePicker } = DatePicker;
const BookingDetail = () => {
    var today = new Date();
    dayjs.extend(utc);
    dayjs.extend(tz);
    dayjs.extend(duration);
    var timeZone = dayjs.tz.guess();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [addSlotForm] = Form.useForm();
    const [editSlotForm] = Form.useForm();
    const [deleteSlotForm] = Form.useForm();
    const { token } = theme.useToken();
    const wrapperStyle = {
        width: "100%",
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };
    const bookedStyle = {
        border: "1px solid white",
        background: "#ffe8f7",
        color: "#ff3191",
        fontWaite: "500",
    };

    const [currency, setCurrency] = useState("");
    const [userLocal, setUserLocal] = useState("en-US");
    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [subCategoryName, setSubCategoryName] = useState("");
    const [openAddSlotModal, setOpenAddSlotModal] = useState(false);
    const [openEditSlot, setOpenEditSlot] = useState(false);
    const [slotLoading, setSlotLoading] = useState(false);
    const [slotDate, setSlotDate] = useState(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
    const [selectedTime, setSelectedTime] = useState([]);
    const [slotList, setSlotList] = useState([]);
    const [selectCalendarDate, setSelectCalendarDate] = useState(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
    const [slotObj, setSlotObj] = useState({});
    const [editSlotTime, setEditSlotTime] = useState([]);
    const [openDeleteSlot, setOpenDeleteSlot] = useState(false);
    const [bookingDateArr, setBookingDateArr] = useState([]);
    const [disable, setDisable] = useState([]);
    const [calenderMsg, setCalenderMsg] = useState("");
    const [selectedTabIndex, setSelectedTabIndex] = useState(null);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);

        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenSize]);

    useEffect(() => {
        if (id !== undefined && id !== null && id !== "") {
            fetchDetail(id);
        }
        const browserLang = window.navigator.language || window.navigator.userLanguage || "en-US";
        setUserLocal(browserLang);
        allCategoryList();
    }, []);

    const getBookedDays = (startDate, endDate, bookedWeekdays) => {
        const dayAbbreviations = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
        const bookedDays = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const dayAbbreviation = dayAbbreviations[currentDate.getDay()];
            if (bookedWeekdays.includes(dayAbbreviation)) {
                bookedDays.push(new Date(currentDate)); // Copy the date to avoid reference issues
            }
            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return bookedDays;
    };

    const findNextAvailableDate = (array) => {
        const today = new Date();
        const sortedBookingDates = array.sort((a, b) => new Date(a) - new Date(b));
        if (selectCalendarDate && selectCalendarDate >= today) {
            // If the current selected date is not expired, return it
            return moment(new Date(selectCalendarDate), "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD");
        }
        for (const bookingDate of sortedBookingDates) {
            const date = new Date(bookingDate);
            if (date >= today && date !== selectCalendarDate) {
                return moment(new Date(date), "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD");
            }
        }
        if (sortedBookingDates[0] && new Date(sortedBookingDates[0]) < today) {
            return moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD");
        }
    };

    // fetch booking details
    const fetchDetail = async (val) => {
        setLoading(true);
        const resp = await dispatch(getBookingDetail(val));
        if (resp && resp?.data) {
            setLoading(false);
            setDetail(resp && resp?.data);
            setCurrency(resp?.data?.countryObject?.currency);
            setCategoryName(resp?.data?.category_name);
            setSubCategoryName(resp?.data?.sub_category_name);
            setCalenderMsg(`Available booking dates for this booking is from ${moment(resp?.data?.start_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                "YYYY-MM-DD"
            )} 
            to ${moment(resp?.data?.end_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD")}.`);
            setBookingDateArr(
                getBookedDays(new Date(resp?.data?.start_date), new Date(resp?.data?.end_date), resp?.data?.week_days).length > 0
                    ? getBookedDays(new Date(resp?.data?.start_date), new Date(resp?.data?.end_date), resp?.data?.week_days)
                    : eachDayOfInterval({
                        start: format(parse(resp?.data?.start_date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()), "yyyy-MM-dd"),
                        end: format(parse(resp?.data?.end_date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()), "yyyy-MM-dd"),
                    })
            );
            fetchDetailByFilter(selectCalendarDate, resp?.data?.category_name, resp?.data?.sub_category_name);
            if (getBookedDays(new Date(resp?.data?.start_date), new Date(resp?.data?.end_date), resp?.data?.week_days).length > 0) {
                if (moment(resp?.data?.start_date).format("YYYY-MM-DD") < moment(today).format("YYYY-MM-DD")) {
                    setSelectCalendarDate(
                        findNextAvailableDate(getBookedDays(new Date(resp?.data?.start_date), new Date(resp?.data?.end_date), resp?.data?.week_days))
                    );
                } else if (new Date(resp?.data?.start_date) == new Date(today)) {
                    setSelectCalendarDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                } else {
                    setSelectCalendarDate(
                        moment(
                            getBookedDays(new Date(resp?.data?.start_date), new Date(resp?.data?.end_date), resp?.data?.week_days)[0],
                            "YYYY-MM-DDTHH:mm:ss.SSSSZ"
                        ).format("YYYY-MM-DD")
                    );
                }
            } else {
                if (moment(resp?.data?.start_date).format("YYYY-MM-DD") > moment(today).format("YYYY-MM-DD")) {
                    setSelectCalendarDate(moment(resp?.data?.start_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                } else {
                    setSelectCalendarDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                }
            }
        } else {
            setLoading(false);
            setDetail({});
        }
    };

    useEffect(() => {
        if (selectedTabIndex !== null) {
            // Dynamically append CSS styles
            var myElement = document.getElementById("dayPicker");
            var styleElement = document.createElement("style");
            styleElement.textContent = `
        [tabindex="${selectedTabIndex}"] {
          background-color:${selectedTabIndex == 0 ? "#da0266 !important" : "#ffe8f7"};
          color: ${selectedTabIndex == 0 ? "white !important" : "#ff3191"};
          font-weight:${selectedTabIndex == 0 ? 600 : 500}
        }
      `;
            myElement.appendChild(styleElement);
        }
    }, [selectedTabIndex]);

    // category / subcategory list
    const allCategoryList = async () => {
        const dataList = await dispatch(getCategoryList());
        if (dataList) {
            setCategoryList(dataList?.data);
        }
    };

    useEffect(() => {
        fetchDetailByFilter(selectCalendarDate, categoryName, subCategoryName);
    }, [selectCalendarDate]);

    const fetchDetailByFilter = async (date, category, subCategory) => {
        setSlotLoading(true);
        const param = {
            booking_id: id,
            date: date,
            category: category,
            sub_category: subCategory,
        };
        const resp = await dispatch(getByDetailAndFilter(param));
        if (resp && resp?.data) {
            setSlotLoading(false);
            setSlotList(resp?.data?.time_slot);
        } else {
            setSlotLoading(false);
            setSlotList([]);
        }
    };

    // formate currency number according user local
    function formatNumberToLocalCurrency(amount, currency) {
        const formatter = new Intl.NumberFormat(userLocal.toString(), {
            style: "currency",
            currency: currency || "USD",
        });
        return formatter.format(amount);
    }

    // manage slot date
    const onchangeDate = (value, dateString) => {
        if (value !== null && dateString) {
            setSlotDate(dateString);
        }
    };

    const handleAddSlotSubmit = async (val) => {
        const payload = {
            booking_id: id,
            date: slotDate,
            slot_start_time: selectedTime[0],
            slot_end_time: selectedTime[1],
        };
        if (selectedTime.length !== 0) {
            const confirmCreateSlot = await dispatch(createSlotApi(payload));
            if (confirmCreateSlot) {
                setSelectedTime([]);
                setSlotDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                addSlotForm.resetFields();
                fetchDetailByFilter(selectCalendarDate, categoryName, subCategoryName);
                setOpenAddSlotModal(false);
            }
        }
    };

    const handleEditSlotSubmit = async (method, val) => {
        if (method == "edit") {
            const payload = {
                is_deleted: false,
                date_id: slotObj?.date_id,
                slot_id: slotObj?.slot_id,
                slot: `${selectedTime[0]}-${selectedTime[1]}`,
            };
            if (selectedTime.length !== 0) {
                const confirmEditSlot = await dispatch(updateTimeSlotApi(payload));
                if (confirmEditSlot) {
                    setSlotObj({});
                    setSelectedTime([]);
                    setEditSlotTime([]);
                    editSlotForm.resetFields();
                    fetchDetailByFilter(selectCalendarDate, categoryName, subCategoryName);
                    setOpenEditSlot(false);
                }
            }
        } else {
            const payload = {
                is_deleted: true,
                date_id: slotObj?.date_id,
                slot_id: slotObj?.slot_id,
                slot: "",
            };
            if (method === "delete" && openDeleteSlot == true) {
                const confirmEditSlot = await dispatch(updateTimeSlotApi(payload));
                if (confirmEditSlot?.error == false) {
                    setSlotObj({});
                    setSelectedTime([]);
                    setEditSlotTime([]);
                    deleteSlotForm.resetFields();
                    fetchDetailByFilter(selectCalendarDate, categoryName, subCategoryName);
                    setOpenDeleteSlot(false);
                }
            }
        }
    };

    const handleSlotCancel = () => {
        if (openDeleteSlot == true || openEditSlot == true) {
            setSlotObj({});
            setSelectedTime([]);
            setOpenEditSlot(false);
            setOpenDeleteSlot(false);
            setEditSlotTime([]);
            editSlotForm.resetFields();
        } else {
            addSlotForm.resetFields();
            setOpenAddSlotModal(false);
            setSelectedTime([]);
            setSlotDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        }
    };

    const disabledDates = bookingDateArr.map((dateString) => new Date(dateString));
    // Function to check if a date should be disabled
    const isDateDisabled = (date) => {
        return !disabledDates.some((disabledDate) => disabledDate.toDateString() === date.toDateString());
    };

    return (
        <>
            <div className={style.detailContainer}>
                {loading ? (
                    <div className={style.loadArbOX}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <div className={style.backBtn} onClick={() => navigate("/booking-list")}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="rgb(206 19 119 / 54%)"
                                height="inherit"
                                width="inherit"
                                style={{
                                    objectFit: "cover",
                                }}
                                version="1.1"
                                id="Layer_1"
                                viewBox="0 0 512 512"
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                                <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <g>
                                        {" "}
                                        <g>
                                            {" "}
                                            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                                        </g>{" "}
                                    </g>{" "}
                                </g>
                            </svg>
                        </div>
                        <Row>
                            <Col span={24}>
                                {Object.keys(detail).length > 0 ? (
                                    <div className={style.interactionDetailContainer}>
                                        <Row gutter={[25, 25]}>
                                            <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                                <div className={style.cardInfoContainer}>
                                                    {detail?.poster_image !== "" ? (
                                                        <div className={style.boookingImg}>
                                                            <img src={detail?.poster_image} />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <h4>{detail?.title}</h4>
                                                    <div className={style.bookingCategoryName}>
                                                        <p>
                                                            {t("Category")} : {categoryName}
                                                        </p>
                                                        <p>
                                                            {t("SubCategory")} : {subCategoryName}
                                                        </p>
                                                    </div>
                                                    <p className={style.descriptionText}>{detail?.pkg_desc}</p>
                                                    <p>
                                                        {t("Price")}{" "}
                                                        <strong className={style.priceText}>{formatNumberToLocalCurrency(detail?.price, currency)}</strong>
                                                    </p>
                                                    <div className={style.chargeableAmountBox}>
                                                        <p className={style.payAmount}>{t("Chargeable Amount")}</p>
                                                        <div className={style.amountNum}>
                                                            {formatNumberToLocalCurrency(detail?.chargeableAmount / 100, detail?.countryObject?.currency)}
                                                        </div>
                                                    </div>
                                                    <div className={style.shortUrlContainer}>
                                                        <p className={style.interactionUrlTxt}>
                                                            <a
                                                                target="_blank"
                                                                href={`${WEBSITE_URL}${detail?.url_string}`}
                                                            >
                                                                {`${WEBSITE_URL}${detail?.url_string}`}
                                                            </a>
                                                        </p>
                                                        <Popover content={<div>{t("Link copied")}!</div>} trigger="click">
                                                            <div
                                                                className={style.interactionDetailIconeDiv}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(`${WEBSITE_URL}${detail?.url_string}`);
                                                                }}
                                                            >
                                                                {" "}
                                                                <FeatherIcon icon="copy" size={24} />
                                                            </div>
                                                        </Popover>
                                                    </div>

                                                    {detail?.is_online == true ? (
                                                        <>
                                                            <div className={style.shortUrlContainer}>
                                                                {t("Meeting Link")} :
                                                                <p className={style.meetingUrlTxt}>
                                                                    <a target="_blank" href={`${detail?.meeting_link}`}>
                                                                        {`${detail?.meeting_link}`}
                                                                    </a>
                                                                </p>
                                                                <Popover content={<div>{t("Click & copied link")}!</div>} trigger="click">
                                                                    <div
                                                                        className={style.interactionDetailIconeDiv}
                                                                        onClick={() => {
                                                                            message.success(t("Link copied successfully"));
                                                                            navigator.clipboard.writeText(detail?.meeting_link);
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <FeatherIcon icon="copy" size={24} />
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className={style.shortUrlContainer}>
                                                            {t("Address")} :<p className={style.meetingUrlTxt}>{`${detail?.address}`}</p>
                                                        </div>
                                                    )}
                                                    <div className={style.booleanValueBox}>
                                                        <Descriptions
                                                            column={1}
                                                            bordered={true}
                                                            className="bookingDescriptiob"
                                                            labelStyle={{ width: screenSize.width <= 699 ? "100%" : "250px" }}
                                                            items={[
                                                                {
                                                                    key: "VAT",
                                                                    label: t("VAT(%)"),
                                                                    children: detail?.vat + "%",
                                                                },
                                                                {
                                                                    key: "isExclusive",
                                                                    label: t("Is VAT Exclusive/Inclusive ?"),
                                                                    children: detail?.isExclusive == true ? "Exclusive" : "Inclusive",
                                                                },
                                                                {
                                                                    key: "Is_Paid",
                                                                    label: t("Is Booking Paid/Free ?"),
                                                                    children: detail?.is_paid == true ? "Paid" : "Free",
                                                                },
                                                                {
                                                                    key: "total_user_allow_in_pack",
                                                                    label: t("Total Users Allowed In Pack"),
                                                                    children: detail?.total_user_allow_in_pack,
                                                                },
                                                                {
                                                                    key: "start_date",
                                                                    label: t("Start date"),
                                                                    children: moment(detail?.start_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"),
                                                                },
                                                                {
                                                                    key: "end_date",
                                                                    label: t("End Date"),
                                                                    children: moment(detail?.end_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"),
                                                                },
                                                                {
                                                                    key: "check_in_time",
                                                                    label: t("Check-In"),
                                                                    children: detail?.check_in_time + `(${moment.tz(moment.tz.guess()).zoneAbbr()})`,
                                                                },
                                                                {
                                                                    key: "check_out_time",
                                                                    label: t("Check-Out"),
                                                                    children: detail?.check_out_time + `(${moment.tz(moment.tz.guess()).zoneAbbr()})`,
                                                                },
                                                                {
                                                                    key: "is_activities_pack_onetime",
                                                                    label: t("Is Activities Pack One Time ?"),
                                                                    children: detail?.isExclusive == true ? "Yes" : "No",
                                                                },
                                                                {
                                                                    key: "timing",
                                                                    label: t("Timing"),
                                                                    children:
                                                                        detail && detail?.timing == "Hour"
                                                                            ? "Hour"
                                                                            : detail && detail?.timing == "Day"
                                                                                ? "Day"
                                                                                : detail && detail?.timing == "Week"
                                                                                    ? "Week"
                                                                                    : detail && detail?.timing == "Month"
                                                                                        ? "Month"
                                                                                        : detail && detail?.timing == "Year"
                                                                                            ? "Year"
                                                                                            : "-",
                                                                },
                                                                {
                                                                    key: "week_days",
                                                                    label: t("Week Days"),
                                                                    children:
                                                                        detail?.week_days && detail?.week_days.length > 0
                                                                            ? detail?.week_days &&
                                                                            detail?.week_days
                                                                                .map((day, i) => {
                                                                                    return day.charAt(0).toUpperCase() + day.substr(1);
                                                                                })
                                                                                .join(" , ")
                                                                            : "-",
                                                                },
                                                                {
                                                                    key: "duration",
                                                                    label: t("Duration"),
                                                                    children: detail?.duration !== "" ? detail?.duration : "-",
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                    <p className={style.dateInfoText}>
                                                        {moment(detail?.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("MMM DD, YYYY HH:mm")}.
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                                                <div style={wrapperStyle}>
                                                    <DayPicker
                                                        id="dayPicker"
                                                        defaultMonth={format(
                                                            parse(detail?.start_date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()),
                                                            "yyyy-MM-dd"
                                                        )}
                                                        modifiers={{
                                                            booked: bookingDateArr,
                                                        }}
                                                        modifiersStyles={{
                                                            booked: bookedStyle,
                                                        }}
                                                        captionLayout="dropdown-buttons"
                                                        fromYear={2000}
                                                        toYear={2050}
                                                        selected={selectCalendarDate}
                                                        showOutsideDays
                                                        disabled={isDateDisabled}
                                                        onDayClick={(day, modifiers, e) => {
                                                            const tabindex = e.currentTarget.getAttribute("tabindex");
                                                            setSelectedTabIndex(tabindex);
                                                            if (modifiers?.booked) {
                                                                setSelectCalendarDate(moment(day, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                                                                setCalenderMsg(
                                                                    `${t("You select")} ${moment(day, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD")}.`
                                                                );
                                                            } else {
                                                                setCalenderMsg(`Try to select booked dates to see available time slot.`);
                                                            }
                                                        }}
                                                        footer={<p className={style.calenderMsg}>{calenderMsg}</p>}
                                                    />
                                                </div>

                                                <div className={style.TimeSloteDEtail}>
                                                    <div className={style.slotHeadeing}>
                                                        <h3>
                                                            {t("Time Slots")}(
                                                            {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD") == selectCalendarDate
                                                                ? t("Today")
                                                                : selectCalendarDate}
                                                            )
                                                        </h3>
                                                        {slotList && slotList.length > 0 && moment(selectCalendarDate).isBefore(moment(), "day") == false ? (
                                                            <Button
                                                                className={style.addSlotBtn}
                                                                onClick={() => {
                                                                    setOpenAddSlotModal(true);
                                                                }}
                                                            >
                                                                {t("Add")}
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <p className={style.timeZoneInfo}>
                                                        All slot's time is in{" "}
                                                        <strong>{moment.tz.guess() + " " + `(${moment.tz(moment.tz.guess()).zoneAbbr()})`}.</strong>
                                                    </p>
                                                    {slotList && slotList.length > 0 ? (
                                                        <div className={style.slotCardsContainer}>
                                                            {slotList &&
                                                                slotList.map((data, i) => {
                                                                    const localTimeSlot = `${moment(data?.start_date_time).format("HH:mm")} - ${moment(
                                                                        data?.end_date_time
                                                                    ).format("HH:mm")}`;
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={
                                                                                    data?.is_available == true
                                                                                        ? `${style.TimeSlotCard}`
                                                                                        : `${style.DisableTimeSlotCard}`
                                                                                }
                                                                                key={i}
                                                                            >
                                                                                <div className={style.slotTime}>
                                                                                    <p>{localTimeSlot}</p>
                                                                                    <div className={style.slotInfo}>
                                                                                        <small style={{ fontSize: "13px" }}>
                                                                                            {t("Booked User Count")}: {data?.user_booked_count}
                                                                                        </small>
                                                                                        {data?.is_available == false && (
                                                                                            <h4>
                                                                                                <i>Slot expired</i>
                                                                                            </h4>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className={style.slotAction}>
                                                                                    <FeatherIcon
                                                                                        icon="edit"
                                                                                        size={20}
                                                                                        onClick={() => {
                                                                                            if (data?.is_available == true) {
                                                                                                setOpenEditSlot(true);
                                                                                                setSlotObj(data);
                                                                                                const slotArr = data?.slot
                                                                                                    ?.split("-")
                                                                                                    .map((time) => time.trim());
                                                                                                setEditSlotTime(slotArr);
                                                                                                editSlotForm.setFieldValue("slot_time", [
                                                                                                    dayjs(slotArr[0], "HH:mm"),
                                                                                                    dayjs(slotArr[1], "HH:mm"),
                                                                                                ]);
                                                                                            } else {
                                                                                                message.warning(
                                                                                                    "you can not edit this time slot,it is expired."
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <FeatherIcon
                                                                                        icon="trash"
                                                                                        size={20}
                                                                                        onClick={() => {
                                                                                            if (data?.is_available == true) {
                                                                                                setOpenDeleteSlot(true);
                                                                                                setSlotObj(data);
                                                                                                setEditSlotTime(
                                                                                                    data?.slot?.split("-").map((time) => time.trim())
                                                                                                );
                                                                                            } else {
                                                                                                message.warning(
                                                                                                    "you can not delete this time slot,it is expired."
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                        </div>
                                                    ) : (
                                                        <div className={style.TimeSlotCard}>{t("No Slot Available")}</div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <center>
                                        <h3>{t("No Booking Detail Found!")}</h3>
                                    </center>
                                )}
                            </Col>
                        </Row>
                    </>
                )}

                <Modal width={600} title={t("Add Slot")} open={openAddSlotModal} onCancel={handleSlotCancel} footer={null}>
                    <Form
                        layout="vertical"
                        name="basic"
                        autoComplete="nope"
                        onFinish={(value) => handleAddSlotSubmit(value)}
                        className={style.interactionForm}
                        form={addSlotForm}
                    >
                        <Form.Item
                            label={t("Booking Date")}
                            name="booking_date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select booking dates!",
                                },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    width: "100%",
                                }}
                                className="historyCalender calendarDatePicker"
                                size="large"
                                onChange={onchangeDate}
                                disabledDate={(d) => {
                                    return moment().add(-1, "days") >= d;
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t("Slot Time")}
                                    <Tooltip
                                        title={`You are currently selecting time according ${moment.tz.guess()} (${moment
                                            .tz(moment.tz.guess())
                                            .zoneAbbr()}) time zone.`}
                                    >
                                        <div className={style.timezoneInfoBox}> ({moment.tz(moment.tz.guess()).zoneAbbr()})</div>
                                    </Tooltip>
                                </>
                            }
                            name="slot_time"
                            rules={[
                                {
                                    required: true,
                                    message: t("Please select booking slot time!"),
                                },
                            ]}
                        >
                            <TimePicker.RangePicker
                                style={{
                                    width: "100%",
                                }}
                                format={"HH:mm"}
                                size="large"
                                onChange={(time, timeString) => {
                                    if (time !== null && timeString) {
                                        setSelectedTime(timeString);
                                    }
                                }}
                                className="historyCalender calendarTimePicker"
                            />
                        </Form.Item>

                        <div className={style.createBtnContainer}>
                            <Button className={style.cancelBtn} onClick={handleSlotCancel}>
                                <div className={style.buttonContent}>{t("Cancel")}</div>
                            </Button>
                            <Button className={style.createBtn} onClick={addSlotForm.submit}>
                                <div className={style.buttonContent}>{t("Submit")}</div>
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal width={600} title={t("Edit Slot")} open={openEditSlot} onCancel={handleSlotCancel} footer={null}>
                    <Form
                        layout="vertical"
                        name="basic"
                        autoComplete="nope"
                        onFinish={(value) => handleEditSlotSubmit("edit", value)}
                        className={style.interactionForm}
                        form={editSlotForm}
                    >
                        <Form.Item
                            label={
                                <>
                                    {t("Slot Time")}
                                    <Tooltip
                                        title={`You are currently selecting time according ${moment.tz.guess()} (${moment
                                            .tz(moment.tz.guess())
                                            .zoneAbbr()}) time zone.`}
                                    >
                                        <div className={style.timezoneInfoBox}> ({moment.tz(moment.tz.guess()).zoneAbbr()})</div>
                                    </Tooltip>
                                </>
                            }
                            name="slot_time"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select booking slot time!",
                                },
                            ]}
                        >
                            <TimePicker.RangePicker
                                style={{
                                    width: "100%",
                                }}
                                format={"HH:mm"}
                                size="large"
                                onChange={(time, timeString) => {
                                    if (time !== null && timeString) {
                                        setSelectedTime(timeString);
                                    }
                                }}
                                className="historyCalender calendarTimePicker"
                            />
                        </Form.Item>

                        <div className={style.createBtnContainer}>
                            <Button className={style.cancelBtn} onClick={handleSlotCancel}>
                                <div className={style.buttonContent}>{t("Cancel")}</div>
                            </Button>
                            <Button className={style.createBtn} onClick={editSlotForm.submit}>
                                <div className={style.buttonContent}>{t("Submit")}</div>
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal width={600} title={t("Delete Slot")} open={openDeleteSlot} onCancel={handleSlotCancel} footer={null}>
                    <Form
                        layout="vertical"
                        name="basic"
                        autoComplete="nope"
                        onFinish={(value) => handleEditSlotSubmit("delete", value)}
                        className={style.interactionForm}
                        form={deleteSlotForm}
                    >
                        <h3 className={style.deleteSlotText}>{t("Are you sure you wants to delete this slot?")}</h3>
                        <div className={style.createBtnContainer}>
                            <Button className={style.cancelBtn} onClick={handleSlotCancel}>
                                <div className={style.buttonContent}>{t("Cancel")}</div>
                            </Button>
                            <Button className={style.createBtn} onClick={deleteSlotForm.submit}>
                                <div className={style.buttonContent}>{t("Confirm")}</div>
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>
        </>
    );
};

export default BookingDetail;
