import React, { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ConfigProvider, Spin } from "antd";
import PublickRoutes from "./routes/PublickRoutes";
import { getItem } from "./utility/LocalStorageControl";
import DashboardRoutes from "./routes/DashboardRoutes";
import "./App.css";
import "./css/Custom.css";
import configs from "./redux/config";
import Cookies from "js-cookie";

// DEFINE MAIN FUNCITON
function App() {
  let isLoggedIn = useSelector((state) => state.auth);
  const [loginStatus, SetLoginStatus] = useState(isLoggedIn?.login)
  useEffect(() => {
    if (isLoggedIn?.login == true) {
      SetLoginStatus(true)
    } else {
      SetLoginStatus(false)
    }
  }, [loginStatus])

  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: false,
      rtl: false,
      topMenu: false,
    };
  });
  const { theme } = configs;

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <BrowserRouter>
          <Suspense fallback={<Spin />}>
            <Routes>
              {loginStatus ? (
                <Route path="/*" element={<DashboardRoutes />} />
              ) : (
                <Route path="/*" element={<PublickRoutes />} />
              )
              }
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </ConfigProvider>
  );
}

// MAIN FUNCTION EXPORT
export default App;
