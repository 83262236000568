import React, { useState, useEffect } from "react";
import {
  Tabs,
  Row,
  Col,
  Table,
  Pagination,
  Tooltip,
  DatePicker,
  Button,
  Empty,
  Spin,
  Form,
} from "antd";
import styles from "./History.module.css";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import {
  getPaymentsHistoryList,
  viewReceiptApi,
} from "../../../redux/qrPayment/action";
import moment from "moment";
import { getItem } from "../../../utility/LocalStorageControl";
import { saveAs } from 'file-saver';

const { RangePicker } = DatePicker;

export default function PaymentHistoryList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [paymentHistoryList, showPaymentHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [paginationCount, setPaginationCount] = useState(0);
  const currency = getItem("userDetails")?.countryObject?.currency;
  var today = new Date();
  var dateBeforeMonth = moment().subtract(1, "months").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateBeforeMonth);
  const [endDate, setEndDate] = useState(
    moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD")
  );
  const [dateRang, setDateRang] = useState([]);
  const [dateChange, setDateChange] = useState(false);
  const [tableStartDate, setTableStartDate] = useState(startDate);
  const [tableEndDate, setTableEndDate] = useState(endDate);
  const [loading, setLoading] = useState(false);
  const [dateFilterForm] = Form.useForm();
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [paymentDataId, setPaymentDataId] = useState('');

  useEffect(() => {
    qrPaymentHistoryList(currentPage, dataPerPage);
  }, [currentPage, dataPerPage, selectedMenu]);

  const qrPaymentHistoryList = async (page, pageNum) => {
    setLoading(true);
    const param = {
      type: selectedMenu,
      startOf: startDate,
      endOf: endDate,
      page: page,
      limit: pageNum,
    };
    const dataList = await dispatch(getPaymentsHistoryList(param));
    if (dataList) {
      showPaymentHistoryList(dataList?.data?.list);
      setPaginationCount(dataList?.data?.totalCount);
      setLoading(false);
    } else {
      showPaymentHistoryList([]);
      setLoading(false);
    }
  };

  // Download Payment Receipt
  const viewPaymentReceipt = async (id) => {
    setLoadingReceipt(true)
    setPaymentDataId(id)
    try {
      const data = await dispatch(viewReceiptApi(id));
      if (data && !Array.isArray(data.data?.data)) {
        window.open(data.data?.data, '_blank');
        setLoadingReceipt(false)
        setPaymentDataId('')
      } else if (data && Array.isArray(data.data?.data)) {
        // If data is an array (Uint8Array), create a Blob and trigger download
        let aa = new Uint8Array(data.data?.data);
        let file = new Blob([aa], { type: 'application/pdf' });
        saveAs(file, 'downloaded-file.pdf');
        setLoadingReceipt(false)
        setPaymentDataId('')
      } else {
        console.error('Error:', data?.error);
        setLoadingReceipt(false)
        setPaymentDataId('')
      }
    } catch (error) {
      console.error('Error:', error);
      setLoadingReceipt(false)
      setPaymentDataId('')
    }

  };

  const columns = [
    {
      title: t("Transaction Date"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (icon, value) => {
        return moment(value?.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
          "MMM DD, YYYY HH:mm"
        );
      },
    },
    {
      title: selectedMenu == "self_payments" ? t("Paid to") : t("Paid By User"),
      dataIndex: "paidByUsers",
      key: "paidByUsers",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.userNameCell}>
              <p>
                {selectedMenu == "self_payments" ? (
                  <Tooltip
                    placement="top"
                    title={value?.business_user_id?.username}
                  >
                    <span>
                      {value?.business_user_id?.username.length > 20
                        ? value?.business_user_id?.username.slice(0, 20) + "..."
                        : value?.business_user_id?.username}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={value?.receipt?.user_name}>
                    <span>
                      {value?.receipt?.user_name.length > 20
                        ? value?.receipt?.user_name.slice(0, 20) + "..."
                        : value?.receipt?.user_name}
                    </span>
                  </Tooltip>
                )}
              </p>
            </div>
          </>
        );
      },
    },
    {
      title: t("Invoice Number"),
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (icon, value) => {
        return value?.receipt?.invoice_number;
      },
    },
    {
      title: t("Transaction for"),
      dataIndex: "transaction_for",
      key: "transaction_for",
      render: (icon, value) => {
        return (
          <Tooltip placement="top" title={value?.receipt?.description}>
            <span>
              {value?.receipt?.description.length > 25
                ? value?.receipt?.description.slice(0, 25) + "..."
                : value?.receipt?.description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: t("Type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (icon, value) => {
        return value?.transaction_type == "qr_payment"
          ? "QR Payment"
          : value?.transaction_type == "booking_purchase"
            ? "Booking"
            : value?.transaction_type;
      },
    },
    {
      title: t("Refund Amount") + `(${currency})`,
      dataIndex: "refund_amount",
      key: "refund_amount",
      render: (icon, value) => {
        return value &&
          value.receipt &&
          value.receipt.refundAmount ? value.receipt.refundAmount : "0"
      },
    },
    {
      title: t("Total Paid") + `(${currency})`,
      dataIndex: "total_Paid",
      key: "total_Paid",
      render: (icon, value) => {
        return value &&
          value?.stripe_response &&
          value?.stripe_response?.amount
          ?
          ((value &&
            value?.stripe_response &&
            value?.stripe_response?.amount) / 100
          )?.toFixed(2)
          :
          "0"
      },
    },
    {
      title: t("Stripe Fees"),
      dataIndex: "Stripe Fees",
      key: "Stripe Fees",
      render: (icon, value) => {
        return value &&
          value?.stripe_response &&
          value?.stripe_response?.balance_transaction_data?.stripe_fee
          ?
          (value &&
            value?.stripe_response &&
            value?.stripe_response?.balance_transaction_data &&
            value?.stripe_response?.balance_transaction_data?.stripe_fee
          )?.toFixed(2)
          :
          "0"
      },
    },
    {
      title: t("Application Fees"),
      dataIndex: "Application Fees",
      key: "Application Fees",
      render: (icon, value) => {
        return value &&
          value?.stripe_response &&
          value?.stripe_response?.balance_transaction_data?.application_fee
          ?
          (value &&
            value?.stripe_response &&
            value?.stripe_response?.balance_transaction_data &&
            value?.stripe_response?.balance_transaction_data?.application_fee
          )?.toFixed(2)
          : "0"
      },
    },
    {
      title: t("Net Received"),
      dataIndex: "Net_Received",
      key: "Net_Received",
      render: (icon, value) => {
        return value &&
          value?.stripe_response &&
          value?.stripe_response?.balance_transaction_data
          ?
          (value &&
            value?.stripe_response &&
            value?.stripe_response?.balance_transaction_data &&
            value?.stripe_response?.balance_transaction_data?.net
          )?.toFixed(2)
          : "0"
      },
    },
    {
      title: t("Receipt"),
      dataIndex: "Receipt",
      key: "Receipt",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.bookingActionBox}>
              {
                paymentDataId == value?._id && loadingReceipt ?
                  <Spin tip="Download..." ><div style={{ height: "20px" }}></div></Spin>
                  :
                  <Tooltip title="Download Payment Receipt" placement="top">
                    <FeatherIcon
                      icon="file-text"
                      size={18}
                      className={styles.actionIcons}
                      onClick={() => {
                        viewPaymentReceipt(value?._id);
                      }}
                    />
                  </Tooltip>
              }
            </div>
          </>
        );
      },
    },
  ];

  const myPaymentColumns = [
    {
      title: t("Transaction Date"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (icon, value) => {
        return moment(value?.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
          "MMM DD, YYYY HH:mm"
        );
      },
    },
    {
      title: selectedMenu == "self_payments" ? t("Paid to") : t("Paid By User"),
      dataIndex: "paidByUsers",
      key: "paidByUsers",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.userNameCell}>
              <p>
                {selectedMenu == "self_payments" ? (
                  <Tooltip
                    placement="top"
                    title={value?.business_user_id?.username}
                  >
                    <span>
                      {value?.business_user_id?.username.length > 20
                        ? value?.business_user_id?.username.slice(0, 20) + "..."
                        : value?.business_user_id?.username}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={value?.receipt?.user_name}>
                    <span>
                      {value?.receipt?.user_name.length > 20
                        ? value?.receipt?.user_name.slice(0, 20) + "..."
                        : value?.receipt?.user_name}
                    </span>
                  </Tooltip>
                )}
              </p>
            </div>
          </>
        );
      },
    },
    {
      title: t("Invoice Number"),
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (icon, value) => {
        return value?.receipt?.invoice_number;
      },
    },
    {
      title: t("Transaction for"),
      dataIndex: "transaction_for",
      key: "transaction_for",
      render: (icon, value) => {
        return (
          <Tooltip placement="top" title={value?.receipt?.description}>
            <span>
              {value?.receipt?.description.length > 25
                ? value?.receipt?.description.slice(0, 25) + "..."
                : value?.receipt?.description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: t("Type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (icon, value) => {
        return value?.transaction_type == "qr_payment"
          ? "QR Payment"
          : value?.transaction_type == "booking_purchase"
            ? "Booking"
            : value?.transaction_type;
      },
    },
    {
      title: t("Refund Amount") + `(${currency})`,
      dataIndex: "refund_amount",
      key: "refund_amount",
      render: (icon, value) => {
        return value &&
          value.receipt &&
          value.receipt.refundAmount ? value.receipt.refundAmount : "0"
      },
    },
    {
      title: t("Total Paid") + `(${currency})`,
      dataIndex: "total_Paid",
      key: "total_Paid",
      render: (icon, value) => {
        return value &&
          value?.stripe_response &&
          value?.stripe_response?.amount
          ?
          ((value &&
            value?.stripe_response &&
            value?.stripe_response?.amount) / 100)?.toFixed(2)
          :
          "0"
      },
    },
    {
      title: t("Receipt"),
      dataIndex: "Receipt",
      key: "Receipt",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.bookingActionBox}>
              {
                paymentDataId == value?._id && loadingReceipt ?
                  <Spin tip="Download..." ><div style={{ height: "20px" }}></div></Spin>
                  :
                  <Tooltip title="Download Payment Receipt" placement="top">
                    <FeatherIcon
                      icon="file-text"
                      size={18}
                      className={styles.actionIcons}
                      onClick={() => {
                        viewPaymentReceipt(value?._id);
                      }}
                    />
                  </Tooltip>
              }
            </div>
          </>
        );
      },
    },
  ];

  const subscriptionColumns = [
    {
      title: t("Transaction Date"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (icon, value) => {
        return moment(value?.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
          "MMM DD, YYYY HH:mm"
        );
      },
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.userNameCell}>
              <p>{value?.subscription_id?.name}</p>
            </div>
          </>
        );
      },
    },
    {
      title: t("Subscription Time"),
      dataIndex: "time",
      key: "time",
      render: (icon, value) => {
        return value?.subscription_id?.timing == "month"
          ? "Month"
          : value?.subscription_id?.timing == "year"
            ? "Year"
            : "-";
      },
    },
    {
      title: t("Amount") + `(${currency})`,
      dataIndex: "amount",
      key: "amount",
      render: (icon, value) => {
        return (value?.stripe_response?.amount_paid / 100)?.toFixed(2);
      },
    },
    {
      title: t("Type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (icon, value) => {
        return value?.transaction_type == "subscription_purchase"
          ? "Subscription Purchase"
          : "-";
      },
    },
    {
      title: t("Receipt"),
      dataIndex: "Receipt",
      key: "Receipt",
      render: (icon, value) => {
        return (
          <>
            <div className={styles.bookingActionBox}>
              {
                paymentDataId == value?._id && loadingReceipt ?
                  <Spin tip="Download..." ><div style={{ height: "20px" }}></div></Spin>
                  :
                  <Tooltip title="Download Payment Receipt" placement="top">
                    <FeatherIcon
                      icon="file-text"
                      size={18}
                      className={styles.actionIcons}
                      onClick={() => {
                        viewPaymentReceipt(value?._id);
                      }}
                    />
                  </Tooltip>
              }
            </div>
          </>
        );
      },
    },
  ];

  const handlePagination = (page, pageNumber) => {
    setCurrentPage(page);
    setDataPerPage(pageNumber);
    qrPaymentHistoryList(page, pageNumber);
  };

  const onchangeDate = (value, dateString) => {
    if (value !== null && dateString) {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
      setDateRang(dateString);
      setDateChange(true);
    } else {
      setDateRang([]);
      setDateChange(false);
      setStartDate(dateBeforeMonth);
      setEndDate(
        moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD")
      );
    }
  };

  return (
    <div className={styles.historyMainContainer}>
      <h1>{t("Payment History")}</h1>
      <div className={styles.historyTabContainer}>
        <Tabs
          onChange={(e) => {
            setSelectedMenu(e);
          }}
          size="large"
          className="settingPageTab"
          type="card"
          defaultActiveKey="all"
          items={[
            {
              label: t("My Payments"),
              key: "self_payments",
            },
            {
              label: t("Customer Payments"),
              key: "all",
            },
            {
              label: t("My Subscription Payments"),
              key: "my_subscriptions",
            },
          ]}
        />
      </div>

      <div>
        <div className={styles.tableHeader}>
          <Form
            form={dateFilterForm}
          >
            <Form.Item
              name='filter_date'
              style={{ marginBottom: '0px' }}
            >
              <RangePicker
                className="historyCalender"
                size="large"
                format={(val) => val.format("YYYY-MM-DD")}
                onChange={onchangeDate}
                disabledDate={(d) => {
                  return d && d > moment().endOf("day");
                }}
              />
            </Form.Item>
          </Form>
          <Button
            className={
              dateChange == true
                ? `${styles.searchBtn}`
                : `${styles.searchDisableBtn}`
            }
            disabled={dateChange == true ? false : true}
            onClick={() => {
              qrPaymentHistoryList(currentPage, dataPerPage);
              setTableStartDate(startDate);
              setTableEndDate(endDate);
            }}
          >
            <Tooltip title={t("Search Payment Data by Date")} placement="top">
              <FeatherIcon icon="search" />
            </Tooltip>
          </Button>
          <Button
            className={
              dateChange == true
                ? `${styles.resetBtn}`
                : `${styles.resetDisableBtn}`
            }
            disabled={dateChange == true ? false : true}
            onClick={() => {
              onchangeDate(null, ["", ""]);
              dateFilterForm.resetFields()
              setStartDate(dateBeforeMonth);
              setEndDate(moment().format("YYYY-MM-DD"));
              qrPaymentHistoryList(currentPage, dataPerPage);
              setTableStartDate(dateBeforeMonth);
              setTableEndDate(moment().format("YYYY-MM-DD"));
            }}
          >
            <Tooltip title={t("Reset Search Data")} placement="top">
              <FeatherIcon icon="refresh-ccw" size={20} />
            </Tooltip>
          </Button>
        </div>
        {loading ? (
          <Table
            title={() => (
              <>
                <div className={styles.tableHeaderText}>
                  {startDate == "" && endDate == "" ? (
                    <h4>
                      {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                        "YYYY-MM-DD"
                      )}
                    </h4>
                  ) : (
                    <h4>
                      {startDate} {"  "}
                      <FeatherIcon icon="arrow-right" size={15} /> {"  "}
                      {endDate}
                    </h4>
                  )}
                </div>
              </>
            )}
            locale={{
              emptyText: (
                <Spin
                  style={{
                    marginTop: "20px",
                  }}
                />
              ),
            }}
            columns={
              selectedMenu == "my_subscriptions" ? subscriptionColumns :
                selectedMenu == "self_payments" ? myPaymentColumns :
                  columns
            }
            dataSource={[]}
            scroll={{
              x: "500px",
            }}
          />
        ) : paymentHistoryList && paymentHistoryList.length > 0 ? (
          selectedMenu === "my_subscriptions" ? (
            <Table
              title={() => (
                <>
                  <div className={styles.tableHeaderText}>
                    {tableStartDate == "" && tableEndDate == "" ? (
                      <h4>
                        {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                          "YYYY-MM-DD"
                        )}
                      </h4>
                    ) : (
                      <h4>
                        {tableStartDate} {"  "}
                        <FeatherIcon icon="arrow-right" size={15} /> {"  "}
                        {tableEndDate}
                      </h4>
                    )}
                  </div>
                </>
              )}
              showSorterTooltip={false}
              columns={subscriptionColumns}
              dataSource={paymentHistoryList}
              pagination={false}
              scroll={{
                x: "500px",
              }}
            />
          ) : selectedMenu === "self_payments" ? (
            <Table
              title={() => (
                <>
                  <div className={styles.tableHeaderText}>
                    {tableStartDate == "" && tableEndDate == "" ? (
                      <h4>
                        {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                          "YYYY-MM-DD"
                        )}
                      </h4>
                    ) : (
                      <h4>
                        {tableStartDate} {"  "}
                        <FeatherIcon icon="arrow-right" size={15} /> {"  "}
                        {tableEndDate}
                      </h4>
                    )}
                  </div>
                </>
              )}
              showSorterTooltip={false}
              columns={myPaymentColumns}
              dataSource={paymentHistoryList}
              pagination={false}
              scroll={{
                x: "500px",
              }}
            />
          ) : (
            <Table
              title={() => (
                <>
                  <div className={styles.tableHeaderText}>
                    {tableStartDate == "" && tableEndDate == "" ? (
                      <h4>
                        {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                          "YYYY-MM-DD"
                        )}
                      </h4>
                    ) : (
                      <h4>
                        {tableStartDate} {"  "}
                        <FeatherIcon icon="arrow-right" size={15} /> {"  "}
                        {tableEndDate}
                      </h4>
                    )}
                  </div>
                </>
              )}
              showSorterTooltip={false}
              columns={columns}
              dataSource={paymentHistoryList}
              pagination={false}
              scroll={{
                x: "500px",
              }}
            />
          )
        ) : (
          <Table
            title={() => (
              <>
                <div className={styles.tableHeaderText}>
                  {startDate == "" && endDate == "" ? (
                    <h4>
                      {moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                        "YYYY-MM-DD"
                      )}
                    </h4>
                  ) : (
                    <h4>
                      {startDate} {"  "}
                      <FeatherIcon icon="arrow-right" size={15} /> {"  "}
                      {endDate}
                    </h4>
                  )}
                </div>
              </>
            )}
            locale={{
              emptyText: (
                <Empty
                  description={<span>{t("No Transaction Data Found")}</span>}
                ></Empty>
              ),
            }}
            scroll={{
              x: "500px",
            }}
            columns={
              selectedMenu == "my_subscriptions" ? subscriptionColumns :
                selectedMenu == "self_payments" ? myPaymentColumns :
                  columns
            }
            dataSource={[]}
          />
        )}

        <Row justify="center">
          <Col className={styles.paginationBox}>
            {paginationCount !== "" &&
              paginationCount !== undefined &&
              paginationCount !== 0 ? (
              <Pagination
                className="interactionPagination"
                total={paginationCount}
                currentPage={currentPage}
                onChange={handlePagination}
                pageSizeOptions={[10, 20, 50, 100]}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                pageSize={dataPerPage}
                showSizeChanger
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
