import Styled from "styled-components";

const Main = Styled.div`
    padding: 0px;
    min-height: 715px;
    background-color: rgb(244, 245, 247);
    &.grid-boxed{
        padding: 0px 180px 20px;
        @media only screen and (max-width: 1599px){
            padding: 0px 130px 20px;
        }
        @media only screen and (max-width: 1399px){
            padding: 0px 50px 20px;
        }
        @media only screen and (max-width: 991px){
            padding: 0px 30px 20px;
        }
    }
    .ant-card-rtl .ant-card-extra{
                margin-right: 0 !important;
            }
    .ant-tabs-tab span svg {        
        ${({ theme }) => (theme.rtl ? "padding-left" : "padding-right")}: 5px;
    }
    /* Picker Under Input */
    .ant-form-item-control-input .ant-picker {
        padding: ${({ theme }) =>
        theme.rtl ? "0 0 0 12px" : "0 12px 0 0"} !important;
    }

    /* progressbars */

    .ant-progress {
        display: inline-flex !important;
        align-items: center;
    }

    .ant-progress>div {
        display: flex;
        flex-direction: column;
    }

    .ant-progress .ant-progress-outer {
        ${({ theme }) =>
        !theme.rtl ? "margin-right" : "margin-left"}: 0 !important;
        ${({ theme }) =>
        !theme.rtl ? "padding-right" : "padding-left"}: 0 !important;
    }

    .ant-progress .ant-progress-text {
        order: 0;
        margin-left: auto;
        ${({ theme }) =>
        theme.rtl ? "margin-right" : "margin-left"}: 10px !important;
        align-self: flex-end;
        text-align: center;
    }

    .ant-progress-status-warning .ant-progress-bg {
        background: #fa8b0c;
    }

    /* progress bars */
    
    @media only screen and (max-width: 1199px){
        // padding: 0px 15px;
    }
    @media only screen and (max-width: 991px){
        min-height: 580px;
    }
    .w-100{
        width: 100%;
    }
    .product-sidebar-col{
        @media only screen and (max-width: 767px){
            order: 2;
        }
    }
    .ant-skeleton-paragraph{
        margin-bottom: 0;
    }

    /* // ant alert */
    .ant-alert-closable{
        .ant-alert-message{
          ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 15px;
        }
    }

    .ant-alert-with-description .ant-alert-description{
        display: inline-block;
    }

    /* // ant Calendar Picker */
    .ant-picker-calendar{
        .ant-badge-status-text{
            color: ${({ theme }) => theme["gray-color"]};
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select{
        @media only screen and (max-width: 400px){
            width: 50% !important;
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select{
        @media only screen and (max-width: 400px){
            width: calc(50% - 8px) !important
        }
    }

    /* // Card Grid */
    .card-grid-wrap{
        .ant-card-grid{
            @media only screen and (max-width: 575px){
                width: 50% !important
            }
        }
    }

    /* // Drawer */
    .atbd-drawer{
        .ant-card-body{
            text-align: center;
        }
    }
    .drawer-placement{
        @media only screen and (max-width: 400px){
            text-align: center;
        }
        .ant-radio-group{
            @media only screen and (max-width: 400px){
                margin-bottom: 15px;
            }
        }
    }
    .ant-drawer-content-wrapper{
        @media only screen and (max-width: 400px){
            width: 260px !important;
        }
        @media only screen and (max-width: 375px){
            width: 220px !important;
        }
    }

    /* // Input */
    .input-wrap{
        @media only screen and (max-width: 991px){
            min-height: 500px;
        }
        input::placeholder{
            color: ${({ theme }) => theme["light-color"]};
        }
    }
    /* // Modal Buttons */
    .modal-btns-wrap{
        margin: 0 -5px;
    }
    /* spinner */
    .ant-spin{
        ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 20px;
        &:last-child{
            ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 0;
        }
    }

    /* Column Cards Wrapper */
    .columnCardsWrapper{
        background: #F4F5F7;
        border-radius: 4px;
        padding: 50px 30px 25px;
    }
    .columnCardsWrapper .ant-card{
        background: #fff;
    }
    .columnCardsWrapper .ant-card-head{
        background: #fff;
    }

    /* Ant Collapse */
    .ant-collapse{
        border-color: #E3E6EF;
        border-radius: 5px;
    }
    .ant-collapse.ant-collapse-icon-position-left .ant-collapse-header{
        color: #5A5F7D;
        padding: 12px 16px 10px 45px;
        background-color: ${({ theme }) => theme["bg-color-light"]};
    }
    .ant-collapse-content p{
        color: #9299B8;
        margin-bottom: 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 20px 20px 12px;
    }
    .ant-collapse-content > .ant-collapse-content-box .ant-collapse-content-box{
        padding: 10.5px 20px;
    }
    .ant-collapse.ant-collapse-borderless{
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item,
    .ant-collapse .ant-collapse-content{
        border-color: #E3E6EF;
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header{
        color: #ADB4D2 !important;
    }

    .ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{

        font-size: 8px;
    }

    .ant-collapse .ant-collapse {
        border: 0 none;
        background: #fff;
    }

    .ant-collapse .ant-collapse > .ant-collapse-item {
        border-bottom: 0;
    }
    .ant-collapse .ant-collapse .ant-collapse-header{
        border: 1px solid #E3E6EF;
        background: #F8F9FB;
    }
    .ant-collapse .ant-collapse .ant-collapse-content{
        margin: 20px 0 10px 0;
        border: 1px solid #E3E6EF;
        border-radius: 0;
    }

    /* // Ant Radio */
    .ant-radio-button-wrapper{
        height: 48px;
        line-height: 46px;
        padding: 0 25.25px;
        @media only screen and (max-width: 1024px){
            padding: 0 10px;
        }
        @media only screen and (max-width: 379px){
            height: 40px !important;
            line-height: 38px !important;
            font-size: 12px;
            padding: 0 6px;
        }
    }

    /* // Select */
    .ant-tree-select .ant-select-selector{
        height: 42px;
    }
    .tag-select-list{
        margin-bottom: -10px;
        .ant-select{
            margin-bottom: 10px;
        }
    }
    .ant-select-selector{
        border-color: #E3E6EF !important;
    }

    .ant-select{
        &.ant-select-multiple{
            .ant-select-selection-item{
                ${({ theme }) =>
        !theme.rtl ? "padding-left" : "padding-right"}: 8px;
            }
        }
        .ant-select-selection-item{
            ${({ theme }) =>
        !theme.rtl ? "padding-left" : "padding-right"}: 10px !important;
        }
        &.ant-select-lg{
            height: 50px;
            line-height: 48px;
            .ant-select-selector{
                height: 50px !important;
                line-height: 48px;
            }
            .ant-select-selection-item{
                line-height: 48px !important;
                ${({ theme }) =>
        !theme.rtl ? "padding-left" : "padding-right"}: 8px;
            }
            &.ant-select-multiple.ant-select-lg .ant-select-selection-item{
                height: 32px;
                line-height: 32px !important;
            }
        }
        &.ant-select-multiple.ant-select-sm{
            .ant-select-selection-item{
                height: 16px;
                line-height: 14px;
                font-size: 11px;
            }
        }
    }

    /* // Slider */
    .slider-with-input{
        .ant-slider{
            ${({ theme }) =>
        theme.rtl ? "margin-left" : "margin-right"}: 15px;
        }
        .slider-with-input__single{
            margin-bottom: 15px;
        }
    }

    /* // Taglist */
    .taglist-wrap{
        margin: -5px;
        .ant-tag {
            line-height: 22px;
            padding: 0 10.2px;
            border: 0 none;
            margin: 5px;
            color: ${({ theme }) => theme["gray-color"]};
            &.ant-tag-has-color{
                color: #fff !important;
            }
            &.ant-tag-magenta{
                color: #eb2f96;
            }
            &.ant-tag-red{
                color: #f5222d;
            }
            &.ant-tag-volcano{
                color: #fa541c;
            }
            &.ant-tag-orange{
                color: #fa8c16;
            }
            &.ant-tag-gold{
                color: #faad14;
            }
            &.ant-tag-line{
                color: #a0d911;
            }
            &.ant-tag-green{
                color: #a0d911;
            }
            &.ant-tag-cyan{
                color: #13c2c2;
            }
            &.ant-tag-blue{
                color: #1890ff;
            }
            &.ant-tag-geekbule{
                color: #2f54eb;
            }
            &.ant-tag-purple{
                color: #722ed1;
            }
        }
    }

    /* // Timepicker List */
    .timepicker-list{
        margin: -5px;
        .ant-picker{
            margin: 5px;
        }
    }

    /* // Ant Menu */
    .ant-menu{
        .ant-menu-submenu-title{
            svg{
                color: ${({ theme }) => theme["light-color"]};
            }
        }
    }

    /* Ant Comment */
    .ant-comment-inner{
        padding: 0;
    }
    .ant-comment-content-detail p{
        color: #9299B8;
    }
    .ant-list-items{
        padding-top: 22px;
    }
    .ant-list-items li:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment-nested{
        margin-top: 22px;
    }
    .ant-comment-actions li span{
        color: #ADB4D2;
    }
    .ant-comment-content-detail textarea{
        resize: none;
        min-height: 170px;
        border-radius: 5px;
    }

    /* // Vector Map */
    .rsm_map{
        min-height: 505px;
        .world-map{
            width: 100%;
            height: auto;
            @media only screen and (max-width: 1599px){
                height: 480px;
            }
            @media only screen and (max-width: 1399px){
                height: 400px;
            }
            @media only screen and (max-width: 575px){
                height: 400px;
            }
            @media only screen and (max-width: 767px){
                height: 300px;
            }
            @media only screen and (max-width: 575px){
                height: 250px;
            }
            @media only screen and (max-width: 479px){
                height: 350px;
            }
            @media only screen and (max-width: 375px){
                height: 240px;
            }
        }
        .controls{
            position: absolute;
            right: 30px;
            bottom: 30px;
            button{
                display: block;
                width: 27px;
                height: 27px;
                background: none;
                color: #5a5f7d;
                border: 1px solid #f1f2f6;
                padding: 0;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                cursor: pointer;
                &:first-child{
                    border-radius: 6px 6px 0 0;
                }
                &:last-child{
                    border-radius: 0 0 6px 6px;
                }
                &:focus{
                    outline: none;
                }
                svg{
                    width: 10px;
                }
            }
            button + button{
                border-top: 0 none;
            }
        }
    }

    /* // Checkout Wrapper */
    .checkoutWraper{
        .ant-card-body{
            padding: 50px 50px 50px 30px !important;
            @media only screen and (max-width: 575px){
                padding: 25px !important;
            }
            .ant-card-body{
                padding: 25px !important;
                @media only screen and (max-width: 375px){
                    padding: 15px !important;
                }
            }
        }
        .ant-steps{
            margin-top: -22px;
        }
    }

    /* // Star Active */
    a{
        i,
        span.fa{
          font-size: 16px;
          color: ${({ theme }) => theme["extra-light-color"]};
        }
        &.starDeactivate{
          i:before{
            content: "\f31b";
          }
        }
        &.starActive{
          i,
          span.fa{
            color: ${({ theme }) => theme["warning-color"]};
          }
          i:before,
          span.fa:before{
            color: ${({ theme }) => theme["warning-color"]};
            content: "\f005";
    
          }
        }
    }

    .ant-timeline{
        color: ${({ theme }) => theme["gray-color"]};
        .ant-timeline-item-content{
            font-size: 16px;
        }
    }

    
    .ant-rate-content{
        font-weight: 500;
        color: ${({ theme }) => theme["gray-color"]}
    }

    .account-card{
        .ant-card-head{
            .ant-card-extra{
                @media only screen and (max-width: 575px){
                   padding-top: 0 !important;
                }
            }
            
        }
                
    }

    /* // Rechart */
    .recharts-default-legend{
        .recharts-legend-item{
            min-width: 100px !important;
        }
    }

    /* // Radio */
    .radio-size-wrap{
            .ant-radio-button-wrapper{
                @media only screen and (max-width: 1450px){
                    padding: 0 11.5px;
                }
            }
        }
    }

    /* // Message  */
    .message-button-list{
        margin: -4px;
        .ant-btn {
            margin: 4px;
        }
    }
    /* Chart Label */

    .chart-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        color: #5a5f7d;
    }

    .chart-label .label-dot {
        ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 8px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
    }

    .chart-label .label-dot.dot-success {
        background: #20c997;
    }

    .chart-label .label-dot.dot-info {
        background: #5f63f2;
    }

    .chart-label .label-dot.dot-warning {
        background: #fa8b0c;
    }

    .chart-label .label-dot {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 10px;
    }

    // Ant comment action
    .ant-comment-actions{
        li{
            position: relative;
            &:not(:last-child){
                margin-right: 8px;
                padding-right: 8px;
                &:after{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background-color: #C6D0DC;
                    content: '';
                }
            }
            .com-time{
                cursor: default;
            }
            span{
                margin-right: 0;
            }
        }
    }

    // Emoji Picker React
    .emoji-picker-react{
        top: 15px;
        right: 25px;
        box-shadow: 0 5px 10px #efefef10;
        @media only screen and (max-width: 479px){
            top: 25px;
            right: -50px;
            width: 260px;
        }
        .emoji-categories{
            padding: 0 10px;
        }
        .emoji-search{
            margin: 0 10px;
        }
        .content-wrapper:before{
            display: none;
        }
        .emoji-group{
            padding: 0 10px;
        }
        .emoji-group:before{
            font-size: 12px;
            font-weight: 600;
            color: ${({ theme }) => theme["dark-color"]};
        }
        .emoji-group .emoji-img{
            margin: 5px !important;
        }
    }

    .wizard-side-border{
        >.ant-card{
            .ant-card-body{
                padding: 0 25px !important;
            }
        }
        .checkout-successful{
            >.ant-card{
                .ant-card-body{
                    padding: 25px !important;
                }
            }
        }
        .payment-method-form.theme-light{
            .shipping-selection__card{
                .ant-card-body{
                    padding: 25px 0 !important;
                }
            }
        }
        .shipping-selection__card{
            .ant-card-body{
                padding: 25px !important;
            }
        }
        .atbd-review-order{
            .ant-card-body{
                padding: 25px 25px 0 !important;
                @media only screen and (max-width: 767px) {
                    padding: 15px 15px 0 !important;
                }
            }
        }
        
        .ant-steps {
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
        }
        .steps-wrapper{
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
            ${({ theme }) =>
        theme.rtl ? "border-right" : "border-left"}: 1px solid ${({
            theme,
        }) => theme["border-color-light"]};
        }
    }
    .editor-compose > div {
        position: static;
        max-width: 100%;
        margin: 25px 0;
    }

    // Ant Dragger
    .ant-upload-drag{
        background-color: #fff !important;
        border-radius: 10px !important;
        display: flex;
        align-items: center;
        min-height: 100px;
        border-color: #C6D0DC;
        &.sDash-uploader-large{
            min-height: 180px;
        }
        .ant-upload-drag-container{
            .ant-upload-text{
                margin-bottom: 0;
                font-size: 15px;
                color: #9299B8;
            }
        }
    }

    // Form Validation
    .ant-form-item{
        &.ant-form-item-has-success{
            .ant-input{
                border-color: ${({ theme }) => theme["success-color"]};
            }
            &.ant-form-item-with-help{
                .ant-form-item-explain{
                    color: ${({ theme }) => theme["success-color"]};
                }
            }
        }
        &.ant-form-item-with-help{
            .ant-form-item-explain{
                margin-top: 6px;
            }
        }
    }
`;

const CalendarWrapper = Styled.div`
    a,div,p,li,ul,li{
        font-family: 'Poppins';
    }
    .react-calendar{
        font-family: 'Poppins';
        width: 100%;
        border-radius: 10px;
        padding: 24px 35px;
        border: 0 none;
        background-color: #fff;
        box-shadow: 0 5px 20px ${({ theme }) => theme["light-color"]}03;
        @media only screen and (max-width: 575px){
            padding: 15px;
        }
        .react-calendar__viewContainer{
            .react-calendar__year-view__months__month {
                &:hover{
                    background-color: transparent;
                }
                &.react-calendar__tile--hasActive{
                    background-color: transparent;
                    color: ${({ theme }) => theme["primary-color"]}
                }
            }
        }
    }
    .react-calendar__navigation{
        margin-bottom: .5rem;
        button{
            &:hover,
            &:focus{
                background-color: #fff !important;
            }
        }
        .react-calendar__navigation__arrow{
            font-size: 20px;
            min-width: auto;
            color: ${({ theme }) => theme["light-color"]};
        }
        .react-calendar__navigation__label{
            font-size: 16px;
            font-weight: 500;
            color: ${({ theme }) => theme["dark-color"]};
        }
    }
    .react-calendar__month-view__weekdays{
        .react-calendar__month-view__weekdays__weekday{
            abbr{
                font-size: 13px;
                font-weight: 500;
                text-decoration: none;
                color: ${({ theme }) => theme["light-color"]};
                @media only screen and (max-width: 1300px){
                    font-size: 11px;
                  }
            }
        }
    }
    .react-calendar__month-view__days{
        .react-calendar__tile{
            max-width: 43px !important;
            padding: 1em 0.5em;
            &:hover{
                background-color: transparent;
                font-weight: 500;
                color: ${({ theme }) => theme["dark-color"]};
            }
            &.react-calendar__month-view__days__day--neighboringMonth{
                abbr{
                    color: ${({ theme }) => theme["extra-light-color"]};
                }
            }
            abbr{
                font-size: 14px;
                color: ${({ theme }) => theme["gray-color"]};
            }
            &.react-calendar__tile--now{
                padding: 0.929em 0.5em;
                background-color: transparent;
                border-radius: 50%;
                border: 1px solid ${({ theme }) => theme["primary-color"]};
            }
            &.react-calendar__tile--active{
                border-radius: 50%;
                background-color: ${({ theme }) => theme["primary-color"]};
                abbr{
                    color: #fff;
                }
            }
        }
    }
    .calendar-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        @media only screen and (max-width: 1599px){
            flex-flow: column;
        }
        .calendar-header__left{
            display: flex;
            align-items: center;
            position: relative;
            .react-calendar__viewContainer{
                min-width: 320px;
                border-radius: 8px;
                padding: 15px !important;
                border: 0 none !important;
                box-shadow: rgba(82, 63, 105, 0.15) 0px 15px 40px 0px !important;
                @media only screen and (max-width: 479px){
                    min-width: 280px;
                }
            }
            @media only screen and (max-width: 1599px){
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 479px){
                flex-flow: column;
            }
            .btn-today{ 
                font-family: 'Poppins';
                font-size: 15px;
                font-weight: 500;
                height: 40px;
                width: 70px;
                color: white;
                background: rgb(227 99 168 /80%);
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: 479px){
                    margin-bottom: 15px;
                }
            }
            .year-select{
                .ant-select-selector{
                    border: 0 none;
                    .ant-select-selection-search{
                        input{
                            height: 35px;
                        }
                    }
                    .ant-select-selection-item{
                        font-size: 16px;
                        font-weight: 500;
                        color: ${({ theme }) => theme["dark-color"]};
                    }
                }
                .ant-select-arrow{
                    right: 18px;
                    ${({ theme }) => (theme.rtl ? "left" : "right")}: 18px;
                }
            }
            .react-calendar{
                ${({ theme }) =>
        theme.rtl ? "margin-right" : "margin-left"}: 30px;
                padding: 0;
                border: none;
                .react-calendar__navigation{
                    align-items: center;
                    margin: 0;
                    border: none;
                    .react-calendar__navigation__label{
                        padding: 0 20px;
                    }
                    .react-calendar__navigation__arrow {
                        border-radius: 5px;
                        width: 34px;
                        height: 34px;
                        svg,
                        img{
                            width: 22px;
                        }
                    }
                }
                .react-calendar__viewContainer{
                    position: absolute;
                    background: #fff;
                    z-index: 999;
                    border: 1px solid #ddd;
                    display: none;
                    &.show{
                        display: block;
                    }
                    .react-calendar__month-view__days{
                        /* display: none !important; */
                    }
                }
            }
            .calender-head__navigation{
                display: flex;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                ${({ theme }) =>
        theme.rtl ? "margin-right" : "margin-left"}: 30px;
                @media only screen and (max-width: 479px){
                    ${({ theme }) =>
        theme.rtl ? "margin-right" : "margin-left"}: 0px;
                }
                .btn-navigate{
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    i,
                    svg{
                        color: ${({ theme }) => theme["light-color"]};
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .date-label{
                    display: inline-block;
                    margin: 0 20px;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme["dark-color"]};
                }
            }
        }
        .calendar-header__right{
            display: flex;
            @media only screen and (max-width: 479px){
                flex-flow: column;
                align-items: center;
            }
            svg{
                width: 14px;
            }
            // .filterIcon{
            //     width: 34px !important;
            //         height: 34px !important;
            // }
            ul{
                li{
                    display: inline-block;
                    &:first-child{
                        ${({ theme }) =>
        theme.rtl
            ? "border-right"
            : "border-left"}: 0px solid ${({ theme }) =>
                theme["border-color-light"]};
  
                    }
                    &:not(:first-child){
                        a{
                            ${({ theme }) =>
        theme.rtl
            ? "border-top-right-radius"
            : "border-top-left-radius"}: 0px;
                            ${({ theme }) =>
        theme.rtl
            ? "border-bottom-right-radius"
            : "border-bottom-left-radius"}: 0px;
                        }
                    }
                    &:not(:last-child){
                        a{
                            ${({ theme }) =>
        theme.rtl
            ? "border-top-left-radius"
            : "border-top-right-radius"}: 0px;
                            ${({ theme }) =>
        theme.rtl
            ? "border-bottom-left-radius"
            : "border-bottom-right-radius"}: 0px;
                        }
                    }
                    a{  
                        font-family: 'Poppins';
                        font-size: 16px;
                        height:30px;
                        font-weight: 500;
                        color: white;
                        background-color: rgb(227 99 168 / 80%);
                        border: 1px solid white;
                        display: block;
                        border-radius: 4px;
                        width: 60px;
                        text-align: center;
                        padding: 6px 13.24px;
                        &.active{
                            color: #fff;
                            border-color: ${({ theme }) =>
        theme["primary-color"]};
                            background-color: ${({ theme }) =>
        theme["primary-color"]};
                        }
                    }
                }
            }
            .schedule-list{
                margin-left: 20px;
                ${({ theme }) =>
        theme.rtl ? "margin-right" : "margin-left"}: 20px;
                display: flex;
                align-items: center;
                color: ${({ theme }) => theme["light-color"]};
                @media only screen and (max-width: 479px){
                    margin: 15px 0 0 0;
                }
                svg,
                img,
                i{
                    margin-right: 6px;
                    ${({ theme }) =>
        theme.rtl ? "margin-left" : "margin-right"}: 6px;
                    color: ${({ theme }) => theme["primary-color"]};
                }
                &.active{
                    color: ${({ theme }) => theme["primary-color"]};
                }
            }
        }
    }

    .table-event{
        
        border: 1px solid ${({ theme }) => theme["border-color-light"]};
        &.table-responsive{
            @media only screen and (max-width: 1599px){
                display: block;
                width: 100%;
                overflow-x: auto;
            }
        }
        tr{
            th,
            td{
                padding: 16px 0;
                border-bottom: 1px solid ${({ theme }) =>
        theme["border-color-light"]};
                &:first-child{
                    min-width: 75px;
                    padding: 16px 18px 16px 18px;
                    ${({ theme }) =>
        theme.rtl
            ? "border-left"
            : "border-right"}: 1px solid ${({ theme }) =>
                theme["border-color-light"]};
                },
            }
            &:last-child{
                td{
                        border-bottom:none !important;
                    }
            }
        }
        thead{
            th{
                padding: 0 22px;
                background-color: ${({ theme }) => theme["bg-color-light"]};
                p{
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
        tbody{
            td{
                
                padding: 0 10px;
                font-size: 12px;
                min-width: 140px;
                color: ${({ theme }) => theme["light-color"]};
                .currentTime{
                    width: calc(100% + 20px);
                    height: 1px;
                    display: block;
                    background: rgb(227 99 168);
                    position: relative;
                    ${({ theme }) => (theme.rtl ? "right" : "left")}: -10px;
                    z-index: 222;
                    &:after{
                        position: absolute;
                        left: 0;
                        ${({ theme }) => (theme.rtl ? "right" : "left")}: 0px;
                        top: -6px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        content: '';
                        background-color: ${({ theme }) =>
        theme["secondary-color"]};
                    }
                }
                .day-event-item{
                    display: block;
                    border-radius: 3px 6px 6px 3px;
                    padding: 6px 12px;
                    margin: 2px 0;
                    position: relative;
                    &:after{
                        position: absolute;
                        left: 0;
                        ${({ theme }) => (theme.rtl ? "right" : "left")}: 0px;
                        bottom: 0;
                        width: 3px;
                        height: 100%;
                        border-radius: 6px 0 0 6px;
                        content: '';
                    }
                    &.primary{
                        background-color: #EFEFFE;
                        color: ${({ theme }) => theme["primary-color"]};
                        &:hover{
                            color: ${({ theme }) => theme["primary-color"]};
                        }
                        &:after{
                            background-color: ${({ theme }) =>
        theme["primary-color"]};
                        }
                    }
                    &.secondary{
                        background-color: #FFF0F6;
                        color: ${({ theme }) => theme["secondary-color"]};
                        &:hover{
                            color: ${({ theme }) => theme["secondary-color"]};
                        }
                        &:after{
                            background-color: ${({ theme }) =>
        theme["secondary-color"]};
                        }
                    }

                    &.upcoming{
                        background: #b3b3b3;
                        color: black;
                        &:after{
                            background-color:black;
                        }
                    }
                    &.expired{
                        background:#ffdddd;
                        color: red;
                        &:after{
                            background-color:red;
                        }
                    }
                    &.cancelled{
                        background: #ffc7c7;
                        color: #ff0000
                        &:after{
                            background-color:#ff0000;
                        }
                    }
                    &.active {
                        background: #d4ffdb;
                        color: #009c15;
                        &:after{
                            background-color:#009c15;
                        }
                      }
                      &.returned {
                        background-color: #0000ff24;
                        color: blue;
                        &:after{
                            background-color:blue;
                        }
                      }
                      &.completed {
                        background: #cecece;
                        color: rgb(90, 90, 90),
                        &:after{
                            background-color:rgb(90, 90, 90);
                        }
                      }
                      &.cancel_request {
                        background: #ffdddd;
                        color: red
                        &:after{
                            background-color:red;
                        }
                      }
                      &.refund_request {
                        background: #ffddc7;
                        color: #ff3c00
                        &:after{
                            background-color:#ff3c00;
                        }
                      }
                      &.refunded {
                        background:#ffddc7;
                        color: #ff3c00
                        &:after{
                            background-color:#ff3c00;
                        }
                      }
                    .event-title{
                        display: block;
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                }
            }
            
        }
    }

    .emptyData{
        min-height: 110px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme["light-color"]};
    }

    .event-week{
        tr{
            th,
            td{
                &:not(:last-child){
                    ${({ theme }) =>
        theme.rtl
            ? "border-left"
            : "border-right"}: 1px solid ${({ theme }) =>
                theme["border-color-light"]};
                }
            }
            &:last-child{
                td{
                        border-bottom:none !important;
                    }
            }
        }
        thead{
            tr{
                th{
                    text-align: center;
                    .week-dayName{
                        font-size: 13px;
                        font-weight: 400;
                        margin-bottom: 2px;
                        color: ${({ theme }) => theme["light-color"]};
                    }
                    .week-date{
                        display: inline-block;
                        line-height: 1.45;
                        border-radius: 9px;
                        font-size: 13px;
                        &.primary{
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
        tbody{
            .ant-dropdown-trigger{
                display: inline-block;
            }
        }
    }

    .schedule-event{
        tr{
            &:hover{
                box-shadow: 0 15px 40px ${({ theme }) =>
        theme["light-color"]}15;
                td{
                    // border-color: #fff;
                }
            }
            td{
                cursor: pointer;
                padding: 10px 20px !important;
                vertical-align: top;
                font-size: 14px;

                @media only screen and (max-width: 575px){
                    word-break: break-all;
                }

                &:first-child{
                    ${({ theme }) =>
        theme.rtl ? "border-left" : "border-right"}: 0 none;
                }
                &.schedule-time{
                    direction: ltr;
                    min-width: 100px;
                    width: 130px;
                    @media only screen and (max-width: 575px){
                        min-width: 55px;
                        width: 60px;
                    }
                }
                .ant-row{
                    &:not(:last-child){
                        margin-bottom: 10px;
                        
                @media only screen and (max-width: 575px){
                    border-bottom: 1px solid #e0e0e0;
                    padding-bottom: 10px
                }

                    }
                }
                .schedule-date{
                    font-weight: 500;
                    color: ${({ theme }) => theme["dark-color"]};
                }
                .schedule-date-name{
                    margin-left: 10px;
                }
                .event-title{
                    font-weight: 500;
                    color: ${({ theme }) => theme["dark-color"]};
                    @media only screen and (max-width: 575px){
                        display: inline-block;
                        margin-top: 6px; 
                    }
                }
                .schedule-time{
                    font-size: 13px;
                    color: ${({ theme }) => theme["light-color"]};
                }
            }
        }
    }

    .events{
        list-style-type: none;
        padding: 0;
        text-align: left;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        li{
            position: relative;
            height: 35px;
            a{
                position: absolute;
                top: 0;
                left: 0;
                ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
                padding: 5px 12px;
                z-index: 1;
                border-radius: 4px;
                font-size: 13px;
                width: 86% !important; 
                max-width: 86% !important;
                &:hover{
                    color: #fff;
                }
                &:after{
                    position: absolute;
                    left: 0;
                    ${({ theme }) => (theme.rtl ? "right" : "left")}: 0px;
                    bottom: 0;
                    width: 3px;
                    height: 100%;
                    border-radius: 6px 0 0 6px;
                    content: '';
                }
                &.primary{
                    background-color: #EFEFFE;
                    color: ${({ theme }) => theme["primary-color"]};
                    &:hover{
                        color: ${({ theme }) => theme["primary-color"]};
                    }
                    &:after{
                        background-color: ${({ theme }) =>
        theme["primary-color"]};
                    }
                }
                &.secondary{
                    background-color: #FFF0F6;
                    color: ${({ theme }) => theme["secondary-color"]};
                    &:hover{
                        color: ${({ theme }) => theme["secondary-color"]};
                    }
                    &:after{
                        background-color: ${({ theme }) =>
        theme["secondary-color"]};
                    }
                }
                &.upcoming{
                    background-color: #00000040;
                    color: black;
                    &:after{
                        background-color: black;
                    }
                }
                &.expired{
                    background-color: #ff00002e;
                    color: red;
                    &:after{
                        background-color:red;
                    }
                }
                &.cancelled{
                    background-color: #ff00002e;
                    color: red;
                    &:after{
                        background-color:red;
                    }
                }
                &.active {
                    background-color: #00800024;
                    color: green;
                    &:after{
                        background-color:green;
                    }
                  }
                  &.returned {
                    background-color: #0000ff26;
                    color: blue;
                    &:after{
                        background-color:blue;
                    }
                  }
                  &.completed {
                    background-color: #80808036;
                    color: gray;
                    &:after{
                        background-color:gray;
                    }
                  }
                  &.cancel_request {
                    background-color: #ff00002e;
                    color: red;
                    &:after{
                        background-color:red;
                    }
                  }
                  &.refund_request {
                    background: #ffddc7;
                    color: #ff3c00
                    &:after{
                        background-color:#ff3c00;
                    }
                  }
                  &.refunded {
                    background: #ffddc7;
                    color: #ff3c00
                    &:after{
                        background-color:#ff3c00;
                    }
                  }
            }
            
        }
        li::marker{
            display: none !important;
        }
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        overflow-y: auto;
        overflow-x: hidden;
    }

   .ant-picker-calendar-date-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      
     .ant-picker-calendar-date-content::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }
      
     .ant-picker-calendar-date-content::-webkit-scrollbar-thumb {
        background-color: rgb(227 99 168 / 50%);
        border-radius: 50px;
      }
      
    
`;

const BlockViewCalendarWrapper = Styled.div`
    &.table-responsive{
        table{
            @media only screen and (max-width: 1599px){
                // display: block;
                width: 100%;
                overflow-x: auto;
            }
            @media only screen and (max-width: 998px){
                display: block;
                width: 750px;
                overflow-x: auto;
            }
        }
    }
    .ant-picker-panel{
        border: 1px solid ${({ theme }) =>
        theme["border-color-light"]} !important;

    }
    .ant-picker-calendar{
        .ant-picker-body{
            padding: 0 !important;
        }
        .ant-picker-calendar-date {
            padding-top: 10px;
            margin: 0 !important;
            border-top: 0 none !important;
        }
        .ant-picker-cell{
            min-width: 155px;
            ${({ theme }) =>
        theme.rtl ? "border-left" : "border-right"}: 1px solid ${({
            theme,
        }) => theme["border-color-light"]};
            .ant-picker-cell-inner{
                // min-width: 155px;
            }
            &:hover{
                .ant-picker-calendar-date{
                    background-color: transparent !important;
                }
            }
            &.ant-picker-cell-today{
                .ant-picker-calendar-date-today{
                    background-color: rgb(227 99 168 / 16%) !important;
                    position: relative;
                    &:after{
                        position: absolute;
                        left: 0;
                        ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
                        top: 0;
                        height: 2px;
                        width: 100%;
                        content: '';
                        background-color: ${({ theme }) =>
        theme["primary-color"]};
                    }
                }
            }
            &.ant-picker-cell-selected{
                .ant-picker-calendar-date{
                    background-color: transparent;
                }
            }
            &.ant-picker-cell-in-view{
                .ant-picker-calendar-date{
                    color: ${({ theme }) => theme["gray-color"]};
                }
            }
            .ant-picker-calendar-date{
                color: ${({ theme }) => theme["extra-light-color"]};
            }
            .ant-picker-calendar-date-value{
                margin: 6px 4px 10px 0;
                color: rgb(227 99 168) !important;
            }
        }
        table{
            thead{
                tr{
                    th{
                        padding: 16px 20px !important;
                        background-color: ${({ theme }) =>
        theme["bg-color-light"]} !important;
                        color: ${({ theme }) => theme["gray-color"]};;
                    }
                }
            }
            tr{
                &:last-child{
                    td{
                        border-bottom: 0 none;
                    }
                }
                th,
                td{
                    ${({ theme }) =>
        theme.rtl ? "border-left" : "border-right"}: 0;
                    border-bottom: 1px solid ${({ theme }) =>
        theme["border-color-light"]};
                    &:last-child{
                        border-right: 0 none;
                        ${({ theme }) =>
        theme.rtl ? "border-left" : "border-right"}: 0 none;
                    }
                }
            }
        }
    }
`;

export { CalendarWrapper, BlockViewCalendarWrapper, Main };
