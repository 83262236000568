import React, { useState, useEffect } from "react";
import styles from "./Detail.module.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  Avatar,
  message,
  Spin,
  Button,
  Input,
  Form,
  Skeleton,
  Row,
  Col,
  Pagination,
  Popover,
  Modal,
  Tooltip,
  Card,
} from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  interactionDetail,
  allReactionsList,
  updateInteraction,
  getCheckShortUrl,
} from "../../../../redux/Interaction/action";
import moment from "moment";
import { chatResponse_time } from "../../../../utility/ComanFunctions";
import { useTranslation } from "react-i18next";
import ChatModule from "../../responseList/chatModule/ChatModule";
import { QrcodeOutlined } from "@ant-design/icons";
import { WEBSITE_URL } from "../../../../config/Constant";
import VideoPlayer from "../createInteraction/VideoPlayer";

function InteractionDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [linksForm] = Form.useForm();
  const [paginationObj, setPaginationObj] = useState({});
  const [editUrlModal, setEditUrlModal] = useState(false);
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [urlForm] = Form.useForm();
  const [errorMsg, setErrorMsg] = useState("");
  const [allList, setAllList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [responseId, setResponseId] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const [validUrlStatus, setValidUrlNameStatus] = useState(true);
  const [urlLoading, setUrlLoading] = useState(false);
  const [addedFields, setAddedFields] = useState(1);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [collectDetail, setCollectDetail] = useState(true);
  const [previewContent, setPreviewContent] = useState("");
  const [CtaLink, setCtaLink] = useState();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      fetchDetail(id);
    }
  }, []);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const fetchDetail = async (val) => {
    const resp = await dispatch(interactionDetail(val));
    if (resp && resp?.data) {
      setLoading(false);
      setDetail(resp && resp?.data);
      setCtaLink(resp && resp?.data?.cta_links);
      setAddedFields(resp?.data?.cta_links?.length);
      setErrorMsg("");
    } else {
      setDetail({});
      setErrorMsg(resp);
    }
  };

  useEffect(() => {
    fetchList(currentPage, dataPerPage);
  }, [currentPage, dataPerPage]);

  const fetchList = async (pageNum, limit) => {
    setLoading(true);
    const params = {
      id: id,
      page: pageNum,
      limit: limit,
    };
    const resp = await dispatch(allReactionsList(params));
    if (resp && resp?.data) {
      setLoading(false);
      setAllList(resp?.data);
      setPaginationObj(resp?.pagination);
      resp?.data.map((data, i) => {
        data.response_time = chatResponse_time(data.created_at);
      });
    } else message.error(resp?.data?.message);
  };

  const showChatModule = (boolean, val) => {
    setIsChatOpen(boolean);
    setResponseData(val);
    setResponseId(val._id);
  };

  // pagination
  const onDataChange = (current, pageSize) => {
    setDataPerPage(pageSize);
    setCurrentPage(current);
  };

  // edit url modal
  const showEditUrlModal = (val) => {
    setEditUrlModal(true);
    urlForm.setFieldsValue({
      short_url: val,
    });
  };

  const showCTAurlModal = (val) => {
    setAddLinkModal(true);
  };

  const confirmEditUrl = async (val) => {
    const payload = {
      short_url: shortUrl,
    };
    if (validUrlStatus == true) {
      const editInteraction = await dispatch(updateInteraction(payload, id));
      if (editInteraction) {
        setEditUrlModal(false);
        fetchDetail(id);
        setShortUrl("");
      }
    }
  };
  const handleCancel = () => {
    setEditUrlModal(false);
    setShortUrl("");
  };

  // URL VALIDATION
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  const onchangeShortUrl = (val) => {
    if (val == detail?.short_url?.short_code) {
      setUrlLoading(false);
      setShortUrl(val.replace(/\s/g, "").toLowerCase());
      setSubmitStatus(false);
    } else {
      setUrlLoading(true);
      setShortUrl(val.replace(/\s/g, "").toLowerCase());
      setSubmitStatus(val == "" ? true : false);
    }
  };

  const onValidUrlCheck = async (value) => {
    if (value == "" || value == detail?.short_url?.short_code) {
      setUrlLoading(false);
    } else {
      const response = await dispatch(getCheckShortUrl(value));
      if (response) {
        setUrlLoading(false);
        setValidUrlNameStatus(true);
      } else {
        setUrlLoading(false);
        setValidUrlNameStatus(false);
      }
    }
  };

  const UpdateLinks = async (value) => {
    let payload = JSON.stringify(value?.cta_links);
    // let payload = { cta_links: JSON.stringify(value) };
    let form_data = new FormData();
    form_data.append("cta_links", payload);
    const editInteraction = await dispatch(updateInteraction(form_data, id));
    if (editInteraction) {
      setAddLinkModal(false);
      fetchDetail(id);
    }
  };

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  const onChange = (checked) => {
    setCollectDetail(checked);
  };

  const initialValues = {
    cta_links: CtaLink?.map((item) => ({
      title: item.title,
      links: item.links,
      _id: item._id,
    })),
  };

  return (
    <div className={styles.detailContainer}>
      {loading ? (
        <div className={styles.loadArbOX}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.mainContainer}>
            <div
              className={styles.backBtn}
              onClick={() => navigate("/interaction")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="rgb(206 19 119 / 54%)"
                height="inherit"
                width="inherit"
                style={{
                  objectFit: "cover",
                }}
                version="1.1"
                id="Layer_1"
                viewBox="0 0 512 512"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <g>
                      {" "}
                      <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
            <Row gutter={25}>
              {isChatOpen && screenSize.width < 992 ? (
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                  {" "}
                  <ChatModule
                    responseData={responseData}
                    responseId={responseId}
                    showChatModule={showChatModule}
                    pageNum={currentPage}
                    limit={dataPerPage}
                    width={true}
                    fetchList={fetchList}
                  />
                </Col>
              ) : (
                ""
              )}
              <Col
                span={isChatOpen ? 12 : 24}
                xxl={isChatOpen ? 12 : 24}
                xl={isChatOpen ? 12 : 24}
                lg={isChatOpen ? 12 : 24}
                md={isChatOpen ? 24 : 24}
                sm={isChatOpen ? 24 : 24}
                xs={isChatOpen ? 24 : 24}
              >
                {isChatOpen ? (
                  ""
                ) : (
                  <Row gutter={25}>
                    <Col span={24}>
                      {Object.keys(detail).length > 0 && errorMsg == "" ? (
                        <div className={styles.interactionDetailContainer}>
                          {detail?.content_type == "video_upload" ||
                            detail?.content_type == "video_record" ? (
                            <div className={styles.ThumbnailBox}>
                              {/* <video
                                src={detail?.upload_content}
                                playsInline
                                autoPlay
                                muted
                                preload="true"
                              >

                              </video> */}
                              <VideoPlayer videoUrl={detail?.upload_content} />
                            </div>
                          ) : detail?.content_type == "image_upload" ? (
                            <div className={styles.ThumbnailBox}>
                              <img src={detail?.upload_content}></img>
                            </div>
                          ) : detail?.content_type == "audio_upload" ||
                            detail?.content_type == "audio_record" ? (
                            <div className={styles.audioContainerBox}>
                              <audio controls>
                                <source
                                  src={detail?.upload_content}
                                  type="audio/mpeg"
                                />
                              </audio>
                            </div>
                          ) : detail?.content_type == "text" ? (
                            <div className={styles.textContentBox}>
                              <div
                                className={styles.textMsg}
                                dangerouslySetInnerHTML={{
                                  __html: detail?.upload_content,
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className={styles.cardInfoContainer}>
                            <h4>{detail?.title}</h4>
                            <p className={styles.descriptionText}>
                              {detail?.description}
                            </p>
                            <div className={styles.shortUrlContainer}>
                              <p className={styles.interactionUrlTxt}>
                                <a>{`${WEBSITE_URL}${detail?.short_url?.short_code}`}</a>
                              </p>
                              <FeatherIcon
                                icon="edit"
                                className={styles.editUrlIcon}
                                onClick={() =>
                                  showEditUrlModal(
                                    detail?.short_url?.short_code
                                  )
                                }
                              />
                            </div>
                            <p className={styles.viewButton}>
                              <Tooltip
                                title={t("Click & go to link")}
                                placement="top"
                              >
                                <a
                                  target="_blank"
                                  href={`${WEBSITE_URL}${detail?.short_url?.short_code}`}
                                >
                                  <div
                                    className={styles.interactionDetailIconeDiv}
                                  >
                                    {" "}
                                    <FeatherIcon icon="link" size={24} />
                                  </div>
                                </a>
                              </Tooltip>
                              {detail?.cta_link_enable == true ? (
                                <Tooltip title={t("Add Links")} placement="top">
                                  <div
                                    className={styles.interactionDetailIconeDiv}
                                    onClick={() => {
                                      showCTAurlModal(detail?.cta_links);
                                    }}
                                  >
                                    {" "}
                                    <FeatherIcon icon="plus" size={24} />
                                  </div>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              <Tooltip
                                title={t("Click & copied link")}
                                placement="top"
                              >
                                <div
                                  className={styles.interactionDetailIconeDiv}
                                  onClick={() => {
                                    message.success(
                                      t("Link copied successfully")
                                    );
                                    navigator.clipboard.writeText(
                                      `${WEBSITE_URL}${detail?.short_url?.short_code}`
                                    );
                                  }}
                                >
                                  {" "}
                                  <FeatherIcon icon="copy" size={24} />
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={t("View QR Code")}
                                placement="top"
                              >
                                <div
                                  className={styles.interactionDetailIconeDiv}
                                  onClick={() => {
                                    setQrModalOpen(true);
                                    setPreviewContent(
                                      detail?.short_url?.qr_code
                                    );
                                  }}
                                >
                                  <QrcodeOutlined
                                    style={{ fontSize: "24px" }}
                                  />
                                </div>
                              </Tooltip>
                            </p>
                            <div className={styles.reactionContainer}>
                              <div className={styles.reactionBox}>
                                <FeatherIcon icon="thumbs-up" size={24} />
                                <p>{detail?.like_count}</p>
                              </div>
                              <div className={styles.reactionBox}>
                                <FeatherIcon icon="thumbs-down" size={24} />
                                <p>{detail?.dislike_count}</p>
                              </div>
                              <div className={styles.reactionBox}>
                                <FeatherIcon icon="eye" size={24} />
                                <p>{detail?.view_count}</p>
                              </div>
                              <div className={styles.reactionBox}>
                                <FeatherIcon icon="message-circle" size={24} />
                                <p>{detail?.reply_count}</p>
                              </div>
                            </div>
                            <p className={styles.dateInfoText}>
                              {moment(
                                detail?.created_at,
                                "YYYY-MM-DDTHH:mm:ss.SSSSZ"
                              ).format("MMM DD, YYYY LT")}
                              .
                            </p>
                          </div>
                        </div>
                      ) : (
                        <h3>{errorMsg}</h3>
                      )}
                    </Col>
                  </Row>
                )}

                <div
                  style={{
                    borderRight: isChatOpen
                      ? "2px solid rgb(217 19 241 / 17%)"
                      : "",
                    borderTop: isChatOpen
                      ? ""
                      : "2px solid rgb(217 19 241 / 17%)",
                    overflow: "auto",
                    height: "70vh",
                  }}
                  className={styles.allReactionContainer}
                >
                  <div className={styles.responsesContainer}>
                    {loading ? (
                      <>
                        <Skeleton.Input
                          active={true}
                          size={"large"}
                          block={false}
                          className="skeletonInputMain"
                          style={{ marginBottom: "20px" }}
                        />
                        {new Array(5).fill("").map((_, i) => {
                          return (
                            <>
                              <div className={styles.interactionCard}>
                                <Skeleton
                                  avatar
                                  paragraph={{
                                    rows: 1,
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {allList &&
                          allList.length > 0 &&
                          Object.keys(detail).length > 0 &&
                          errorMsg == "" ? (
                          <>
                            <div className={styles.parentDiv}>
                              <div style={{ width: "100%" }}>
                                <div
                                  className={styles.interactionCardContainer}
                                >
                                  <Row gutter={25}>
                                    {allList &&
                                      allList.map((data, i) => {
                                        data.response_time = chatResponse_time(
                                          data.created_at
                                        );
                                        return (
                                          <>
                                            <Col span={24} key={i}>
                                              <div
                                                className={
                                                  styles.customUserResponseCard
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.userThumbNail
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.customUserResponseCardImageTitle
                                                    }
                                                  >
                                                    {data?.user_id
                                                      ?.user_image !== "" ? (
                                                      <Avatar
                                                        className={
                                                          styles.userAvatar
                                                        }
                                                        size={35}
                                                        src={
                                                          data?.user_id
                                                            ?.user_image
                                                        }
                                                      />
                                                    ) : (
                                                      <Avatar
                                                        size={35}
                                                        icon={<UserOutlined />}
                                                      />
                                                    )}
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        data?.user_id
                                                          ?.username !== ""
                                                          ? data?.user_id
                                                            ?.username
                                                          : data?.user_id?.email
                                                      }
                                                    >
                                                      <h3>
                                                        {data?.user_id
                                                          ?.username !== ""
                                                          ? data?.user_id
                                                            ?.username
                                                            .length > 15
                                                            ? data?.user_id?.username.slice(
                                                              0,
                                                              15
                                                            ) + "..."
                                                            : data?.user_id
                                                              ?.username
                                                          : data?.user_id?.email
                                                            .length > 15
                                                            ? data?.user_id?.email.slice(
                                                              0,
                                                              15
                                                            ) + "..."
                                                            : data?.user_id
                                                              ?.email}
                                                      </h3>
                                                    </Tooltip>
                                                    <div
                                                      className={
                                                        styles.customUserResponseTime
                                                      }
                                                    >
                                                      <span>
                                                        {moment(
                                                          data.created_at
                                                        ).format(
                                                          "MMM DD YYYY, hh:mm a"
                                                        )}{" "}
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div
                                                    className={
                                                      styles.viewActionIcon
                                                    }
                                                    onClick={() => {
                                                      showChatModule(
                                                        true,
                                                        data
                                                      );
                                                    }}
                                                  >
                                                    <FeatherIcon
                                                      icon="message-square"
                                                      size={16}
                                                    />
                                                  </div>
                                                </div>
                                                <Row>
                                                  <Col span={24}>
                                                    <div
                                                      className={
                                                        styles.customUserResponseTitle
                                                      }
                                                      onClick={() => {
                                                        navigate(
                                                          `/interaction-detail/${data?.interaction_id?._id}`
                                                        );
                                                      }}
                                                    >
                                                      {data?.interaction_id
                                                        ?.title !== ""
                                                        ? data?.interaction_id
                                                          ?.title
                                                        : ""}
                                                    </div>
                                                  </Col>
                                                  <Col span={24}>
                                                    <div
                                                      className={
                                                        styles.customUserResponseDescription
                                                      }
                                                    >
                                                      {data?.interaction_id
                                                        ?.description !== ""
                                                        ? data?.interaction_id
                                                          ?.description
                                                          .length > 50
                                                          ? data?.interaction_id?.description.slice(
                                                            0,
                                                            50
                                                          ) + "..."
                                                          : data?.interaction_id
                                                            ?.description
                                                        : ""}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Col>
                                          </>
                                        );
                                      })}
                                  </Row>
                                </div>
                              </div>
                            </div>
                            <div className={styles.paginationComponent}>
                              <Pagination
                                total={paginationObj?.total_counts}
                                showSizeChanger
                                pageSizeOptions={[10, 20, 50, 100]}
                                defaultPageSize={dataPerPage}
                                defaultCurrent={currentPage}
                                onChange={onDataChange}
                                className="interactionPagination"
                              />
                            </div>
                          </>
                        ) : (
                          <p>{t("No Response Data")}</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
              {isChatOpen && screenSize.width > 992 ? (
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                  {" "}
                  <ChatModule
                    responseData={responseData}
                    responseId={responseId}
                    showChatModule={showChatModule}
                    pageNum={currentPage}
                    limit={dataPerPage}
                    width={true}
                    fetchList={fetchList}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>

          <Modal
            title={t("Edit Short URL")}
            centered={true}
            open={editUrlModal}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              onFinish={(value) => confirmEditUrl(value)}
              form={urlForm}
              layout="vertical"
              className={styles.urlModal}
            >
              <Form.Item
                name="short_url"
                label={
                  <span>
                    <span style={{ color: "red" }}>* </span>
                    {t("Short URL")}
                  </span>
                }
                className={styles.inputMain}
                hasFeedback
                validateStatus={
                  submitStatus
                    ? shortUrl == "" && validUrlStatus
                      ? "error"
                      : ""
                    : shortUrl == ""
                      ? ""
                      : urlLoading
                        ? "validating"
                        : validUrlStatus
                          ? "success"
                          : "error"
                }
                help={
                  submitStatus
                    ? shortUrl == "" && validUrlStatus
                      ? t("Please enter url!")
                      : ""
                    : shortUrl == ""
                      ? ""
                      : urlLoading
                        ? ""
                        : validUrlStatus
                          ? ""
                          : t("This short Url is not available.")
                }
                rules={[
                  {
                    required:
                      submitStatus && shortUrl == "" && !validUrlStatus
                        ? true
                        : false,
                    message: t("Please Enter short url"),
                  },
                  {
                    pattern: new RegExp(
                      /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                    ),
                    message: t(
                      "URL can not contain whitespace or upper case letters"
                    ),
                  },
                ]}
              >
                <Input
                  className={styles.profileInputFiled}
                  style={{ padding: "0px 8px" }}
                  prefix={
                    <>
                      <b className={styles.backslash}>
                        {WEBSITE_URL}
                      </b>
                    </>
                  }
                  onPaste={(e) => {
                    e.preventDefault()
                  }}
                  defaultValue={shortUrl}
                  onChange={(e) => {
                    onchangeShortUrl(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Space") event.preventDefault();
                  }}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toLocaleLowerCase())
                  }
                  placeholder={t("Enter url")}
                  disabled={false}
                  autoFocus={true}
                />
              </Form.Item>

              <div className={styles.createBtnContainer}>
                <Button className={styles.cancelBtn} onClick={handleCancel}>
                  <div className={styles.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button
                  className={styles.createBtn}
                  onClick={urlForm.submit}
                  style={{
                    cursor:
                      shortUrl == detail?.short_url?.short_code ||
                        shortUrl == "" ||
                        validUrlStatus == false
                        ? "no-drop"
                        : "pointer",
                    background:
                      shortUrl == detail?.short_url?.short_code ||
                        shortUrl == "" ||
                        validUrlStatus == false
                        ? "#ff8ace"
                        : "rgb(223 71 153)",
                    color:
                      shortUrl == detail?.short_url?.short_code ||
                        shortUrl == "" ||
                        validUrlStatus == false
                        ? "rgb(251 251 251 / 69%)"
                        : "white",
                  }}
                  disabled={
                    shortUrl == "" ||
                      shortUrl == detail?.short_url?.short_code ||
                      validUrlStatus == false
                      ? true
                      : false
                  }
                >
                  <div className={styles.buttonContent}>{t("Submit")}</div>
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            title={t("Add Link")}
            centered={true}
            open={addLinkModal}
            onCancel={() => {
              setAddLinkModal(false);
            }}
            footer={null}
          >
            <Form
              onFinish={(value) => UpdateLinks(value)}
              form={linksForm}
              initialValues={initialValues}
              layout="vertical"
              className={styles.urlModal}
            >
              <>
                <Form.List name="cta_links">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, i) => (
                        <Card
                          size="small"
                          className={styles.ExtraCTACard}
                          title={`CTA ${field.name + 1}`}
                          key={field.key}
                          extra={
                            <CloseOutlined
                              style={{
                                display: i == 0 ? "none" : "block",
                              }}
                              onClick={() => {
                                remove(field.name);
                                setAddedFields(addedFields - 1);
                                onChange(!collectDetail);
                              }}
                            />
                          }
                        >
                          <>
                            <Form.Item
                              label={t("Title")}
                              name={[field.name, "title"]}
                              className={styles.CTALinkFormItem}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter a title"),
                                },
                                {
                                  max: 25,
                                  message: t("Length should be less than 25"),
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("Enter Title")}
                              />
                            </Form.Item>
                            <Form.Item
                              label={t("Link")}
                              name={[field.name, "links"]}
                              className={styles.CTALinkFormItem}
                              rules={[
                                {
                                  required: true,
                                  message: t("Please enter link"),
                                },
                                {
                                  type: "url",
                                  message: t("Please enter valid url."),
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder={t("Enter Link")}
                                onChange={(e) => {
                                  validateUrl(e.target.value);
                                }}
                              />
                            </Form.Item>
                          </>
                        </Card>
                      ))}
                      {addedFields < 3 && (
                        <Button
                          type="dashed"
                          className={styles.ExtraCTACard}
                          onClick={() => {
                            form
                              .validateFields()
                              .then(() => {
                                add();
                                setAddedFields(addedFields + 1);
                              })
                              .catch((errorInfo) => {
                                console.error("Validation failed:", errorInfo);
                              });
                          }}
                          block
                        >
                          + {t("Add Item")}
                        </Button>
                      )}
                    </div>
                  )}
                </Form.List>
              </>
              <div className={styles.createBtnContainer}>
                <Button
                  className={styles.cancelBtn}
                  onClick={() => {
                    setAddLinkModal(false);
                  }}
                >
                  <div className={styles.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button className={styles.createBtn} onClick={linksForm.submit}>
                  <div className={styles.buttonContent}>{t("Submit")}</div>
                </Button>
              </div>
            </Form>
          </Modal>

          <Modal
            open={qrModalOpen}
            footer={null}
            centered={true}
            onCancel={() => {
              setQrModalOpen(false);
              setPreviewContent("");
            }}
            width={400}
            className="interactionPreviewModal"
          >
            <div className={styles.modalQrPreviewContent}>
              <div className={styles.QrPreviewImg}>
                <img src={previewContent}></img>
              </div>
              <p>
                {t("Scan this qr code to View this Interaction's web view")}
              </p>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default InteractionDetail;
