import React, { useState, useEffect } from "react";
import styles from "./layout.module.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Dashboard from "../Components/dashboard/Dashboard";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Spin, message } from "antd";
import { getItem } from "../../utility/LocalStorageControl";

function MainLayout({ children }) {
  const [loading, setLoading] = useState(false);
  const [toke, setToken] = useState(getItem("access_token"));

  // useEffect(() => {
  //   setLoading(true)
  //   if (toke !== null && toke !== undefined && toke !== '') {
  //     setTimeout(() => {
  //       setLoading(false)
  //     }, 500);
  //   } else {
  //     setLoading(false)
  //   }
  // }, []);

  return (
    <>
      <div className={styles.mainBodyLayout}>
        {/* {
          loading ?
            <Spin />
            :
            (<> */}
        <div className={styles.sidebarMenuComponent}>
          <Sidebar />
        </div>
        <div className={styles.MainBodyComponent}>
          <div className={styles.headerComponent}>
            <Header />
          </div>
          <div className={styles.MainComponentContainer}>{children}</div>
          <div className={styles.footerComponent}>
            <Footer />
          </div>
        </div>
        {/* </>)
        } */}

      </div>
    </>
  );
}

export default MainLayout;
