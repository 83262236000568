import { API } from "../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  subScriptionList: {},
};

//DEFINE AUTH REDUCER FUNCTION
const SubScriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case API.subscription.list:
      return {
        ...state,
        subScriptionList: action.subScriptionList,
        loading: false,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default SubScriptionReducer;
