import React, { useEffect, useState } from "react";
import styles from "./AddInteraction.module.css";
import { Form, Input, Button, Switch, Popover, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useTranslation } from "react-i18next";
import { getCheckShortUrl } from "../../../../redux/Interaction/action";
import { useDispatch } from "react-redux";
import suggestionIcon from "../../../../assets/Images/exclamation-mark.svg";
import { getItem } from "../../../../utility/LocalStorageControl";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { WEBSITE_URL } from "../../../../config/Constant";

function AddInteraction() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [CTASwitch, setCTASwitch] = useState(false);
  const [isReplay, setIsReplay] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shortUrl, setShortUrl] = useState("");
  const [description, setDescription] = useState("");
  const [validUrlStatus, setValidUrlNameStatus] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const use_Detail = getItem("userDetails");
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [addedFields, setAddedFields] = useState(1);

  // for short url api
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  const handleSubmit = async (value) => {
    if (
      validUrlStatus == true &&
      title !== "" &&
      shortUrl !== "" &&
      loading == false
    ) {
      let Obj = {};
      Obj.title = title;
      Obj.description = description;
      Obj.is_viewers_reply = isReplay;
      Obj.cta_link_enable = CTASwitch;
      Obj.short_url = shortUrl;
      if (value?.cta_links) {
        Obj.cta_links = value?.cta_links;
      }
      if (validUrlStatus == true) {
        if (CTASwitch == true) {
          if (value?.cta_links?.length > 0) {
            navigate("/add-interaction/create-video", {
              state: { formData: Obj },
            });
          }
        } else {
          navigate("/add-interaction/create-video", {
            state: { formData: Obj },
          });
        }
      }
    }
  };

  const onChange = (checked) => {
    if (checked == true) {
      if (CTASwitch == false) {
        setIsReplay(true);
      } else {
        setCTASwitch(false);
        setIsReplay(true);
      }
    } else {
      setIsReplay(false);
    }
  };

  const onChangeCTALink = (checked) => {
    if (checked == true) {
      if (isReplay == false) {
        setCTASwitch(true);
      } else {
        setIsReplay(false);
        setCTASwitch(true);
      }
    } else {
      setCTASwitch(false);
    }
  };

  const onchangeShortUrl = (val) => {
    setLoading(true);
    setShortUrl(val);
    setSubmitClick(true);
    if (shortUrl == val) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const onValidUrlCheck = async (value) => {
    setLoading(true);
    if (value == "") {
      setLoading(false);
      setShortUrl("");
      setValidUrlNameStatus(false);
    } else {
      const response = await dispatch(getCheckShortUrl(value));
      if (response == false) {
        setLoading(false);
        setValidUrlNameStatus(false);
      } else {
        setLoading(false);
        setValidUrlNameStatus(true);
      }
    }
  };

  return (
    <div className={styles.addInteractionContainer}>
      <h2 className={styles.HeaderTitle}>
        <div className={styles.backBtn}>
          <svg
            onClick={() => navigate("/interaction")}
            xmlns="http://www.w3.org/2000/svg"
            fill="rgb(206 19 119 / 54%)"
            height="inherit"
            width="inherit"
            style={{
              objectFit: "cover",
            }}
            version="1.1"
            id="Layer_1"
            viewBox="0 0 512 512"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            />

            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <g>
                  {" "}
                  <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>
        </div>{" "}
        <span>{t("Interaction")}</span>
      </h2>

      <div className={styles.formContainer}>
        <h2>{t("Let's Get Started")}</h2>

        <Form
          layout="vertical"
          name="basic"
          autoComplete="nope"
          onFinish={(value) => handleSubmit(value)}
          className={styles.interactionForm}
          form={form}
          initialValues={{ cta_links: [{ title: "", links: "" }] }}
        >
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[
              {
                required: true,
                message: t("Please enter title!"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("Enter Title")}
              onChange={(e) => {
                setTitle(e.target.value);
                if (e.target.value.length > 4) {
                  setOpenSuggestionBox(true);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="short_url"
            label={
              <>
                <span> {t("Short URL")}</span>
                {title !== "" && (
                  <Popover
                    placement="rightTop"
                    trigger="click"
                    open={openSuggestionBox}
                    content={
                      <>
                        <div className={styles.suggestionContainer}>
                          <div className={styles.suggestionText}>
                            <p>
                              {t(
                                "Click this to use it As Short Url for your Interaction"
                              )}
                            </p>
                            <div
                              className={styles.suggestionUrl}
                              onClick={() => {
                                setOpenSuggestionBox(false);
                                form.setFieldsValue({
                                  short_url:
                                    (use_Detail?.username?.length > 4
                                      ? use_Detail?.username?.slice(0, 4)
                                      : use_Detail?.username) +
                                    "-" +
                                    (title !== "" &&
                                      title.replace(/\s/g, "").length > 4
                                      ? title.replace(/\s/g, "").slice(0, 4)
                                      : title.replace(/\s/g, "")),
                                });
                                onchangeShortUrl(
                                  (use_Detail?.username?.length > 4
                                    ? use_Detail?.username?.slice(0, 4)
                                    : use_Detail?.username) +
                                  "-" +
                                  (title !== "" &&
                                    title.replace(/\s/g, "").length > 4
                                    ? title.replace(/\s/g, "").slice(0, 4)
                                    : title.replace(/\s/g, ""))
                                );
                              }}
                            >
                              {(use_Detail?.username?.length > 4
                                ? use_Detail?.username?.slice(0, 4)
                                : use_Detail?.username) +
                                "-" +
                                (title !== "" &&
                                  title.replace(/\s/g, "").length > 4
                                  ? title.replace(/\s/g, "").slice(0, 4)
                                  : title.replace(/\s/g, ""))}
                            </div>
                          </div>
                          <FeatherIcon
                            icon="x-circle"
                            className={styles.cancelIcon}
                            onClick={() => setOpenSuggestionBox(false)}
                          />
                        </div>
                      </>
                    }
                  >
                    <div
                      className={styles.suggestionMark}
                      onClick={() => setOpenSuggestionBox(true)}
                    >
                      <img src={suggestionIcon} />
                    </div>
                  </Popover>
                )}
              </>
            }
            hasFeedback
            validateStatus={
              submitClick
                ? shortUrl == "" && !validUrlStatus
                  ? "error"
                  : loading
                    ? "validating"
                    : validUrlStatus
                      ? "success"
                      : "error"
                : ""
            }
            help={
              submitClick
                ? shortUrl == "" && !validUrlStatus
                  ? t("Please enter url!")
                  : loading
                    ? ""
                    : validUrlStatus
                      ? ""
                      : t("This short Url is not available.")
                : ""
            }
            rules={[
              {
                required: shortUrl == "" && !validUrlStatus ? true : false,
                message: t("Please Enter short url"),
              },
              {
                pattern: new RegExp(/^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/),
                message: t(
                  t("URL can not contain whitespace or upper case letters")
                ),
              },
            ]}
          >
            <Input
              style={{ padding: "0px 8px" }}
              prefix={
                <>
                  <b className={styles.backslash}>{WEBSITE_URL}</b>
                </>
              }
              onPaste={(e) => {
                e.preventDefault()
              }}
              onChange={(e) => {
                onchangeShortUrl(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.code === "Space") event.preventDefault();
                if (event.key === "/") event.preventDefault();
              }}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toLocaleLowerCase())
              }
              placeholder={t("Enter url")}
            />
          </Form.Item>
          <Form.Item label={t("Description")} name="description">
            <TextArea
              size="large"
              rows={3}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              placeholder={t("Enter description")}
            />
          </Form.Item>
          <Row>
            <Col xl={12} xxl={12} lg={12} md={24} xs={24} sm={24}>
              <Form.Item name="is_viewers_reply" className="interactionSwitch">
                <p className={styles.switchLabelText}>
                  {t("Can User Reply on Interaction")}
                </p>
                <Switch
                  checked={isReplay}
                  onChange={onChange}
                  className="interactionSwitch"
                />
              </Form.Item>
            </Col>

            <Col xl={12} xxl={12} lg={12} md={24} xs={24} sm={24}>
              <Form.Item name="cta_link_enable" className="interactionSwitch">
                <p className={styles.switchLabelText}>{t("Add CTA Link")}</p>
                <Switch
                  onChange={onChangeCTALink}
                  checked={CTASwitch}
                  className="interactionSwitch"
                />
              </Form.Item>
            </Col>
          </Row>

          {CTASwitch ? (
            <>
              <Form.List name="cta_links">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map((field, i) => (
                      <Card
                        size="small"
                        className={styles.ExtraCTACard}
                        title={`CTA ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            style={{
                              display: i == 0 ? "none" : "block",
                            }}
                            onClick={() => {
                              remove(field.name);
                              setAddedFields(addedFields - 1);
                              onChange(!CTASwitch);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label={t("Title")}
                          name={[field.name, "title"]}
                          className={styles.CTALinkFormItem}
                          rules={[
                            {
                              required: true,
                              message: t("Please enter a title"),
                            },
                            {
                              max: 25,
                              message: t("Length should be less than 25"),
                            },
                          ]}
                        >
                          <Input size="large" placeholder={t("Enter title")} />
                        </Form.Item>

                        <Form.Item
                          label={t("Link")}
                          name={[field.name, "links"]}
                          className={styles.CTALinkFormItem}
                          rules={[
                            {
                              required: true,
                              message: t("Please enter link"),
                            },
                            {
                              type: "url",
                              message: t("Please enter valid url."),
                            },
                          ]}
                        >
                          <Input size="large" placeholder={t("Enter Link")} />
                        </Form.Item>
                      </Card>
                    ))}

                    {addedFields < 3 && (
                      <Button
                        type="dashed"
                        className={styles.ExtraCTACard}
                        onClick={() => {
                          add();
                          setAddedFields(addedFields + 1);
                        }}
                        block
                      >
                        + {t("Add Item")}
                      </Button>
                    )}
                  </div>
                )}
              </Form.List>
            </>
          ) : (
            ""
          )}

          <div className={styles.createInteractionBtnContainer}>
            <Button
              className={styles.cancelBtn}
              onClick={() => navigate("/interaction")}
            >
              <div className={styles.buttonContent}>{t("Cancel")}</div>
            </Button>
            <Button
              type="submit"
              className={styles.createBtn}
              onClick={() => {
                form.submit();
                handleSubmit();
                setSubmitClick(true);
              }}
            >
              <div className={styles.buttonContent}>{t("Create")}</div>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddInteraction;
