import React from "react";
import styles from "./layout.module.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className={styles.footerContainer}>
      <p>@{new Date()?.getFullYear()} ATZ.ai {t("All Rights Reserved")}.</p>
    </div>
  );
}

export default Footer;
