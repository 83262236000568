import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// all booking List API
export const getBookingList = (params) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.Booking.bookingList}?page=${params?.page}&limit=${params?.limit}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// all booking List API
export const getBookingDetail = (id) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.Booking.bookingDetail}${id}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// create booking API CALL
export const createBookingApi = (payload, uploadPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.Booking.createBooking,
        payload,
        {
          "Content-Type": "multipart/form-data",
        },
        uploadPercentage
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// all category/ subcategory List API
export const getCategoryList = (param) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(`${API.Booking.categoryList}`);
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// ?edit booking Detail
export const editBookingDetailApi = (payload, ID, uploadPercentage) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.Booking.editBooking}${ID}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }, uploadPercentage
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// . Get By Details and filter Api  ---->  booking/get_by_date?booking_id=6576b708d178f514b45c7341&date=2023-12-07&category=crick&sub_category=bow
export const getByDetailAndFilter = (params) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.Booking.getByDetailFilter}?booking_id=${params?.booking_id}&date=${params?.date}&category=${params?.category}&sub_category=${params?.sub_category}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// add slot Add Slot
export const createSlotApi = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `${API.Booking.addSlot}`,
        payload
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// update time slot (edit/delete) Slot
export const updateTimeSlotApi = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `${API.Booking.editDeleteSlot}`,
        payload
      );
      if (!response.data.error) {
        message.success(response?.data?.message);
        return response?.data;
      } else {
        message.error(response?.data?.message);
        return { status: false };
      }
    } catch (err) {
      return { status: false };
    }
  };
};


// delete interaction API CALL
export const deleteBooking = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(API.Booking.deleteBooking + id);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// cancel user's booking from admin side api
export const CancelMyBookingAdminSideApi = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.Booking.cancelBookingAdminSide + id, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};


// refund-purchase-amount api
export const RefundPurchaseAmount = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.Booking.refundPurchase, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};


// End package admin side
export const EndPurchasePackage = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.Booking.endPackage, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// cancel user's booking from user side api
export const CancelBookingUserSideApi = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.Booking.cancelBookingUserSide + id, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};