import React, { useState, useEffect, useRef } from "react";
import style from "./Booking.module.css";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    Row,
    QRCode,
    Divider,
    Select,
    Modal,
    Form,
    Input,
    Switch,
    Radio,
    Skeleton,
    Tooltip,
    message,
    DatePicker,
    TimePicker,
    InputNumber,
    Checkbox,
    Table,
    Pagination,
    Spin,
    Empty,
    Space,
    Popover,
    Progress,
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import { countryList } from "../../../redux/authentication/action";
import { getItem, setItem } from "../../../utility/LocalStorageControl";
import { getCheckShortUrl } from "../../../redux/Interaction/action";
import { createQrPaymentApi, getQrPaymentsList, getQrPaymentsDetail, resetQrPayment, editQrDetailApi } from "../../../redux/qrPayment/action";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "../../../config/dataService/DataService";
import { QrcodeOutlined, EyeOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { createBookingApi, editBookingDetailApi, getBookingList, getCategoryList, deleteBooking } from "../../../redux/booking/action";
// import moment from "moment";
import mapboxgl from "mapbox-gl";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { getUserDetailData } from "../../../redux/profileSetting/action";
import moment from "moment-timezone";
import { toDate } from "date-fns";
import Cookies from "js-cookie";
import { WEBSITE_URL } from "../../../config/Constant";

mapboxgl.accessToken = "pk.eyJ1Ijoic2VsbG51IiwiYSI6ImNrNTU5MmVsdDBycDEza3Q3ZGprcXQwMTMifQ.0WOgZMi7JdoS4tXkXIW_dg";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const BookingList = () => {
    var today = new Date();
    dayjs.extend(utc);
    dayjs.extend(tz);
    var timeZone = dayjs.tz.guess();
    var dateBeforeMonth = moment().subtract(1, "months").format("YYYY-MM-DD");
    var weekDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    var hourDuration = [
        "0.5",
        "1",
        "1.5",
        "2",
        "2.5",
        "3",
        "3.5",
        "4",
        "4.5",
        "5",
        " 5.5",
        "6",
        "6.5",
        "7",
        "7.5",
        "8",
        "8.5",
        "9",
        "9.5",
        "10",
        "10.5",
        "11",
        "11.5",
        "12",
    ];
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bookingList, setBookingList] = useState([]);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [splitEnable, setSplitEnable] = useState(false);
    const [countryListLoading, setCountryListLoading] = useState(true);
    const [countryDataList, setCountryDataList] = useState([]);
    const [countryNameList, setCountryNameList] = useState([]);
    const [countryValue, setCountryValue] = useState("");
    const [submitStatus, setSubmitStatus] = useState(null);
    const [shortUrl, setShortUrl] = useState("");
    const [validUrlStatus, setValidUrlNameStatus] = useState(true);
    const [urlLoading, setUrlLoading] = useState(false);
    const [isExclusive, setIsExclusive] = useState(false);
    const [editBookingObj, setEditBookingObj] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editForm] = Form.useForm();
    const [userDetail, setUserDetail] = useState(getItem("userDetails"));

    const [bookingStatus, setBookingStatus] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [startDate, setStartDate] = useState(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
    const [selectedTime, setSelectedTime] = useState(moment());
    const [timing, setTiming] = useState("Hour");
    const [duration, setDuration] = useState(1);
    const [checkedList, setCheckedList] = useState(weekDays);
    const checkAll = weekDays.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < weekDays.length;
    const [isPackOneTime, setIsPackOneTime] = useState(false);
    const inputFile = useRef(null);
    const [file, setFile] = useState([]);
    const [filePreview, setFilePreview] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [paginationCount, setPaginationCount] = useState(0);
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const mapRef = React.useRef(null);
    const [lng, setLng] = useState(getItem("userGeoLocation")?.longitude);
    const [lat, setLat] = useState(getItem("userGeoLocation")?.latitude);
    const [map, setMap] = useState(null);
    const [address, setAddress] = useState("");
    const [mapObj, setMapObj] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [subCategoryName, setSubCategoryName] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteForm] = Form.useForm();
    const [bookingId, setBookingId] = useState("");
    const [isPaid, setIsPaid] = useState(false);
    const [connectStripeBtnClick, setConnectStripeBtnClick] = useState(false);
    const [isStripeConnect, setIsStripeConnect] = useState(getItem("userDetails")?.flags?.is_stripe_connected);
    const [stripeConnectModal, setStripeConnectModal] = useState(false);
    const [progressCount, setProgressCount] = useState(0);
    const [openCategoryList, setOpenCategoryList] = useState(false);
    const [inputCategoryClicked, setInputCategoryClicked] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);

    const [openSubCatList, setOpenSubCatList] = useState(false);
    const [inputSubCatClicked, setInputSubCatClicked] = useState(false);
    const selectRef = useRef();
    const selectSubRef = useRef();
    const [categoryValidErr, setCategoryValidErr] = useState(false);
    const [subCategoryValidErr, setSubCategoryValidErr] = useState(false);
    const [modalLOading, setModalLOading] = useState(false)

    // GET COUNTRY LIST
    useEffect(() => {
        getCountryList();
        allCategoryList();
    }, []);

    useEffect(() => {
        allBookingList(currentPage, dataPerPage);
    }, [currentPage, dataPerPage]);

    // remove sub category if category change
    useEffect(() => {
        if (categoryLoading == true && (categoryId !== "" || categoryName !== "")) {
            setCategoryLoading(false);
            setSubCategoryId("");
            setSubCategoryName("");
        }
    }, [categoryName]);

    const uploadPercentage = (val) => {
        setProgressCount(val);
    };

    const allBookingList = async (page, limit) => {
        setLoading(true);
        const param = {
            page: page,
            limit: limit,
        };
        const dataList = await dispatch(getBookingList(param));
        if (dataList) {
            setBookingList(dataList?.data);
            setPaginationCount(dataList?.pagination?.total_counts);
            setLoading(false);
        }
    };

    const getUserDetail = async () => {
        let val = getItem("userDetails")?.username
        let username = val && val ? val : Cookies.get("username")

        let response = await dispatch(getUserDetailData(username));
        if (response) {
            setUserDetail(response?.data);
            setIsStripeConnect(response?.data?.flags?.is_stripe_connected);
            setItem("userDetails", response?.data);
            setConnectStripeBtnClick(false);
            window.location.reload();
        } else {
            setConnectStripeBtnClick(false);
            window.location.reload();
        }
    };

    // category / subcategory list
    const allCategoryList = async () => {
        const dataList = await dispatch(getCategoryList());
        if (dataList) {
            setCategoryList(dataList?.data);
        }
    };
    const subCategoryObj = categoryId !== "" ? categoryList && categoryList.find((e) => e._id === categoryId) : {};

    // manage slot date
    const onchangeDate = (value, dateString) => {
        if (value !== null && dateString) {
            setStartDate(dateString[0]);
            setEndDate(dateString[1]);
        }
    };

    const getCountryList = async () => {
        setCountryListLoading(true);
        let response = await dispatch(countryList());
        if (response.status) {
            if (response.data.length > 0) {
                setCountryListLoading(false);
                setCountryDataList(response.data);
                if (response.data.find((item) => item.country.name === getItem("country_details")?.country_name)) {
                    setCountryValue(response.data.find((item) => item.country.name === getItem("country_details")?.country_name)?.country?.name);
                } else {
                    setCountryValue("");
                }
                setCountryNameList(
                    response.data.map(function (item) {
                        return {
                            label: item?.country?.name,
                            value: item?.country?.name,
                        };
                    })
                );
            }
        } else {
            setCountryListLoading(true);
        }
    };

    const showModal = () => {
        setModalLOading(true)
        form.resetFields();
        form.resetFields(["short_url"]);
        setShortUrl("");
        setOpen(true);
        setTimeout(() => {
            setModalLOading(false)
        }, 500);
    };

    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files[0]["type"]?.split("/")[0] == "image") {
            if (
                event.target.files[0]["type"]?.split("/")[1] == "png" ||
                event.target.files[0]["type"]?.split("/")[1] == "jpeg" ||
                event.target.files[0]["type"]?.split("/")[1] == "jpg"
            ) {
                setFile(event.target.files[0]);
                setFilePreview(
                    <div className={style.previewImgContainer}>
                        <img crossOrigin="anonymous" alt="" src={URL.createObjectURL(event.target.files[0])} />
                    </div>
                );
            } else {
                message.error(t("please select jpg, jpeg or png image"));
            }
        } else {
            message.error(t("please select image"));
        }
    };

    const handleSubmit = async (val) => {
        if (categoryName !== "" && subCategoryName !== "") {
            const form_data = new FormData();

            if (file.length !== 0 && file !== "" && file !== undefined) {
                form_data.append("poster_image", file);
            } else {
                form_data.append("poster_image", "");
            }
            form_data.append("title", val?.title);
            form_data.append("name", val?.title);
            form_data.append("longitude", lng);
            form_data.append("latitude", lat);
            form_data.append("address", address);
            form_data.append("type", "booking");
            form_data.append("pkg_desc", val?.pkg_desc);
            form_data.append("price", val?.price ? +convertComaInToDot(val?.price) : 0);
            form_data.append("short_url", val.short_url);
            form_data.append("splitEnable", splitEnable);
            form_data.append("isExclusive", isExclusive);
            form_data.append("vat", val.vat ? +convertComaInToDot(val.vat) : 0);
            form_data.append("total_user_allow_in_pack", +val?.total_user_allow_in_pack);
            form_data.append("duration", val?.duration ? val?.duration : "1");
            form_data.append("is_activities_pack_onetime", isPackOneTime);
            form_data.append("category_id", categoryId);
            form_data.append("category_name", categoryName);
            form_data.append("sub_category_id", subCategoryId);
            form_data.append("sub_category_name", subCategoryName);
            form_data.append("start_date", startDate);
            form_data.append("end_date", endDate);
            form_data.append("check_in_time", selectedTime[0]);
            form_data.append("check_out_time", selectedTime[1]);
            form_data.append("timing", isPackOneTime == false ? timing : "");
            form_data.append("week_days", isPackOneTime == false && timing == "Hour" && checkedList.length > 0 ? JSON.stringify(checkedList) : []);
            form_data.append("is_online", bookingStatus == true ? true : false);
            form_data.append("meeting_link", val?.meeting_link ? val?.meeting_link : "");
            form_data.append("is_paid", isPaid);
            setProgressCount(0);

            // for (const pair of form_data.entries()) {
            //   console.log("🚀 ~ file: Interaction.js:304 ~ handleSubmit ~ pair:", pair);
            // }
            if (shortUrl !== "" && shortUrl !== null && shortUrl !== undefined) {
                const createResponse = await dispatch(createBookingApi(form_data, uploadPercentage));
                if (createResponse?.data) {
                    handleCancel();
                    allBookingList(currentPage, dataPerPage);
                    setProgressCount(0);
                } else {
                    handleCancel();
                    setProgressCount(0);
                }
            } else {
                if (shortUrl && !validUrlStatus) {
                    message.warning("Enter Valid short URL");
                }
                message.warning("Enter short URL");
            }
        } else {
            if (categoryName == "" && subCategoryName == "") {
                setCategoryValidErr(true);
                setSubCategoryValidErr(true);
            } else if (categoryName == "") {
                setCategoryValidErr(true);
            } else if (subCategoryName == "") {
                setSubCategoryValidErr(true);
            } else {
                setCategoryValidErr(false);
                setSubCategoryValidErr(false);
            }
        }
    };

    const handleCancel = () => {
        form.resetFields();
        form.resetFields(["short_url"]);
        setOpen(false);
        setShortUrl("");
        setIsExclusive(false);
        setCategoryId("");
        setSubCategoryId("");
        setStartDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setEndDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setTiming("Hour");
        setIsPackOneTime(false);
        setCheckedList(weekDays);
        setFile([]);
        setBookingStatus(true);
        setFilePreview([]);
        setValidUrlNameStatus(false);
        setMapObj();
        setLat(getItem("userGeoLocation")?.latitude);
        setLng(getItem("userGeoLocation")?.longitude);
        setAddress("");
        setCategoryName("");
        setSubCategoryName("");
        setIsPaid(false);
        setProgressCount(0);
        setOpenCategoryList(false);
        setInputCategoryClicked(false);
        setCategoryLoading(false);
        setOpenSubCatList(false);
        setCategoryValidErr(false);
        setSubCategoryValidErr(false);
        selectSubRef.current = null;
    };

    // URL VALIDATION
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onValidUrlCheck(shortUrl);
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [shortUrl]);

    const onchangeShortUrl = (val) => {
        setUrlLoading(true);
        setShortUrl(val.replace(/\s/g, "").toLowerCase());
        setSubmitStatus(val == "" ? true : false);
    };
    const onValidUrlCheck = async (value) => {
        if (value == "") {
            setUrlLoading(false);
        } else {
            const response = await dispatch(getCheckShortUrl(value));
            if (response) {
                setUrlLoading(false);
                setValidUrlNameStatus(true);
            } else {
                setUrlLoading(false);
                setValidUrlNameStatus(false);
            }
        }
    };

    // check first digit of entered amount
    function firstDigit(num) {
        const matches = String(num).match(/\d/);
        const digit = Number(matches[0]);
        return num < 0 ? -digit : digit;
    }

    // edit details
    const showEditModal = (data) => {
        setModalLOading(true)
        setEditModalOpen(true);
        setEditBookingObj(data);
        setIsPackOneTime(data?.is_activities_pack_onetime == true ? true : false);
        setIsExclusive(data?.isExclusive == true ? true : false);
        setTiming(data?.timing);
        setCheckedList(data?.week_days);
        setSubCategoryId(data?.sub_category_id);
        setAddress(data?.address);
        setStartDate(moment(data?.start_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setEndDate(moment(data?.end_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setSelectedTime([data?.check_in_time, data?.check_out_time]);
        setLng(data?.location?.coordinates[0]);
        setLat(data?.location?.coordinates[1]);
        setCategoryId(data?.category_id);
        setCategoryName(data?.category_name);
        setSubCategoryName(data?.sub_category_name);
        setIsPaid(data?.is_paid == true ? true : false);
        if (data?.poster_image !== "") {
            setFilePreview(
                <div className={style.previewImgContainer}>
                    <img alt="" src={data?.poster_image} />
                </div>
            );
        }
        editForm.setFieldsValue({
            title: data?.title,
            pkg_desc: data?.pkg_desc,
            price: data?.price,
            vat: data?.vat,
            isExclusive: setIsExclusive(data?.isExclusive == true ? true : false),
            splitEnable: setSplitEnable(data?.splitEnable == true ? true : false),
            short_url: data?.url_string,
            address: data?.address,
            total_user_allow_in_pack: data?.total_user_allow_in_pack,
            duration: data?.duration,
            is_activities_pack_onetime: data?.is_activities_pack_onetime,
            category_id: data?.category_id,
            category_name: categoryName,
            sub_category_id: setSubCategoryId(data?.sub_category_id),
            sub_category_name: subCategoryName,
            timing: setTiming(data?.timing),
            week_days: setCheckedList(data?.week_days),
            is_online: data?.is_online == true ? setBookingStatus(true) : setBookingStatus(false),
            meeting_link: data?.meeting_link ? data?.meeting_link : "",
            location: data?.address !== "" ? data?.address : "",
            start_date: dayjs(data?.start_date),
            end_date: dayjs(data?.end_date),
            booking_date: [dayjs.utc(data?.start_date).tz(timeZone), dayjs.utc(data?.end_date).tz(timeZone)],
            slot_time: [dayjs(data?.check_in_time, "HH:mm"), dayjs(data?.check_out_time, "HH:mm")],
        });
        setTimeout(() => {
            setModalLOading(false)
        }, 500);
    };

    const handleEditCancel = (e) => {
        setSplitEnable(false);
        editForm.resetFields();
        setEditModalOpen(false);
        setShortUrl("");
        setIsExclusive(false);
        setCategoryId("");
        setSubCategoryId("");
        setStartDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setEndDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
        setTiming("Hour");
        setIsPackOneTime(false);
        setCheckedList(weekDays);
        setFile([]);
        setBookingStatus(true);
        setFilePreview([]);
        setValidUrlNameStatus(false);
        setMapObj();
        setLat(getItem("userGeoLocation")?.latitude);
        setLng(getItem("userGeoLocation")?.longitude);
        setAddress("");
        setCategoryName("");
        setSubCategoryName("");
        setIsPaid(false);
        setEditBookingObj({});
        setProgressCount(0);
        setOpenCategoryList(false);
        setInputCategoryClicked(false);
        setCategoryLoading(false);
        setOpenSubCatList(false);
        setCategoryValidErr(false);
        setSubCategoryValidErr(false);
        selectSubRef.current = null;
    };

    const handleEditSubmit = async (val) => {
        if (categoryName !== "" && subCategoryName !== "") {
            const form_data = new FormData();

            if (file.length !== 0) {
                form_data.append("poster_image", file);
            }
            form_data.append("title", val?.title);
            form_data.append("name", val?.title);
            form_data.append("longitude", lng);
            form_data.append("latitude", lat);
            form_data.append("address", address);
            form_data.append("type", "booking");
            form_data.append("pkg_desc", val?.pkg_desc);
            form_data.append("price", val?.price ? +convertComaInToDot(val?.price) : 0);
            form_data.append("short_url", val.short_url);
            form_data.append("splitEnable", splitEnable);
            form_data.append("isExclusive", isExclusive);
            form_data.append("vat", val?.vat ? +convertComaInToDot(val.vat) : 0);
            form_data.append("total_user_allow_in_pack", +val?.total_user_allow_in_pack);
            form_data.append("duration", val?.duration ? val?.duration : "1");
            form_data.append("is_activities_pack_onetime", isPackOneTime);
            form_data.append("category_id", categoryId);
            form_data.append("category_name", categoryName);
            form_data.append("sub_category_id", subCategoryId);
            form_data.append("sub_category_name", subCategoryName);
            form_data.append("start_date", startDate);
            form_data.append("end_date", endDate);
            form_data.append("check_in_time", selectedTime[0]);
            form_data.append("check_out_time", selectedTime[1]);
            form_data.append("timing", isPackOneTime == false ? timing : "");
            form_data.append("week_days", isPackOneTime == false && timing == "Hour" && checkedList.length > 0 ? JSON.stringify(checkedList) : []);
            form_data.append("is_online", bookingStatus == true ? true : false);
            form_data.append("meeting_link", val?.meeting_link ? val?.meeting_link : "");
            form_data.append("is_paid", isPaid);
            setProgressCount(0);
            const confirmEdit = await dispatch(editBookingDetailApi(form_data, editBookingObj?._id, uploadPercentage));
            if (confirmEdit) {
                allBookingList(currentPage, dataPerPage);
                handleEditCancel();
            } else {
                handleEditCancel();
            }
        } else {
            if (categoryName == "" && subCategoryName == "") {
                setCategoryValidErr(true);
                setSubCategoryValidErr(true);
            } else if (categoryName == "") {
                setCategoryValidErr(true);
            } else if (subCategoryName == "") {
                setSubCategoryValidErr(true);
            } else {
                setCategoryValidErr(false);
                setSubCategoryValidErr(false);
            }
        }
    };

    useEffect(() => {
        const initializeMap = ({ setMap, mapRef }) => {
            const map = new mapboxgl.Map({
                container: mapRef.current,
                style: "mapbox://styles/mapbox/streets-v11",
                zoom: 13,
                center: [lng, lat],
            });

            map.on("load", () => {
                setMap(map);
                map.resize();
                map.addControl(new mapboxgl.FullscreenControl());

                const geocoder = new MapboxGeocoder({
                    accessToken: "pk.eyJ1Ijoic2VsbG51IiwiYSI6ImNrNTU5MmVsdDBycDEza3Q3ZGprcXQwMTMifQ.0WOgZMi7JdoS4tXkXIW_dg",
                    mapboxgl: mapboxgl,
                });
                map.addControl(geocoder);
                geocoder.on("result", (event) => {
                    setMapObj(event.result);
                    const latitude = event.result.center[1];
                    const longitude = event.result.center[0];
                    map.setCenter([longitude, latitude]);
                });
            });
        };

        if (openLocationModal == true) {
            setTimeout(() => {
                if (!map) initializeMap({ setMap, mapRef });
            }, 500);
        }
    }, [openLocationModal, map]);

    // choose location map
    const showMapBox = async () => {
        setOpenLocationModal(true);
    };

    const handleDeleteSubmit = async () => {
        const confirmDelete = await dispatch(deleteBooking(bookingId));
        if (confirmDelete) {
            setOpenDeleteModal(false);
            setBookingId("");
            allBookingList(currentPage, dataPerPage);
            deleteForm.resetFields();
        }
    };

    // formate currency number according user local
    function formatNumberToLocalCurrency(amount, currency) {
        const browserLang = window.navigator.language || window.navigator.userLanguage;
        const formatter = new Intl.NumberFormat(browserLang, {
            style: "currency",
            currency: currency,
        });
        return formatter.format(amount);
    }

    const convertComaInToDot = (num) => {
        if (num !== 0 && num !== undefined && num !== null) {
            const numberWithDots = num.toString()?.replace(/,/g, ".");
            // Convert the result back to a number
            const resultNumber = parseFloat(numberWithDots)?.toFixed(2);
            return resultNumber;
        }
    };

    const handlePagination = (page, pageNumber) => {
        setCurrentPage(page);
        setDataPerPage(pageNumber);
        allBookingList(page, pageNumber);
    };

    return (
        <>
            <div className={style.interactionContainer}>
                <div className={style.interactionHeader}>
                    <h1>{t("Booking List")}</h1>
                    <div className={style.premiumBtnContainer}>
                        <Button className={style.premiumBtn} onClick={showModal}>
                            <div className={style.buttonContent}>{t("Create Booking")}</div>
                        </Button>
                    </div>
                </div>

                {loading ? (
                    <Row justify="center">
                        <Col span={24}>
                            <div className={style.bookingListTableContainer}>
                                <table className={style.bookingEmptyListTable}>
                                    <thead>
                                        <tr>
                                            <th scope="col">{t("Title")}</th>
                                            <th scope="col">{t("Total Asset Allowed")}</th>
                                            <th scope="col">{t("Timing")}</th>
                                            <th scope="col">{t("Start date")}</th>
                                            <th scope="col">{t("End Date")}</th>
                                            <th scope="col">
                                                {t("Check In Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                            </th>
                                            <th scope="col">
                                                {t("Check Out Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                            </th>
                                            <th scope="col">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={12}>
                                                <div className={style.tableDataLoading}>
                                                    <Spin
                                                        style={{
                                                            marginTop: "20px",
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                ) : bookingList && bookingList.length > 0 ? (
                    <>
                        <Row justify="center">
                            <Col span={24}>
                                <div className={style.bookingListTableContainer}>
                                    <table className={style.bookingListTable}>
                                        <thead>
                                            <tr>
                                                <th scope="col">{t("Title")}</th>
                                                <th scope="col">{t("Total Asset Allowed")}</th>
                                                <th scope="col">{t("Timing")}</th>
                                                <th scope="col">{t("Start date")}</th>
                                                <th scope="col">{t("End Date")}</th>
                                                <th scope="col">
                                                    {t("Check In Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                                </th>
                                                <th scope="col">
                                                    {t("Check Out Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                                </th>
                                                <th scope="col">{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map((value, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                {
                                                                    <Tooltip placement="top" title={value?.title}>
                                                                        {value?.title.length > 25 ? value?.title.slice(0, 25) + "..." : value?.title}
                                                                    </Tooltip>
                                                                }
                                                            </td>
                                                            <td>{value.total_user_allow_in_pack}</td>
                                                            <td>
                                                                {value && value?.timing == "Hour"
                                                                    ? t("Hour")
                                                                    : value && value?.timing == "Day"
                                                                        ? t("Day")
                                                                        : value && value?.timing == "Week"
                                                                            ? t("Week")
                                                                            : value && value?.timing == "Month"
                                                                                ? t("Month")
                                                                                : value && value?.timing == "Year"
                                                                                    ? t("Year")
                                                                                    : "-"}
                                                            </td>
                                                            <td>{moment(value?.start_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("MMM DD, YYYY")}</td>
                                                            <td>{moment(value?.end_date, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("MMM DD, YYYY")}</td>
                                                            <td>{value?.check_in_time}</td>
                                                            <td>{value?.check_out_time}</td>
                                                            <td>
                                                                <Popover
                                                                    placement="left"
                                                                    content={
                                                                        <>
                                                                            <div className={style.bookingActionBox}>
                                                                                <Tooltip placement="top" title={t("View Booking Detail")}>
                                                                                    <div
                                                                                        className={style.actionIcons}
                                                                                        onClick={() => {
                                                                                            navigate(`/booking-detail/${value?._id}`);
                                                                                        }}
                                                                                    >
                                                                                        <FeatherIcon icon="eye" size={18} />
                                                                                    </div>
                                                                                </Tooltip>
                                                                                <Tooltip placement="top" title={t("View Booking Detail")}>
                                                                                    <div
                                                                                        className={style.actionIcons}
                                                                                        onClick={() => {
                                                                                            showEditModal(value);
                                                                                        }}
                                                                                    >
                                                                                        <FeatherIcon icon="edit" size={18} />
                                                                                    </div>
                                                                                </Tooltip>
                                                                                <Tooltip placement="top" title={t("Delete Booking")}>
                                                                                    <div
                                                                                        className={style.actionIcons}
                                                                                        onClick={() => {
                                                                                            if (value?.is_expired == true) {
                                                                                                setBookingId(value?._id);
                                                                                                setOpenDeleteModal(true);
                                                                                            } else {
                                                                                                message.warning(
                                                                                                    "You can not delete this booking until it get expire.",
                                                                                                    5
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <FeatherIcon icon="trash" size={18} />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                >
                                                                    <FeatherIcon icon="more-vertical" size={20} />
                                                                </Popover>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row justify="center">
                        <Col span={24}>
                            <div className={style.bookingListTableContainer}>
                                <table className={style.bookingEmptyListTable}>
                                    <thead>
                                        <tr>
                                            <th scope="col">{t("Title")}</th>
                                            <th scope="col">{t("Total Asset Allowed")}</th>
                                            <th scope="col">{t("Timing")}</th>
                                            <th scope="col">{t("Start date")}</th>
                                            <th scope="col">{t("End Date")}</th>
                                            <th scope="col">
                                                {t("Check In Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                            </th>
                                            <th scope="col">
                                                {t("Check Out Time")}({moment.tz(moment.tz.guess()).zoneAbbr()})
                                            </th>
                                            <th scope="col">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={12}>
                                                <Empty className={style.emptyTableContent} description={<span>No Booking Data Found</span>}></Empty>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                )}
                {bookingList && bookingList.length > 0 ? (
                    <Row justify="center">
                        <Col>
                            <div className={style.paginationBox}>
                                <Pagination
                                    className="interactionPagination"
                                    total={paginationCount}
                                    currentPage={currentPage}
                                    current={currentPage}
                                    onChange={handlePagination}
                                    pageSizeOptions={[10, 20, 50, 100]}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                    pageSize={dataPerPage}
                                    showSizeChanger
                                />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}

                <Modal open={open} title={t("Create Booking")} footer={null} onCancel={handleCancel} maskClosable={false} width={600}>
                    {
                        modalLOading ?
                            <div className={style.loaderBox}>
                                <Spin />
                            </div>
                            :
                            (
                                <Form
                                    layout="vertical"
                                    name="basic"
                                    autoComplete="nope"
                                    onFinish={(value) => handleSubmit(value)}
                                    className={style.interactionForm}
                                    form={form}
                                    initialValues={{
                                        location: address,
                                    }}
                                >
                                    <Form.Item name="poster_image" label={t("Banner")}>
                                        {filePreview.length !== 0 ? (
                                            <>
                                                <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={onChangeFile.bind(this)} />
                                                <div className={style.editContentLabel}>
                                                    <span>{t("Edit Image")}</span>
                                                    <FeatherIcon
                                                        icon="edit"
                                                        size={18}
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {filePreview.length == 0 ? (
                                            <>
                                                <div className={style.uploadIconeDiv}>
                                                    <div
                                                        className={style.iconeDiv}
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                        }}
                                                    >
                                                        <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={onChangeFile.bind(this)} />
                                                        <FeatherIcon icon="upload" size={20} />
                                                    </div>
                                                    <p>Select your file</p>{" "}
                                                </div>
                                            </>
                                        ) : (
                                            filePreview
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Title")}
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter payment title!",
                                            },
                                        ]}
                                    >
                                        <Input size="large" placeholder="Enter title" />
                                    </Form.Item>
                                    <Form.Item label={t("Description")} name="pkg_desc">
                                        <TextArea placeholder="Enter package description" rows={3} />
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col xxl={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("Category")}
                                                name="Category"
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) => {
                                                            // if (categoryId == "" || categoryName == "" || value == "") {
                                                            //   return Promise.reject("Please select Category!");
                                                            // }
                                                            if (selectRef?.current?.input?.value !== null && selectRef?.current?.input?.value !== undefined) {
                                                                value = selectRef?.current?.input?.value;
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <div className={style.categoryCustomField}>
                                                    <Input
                                                        placeholder="Select Category"
                                                        id="category_name_text"
                                                        ref={selectRef}
                                                        value={categoryName}
                                                        onClick={() => {
                                                            setCategoryLoading(true);
                                                            form.resetFields(["subcategory"]);
                                                            setSubCategoryValidErr(false);
                                                            setSubCategoryId("");
                                                            selectRef.current = null;
                                                            setSubCategoryName("");
                                                            if (categoryId == "") {
                                                                setOpenCategoryList(true);
                                                                setInputCategoryClicked(true);
                                                            } else {
                                                                setCategoryId("");
                                                                setCategoryName("");
                                                                setInputCategoryClicked(true);
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (inputCategoryClicked && categoryId == "") {
                                                                setOpenCategoryList(false);
                                                                setCategoryId("");
                                                                setCategoryName(e.target.value);
                                                                selectRef.current = null;
                                                            }
                                                        }}
                                                    />
                                                    {openCategoryList == true && (
                                                        <ul className={style.category_dropdownList}>
                                                            {categoryList &&
                                                                categoryList.map((data, i) => {
                                                                    const obj = {
                                                                        label: data?.category_name,
                                                                        value: data?._id,
                                                                    };
                                                                    return (
                                                                        <li
                                                                            key={i}
                                                                            className={categoryId == data?._id ? `${style.activeCategory}` : ""}
                                                                            onClick={() => {
                                                                                setOpenCategoryList(false);
                                                                                const category_Obj = categoryList && categoryList.find((e) => e._id === obj.value);
                                                                                setCategoryName(category_Obj?.category_name);
                                                                                setCategoryValidErr(false);
                                                                                setCategoryId(category_Obj?._id);
                                                                                selectRef.current.value = obj.value;
                                                                                form.resetFields(["subcategory"]);
                                                                                setSubCategoryId("");
                                                                                setSubCategoryName("");
                                                                            }}
                                                                        >
                                                                            {obj.label}
                                                                        </li>
                                                                    );
                                                                })}
                                                        </ul>
                                                    )}
                                                </div>
                                                {/* {
                    categoryName == '' && categoryValidErr == true &&
                    <span style={{
                      margin: '0px', color: "#ff4d4f"
                    }}>
                      Please select category!
                    </span>} */}
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("SubCategory")}
                                                name="subcategory"
                                                rules={[
                                                    () => ({
                                                        required: true,
                                                        validator: (_, value) => {
                                                            if (subCategoryId !== "" || subCategoryName !== "" || value) {
                                                                return Promise.resolve();
                                                            }
                                                            if (selectSubRef?.current?.input?.value !== null && selectSubRef?.current?.input?.value !== undefined) {
                                                                value = selectSubRef?.current?.input?.value;
                                                                return Promise.resolve();
                                                            }

                                                            if (categoryName == "") {
                                                                return Promise.reject("First select category.");
                                                            }
                                                        },
                                                    }),
                                                ]}
                                            >
                                                {categoryLoading == true ? (
                                                    <Spin />
                                                ) : categoryId == "" ? (
                                                    <Input
                                                        placeholder="Select subCategory"
                                                        id="subCategory_name_text"
                                                        onChange={(e) => {
                                                            setSubCategoryId("");
                                                            setSubCategoryName(e.target.value);
                                                            selectSubRef.current = null;
                                                        }}
                                                    />
                                                ) : (
                                                    <div className={style.categoryCustomField}>
                                                        <Input
                                                            placeholder="Select subCategory"
                                                            id="subcategory_name_text"
                                                            value={subCategoryName}
                                                            onClick={() => {
                                                                if (subCategoryId == "") {
                                                                    setOpenSubCatList(true);
                                                                    setInputSubCatClicked(true);
                                                                    selectSubRef.current = null;
                                                                } else {
                                                                    setSubCategoryId("");
                                                                    setSubCategoryName("");
                                                                    setInputSubCatClicked(true);
                                                                    selectSubRef.current = null;
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (inputCategoryClicked && subCategoryId == "") {
                                                                    setOpenSubCatList(false);
                                                                    setSubCategoryId("");
                                                                    setSubCategoryName(e.target.value);
                                                                    selectSubRef.current = null;
                                                                }
                                                            }}
                                                        />
                                                        {openSubCatList == true && (
                                                            <ul className={style.category_dropdownList}>
                                                                {subCategoryObj?.sub_category &&
                                                                    subCategoryObj?.sub_category.map((data, i) => {
                                                                        const obj = {
                                                                            label: data?.sub_category_name,
                                                                            value: data?._id,
                                                                        };
                                                                        return (
                                                                            <li
                                                                                key={i}
                                                                                className={subCategoryId == data?._id ? `${style.activeCategory}` : ""}
                                                                                onClick={() => {
                                                                                    setSubCategoryId(obj.value);
                                                                                    setOpenSubCatList(false);
                                                                                    setSubCategoryName(obj?.label);
                                                                                }}
                                                                            >
                                                                                {obj.label}
                                                                            </li>
                                                                        );
                                                                    })}
                                                            </ul>
                                                        )}
                                                    </div>
                                                )}
                                                {subCategoryName == "" && subCategoryValidErr == true ? (
                                                    <span
                                                        style={{
                                                            margin: "0px",
                                                            color: "#ff4d4f",
                                                        }}
                                                    >
                                                        Please select sub category!
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item name="is_paid" label="Is Booking Paid/Free ?">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={isPaid}
                                            onChange={(e) => {
                                                if (isStripeConnect === false && e.target.value == true) {
                                                    setStripeConnectModal(true);
                                                    handleCancel();
                                                } else {
                                                    setIsPaid(e.target.value == true ? true : false);
                                                }
                                            }}
                                        >
                                            <Radio value={true}>Paid</Radio>
                                            <Radio value={false}>Free</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isPaid == true ? (
                                        <>
                                            <Row gutter={[16, 16]}>
                                                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label={t("Price") + " " + `(${userDetail?.countryObject?.currency})`}
                                                        name="price"
                                                        rules={[
                                                            {
                                                                required: isPaid == true ? true : false,
                                                                message: "Please enter price!",
                                                            },
                                                            () => ({
                                                                validator(_, value) {
                                                                    // reject if usr try to enter comma or dot which is not same as their country formate
                                                                    const sum =
                                                                        value !== "" &&
                                                                        value
                                                                            .toString()
                                                                            .split("")
                                                                            .map(Number)
                                                                            .reduce(function (a, b) {
                                                                                return a + b;
                                                                            }, 0);
                                                                    if (sum === 0 || value < 0) {
                                                                        return Promise.reject("Enter valid Amount, it can't be 0.");
                                                                    }
                                                                    if (convertComaInToDot(value) < 0.5) {
                                                                        return Promise.reject(
                                                                            `Amount must be at least ${formatNumberToLocalCurrency(
                                                                                0.5,
                                                                                userDetail?.countryObject?.currency
                                                                            )} .`
                                                                        );
                                                                    }
                                                                    // shows error if try to use dot or comma together
                                                                    if (/^-?\d+(\.\d+)?$/.test(value) == false && /^-?\d+(\,\d+)?$/.test(value) == false) {
                                                                        return Promise.reject("Please enter valid amount.");
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            pattern="[0-9]"
                                                            inputMode="decimal"
                                                            className={style.profileInputFiled}
                                                            placeholder={t("Add Price")}
                                                            onKeyDown={(evt) => {
                                                                var theEvent = evt || window.event;
                                                                var key = theEvent.keyCode || theEvent.which;
                                                                var keyCode = key;
                                                                key = String.fromCharCode(key);
                                                                if (key.length == 0) return;
                                                                var regex = /^[0-9.,\b]+$/;
                                                                if (keyCode == 188 || keyCode == 190) {
                                                                    return;
                                                                } else {
                                                                    if (!regex.test(key)) {
                                                                        theEvent.returnValue = false;
                                                                        if (theEvent.preventDefault) theEvent.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label={t("VAT(%)")}
                                                        name="vat"
                                                        rules={[
                                                            {
                                                                required: isPaid == true ? true : false,
                                                                message: "Please Enter vat",
                                                            },
                                                            () => ({
                                                                validator(_, value) {
                                                                    const sum =
                                                                        value !== "" &&
                                                                        value
                                                                            .toString()
                                                                            .split("")
                                                                            .map(Number)
                                                                            .reduce(function (a, b) {
                                                                                return a + b;
                                                                            }, 0);
                                                                    if (value > 100) {
                                                                        return Promise.reject("Vat can't be more than 100%");
                                                                    }
                                                                    // shows error if try to use dot or comma together
                                                                    if (/^-?\d+(\.\d+)?$/.test(value) == false && /^-?\d+(\,\d+)?$/.test(value) == false) {
                                                                        return Promise.reject("Please enter valid amount.");
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Add vat"
                                                            pattern="[0-9]"
                                                            inputMode="decimal"
                                                            className={style.profileInputFiled}
                                                            onKeyDown={(evt) => {
                                                                var theEvent = evt || window.event;
                                                                var key = theEvent.keyCode || theEvent.which;
                                                                var keyCode = key;
                                                                key = String.fromCharCode(key);
                                                                if (key.length == 0) return;

                                                                var regex = /^[0-9.,\b]+$/;
                                                                if (keyCode == 188 || keyCode == 190) {
                                                                    return;
                                                                } else {
                                                                    if (!regex.test(key)) {
                                                                        theEvent.returnValue = false;
                                                                        if (theEvent.preventDefault) theEvent.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Form.Item name="isExclusive" className="interactionSwitch">
                                                        <p className={style.switchLabelText}>{t("Is VAT Exclusive")} ?</p>
                                                        <Switch
                                                            checked={isExclusive}
                                                            onChange={(e) => {
                                                                setIsExclusive(e);
                                                            }}
                                                            className="interactionSwitch"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <Row gutter={[16, 16]}>
                                        <Col xxl={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("Start date")}
                                                name="start_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select booking start date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    className="historyCalender calendarDatePicker"
                                                    size="large"
                                                    format={(val) => val.format("YYYY-MM-DD")}
                                                    disabledDate={(d) => {
                                                        return moment().add(-1, "days") >= d;
                                                    }}
                                                    onChange={(value, dateString) => {
                                                        setStartDate(dateString);
                                                        form.resetFields(["end_date"]);
                                                        setEndDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("End Date")}
                                                name="end_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select booking end date!",
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            if (new Date(value) < new Date(startDate)) {
                                                                return Promise.reject("End date can't be less than start date");
                                                            }
                                                            if (new Date(value) == new Date(startDate)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    className="historyCalender calendarDatePicker"
                                                    size="large"
                                                    format={(val) => val.format("YYYY-MM-DD")}
                                                    disabledDate={(d) => moment(startDate).add(0, "days") >= d}
                                                    onChange={(value, dateString) => {
                                                        setEndDate(dateString);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        label={
                                            <>
                                                {t("Slot Time")}
                                                <Tooltip
                                                    title={`You are currently selecting time according ${moment.tz.guess()} (${moment
                                                        .tz(moment.tz.guess())
                                                        .zoneAbbr()}) time zone.`}
                                                >
                                                    <div className={style.timezoneInfoBox}> ({moment.tz(moment.tz.guess()).zoneAbbr()})</div>
                                                </Tooltip>
                                            </>
                                        }
                                        name="slot_time"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select booking slot time!",
                                            },
                                        ]}
                                    >
                                        <TimePicker.RangePicker
                                            style={{
                                                width: "100%",
                                            }}
                                            format={"HH:mm"}
                                            size="large"
                                            onChange={(time, timeString) => {
                                                if (time !== null && timeString) {
                                                    setSelectedTime(timeString);
                                                }
                                            }}
                                            className="historyCalender calendarTimePicker"
                                        />
                                    </Form.Item>
                                    <Form.Item label={t("Is Activities Pack Onetime")} name="is_activities_pack_onetime">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={isPackOneTime}
                                            onChange={(e) => {
                                                setIsPackOneTime(e.target.value == true ? true : false);
                                            }}
                                        >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isPackOneTime == false ? (
                                        <>
                                            <Row gutter={[16, 16]}>
                                                <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                                                    <Form.Item
                                                        label={t("Timing")}
                                                        name="Timing"
                                                        rules={[
                                                            {
                                                                required: timing == "" ? true : false,
                                                                message: "Please select time!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="profileCountrySelectBox"
                                                            showSearch
                                                            style={{ height: "50px", textAlign: "center" }}
                                                            placeholder="Select time"
                                                            onChange={(value) => {
                                                                setTiming(value);
                                                                setDuration(1);
                                                                form.resetFields(["duration"]);
                                                            }}
                                                            defaultValue={timing}
                                                            options={[
                                                                {
                                                                    value: "Hour",
                                                                    label: "Hour",
                                                                },
                                                                {
                                                                    value: "Day",
                                                                    label: "Day",
                                                                },
                                                                {
                                                                    value: "Week",
                                                                    label: "Week",
                                                                },
                                                                {
                                                                    value: "Month",
                                                                    label: "Month",
                                                                },
                                                                {
                                                                    value: "Year",
                                                                    label: "Year",
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                                                    <Form.Item
                                                        label={t("Duration")}
                                                        name="duration"
                                                        rules={[
                                                            {
                                                                required: timing == "" ? true : false,
                                                                message: "Please select duration!",
                                                            },
                                                        ]}
                                                    >
                                                        {timing !== "Hour" ? (
                                                            <InputNumber
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                defaultValue="1"
                                                                min="1"
                                                                // max={"24"}
                                                                max={
                                                                    timing == "Day"
                                                                        ? "365"
                                                                        : timing == "Week"
                                                                            ? "52"
                                                                            : timing == "Month"
                                                                                ? "12"
                                                                                : timing == "Year"
                                                                                    ? "10"
                                                                                    : "24"
                                                                }
                                                                onChange={(val) => {
                                                                    setDuration(val);
                                                                }}
                                                                value={duration}
                                                            />
                                                        ) : (
                                                            <Select
                                                                className="profileCountrySelectBox"
                                                                showSearch
                                                                style={{ height: "50px", textAlign: "center" }}
                                                                placeholder="Select time duration"
                                                                optionFilterProp="children"
                                                                required
                                                                onChange={(val) => {
                                                                    setDuration(val);
                                                                }}
                                                                defaultValue={duration}
                                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                                options={
                                                                    hourDuration &&
                                                                    hourDuration.map((data, index) => {
                                                                        const obj = {
                                                                            value: data,
                                                                            label: data,
                                                                        };
                                                                        return obj;
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            {timing === "Hour" ? (
                                                <Form.Item
                                                    label={t("Week Days")}
                                                    name="week_days"
                                                    rules={[
                                                        {
                                                            required: timing == "Hour" && checkedList.length == 0 ? true : false,
                                                            message: "Please select week days!",
                                                        },
                                                    ]}
                                                >
                                                    <div className={style.weekDaysContainer}>
                                                        <div className={style.selectAllBtn}>
                                                            <Checkbox
                                                                className="bookingCheckBox"
                                                                indeterminate={indeterminate}
                                                                onChange={(e) => {
                                                                    // setCheckedList(e.target.checked ? (weekDays.map(a => a.charAt(0).toUpperCase() + a.substr(1))) : []);
                                                                    setCheckedList(e.target.checked ? weekDays : []);
                                                                }}
                                                                checked={checkAll}
                                                            >
                                                                Select all
                                                            </Checkbox>
                                                        </div>
                                                        <CheckboxGroup
                                                            className="bookingCheckBox"
                                                            value={checkedList}
                                                            defaultValue={checkedList}
                                                            onChange={(list) => {
                                                                // setCheckedList(list.map(a => a.charAt(0).toLowerCase() + a.substr(1)));
                                                                setCheckedList(list);
                                                            }}
                                                            options={weekDays}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <Form.Item label={t("Status")} name="status">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={bookingStatus == true ? "online" : "offline"}
                                            onChange={(e) => {
                                                setBookingStatus(e.target.value == "online" ? true : false);
                                            }}
                                        >
                                            <Radio value="online">{t("Online")}</Radio>
                                            <Radio value="offline">{t("Offline")}</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {bookingStatus == true ? (
                                        <>
                                            <div>
                                                <Form.Item
                                                    label={t("Meeting Link")}
                                                    name="meeting_link"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please add meeting link!",
                                                        },
                                                        {
                                                            type: "url",
                                                            message: "Please enter valid url.",
                                                        },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder={t("Add meeting link")} />
                                                </Form.Item>
                                            </div>
                                        </>
                                    ) : bookingStatus == false ? (
                                        <>
                                            <Form.Item label={t("Location")} name="location">
                                                <Input size="large" placeholder="Enter location" onClick={showMapBox} defaultValue={address} />
                                            </Form.Item>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <Form.Item
                                        label={t("Total Users Allowed In Pack")}
                                        name="total_user_allow_in_pack"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    const sum =
                                                        value !== "" &&
                                                        value
                                                            ?.toString()
                                                            ?.split("")
                                                            .map(Number)
                                                            .reduce(function (a, b) {
                                                                return a + b;
                                                            }, 0);
                                                    if (sum === 0 || value < 0) {
                                                        return Promise.reject("Enter valid user number, it can't be 0.");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Add numbers of users"
                                            pattern="[0-9]"
                                            inputMode="numeric"
                                            min={1}
                                            className={style.profileInputFiled}
                                            onKeyDown={(evt) => {
                                                var theEvent = evt || window.event;
                                                var key = theEvent.keyCode || theEvent.which;
                                                var keyCode = key;
                                                key = String.fromCharCode(key);
                                                if (key.length == 0) return;
                                                var regex = /^[0-9\b]+$/;
                                                if (!regex.test(key)) {
                                                    theEvent.returnValue = false;
                                                    if (theEvent.preventDefault) theEvent.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="short_url"
                                        label={t("Short URL")}
                                        hasFeedback
                                        validateStatus={
                                            submitStatus
                                                ? shortUrl == "" && validUrlStatus
                                                    ? "error"
                                                    : ""
                                                : shortUrl == ""
                                                    ? ""
                                                    : urlLoading
                                                        ? "validating"
                                                        : validUrlStatus
                                                            ? "success"
                                                            : "error"
                                        }
                                        help={
                                            submitStatus
                                                ? shortUrl == "" && validUrlStatus
                                                    ? "Please enter url!"
                                                    : ""
                                                : shortUrl == ""
                                                    ? ""
                                                    : urlLoading
                                                        ? ""
                                                        : validUrlStatus
                                                            ? ""
                                                            : "This short Url is not available."
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter short url",
                                            },
                                            {
                                                pattern: new RegExp(/^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/),
                                                message: "URL can not contain whitespace or upper case letters",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={style.profileInputFiled}
                                            style={{ padding: "0px 8px" }}
                                            prefix={
                                                <>
                                                    <b className={style.backslash}>{`${WEBSITE_URL}`}</b>
                                                </>
                                            }
                                            defaultValue={shortUrl}
                                            onChange={(e) => {
                                                onchangeShortUrl(e.target.value);
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault()
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.code === "Space") event.preventDefault();

                                            }}
                                            onInput={(e) => (e.target.value = ("" + e.target.value).toLocaleLowerCase())}
                                            placeholder="Enter url..."
                                            disabled={false}
                                            autoFocus={true}
                                        />
                                    </Form.Item>
                                    <center>
                                        <i>
                                            Please fill all mandatory fields<span style={{ color: "red" }}>(*)</span> before submit.
                                        </i>
                                    </center>

                                    {progressCount > 0 && (
                                        <div className={style.progressCircle}>
                                            <Progress type="circle" percent={progressCount} strokeColor="#E60C8F" />
                                        </div>
                                    )}

                                    <div className={style.createBtnContainer}>
                                        <Button className={style.cancelBtn} onClick={handleCancel}>
                                            <div className={style.buttonContent}>{t("Cancel")}</div>
                                        </Button>
                                        <Button
                                            className={style.createBtn}
                                            onClick={(e) => {
                                                if (categoryName == "" && subCategoryName == "") {
                                                    setCategoryValidErr(true);
                                                    setSubCategoryValidErr(true);
                                                } else if (categoryName == "") {
                                                    setCategoryValidErr(true);
                                                } else if (subCategoryName == "") {
                                                    setSubCategoryValidErr(true);
                                                } else {
                                                    setCategoryValidErr(false);
                                                    setSubCategoryValidErr(false);
                                                }
                                                if (progressCount == 0) {
                                                    form.submit();
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                        >
                                            <div className={style.buttonContent}>{progressCount > 0 ? <Spin size="whiteSpin" /> : t("Submit")}</div>
                                        </Button>
                                    </div>
                                </Form>
                            )
                    }

                </Modal>

                <Modal width={600} title="Edit Booking Details" open={editModalOpen} onCancel={handleEditCancel} footer={null}>
                    {
                        modalLOading ?
                            <div className={style.loaderBox}>
                                <Spin />
                            </div>
                            :
                            (
                                <Form
                                    layout="vertical"
                                    name="basic"
                                    autoComplete="nope"
                                    onFinish={(value) => handleEditSubmit(value)}
                                    className={style.interactionForm}
                                    form={editForm}
                                    initialValues={{
                                        location: address,
                                    }}
                                >
                                    <Form.Item name="poster_image" label={t("Banner")}>
                                        {filePreview.length !== 0 ? (
                                            <>
                                                <div className={style.editContentLabel}>
                                                    <span>{t("Edit Image")}</span>
                                                    <FeatherIcon
                                                        icon="edit"
                                                        size={18}
                                                        onClick={() => {
                                                            // setFile([])
                                                            // setFilePreview([])
                                                            inputFile.current.click();
                                                        }}
                                                    />
                                                    <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={onChangeFile.bind(this)} />
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {filePreview.length == 0 ? (
                                            <>
                                                <div className={style.uploadIconeDiv}>
                                                    <div
                                                        className={style.iconeDiv}
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                        }}
                                                    >
                                                        <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={onChangeFile.bind(this)} />
                                                        <FeatherIcon icon="upload" size={20} />
                                                    </div>
                                                    <p>Select your file</p>{" "}
                                                </div>
                                            </>
                                        ) : (
                                            filePreview
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={t("Title")}
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter payment title!",
                                            },
                                        ]}
                                    >
                                        <Input size="large" placeholder="Enter title" />
                                    </Form.Item>
                                    <Form.Item label={t("Description")} name="pkg_desc">
                                        <TextArea placeholder="Enter package description" rows={3} />
                                    </Form.Item>

                                    <Row gutter={[16, 16]}>
                                        <Col xxl={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("Category")}
                                                name="Category"
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) => {
                                                            // if (categoryId == "" || categoryName == "" || value == "") {
                                                            //   return Promise.reject("Please select Category!");
                                                            // }
                                                            if (selectRef?.current?.input?.value !== null && selectRef?.current?.input?.value !== undefined) {
                                                                value = selectRef?.current?.input?.value;
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <div className={style.categoryCustomField}>
                                                    <Input
                                                        placeholder="Select Category"
                                                        id="category_name_text"
                                                        ref={selectRef}
                                                        value={categoryName}
                                                        onClick={() => {
                                                            setCategoryLoading(true);
                                                            form.resetFields(["subcategory"]);
                                                            setSubCategoryValidErr(false);
                                                            setSubCategoryId("");
                                                            selectRef.current = null;
                                                            setSubCategoryName("");
                                                            if (categoryId == "") {
                                                                setOpenCategoryList(true);
                                                                setInputCategoryClicked(true);
                                                            } else {
                                                                setCategoryId("");
                                                                setCategoryName("");
                                                                setInputCategoryClicked(true);
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (inputCategoryClicked && categoryId == "") {
                                                                setOpenCategoryList(false);
                                                                setCategoryId("");
                                                                setCategoryName(e.target.value);
                                                                selectRef.current = null;
                                                            }
                                                        }}
                                                    />

                                                    {openCategoryList == true && (
                                                        <ul className={style.category_dropdownList}>
                                                            {categoryList &&
                                                                categoryList.map((data, i) => {
                                                                    const obj = {
                                                                        label: data?.category_name,
                                                                        value: data?._id,
                                                                    };
                                                                    return (
                                                                        <li
                                                                            key={i}
                                                                            className={categoryId == data?._id ? `${style.activeCategory}` : ""}
                                                                            onClick={() => {
                                                                                setOpenCategoryList(false);
                                                                                const category_Obj = categoryList && categoryList.find((e) => e._id === obj.value);
                                                                                setCategoryName(category_Obj?.category_name);
                                                                                setCategoryValidErr(false);
                                                                                setCategoryId(category_Obj?._id);
                                                                                selectRef.current.value = obj.value;
                                                                                form.resetFields(["subcategory"]);
                                                                                setSubCategoryId("");
                                                                                setSubCategoryName("");
                                                                            }}
                                                                        >
                                                                            {obj.label}
                                                                        </li>
                                                                    );
                                                                })}
                                                        </ul>
                                                    )}
                                                </div>
                                                {categoryName == "" && categoryValidErr == true && (
                                                    <span
                                                        style={{
                                                            margin: "0px",
                                                            color: "#ff4d4f",
                                                        }}
                                                    >
                                                        Please select category!
                                                    </span>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("SubCategory")}
                                                name="subcategory"
                                                rules={[
                                                    () => ({
                                                        required: true,
                                                        validator: (_, value) => {
                                                            if (subCategoryId !== "" || subCategoryName !== "" || value) {
                                                                return Promise.resolve();
                                                            }
                                                            if (categoryName == "" && value) {
                                                                return Promise.reject("First select category.");
                                                            }
                                                            if (selectSubRef?.current?.input?.value !== null && selectSubRef?.current?.input?.value !== undefined) {
                                                                value = selectSubRef?.current?.input?.value;
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    }),
                                                ]}
                                            >
                                                {categoryLoading == true ? (
                                                    <Spin />
                                                ) : categoryId == "" ? (
                                                    <Input
                                                        placeholder="Select subCategory"
                                                        id="subCategory_name_text"
                                                        onChange={(e) => {
                                                            setSubCategoryId("");
                                                            setSubCategoryName(e.target.value);
                                                            selectSubRef.current = null;
                                                        }}
                                                    />
                                                ) : (
                                                    <div className={style.categoryCustomField}>
                                                        <Input
                                                            placeholder="Select subCategory"
                                                            id="subcategory_name_text"
                                                            value={subCategoryName}
                                                            defaultValue={subCategoryName}
                                                            // ref={selectSubRef}
                                                            onClick={() => {
                                                                if (subCategoryId == "") {
                                                                    setOpenSubCatList(true);
                                                                    setInputSubCatClicked(true);
                                                                    selectSubRef.current = null;
                                                                } else {
                                                                    setSubCategoryId("");
                                                                    setSubCategoryName("");
                                                                    setInputSubCatClicked(true);
                                                                    selectSubRef.current = null;
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (inputCategoryClicked && subCategoryId == "") {
                                                                    setOpenSubCatList(false);
                                                                    setSubCategoryId("");
                                                                    setSubCategoryName(e.target.value);
                                                                    selectSubRef.current = null;
                                                                }
                                                            }}
                                                        />
                                                        {openSubCatList == true && (
                                                            <ul className={style.category_dropdownList}>
                                                                {subCategoryObj?.sub_category &&
                                                                    subCategoryObj?.sub_category.map((data, i) => {
                                                                        const obj = {
                                                                            label: data?.sub_category_name,
                                                                            value: data?._id,
                                                                        };
                                                                        return (
                                                                            <li
                                                                                key={i}
                                                                                className={subCategoryId == data?._id ? `${style.activeCategory}` : ""}
                                                                                onClick={() => {
                                                                                    setSubCategoryId(obj.value);
                                                                                    setOpenSubCatList(false);
                                                                                    setSubCategoryName(obj?.label);
                                                                                    // selectSubRef.current.value = obj.value;
                                                                                }}
                                                                            >
                                                                                {obj.label}
                                                                            </li>
                                                                        );
                                                                    })}
                                                            </ul>
                                                        )}
                                                    </div>
                                                )}
                                                {subCategoryName == "" && subCategoryValidErr == true ? (
                                                    <span
                                                        style={{
                                                            margin: "0px",
                                                            color: "#ff4d4f",
                                                        }}
                                                    >
                                                        Please select sub category!
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item name="is_paid" label="Is Booking Paid/Free ?">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={isPaid}
                                            onChange={(e) => {
                                                if (isStripeConnect === false && e.target.value == true) {
                                                    setStripeConnectModal(true);
                                                    handleEditCancel();
                                                } else {
                                                    setIsPaid(e.target.value == true ? true : false);
                                                }
                                            }}
                                        >
                                            <Radio value={true}>Paid</Radio>
                                            <Radio value={false}>Free</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isPaid == true ? (
                                        <>
                                            <Row gutter={[16, 16]}>
                                                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label={t("Price") + " " + `(${userDetail?.countryObject?.currency})`}
                                                        name="price"
                                                        rules={[
                                                            {
                                                                required: isPaid == true ? true : false,
                                                                message: "Please enter price!",
                                                            },
                                                            () => ({
                                                                validator(_, value) {
                                                                    const sum =
                                                                        value !== "" &&
                                                                        value
                                                                            .toString()
                                                                            .split("")
                                                                            .map(Number)
                                                                            .reduce(function (a, b) {
                                                                                return a + b;
                                                                            }, 0);
                                                                    if (sum === 0 || value < 0) {
                                                                        return Promise.reject("Enter valid Amount, it can't be 0.");
                                                                    }
                                                                    if (convertComaInToDot(value) < 0.5) {
                                                                        return Promise.reject(
                                                                            `Amount must be at least ${formatNumberToLocalCurrency(
                                                                                0.5,
                                                                                userDetail?.countryObject?.currency
                                                                            )} .`
                                                                        );
                                                                    }
                                                                    // shows error if try to use dot or comma together
                                                                    if (/^-?\d+(\.\d+)?$/.test(value) == false && /^-?\d+(\,\d+)?$/.test(value) == false) {
                                                                        return Promise.reject("Please enter valid amount.");
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            pattern="[0-9]"
                                                            inputMode="decimal"
                                                            className={style.profileInputFiled}
                                                            placeholder={t("Add Price")}
                                                            onKeyDown={(evt) => {
                                                                var theEvent = evt || window.event;
                                                                var key = theEvent.keyCode || theEvent.which;
                                                                var keyCode = key;
                                                                key = String.fromCharCode(key);
                                                                if (key.length == 0) return;
                                                                var regex = /^[0-9.,\b]+$/;
                                                                if (keyCode == 188 || keyCode == 190) {
                                                                    return;
                                                                } else {
                                                                    if (!regex.test(key)) {
                                                                        theEvent.returnValue = false;
                                                                        if (theEvent.preventDefault) theEvent.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <Form.Item
                                                        label={t("VAT(%)")}
                                                        name="vat"
                                                        rules={[
                                                            {
                                                                required: isPaid == true ? true : false,
                                                                message: "Please Enter vat",
                                                            },
                                                            () => ({
                                                                validator(_, value) {
                                                                    const sum =
                                                                        value !== "" &&
                                                                        value
                                                                            .toString()
                                                                            .split("")
                                                                            .map(Number)
                                                                            .reduce(function (a, b) {
                                                                                return a + b;
                                                                            }, 0);
                                                                    if (value > 100) {
                                                                        return Promise.reject("Vat can't be more than 100%");
                                                                    }
                                                                    // shows error if try to use dot or comma together
                                                                    if (/^-?\d+(\.\d+)?$/.test(value) == false && /^-?\d+(\,\d+)?$/.test(value) == false) {
                                                                        return Promise.reject("Please enter valid amount.");
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Add vat"
                                                            pattern="[0-9]"
                                                            inputMode="decimal"
                                                            className={style.profileInputFiled}
                                                            onKeyDown={(evt) => {
                                                                var theEvent = evt || window.event;
                                                                var key = theEvent.keyCode || theEvent.which;
                                                                var keyCode = key;
                                                                key = String.fromCharCode(key);
                                                                if (key.length == 0) return;
                                                                var regex = /^[0-9.,\b]+$/;
                                                                if (keyCode == 188 || keyCode == 190) {
                                                                    return;
                                                                } else {
                                                                    if (!regex.test(key)) {
                                                                        theEvent.returnValue = false;
                                                                        if (theEvent.preventDefault) theEvent.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24}>
                                                    <Form.Item name="isExclusive" className="interactionSwitch">
                                                        <p className={style.switchLabelText}>{t("Is VAT Exclusive")} ?</p>
                                                        <Switch
                                                            checked={isExclusive}
                                                            onChange={(e) => {
                                                                setIsExclusive(e);
                                                            }}
                                                            className="interactionSwitch"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {/* <Form.Item label={t("Booking Date")} name="booking_date">
                            <RangePicker
                                className="historyCalender calendarDatePicker"
                                size="large"
                                onChange={onchangeDate}
                                disabledDate={(d) => {
                                    return moment().add(-1, "days") >= d;
                                }}
                            />
                        </Form.Item> */}

                                    <Row gutter={[16, 16]}>
                                        <Col xxl={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("Start date")}
                                                name="start_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select booking start date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    className="historyCalender calendarDatePicker"
                                                    size="large"
                                                    format={(val) => val.format("YYYY-MM-DD")}
                                                    disabledDate={(d) => {
                                                        return moment().add(-1, "days") >= d;
                                                    }}
                                                    onChange={(value, dateString) => {
                                                        setStartDate(dateString);
                                                        editForm.resetFields(["end_date"]);
                                                        setEndDate(moment(today, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t("End Date")}
                                                name="end_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select booking end date!",
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            if (new Date(value) < new Date(startDate)) {
                                                                return Promise.reject("End date can't be less than start date");
                                                            }
                                                            if (new Date(value) == new Date(startDate)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <DatePicker
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    className="historyCalender calendarDatePicker"
                                                    size="large"
                                                    format={(val) => val.format("YYYY-MM-DD")}
                                                    disabledDate={(d) => moment(startDate).add(0, "days") >= d}
                                                    onChange={(value, dateString) => {
                                                        setEndDate(dateString);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        label={
                                            <>
                                                {t("Slot Time")}
                                                <Tooltip
                                                    title={`You are currently selecting time according ${moment.tz.guess()} (${moment
                                                        .tz(moment.tz.guess())
                                                        .zoneAbbr()}) time zone.`}
                                                >
                                                    <div className={style.timezoneInfoBox}> ({moment.tz(moment.tz.guess()).zoneAbbr()})</div>
                                                </Tooltip>
                                            </>
                                        }
                                        name="slot_time"
                                    >
                                        <TimePicker.RangePicker
                                            style={{
                                                width: "100%",
                                            }}
                                            format={"HH:mm"}
                                            size="large"
                                            onChange={(time, timeString) => {
                                                if (time !== null && timeString) {
                                                    setSelectedTime(timeString);
                                                }
                                            }}
                                            className="historyCalender calendarTimePicker"
                                        />
                                    </Form.Item>
                                    <Form.Item label={t("Is Activities Pack Onetime")} name="is_activities_pack_onetime">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={isPackOneTime}
                                            onChange={(e) => {
                                                setIsPackOneTime(e.target.value == true ? true : false);
                                            }}
                                        >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isPackOneTime == false ? (
                                        <>
                                            <Row gutter={[16, 16]}>
                                                <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                                                    <Form.Item
                                                        label={t("Timing")}
                                                        name="Timing"
                                                        rules={[
                                                            {
                                                                required: timing == "" ? true : false,
                                                                message: "Please select time!",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="profileCountrySelectBox"
                                                            showSearch
                                                            style={{ height: "50px", textAlign: "center" }}
                                                            placeholder="Select time"
                                                            defaultValue={timing}
                                                            onChange={(value) => {
                                                                setTiming(value);
                                                                setDuration(1);
                                                                editForm.resetFields(["duration"]);
                                                            }}
                                                            options={[
                                                                {
                                                                    value: "Hour",
                                                                    label: "Hour",
                                                                },
                                                                {
                                                                    value: "Day",
                                                                    label: "Day",
                                                                },
                                                                {
                                                                    value: "Week",
                                                                    label: "Week",
                                                                },
                                                                {
                                                                    value: "Month",
                                                                    label: "Month",
                                                                },
                                                                {
                                                                    value: "Year",
                                                                    label: "Year",
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                                                    <Form.Item
                                                        label={t("Duration")}
                                                        name="duration"
                                                        rules={[
                                                            {
                                                                required: duration == "" ? true : false,
                                                                message: "Please select duration!",
                                                            },
                                                        ]}
                                                    >
                                                        {timing !== "Hour" ? (
                                                            <InputNumber
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                defaultValue="1"
                                                                min="1"
                                                                // max={"24"}
                                                                max={
                                                                    timing == "Day"
                                                                        ? "365"
                                                                        : timing == "Week"
                                                                            ? "52"
                                                                            : timing == "Month"
                                                                                ? "12"
                                                                                : timing == "Year"
                                                                                    ? "10"
                                                                                    : "24"
                                                                }
                                                                onChange={(val) => {
                                                                    setDuration(val);
                                                                }}
                                                            />
                                                        ) : (
                                                            <Select
                                                                className="profileCountrySelectBox"
                                                                showSearch
                                                                style={{ height: "50px", textAlign: "center" }}
                                                                placeholder="Select time duration"
                                                                optionFilterProp="children"
                                                                required
                                                                onChange={(val) => {
                                                                    setDuration(val);
                                                                }}
                                                                defaultValue={duration}
                                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                                options={
                                                                    hourDuration &&
                                                                    hourDuration.map((data, index) => {
                                                                        const obj = {
                                                                            value: data,
                                                                            label: data,
                                                                        };
                                                                        return obj;
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            {timing === "Hour" ? (
                                                <Form.Item
                                                    label={t("Week Days")}
                                                    name="week_days"
                                                    rules={[
                                                        {
                                                            required: timing == "Hour" && checkedList.length == 0 ? true : false,
                                                            message: "Please select week days!",
                                                        },
                                                    ]}
                                                >
                                                    <div className={style.weekDaysContainer}>
                                                        <div className={style.selectAllBtn}>
                                                            <Checkbox
                                                                className="bookingCheckBox"
                                                                indeterminate={indeterminate}
                                                                onChange={(e) => {
                                                                    // setCheckedList(e.target.checked ? (weekDays.map(a => a.charAt(0).toUpperCase() + a.substr(1))) : []);
                                                                    setCheckedList(e.target.checked ? weekDays : []);
                                                                }}
                                                                checked={checkAll}
                                                            >
                                                                Select all
                                                            </Checkbox>
                                                        </div>
                                                        <CheckboxGroup
                                                            className="bookingCheckBox"
                                                            value={checkedList}
                                                            defaultValue={weekDays}
                                                            onChange={(list) => {
                                                                // setCheckedList(list.map(a => a.charAt(0).toLowerCase() + a.substr(1)));
                                                                setCheckedList(list);
                                                            }}
                                                            options={weekDays}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <Form.Item label={t("Status")} name="status">
                                        <Radio.Group
                                            className="customRadioBtn"
                                            defaultValue={bookingStatus == true ? "online" : "offline"}
                                            onChange={(e) => {
                                                setBookingStatus(e.target.value == "online" ? true : false);
                                            }}
                                        >
                                            <Radio value="online">{t("Online")}</Radio>
                                            <Radio value="offline">{t("Offline")}</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {bookingStatus == true ? (
                                        <>
                                            <div>
                                                <Form.Item
                                                    label={t("Meeting Link")}
                                                    name="meeting_link"
                                                    rules={[
                                                        {
                                                            required: bookingStatus == true ? true : false,
                                                            message: "Please add meeting link!",
                                                        },
                                                        {
                                                            type: "url",
                                                            message: "Please enter valid url.",
                                                        },
                                                    ]}
                                                >
                                                    <Input size="large" placeholder={t("Add meeting link")} />
                                                </Form.Item>
                                            </div>
                                        </>
                                    ) : bookingStatus == false ? (
                                        <>
                                            <Form.Item
                                                label={t("Location")}
                                                name="location"
                                                rules={[
                                                    {
                                                        required: bookingStatus == false ? true : false,
                                                        message: "Please enter your location!",
                                                    },
                                                ]}
                                            >
                                                <Input size="large" placeholder="Enter location" onClick={showMapBox} defaultValue={address} />
                                            </Form.Item>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <Form.Item
                                        label={t("Total Users Allowed In Pack")}
                                        name="total_user_allow_in_pack"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    const sum =
                                                        value !== "" &&
                                                        value
                                                            .toString()
                                                            .split("")
                                                            .map(Number)
                                                            .reduce(function (a, b) {
                                                                return a + b;
                                                            }, 0);
                                                    if (sum === 0 || value < 0) {
                                                        return Promise.reject("Enter valid user number, it can't be 0.");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Add numbers of users"
                                            pattern="[0-9]"
                                            inputMode="numeric"
                                            min={1}
                                            className={style.profileInputFiled}
                                            onKeyDown={(evt) => {
                                                var theEvent = evt || window.event;
                                                var key = theEvent.keyCode || theEvent.which;
                                                var keyCode = key;
                                                key = String.fromCharCode(key);
                                                if (key.length == 0) return;
                                                var regex = /^[0-9\b]+$/;
                                                if (!regex.test(key)) {
                                                    theEvent.returnValue = false;
                                                    if (theEvent.preventDefault) theEvent.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="short_url"
                                        label={t("Short URL")}
                                        hasFeedback
                                        validateStatus={
                                            submitStatus
                                                ? shortUrl == "" && validUrlStatus
                                                    ? "error"
                                                    : ""
                                                : shortUrl == ""
                                                    ? ""
                                                    : urlLoading
                                                        ? "validating"
                                                        : validUrlStatus
                                                            ? "success"
                                                            : "error"
                                        }
                                        help={
                                            submitStatus
                                                ? shortUrl == "" && validUrlStatus
                                                    ? "Please enter url!"
                                                    : ""
                                                : shortUrl == ""
                                                    ? ""
                                                    : urlLoading
                                                        ? ""
                                                        : validUrlStatus
                                                            ? ""
                                                            : "This short Url is not available."
                                        }
                                        rules={[
                                            {
                                                pattern: new RegExp(/^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/),
                                                message: "URL can not contain whitespace or upper case letters",
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={style.profileInputFiled}
                                            style={{ padding: "0px 8px" }}
                                            prefix={
                                                <>
                                                    <b className={style.backslash}>{WEBSITE_URL}</b>
                                                </>
                                            }
                                            defaultValue={shortUrl}
                                            onChange={(e) => {
                                                onchangeShortUrl(e.target.value);
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault()
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.code === "Space") event.preventDefault();
                                            }}
                                            onInput={(e) => (e.target.value = ("" + e.target.value).toLocaleLowerCase())}
                                            placeholder="Enter url..."
                                            disabled={false}
                                            autoFocus={true}
                                        />
                                    </Form.Item>
                                    <center>
                                        <i>
                                            Please fill all mandatory fields<span style={{ color: "red" }}>(*)</span> before submit.
                                        </i>
                                    </center>

                                    {progressCount > 0 && (
                                        <div className={style.progressCircle}>
                                            <Progress type="circle" percent={progressCount} strokeColor="#E60C8F" />
                                        </div>
                                    )}

                                    <div className={style.createBtnContainer}>
                                        <Button className={style.cancelBtn} onClick={handleEditCancel}>
                                            <div className={style.buttonContent}>{t("Cancel")}</div>
                                        </Button>
                                        <Button
                                            className={style.createBtn}
                                            onClick={(e) => {
                                                if (categoryName == "" && subCategoryName == "") {
                                                    setCategoryValidErr(true);
                                                    setSubCategoryValidErr(true);
                                                } else if (categoryName == "") {
                                                    setCategoryValidErr(true);
                                                } else if (subCategoryName == "") {
                                                    setSubCategoryValidErr(true);
                                                } else {
                                                    setCategoryValidErr(false);
                                                    setSubCategoryValidErr(false);
                                                }
                                                if (progressCount == 0) {
                                                    editForm.submit();
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                        >
                                            {progressCount > 0 ? <Spin size="default" className="whiteSpin" /> : <div className={style.buttonContent}>{t("Submit")}</div>}
                                        </Button>
                                    </div>
                                </Form>
                            )
                    }

                </Modal>

                <Modal
                    width={600}
                    open={openLocationModal}
                    footer={null}
                    centered={true}
                    onCancel={() => {
                        setOpenLocationModal(false);
                        setMap(null);
                    }}
                    className="interactionPreviewModal"
                >
                    <div className={style.modalPreviewContent}>
                        <h3>Search Your Location:</h3>
                        <div className={style.modalMapBody}>
                            <div id="map" ref={(el) => (mapRef.current = el)} className="map-container"></div>
                        </div>
                        <div className={style.createBtnContainer}>
                            <Button
                                className={style.cancelBtn}
                                onClick={() => {
                                    setOpenLocationModal(false);
                                    setMap(null);
                                }}
                            >
                                <div className={style.buttonContent}>{t("Cancel")}</div>
                            </Button>
                            <Button
                                className={style.createBtn}
                                onClick={() => {
                                    if (mapObj) {
                                        setLat(mapObj && mapObj?.center[1]);
                                        setLng(mapObj && mapObj?.center[0]);
                                        setOpenLocationModal(false);
                                        setMap(null);
                                        setAddress(mapObj && mapObj?.place_name);
                                        if (editModalOpen == true) {
                                            editForm.setFieldValue("location", mapObj && mapObj?.place_name);
                                        } else {
                                            form.setFieldValue("location", mapObj && mapObj?.place_name);
                                        }
                                    } else {
                                        message.warning("Choose your location");
                                    }
                                }}
                            >
                                <div className={style.buttonContent}>Save</div>
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    width={600}
                    title="Delete Booking"
                    open={openDeleteModal}
                    onCancel={() => {
                        setOpenDeleteModal(false);
                        setBookingId("");
                    }}
                    footer={null}
                >
                    <Form
                        layout="vertical"
                        name="basic"
                        autoComplete="nope"
                        onFinish={(value) => handleDeleteSubmit()}
                        className={style.interactionForm}
                        form={deleteForm}
                    >
                        <h3 className={style.deleteSlotText}>Are you sure you wants to delete this booking?</h3>
                        <div className={style.createBtnContainer}>
                            <Button
                                className={style.cancelBtn}
                                onClick={() => {
                                    setOpenDeleteModal(false);
                                    setBookingId("");
                                }}
                            >
                                <div className={style.buttonContent}>{t("Cancel")}</div>
                            </Button>
                            <Button className={style.createBtn} onClick={deleteForm.submit}>
                                <div className={style.buttonContent}>{t("Confirm")}</div>
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    width={600}
                    title="Connect Stripe Account"
                    open={stripeConnectModal}
                    onCancel={() => {
                        setStripeConnectModal(false);
                        form.resetFields(["is_paid"]);
                    }}
                    footer={null}
                    centered
                >
                    <div className={style.noDataContent}>
                        <div className={style.noDataText}>
                            <h3>{t("You Are not Connected to Stripe!")}</h3>
                            <p>{t("You have to create account on stripe to create Paid Booking.")}</p>
                            <p className={style.refreshMsg}>{t("Refresh the page after successfully creating your account on stripe")}</p>
                        </div>
                    </div>

                    <div className={style.createBtnContainer}>
                        {connectStripeBtnClick == false ? (
                            <Button
                                className={style.addStripeBtn}
                                onClick={() => {
                                    setConnectStripeBtnClick(true);
                                    window.open(userDetail?.flags?.stripe_connect_url);
                                }}
                            >
                                <div className={style.buttonContent}>{t("Add Stripe Account")}</div>
                            </Button>
                        ) : (
                            <Button
                                className={style.addStripeBtn}
                                onClick={() => {
                                    getUserDetail();
                                }}
                            >
                                <div className={style.buttonContent}>{t("Refresh Page")}</div>
                            </Button>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default BookingList;
