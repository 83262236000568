import { useEffect, useRef, useState } from 'react';
import styles from "./AddInteraction.module.css";
import ReactPlayer from "react-player";
import { PlayCircleOutlined } from '@ant-design/icons';

const VideoPlayer = ({ videoUrl, onClickEvent }) => {
    const [playing, setPlaying] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);

    const handlePlayButtonClick = () => {
        setPlaying((prevPlaying) => !prevPlaying);
    };

    const handleProgress = (state) => {
        const percentage = (state.played * 100).toFixed(2);
        setProgressPercentage(parseFloat(percentage));
        if (percentage == 100) {
            setTimeout(() => {
                setProgressPercentage(0)
                setPlaying(false)
            }, 200);
        }
    };

    return (
        <>
            <ReactPlayer
                url={videoUrl}
                playing={playing}
                controls={true}
                playsinline
                preload="auto"
                onProgress={handleProgress}
                className={styles.reactVideoPlayer}
            />
        </>
    );
};

export default VideoPlayer;

