import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// get business detail CALL
export const getBusinessDetails = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.businessUser.businessDetail);
      if (!response.data.error) {
        return response?.data;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return { status: false, data: [] };
    }
  };
};

//update business ACCOUNT USER detail API CALL
export const updateBusinessAccountDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.businessUser.updateBusinessUser,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        message.success(response.data.message);
        return response;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
