import React, { useState, useEffect } from "react";
import styles from "./layout.module.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Row, Col, Button, Avatar, Modal, Drawer, theme, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  updateReduxUserDetails,
} from "../../redux/authentication/action";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { getItem, setItem } from "../../utility/LocalStorageControl";
import webIcon from "../../assets/Images/internet-svgrepo-com (1).svg";
import { QrcodeOutlined } from "@ant-design/icons";
import { WEBSITE_URL } from "../../config/Constant";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { token } = theme.useToken();
  const containerStyle = {
    height: "auto",
    overflow: "hidden",
    background: "white",
    border: `1px solid ${token.colorBorderSecondary}`,
  };

  const userDetail = getItem("userDetails")

  // useEffect(() => {
  //   updateUserDetails();
  // }, []);

  // const updateUserDetails = async () => {
  //   await dispatch(updateReduxUserDetails(getItem("userDetails")));
  // };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    i18n.changeLanguage(getItem("apiLanguageSelection"));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await dispatch(logOut());
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // main mobile Menu
  const showMobileMenuDrawer = () => {
    setMobileMenuOpen(true);
  };
  const onMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const items = [
    {
      label: t("dashboard"),
      key: "dashboard",
      icon: <FeatherIcon icon="home" size={16} />,
    },
    {
      label: t("Response List"),
      key: "response_list",
      icon: <FeatherIcon icon="message-square" size={16} />,
    },
    {
      label: t("My Reactions"),
      key: "my_reactions",
      icon: <FeatherIcon icon="message-circle" size={16} />,
    },
    {
      label: t("QR Payment"),
      key: "qrPayment",
      icon: <QrcodeOutlined style={{ fontSize: "16px" }} />,
    },
    {
      label: t("Payment History"),
      key: "payment_history",
      icon: <FeatherIcon icon="credit-card" size={16} />,
    },
    {
      label: t("Queue"),
      key: "queue",
      icon: <FeatherIcon icon="users" size={16} />,
    },
    {
      label: t("My Booking"),
      key: "calendar",
      icon: <FeatherIcon icon="book" size={16} />,
    },
    {
      label: t("Booking List"),
      key: "booking_list",
      icon: <FeatherIcon icon="bookmark" size={16} />,
    },
    {
      label: t("User Booking List"),
      key: "user_booking_list",
      icon: <FeatherIcon icon="calendar" size={16} />,
    },
    {
      label: t("Subscription"),
      key: "Subscription",
      icon: <FeatherIcon icon="award" size={16} />,
    },
    {
      label: t("New Interaction"),
      key: "new_interaction",
      icon: <FeatherIcon icon="plus" size={16} />,
    },
    {
      label: t("setting"),
      key: "Setting",
      icon: <FeatherIcon icon="settings" size={16} />,
      children: [
        {
          label: t("profile"),
          key: "profile",
          icon:
            userDetail && userDetail?.user_image !== "" ? (
              <Avatar
                src={userDetail && userDetail?.user_image}
                size={35}
                className={styles.mobileMainMenuProfile}
              />
            ) : (
              <Avatar
                icon={<FeatherIcon icon="user" size={20} />}
                size={35}
                className={styles.mobileMainMenuAvatar}
              />
            ),
        },
        {
          label: t("logOut"),
          icon: (
            <Avatar
              icon={<FeatherIcon icon="log-out" size={20} />}
              size={35}
              className={styles.mobileMainMenuLogOut}
            />
          ),
          key: "logout",
        },
      ],
    },
  ];

  const selectedMenuItem = (e) => {
    if (e.key == "dashboard") {
      setMobileMenuOpen(false);
      navigate("/");
    } else if (e.key == "response_list") {
      navigate("/interaction-responses");
      setMobileMenuOpen(false);
    } else if (e.key == "queue") {
      navigate("/queue");
      setMobileMenuOpen(false);
    } else if (e.key == "my_reactions") {
      navigate("/reactions");
      setMobileMenuOpen(false);
    } else if (e.key == "Subscription") {
      navigate("/subscription");
      setMobileMenuOpen(false);
    } else if (e.key == "qrPayment") {
      navigate("/qrPayment");
      setMobileMenuOpen(false);
    } else if (e.key == "payment_history") {
      navigate("/payment-history");
      setMobileMenuOpen(false);
    } else if (e.key == "calendar") {
      navigate("/calendar");
      setMobileMenuOpen(false);
    } else if (e.key === "booking_list") {
      navigate("/booking-list");
      setMobileMenuOpen(false);
    } else if (e.key == "new_interaction") {
      navigate("/add-interaction");
      setMobileMenuOpen(false);
    } else if (e.key == "profile") {
      navigate("/account-setting/profile");
      setMobileMenuOpen(false);
    } else if (e.key == "logout") {
      showModal();
      setMobileMenuOpen(false);
    } else if (e.key == "user_booking_list") {
      navigate("/users-booking-list/month");
      setMobileMenuOpen(false);
    } else {
      setMobileMenuOpen(false);
      navigate("/");
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerLeftMenuList}>
          {screenSize.width <= 499 && (
            <>
              <div
                className={styles.mobileMainMenuIcon}
                onClick={showMobileMenuDrawer}
              >
                <FeatherIcon icon="align-justify" size={30} />
              </div>
              <Drawer
                rootClassName="mobileInteractionMenuDrawer"
                placement="left"
                closable={true}
                onClose={onMobileMenuClose}
                open={mobileMenuOpen}
                getContainer={false}
                style={containerStyle}
                className={styles.interactionMenuDrawer}
              >
                <div className={styles.menuItems}>
                  <Menu
                    onClick={selectedMenuItem}
                    style={{ width: "100%", maxHeight: "80vh" }}
                    defaultSelectedKeys={"dashboard"}
                    className="mobileMainMenuDrawer"
                    mode="inline"
                    items={items}
                  />
                </div>
              </Drawer>

              <div
                onClick={() => navigate("/")}
                className={styles.mobileMainLogo}
              >
                <svg
                  width="512"
                  height="587"
                  viewBox="0 0 512 587"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_184_25)">
                    <path
                      d="M268.027 269.063L295.894 217.152L332.781 286.681C317.575 292.847 298.67 294.924 281.903 295.239C271.496 295.427 261.898 294.924 254.44 294.358L268.027 269.063Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M235.997 191.731L259.24 148L286.818 200.037C267.854 190.724 250.162 189.781 235.997 191.731Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M358.683 335.509C342.321 337.523 324.629 339.222 305.839 340.291C277.162 341.99 245.826 342.305 212.581 340.417C195.468 339.41 177.776 337.9 159.737 335.572L222.584 217.089C233.106 213.062 251.896 208.72 273.231 217.026L222.41 311.661L227.44 312.417L236.922 313.864C238.367 314.115 255.134 316.569 276.642 316.569C281.903 316.569 287.395 316.444 293.061 316.066C309.828 314.996 327.693 311.976 342.783 305.306L358.683 335.509Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M413.32 438.45H357.874L335.095 395.474C278.493 406.612 226.921 404.095 183.212 395.789L160.548 438.45H105.102L134.299 383.393L144.995 363.195C162.803 365.964 180.205 367.914 197.203 369.299C242.3 372.948 284.159 372.193 321.045 369.173C339.778 367.663 357.296 365.523 373.253 363.132L383.718 382.827L413.32 438.45Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M457.731 340.177C460.408 339.33 462.513 343.664 460.115 345.123C434.089 360.954 408.564 373.067 383.776 382.14C367.183 388.172 350.937 392.872 335.095 396.309C278.493 408.655 226.921 405.849 183.212 396.59C165.578 392.872 149.216 388.032 134.357 382.771C93.9803 368.43 64.4222 350.511 49.466 340.373C47.3017 338.905 48.8517 336.009 51.3336 336.832C83.3885 347.458 114.761 355.057 145.053 360.254C162.861 363.341 180.263 365.515 197.261 367.058C242.358 371.127 284.217 370.285 321.104 366.918C339.836 365.235 357.354 362.85 373.312 360.184C410.321 353.97 439.36 345.991 457.731 340.177Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M254.44 294.358L268.027 269.063L281.903 295.176C271.496 295.427 261.841 294.924 254.44 294.358Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M305.837 340.291C277.161 341.99 245.824 342.305 212.58 340.417L227.381 312.479L236.863 313.927C238.308 314.178 255.075 316.632 276.582 316.632C281.844 316.632 287.336 316.507 293.002 316.129L305.837 340.291Z"
                      fill="#E60C8F"
                    />
                    <path
                      d="M335.095 395.474C278.493 406.612 226.921 404.095 183.212 395.789L197.261 369.299C242.358 372.948 284.217 372.193 321.103 369.173L335.095 395.474Z"
                      fill="#E60C8F"
                    />
                  </g>
                  <path
                    d="M452.955 323.259C457.106 322.043 461.315 322.566 465.221 324.14C469.127 325.715 473.006 328.543 475.558 332.1C478.345 335.617 480.317 340.024 480.793 344.189C481.17 346.372 481.035 348.395 480.899 350.417C480.528 352.479 480.157 354.542 479.509 356.403C479.206 357.454 478.072 357.9 477.047 357.577C476.535 357.416 476.257 357.214 476.174 356.729L476.132 356.486C475.091 354.667 474.563 353.009 473.799 351.392C473.036 349.775 472.507 348.117 471.55 346.783C470.107 344.034 468.47 341.568 466.875 339.345C465.28 337.122 463.215 334.98 460.721 333.162C458.42 331.06 455.691 329.283 452.642 327.06L452.365 326.858C451.534 326.252 451.324 325.039 451.905 324.189C452.056 323.664 452.527 323.582 452.955 323.259Z"
                    fill="#E60C8F"
                  />
                  <path
                    d="M57.3924 320.072C53.1876 319.057 49.009 319.783 45.1838 321.544C41.3585 323.305 37.6197 326.317 35.2423 329.993C32.6279 333.64 30.871 338.137 30.5959 342.32C30.3249 344.519 30.5579 346.532 30.7908 348.545C31.2607 350.588 31.7306 352.63 32.4676 354.458C32.821 355.493 33.9756 355.884 34.9837 355.512C35.4877 355.326 35.7547 355.111 35.815 354.622L35.8451 354.378C36.7968 352.511 37.2446 350.829 37.9293 349.177C38.614 347.525 39.0618 345.844 39.9533 344.465C41.2625 341.65 42.7785 339.108 44.2644 336.81C45.7502 334.513 47.71 332.274 50.1136 330.337C52.3103 328.128 54.9508 326.22 57.8885 323.854L58.1555 323.639C58.9567 322.993 59.1073 321.771 58.4868 320.951C58.3101 320.433 57.8362 320.374 57.3924 320.072Z"
                    fill="#E60C8F"
                  />
                  <defs>
                    <clipPath id="clip0_184_25">
                      <rect
                        width="441.485"
                        height="290.45"
                        fill="white"
                        transform="translate(38.4407 148)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </>
          )}
        </div>

        <div className={styles.headerMenuList}></div>
        <div className={styles.RightSideHeader}>
          <div>
            <div
              className={styles.langIcon}
              onClick={() => {
                window.open(
                  WEBSITE_URL,
                  "_blank"
                );
              }}
            >
              <img src={webIcon} />
            </div>
          </div>
        </div>

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered={true}
          footer={false}
          width={600}
        >
          <div className={styles.signOutConfirm}>
            <h3>{t("Are your sure You wants to log out?")}</h3>
            <div className={styles.modalBtnContainer}>
              <Row>
                <Col
                  xxl={12}
                  md={12}
                  lg={12}
                  sm={12}
                  xs={screenSize.width > 350 ? 12 : 24}
                >
                  <Button className={styles.premiumBtn} onClick={handleCancel}>
                    <div className={styles.buttonContent}>{t("Cancel")}</div>
                  </Button>
                </Col>
                <Col
                  xxl={12}
                  md={12}
                  lg={12}
                  sm={12}
                  xs={screenSize.width > 350 ? 12 : 24}
                >
                  <div className={styles.premiumBtnContainer}>
                    <Button className={styles.premiumBtn} onClick={handleOk}>
                      <div className={styles.buttonContent}>{t("Confirm")}</div>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default Header;
