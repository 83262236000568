import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "../components/signInComponent/SignIn";
import SignUpComponent from "../components/signUpComponent/SignUpComponent";
import SubscriptionMessage from "../components/subscriptionMessage/SubscriptionMessage";

import TermsOfService from "../components/TermsAndPrivacy/Termsandcondition";
import Privacy from "../components/TermsAndPrivacy/Privacy";

// DEFINE MAIN FUNCTION
const PublickRoutes = () => {
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route exact path="/stripe" element={<SubscriptionMessage />} />
        <Route
          exact
          path="/accountVarification"
          element={<SignUpComponent />}
        />
        <Route exact path="/login" element={<SignIn />} />
        <Route exact path="/sign-up" element={<SignUpComponent />} />
        <Route exact path="/*" element={<Navigate to="/login" />} />
        <Route exact path="/terms-of-service" element={<TermsOfService />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />

      </Routes>{" "}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default PublickRoutes;
