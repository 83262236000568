import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";

export default function CKEditorMsg({
  onChange,
  value,
  data,
  readOnly = false,
  isConfig = true,
  chekcImport = false,
}) {
  const configs = {
    fullPage: true,
    allowedContent: true,
    fontFamily: {
      options: [
        "default",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
        "Wingdings, Zapf Dingbats, sans-serif",
        "Webdings, sans-serif",
        "Verdana, Geneva, sans-serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Times New Roman, Times, serif",
        "Tahoma, Geneva, sans-serif",
        "Symbol, sans-serif",
        "Palatino Linotype, Book Antiqua, Palatino, serif",
        "MS Serif, New York, serif",
        "MS Sans Serif, Geneva, sans-serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Lucida Console, Monaco, monospace",
        "Impact, Charcoal, sans-serif",
        "Georgia, serif",
        "Garamond, serif",
        "Courier New, Courier, monospace",
        "Comic Sans MS, cursive",
        "Bookman Old Style, serif",
        "Arial Black, Gadget, sans-serif",
      ],
    },
    fontSize: {
      options: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    },
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "|",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "|",
      "redo",
      "fontFamily",
      "fontSize",
      "colorButton",
      "colorPicker",
      "fontColor",
    ],
    colorButton: {
      colors: [
        "#000000", // Black
        "#ff0000", // Red
        "#00ff00", // Green
        "#0000ff", // Blue
      ],
    },
    colorPicker: {
      colors: [
        "rgba(0, 0, 0, 1)",
        "rgba(255, 0, 0, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 0, 255, 1)",
      ],
    },
  };

  return (
    <div className="App">
      <CKEditor
        data={data}
        config={configs}
        editor={ClassicExtended}
        // onReady={editor => {}}
        // onBlur={(event, editor) => {}}
        // onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log({ event, editor, data });
          onChange(data);
        }}
      />
    </div>
  );
}
