import { theme, darkTheme } from './themeVariables';

const configs = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  auth0: true,
};

export default configs;
