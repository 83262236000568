import React, { useEffect, useState } from "react";
import styles from "./Calendar.module.css";
import { useTranslation } from "react-i18next";
import {
  Col,
  Row,
  Popover,
  Tabs,
  Card,
  Pagination,
  Skeleton,
  Empty, Tooltip
} from "antd";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import { getMyBookingList } from "../../../redux/myBooking/action";
import moment from "moment";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration'

// DEFINE MAIN FUNCTIONS
function Calendar() {
  // DEFINE ALL STATES
  var today = new Date();
  dayjs.extend(utc)
  dayjs.extend(tz)
  dayjs.extend(duration);
  var timeZone = dayjs.tz.guess()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listLoading, setListLoading] = useState(true);
  const [bookingList, setBookingList] = useState(1);
  const [selectedType, setSelectedType] = useState("active");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    list();
  }, [currentPage, pageSize, selectedType]);

  const list = async (
    pageNumber = currentPage,
    pageLimit = pageSize,
    packageType = selectedType
  ) => {
    setListLoading(true);
    let query = {
      is_expired: packageType == "active" ? false : true,
      type: "my_booking",
      page: pageNumber,
      limit: pageLimit,
    };
    let result = await dispatch(getMyBookingList(query));
    if (result) {
      setTotalCounts(result.totalCount);
      setBookingList(result.list);
      setListLoading(false);
    } else {
      setTotalCounts(0);
      setBookingList([]);
      setListLoading(false);
    }
  };
  // DEFINE FUNCTION FOR QUEUE DETAILS TABLE PAGENATION TABLE IN PAGE CHANGE
  const handelpageChange = (current, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(current);
  };

  return (
    <div className={styles.interactionContainer}>
      <div className={styles.interactionHeader}>
        <h1 style={{ fontSize: "24px" }}>{t("My Booking")}</h1>
      </div>
      <Card>
        <div className={styles.interactionHeader}>
          <Tabs
            defaultActiveKey="active"
            items={[
              {
                key: "active",
                label: <h1>{t("Active Booking")}</h1>,
                children: (
                  <>
                    <div className={styles.CalendarListContainer}>
                      <Row>
                        {listLoading == true ? (
                          <>
                            {new Array(8).fill("").map((_, i) => {
                              return (
                                <Col
                                  xxl={6}
                                  xl={8}
                                  lg={8}
                                  md={12}
                                  sm={24}
                                  xs={24}
                                  key={i}
                                >
                                  <Skeleton.Input
                                    style={{
                                      height: "228px",
                                      margin: "13px 10px",
                                      borderRadius: "0 0 20px 20px",
                                      width: "366px",
                                    }}
                                    active={true}
                                    size={"large"}
                                  />
                                </Col>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {bookingList && bookingList?.length > 0 ? (
                              bookingList.map((value, index) => {
                                return (
                                  <>
                                    <Col
                                      xxl={6}
                                      xl={8}
                                      lg={8}
                                      md={12}
                                      sm={24}
                                      xs={24}
                                      key={index}
                                    >
                                      <div className={styles.calendarListCard}>
                                        <div className={styles.cardHeader}>
                                          <div
                                            className={styles.bookingDateDetail}
                                          >
                                            <p>
                                              {moment(value?.start_date).format('YYYY')}
                                            </p>
                                            <h3>
                                              {moment(value?.start_date).format('DD')}
                                            </h3>
                                            <p>
                                              {" "}
                                              {moment(value?.start_date).format('MMM')}
                                            </p>
                                          </div>
                                          {" - "}
                                          <div
                                            className={styles.bookingDateDetail}
                                          >
                                            <p>
                                              {moment(value?.end_date).format('YYYY')}
                                            </p>
                                            <h3>
                                              {moment(value?.end_date).format('DD')}
                                            </h3>
                                            <p>
                                              {" "}
                                              {moment(value?.end_date).format('MMM')}
                                            </p>
                                          </div>
                                          <Popover
                                            placement="topLeft"
                                            content={
                                              <>
                                                <div
                                                  className={
                                                    styles.actionContainer
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.viewActionIcon
                                                    }
                                                    onClick={() =>
                                                      navigate(
                                                        `/calendar-detail/${value?._id}`
                                                      )
                                                    }
                                                  >
                                                    <FeatherIcon
                                                      icon="eye"
                                                      size={20}
                                                    />
                                                  </div>
                                                  {/* <div
                                                    className={
                                                      styles.editActionIcon
                                                    }
                                                  >
                                                    <FeatherIcon
                                                      icon="edit"
                                                      size={20}
                                                    />
                                                  </div> */}
                                                </div>
                                              </>
                                            }
                                          >
                                            <div className={styles.iconBox}>
                                              <FeatherIcon
                                                icon="more-vertical"
                                                size={20}
                                              />
                                            </div>
                                          </Popover>
                                        </div>
                                        <div
                                          className={styles.CardContent}
                                          onClick={() =>
                                            navigate(
                                              `/calendar-detail/${value?._id}`
                                            )
                                          }
                                        >
                                          <h3 className={styles.title}>
                                            <Tooltip placement="top" title={value?.booking_id?.title}>
                                              {value?.booking_id?.title.length > 25
                                                ? value?.booking_id?.title.slice(0, 25) + "..."
                                                : value?.booking_id?.title}
                                            </Tooltip>
                                          </h3>
                                          {/* <div className={styles.totalPerson}>
                                            <FeatherIcon
                                              icon="calendar"
                                              size={15}
                                            />
                                            <p>
                                              {" "}
                                              {dayjs.utc(value?.start_date).tz(timeZone).format('YYYY')}
                                            </p>
                                          </div> */}
                                          <div
                                            className={styles.bookingTimeDetail}
                                          >
                                            <FeatherIcon
                                              icon="clock"
                                              size={15}
                                            />
                                            <p>
                                              {`${moment(value.start_date).format("HH:mm")} - ${moment(value.end_date).format("HH:mm")}`}
                                            </p>
                                          </div>
                                          <div className={styles.totalPerson}>
                                            <FeatherIcon
                                              icon="users"
                                              size={15}
                                            />
                                            <p>{t("Total Person")} : </p>
                                            <p>
                                              {value?.total_user_allow_in_pack}
                                            </p>
                                          </div>
                                          <div
                                            className={
                                              styles.bookingOtherDetail
                                            }
                                          >
                                            <p>
                                              {
                                                value?.status == "upcoming" ? "Upcoming"
                                                  : value?.status == "expired" ? "Expired"
                                                    : value?.status == "active" ? "Active"
                                                      : value?.status == "refund_request" ? "Refund Request"
                                                        : value?.status == "cancelled" ? "cancelled"
                                                          : value?.status == "cancel_request" ? "Cancelled Request"
                                                            : value?.status == "refunded" ? "Refunded"
                                                              : value?.status == "completed" ? "Completed"
                                                                : value?.status
                                              }
                                            </p>
                                            {value?.booking_id?.is_paid == true ? (
                                              <p>{t("Paid")}</p>
                                            ) : (
                                              <p>{t("Free")}</p>
                                            )}
                                            {value?.booking_id?.is_online == true ? (
                                              <p>{t("Online")}</p>
                                            ) : (
                                              <p>{t("Offline")}</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                );
                              })
                            ) : (
                              <div style={{ width: "100%" }}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </div>
                            )}
                          </>
                        )}
                      </Row>
                    </div>
                  </>
                ),
              },
              {
                key: "expired",
                label: <h1>{t("Expired Booking")}</h1>,
                children: (
                  <>
                    <div className={styles.CalendarListContainer}>
                      <Row>
                        {listLoading == true ? (
                          <>
                            {new Array(8).fill("").map((_, i) => {
                              return (
                                <Col
                                  xxl={6}
                                  xl={8}
                                  lg={8}
                                  md={12}
                                  sm={24}
                                  xs={24}
                                  key={i}
                                >
                                  <Skeleton.Input
                                    style={{
                                      height: "228px",
                                      margin: "13px 10px",
                                      borderRadius: "0 0 20px 20px",
                                      width: "366px",
                                    }}
                                    active={true}
                                    size={"large"}
                                  />
                                </Col>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {bookingList && bookingList?.length > 0 ? (
                              bookingList.map((value, index) => {
                                return (
                                  <>
                                    <Col
                                      xxl={6}
                                      xl={8}
                                      lg={8}
                                      md={12}
                                      sm={24}
                                      xs={24}
                                      key={index}
                                    >
                                      <div className={styles.calendarListCard}>
                                        <div className={styles.cardHeader}>
                                          <div
                                            className={styles.bookingDateDetail}
                                          >
                                            <p>
                                              {moment(value?.start_date).format('YYYY')}
                                            </p>
                                            <h3>
                                              {moment(value?.start_date).format('DD')}
                                            </h3>
                                            <p>
                                              {" "}
                                              {moment(value?.start_date).format('MMM')}
                                            </p>
                                          </div>
                                          {" - "}
                                          <div
                                            className={styles.bookingDateDetail}
                                          >
                                            <p>
                                              {moment(value?.end_date).format('YYYY')}
                                            </p>
                                            <h3>
                                              {moment(value?.end_date).format('DD')}
                                            </h3>
                                            <p>
                                              {" "}
                                              {moment(value?.end_date).format('MMM')}
                                            </p>
                                          </div>
                                          <Popover
                                            placement="topLeft"
                                            content={
                                              <>
                                                <div
                                                  className={
                                                    styles.actionContainer
                                                  }
                                                >
                                                  {/* <div
                                                    className={
                                                      styles.deleteActionIcon
                                                    }
                                                  >
                                                    <FeatherIcon
                                                      icon="trash-2"
                                                      size={20}
                                                    />
                                                  </div> */}
                                                  <div
                                                    className={
                                                      styles.viewActionIcon
                                                    }
                                                    onClick={() =>
                                                      navigate(
                                                        `/calendar-detail/${value?._id}`
                                                      )
                                                    }
                                                  >
                                                    <FeatherIcon
                                                      icon="eye"
                                                      size={20}
                                                    />
                                                  </div>
                                                  {/* <div
                                                    className={
                                                      styles.editActionIcon
                                                    }
                                                  >
                                                    <FeatherIcon
                                                      icon="edit"
                                                      size={20}
                                                    />
                                                  </div> */}
                                                </div>
                                              </>
                                            }
                                          >
                                            <div className={styles.iconBox}>
                                              <FeatherIcon
                                                icon="more-vertical"
                                                size={20}
                                              />
                                            </div>
                                          </Popover>
                                        </div>
                                        <div
                                          className={styles.CardContent}
                                          onClick={() =>
                                            navigate(
                                              `/calendar-detail/${value?._id}`
                                            )
                                          }
                                        >
                                          <h3 className={styles.title}>
                                            {value?.booking_id?.title}
                                          </h3>
                                          {/* <div className={styles.totalPerson}>
                                            <FeatherIcon
                                              icon="calendar"
                                              size={15}
                                            />
                                            <p>
                                              {" "}
                                              {moment(value?.created_at).format(
                                                "LL"
                                              )}
                                            </p>
                                          </div> */}
                                          <div
                                            className={styles.bookingTimeDetail}
                                          >
                                            <FeatherIcon
                                              icon="clock"
                                              size={15}
                                            />
                                            <p>
                                              {`${moment(value.start_date).format("HH:mm")} - ${moment(value.end_date).format("HH:mm")}`}
                                            </p>
                                          </div>
                                          <div className={styles.totalPerson}>
                                            <FeatherIcon
                                              icon="users"
                                              size={15}
                                            />
                                            <p>{t("Total Person")} : </p>
                                            <p>
                                              {value?.total_user_allow_in_pack}
                                            </p>
                                          </div>
                                          <div
                                            className={
                                              styles.bookingOtherDetail
                                            }
                                          >
                                            <p>
                                              {value?.status
                                                ?.charAt(0)
                                                .toUpperCase() +
                                                value?.status?.slice(1)}
                                            </p>
                                            {value?.booking_id?.is_paid == true ? (
                                              <p>{t("Paid")}</p>
                                            ) : (
                                              <p>{t("Free")}</p>
                                            )}
                                            {value?.booking_id?.is_online == true ? (
                                              <p>{t("Online")}</p>
                                            ) : (
                                              <p>{t("Offline")}</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </>
                                );
                              })
                            ) : (
                              <div style={{ width: "100%" }}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </div>
                            )}
                          </>
                        )}
                      </Row>
                    </div>
                  </>
                ),
              },
            ]}
            onChange={(value) => {
              setCurrentPage(1);
              setPageSize(8);
              setSelectedType(value);
            }}
          />
        </div>

        <div className={styles.paginationComponent}>
          <Pagination
            className="interactionPagination"
            showSizeChanger
            current={currentPage}
            total={totalCounts}
            pageSizeOptions={["5", "10", "15", "20"]}
            pageSize={pageSize}
            onChange={handelpageChange}
          />
        </div>
      </Card>
    </div>
  );
}

export default Calendar;
