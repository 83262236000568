import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Queue.module.css";
import {
  Table,
  Switch,
  Input,
  Pagination,
  Select,
  Tooltip,
  Popover,
  Popconfirm,
  message,
  Button,
  Modal,
  Form,
  Col,
  Row,
  Tabs,
  Card,
  notification,
  Progress,
} from "antd";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import { QrcodeOutlined } from "@ant-design/icons";
import { getItem } from "../../../utility/LocalStorageControl";
import {
  deleteQueue,
  getPeopleOfQueue,
  getQueueList,
  queueDone,
  registerQueue,
  updateQueue,
  viewQueueReview,
} from "../../../redux/queue/action";
import { getCheckShortUrl } from "../../../redux/Interaction/action";
import { SocketContext } from "../../../socket/SocketContext";
import { WEBSITE_URL } from "../../../config/Constant";

// DEFINE MAIN FUNCTIONS
const Queue = () => {
  // DEFINE ALL STATES
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //  DEFINE ALL QUEUE TABEL STATES
  const [listLoading, setListLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  //  DEFINE ALL QUEUE DETAILS TABEL STATES
  const [queueDetailsLoading, setQueueDetailsLoading] = useState(true);
  const [queueDetailsTotalCounts, setQueueDetailsTotalCounts] = useState(0);
  const [queueDetailsTableCurrentPage, setQueueDetailsTableCurrentPage] =
    useState(1);
  const [queueDetailsTablePageSize, setQueueDetailsTablePageSize] = useState(5);
  const [queueDetails, setQueueDetails] = useState([]);
  //  DEFINE ALL QUEUE HISTORY TABEL STATES
  const [queueHistoryLoading, setQueueHistoryLoading] = useState(true);
  const [progressCount, setProgressCount] = useState(0);
  const [queueHistoryTotalCounts, setQueueHistoryTotalCounts] = useState(0);
  const [queueHistoryTableCurrentPage, setQueueHistoryTableCurrentPage] =
    useState(1);
  const [queueHistoryTablePageSize, setQueueHistoryTablePageSize] = useState(5);
  const [queueHistory, setQueueHistory] = useState(5);
  const [queues, setQueues] = useState([]);
  const [form] = Form.useForm();
  const [selectedQueue, setSelectedQueue] = useState([]);
  const [createvisible, setCreateVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [queueNameLoading, setQueueNameLoading] = useState(true);
  const [shortUrl, setShortUrl] = useState("");
  const [validUrlStatus, setValidUrlNameStatus] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [currentQueue, setCurrentQueue] = useState([]);
  let currentQueueRef = useRef();
  const [selecetdQueueTab, setSelecetdQueueTab] = useState("1");
  const [api, contextHolder] = notification.useNotification();
  const [showAction, setShowAction] = useState(false);
  let showSelectTableRef = useRef(null);

  //  DEFINE USEEFFECT
  useEffect(() => {
    setListLoading(true);
    getList();
  }, []);

  //  DEFINE USEEFFECT FOR QUEUE DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 800);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  //  DEFINE USEEFFECT FOR QUEUE DETAILS DATA
  useEffect(() => {
    if (currentQueue?.queueName) {
      getQueueDatails();
    }
  }, [
    currentQueue?.queueName,
    queueDetailsTableCurrentPage,
    queueDetailsTablePageSize,
  ]);

  //  DEFINE USEEFFECT FOR QUEUE HISTORY DATA
  useEffect(() => {
    if (currentQueue?.queueName) {
      getQueueHistory();
    }
  }, [
    currentQueue?.queueName,
    queueHistoryTableCurrentPage,
    queueHistoryTablePageSize,
  ]);

  //  DEFINE USEEFFECT FOR QUEUE SOCKER MANAGE DATA
  useEffect(() => {
    socket.on("updatedQueNum", (data) => {
      if (data.queueId === currentQueueRef?.current?._id) {
        getQueueDatails();
      }
    });
    socket.on("queueDataUpdate", (data) => {
      if (data.queueId === currentQueueRef?.current?._id) {
        getQueueDatails();
      }
    });
    socket.on("user_join_queue", (data) => {
      if (getItem("userDetails")?._id == data?.business_id) {
        setQrModalOpen(false);
        handleCurrentQueue({
          _id: data?.queueId,
          queueName: data?.queueName,
          userToken: data?.userToken,
        });
        api.info({
          key: "updatable",
          message: `${data?.queueName} joined the queue`,
          description: `Now Token Number ${data?.userToken} Queue user is joined`,
          duration: 5,
        });
      }
    });
  }, [socket]);

  // UPLOAD PERCENTAGE
  const uploadPercentage = (val) => {
    setProgressCount(val);
  };

  // GET QUEUE LIST API CALLING FUNCTION
  const getList = async () => {
    let response = await dispatch(getQueueList(currentPage, pageSize));
    if (response) {
      setQueues(response.list);
      setListLoading(false);
      setTotalCounts(response.totalCounts);
    } else {
      setListLoading(true);
      setTimeout(() => {
        setListLoading(false);
      }, 800);
    }
  };

  //  DEFINE FUNCTION FOR FOR QUEUE DETAILS API CALLING
  const getQueueDatails = async (
    pageNumber = queueDetailsTableCurrentPage,
    pageLimit = queueDetailsTablePageSize,
    queueId = currentQueueRef?.current?._id
  ) => {
    let query = {
      page: pageNumber,
      limit: pageLimit,
      queue: queueId,
    };
    let data = await dispatch(getPeopleOfQueue(query));
    if (data) {
      setQueueDetailsTotalCounts(data.totalCounts);
      setQueueDetails(data.list);
      setQueueDetailsLoading(false);
    }
  };

  //  DEFINE FUNCTION FOR FOR QUEUE HISTORY API CALLING
  const getQueueHistory = async (
    pageNumber = queueHistoryTableCurrentPage,
    pageLimit = queueHistoryTablePageSize,
    queueId = currentQueue._id
  ) => {
    let query = {
      page: pageNumber,
      limit: pageLimit,
      queue: queueId,
    };
    let data = await dispatch(viewQueueReview(query));
    if (data) {
      setQueueHistoryTotalCounts(data.totalCounts);
      setQueueHistory(data.list);
    }
    setQueueHistoryLoading(false);
  };

  // DEFINE FUNCTION FOR DONE QUEUE API CALLING
  const handelQueueDone = async (values, index) => {
    if (index > 0) {
      return message.error(t("Please attend the first person"));
    }
    let payload = {
      queueId: currentQueue._id,
      runningNumber: values.userToken,
    };
    let response = await dispatch(queueDone(values._id, payload));
    if (response) {
      setQueueDetailsLoading(true);
      getQueueDatails();
    }
  };

  // DEFINE FUNCTION FOR SELECT CURRENT QUEUE
  const handleCurrentQueue = (value) => {
    if (currentQueue?.queueName !== value?.queueName) {
      setCurrentQueue(value);
      currentQueueRef.current = value;
    } else {
      setCurrentQueue([]);
      currentQueueRef.current = [];
    }
    setTimeout(() => {
      showSelectTableRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
    setQueueDetailsTablePageSize(5);
    setQueueDetailsTableCurrentPage(1);
    setQueueHistoryTablePageSize(5);
    setQueueHistoryTableCurrentPage(1);
    setQueueDetailsLoading(true);
    setSelecetdQueueTab("1");
  };

  // DEFINE FUNCTION FOR QUEUE TABLE PAGENATION TABLE IN PAGE CHANGE
  const handlePageChange = async (current, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(current);
    let response = await dispatch(getQueueList(current, pageSize));
    if (response) {
      setQueues(response.list);
      setListLoading(false);
      setTotalCounts(response.totalCounts);
    } else {
      setListLoading(true);
      setTimeout(() => {
        setListLoading(false);
      }, 800);
    }
  };

  // DEFINE FUNCTION FOR QUEUE DETAILS TABLE PAGENATION TABLE IN PAGE CHANGE
  const handleQueueDetailsPageChange = (current, pageSize) => {
    setQueueDetailsTablePageSize(pageSize);
    setQueueDetailsTableCurrentPage(current);
  };

  // DEFINE FUNCTION FOR QUEUE HISTORY TABLE PAGENATION TABLE IN PAGE CHANGE
  const handleQueueHistoryPageChange = (current, pageSize) => {
    setQueueHistoryTablePageSize(pageSize);
    setQueueHistoryTableCurrentPage(current);
  };

  // DEFINE FUNCTION FOR JOIN MEETING OPEN
  const handleJoinMeeting = (record) => {
    if (record && record !== "") {
      let meetingUrl = record;
      if (
        !meetingUrl.startsWith("http://") &&
        !meetingUrl.startsWith("https://")
      ) {
        meetingUrl = "https://" + meetingUrl;
      }
      window.open(meetingUrl);
    }
  };

  // DEFINE FUNCTION FOR EDIT MODEL OPEN
  const handleEditQueue = (values) => {
    setProgressCount(0);
    setIsUpdate(true);
    setSelectedQueue(values);
    setModalTitle(`Edit Queue`);
    setShortUrl(values?.short_url?.short_code);
    let isUpdate = {
      ...values,
      short_url: values?.short_url?.short_code,
      maxLimit: values?.maxLimit?.toString(),
      estimation_time: values?.estimation_time?.toString(),
    };
    form.setFieldsValue(isUpdate);
    setCreateVisible(true);
    setValidUrlNameStatus(true);
    setSubmitClick(true);
  };

  // DEFINE FUNCTION FOR DELETE QUEUE
  const handleDeleteQueue = async (values) => {
    let data = await dispatch(deleteQueue(values._id));
    if (data) {
      setShortUrl("");
      setValidUrlNameStatus(false);
      setSubmitClick(false);
      setCurrentQueue([]);
      currentQueueRef.current = [];
      getList();
    }
  };

  //  DEFINE FUNCTION FOR SWITCH CHANGE API CALLING
  const handleSwitchChange = async (checked, queueId) => {
    let queueStatus = {
      isActive: checked,
    };
    let data = await dispatch(updateQueue(queueId, queueStatus));
    if (data) {
      getList();
    }
  };

  // DEFINE FUNCTION FOR QUEUE NAME COPY
  const copyLinkToClipboard = (queueName) => {
    const link = `${WEBSITE_URL}${queueName}`;
    if (window.isSecureContext && navigator.clipboard) {
      window.navigator.clipboard.writeText(link).then(() => {
        message.success(t("Link copied to clipboard"));
      });
    } else {
      unsecuredCopyToClipboard(link);
    }
  };

  // DEFINE FUNCTION FOR QUEUE NAME COPY
  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      message.success(t("Link copied to clipboard"));
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  // DEFINE ARRAY FOR QUEUE TABLE FIELDS
  const columns = [
    {
      title: t("Queue Name"),
      dataIndex: "queueName",
      key: "queueName",
      width: "8%",
      render: (text) => (
        <Popover
          content={text}
          title="Full Name"
          trigger="hover"
          placement="top"
        >
          {text?.length > 20 ? `${text?.slice(0, 20)}...` : text}
        </Popover>
      ),
    },
    {
      title: t("Queue URL"),
      dataIndex: "short_url",
      key: "short_url ",
      width: "6%",
      render: (text, record) => (
        <Tooltip title={"Click to Copy Link"}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => copyLinkToClipboard(record?.short_url?.short_code)}
          >
            {record?.short_url?.short_code}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t("Estimated Time (Minutes)"),
      dataIndex: "estimation_time",
      key: "estimation_time ",
      width: "6%",
      render: (text, record) =>
        text?.length > 20 ? `${text?.slice(0, 20)}...` : text,
    },
    {
      title: t("Queue Description"),
      dataIndex: "description",
      key: "description",
      width: "6%",
      render: (text) => (
        <Popover
          content={text}
          title="Full Description"
          trigger="hover"
          placement="top"
        >
          {text?.length > 20 ? `${text?.slice(0, 20)}...` : text}
        </Popover>
      ),
    },
    {
      title: t("Active"),
      dataIndex: "isActive",
      key: "isActive",
      width: "6%",
      render: (text, record) => (
        <>
          <Switch
            checked={text}
            className="interactionSwitch"
            onChange={(checked) => handleSwitchChange(checked, record._id)}
          />
        </>
      ),
    },
    {
      title: t("Limit"),
      dataIndex: "maxLimit",
      key: "maxLimit",
      width: "6%",
    },
    {
      title: t("Type of Queue"),
      dataIndex: "type",
      key: "type",
      width: "4%",
      render: (text, record) => (
        <>{text?.charAt(0).toUpperCase() + text?.slice(1)}</>
      ),
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      width: "10%",
      render: (text, record) => (
        <Tooltip title={text ? (text == "" ? "" : text) : ""}>
          {text
            ? text == ""
              ? "-"
              : text?.length > 20
                ? `${text?.slice(0, 25)}...`
                : text
            : "-"}
        </Tooltip>
      ),
    },
    {
      title: t("Meeting Url"),
      dataIndex: "meetingURL",
      key: "meetingURL",
      width: "12%",
      render: (text, record) => (
        <Tooltip
          title={text ? (text == "" ? "" : t("Click to join meeting")) : ""}
        >
          <p
            style={{ cursor: text ? (text == "" ? "" : "pointer") : "" }}
            onClick={() => handleJoinMeeting(text)}
          >
            {text
              ? text == ""
                ? "-"
                : text?.length > 20
                  ? `${text?.slice(0, 25)}...`
                  : text
              : "-"}
          </p>
        </Tooltip>
      ),
    },
    {
      title: t("Select Queue"),
      key: "selectQueue",
      width: "6%",
      render: (text, record) => (
        <Button
          className={`${currentQueue.length !== 0 && currentQueue?._id === record._id
            ? styles.selectedButton
            : styles.unselectedButton
            }`}
          onClick={(e) => handleCurrentQueue(record)}
        >
          {t("Select")}
        </Button>
      ),
    },
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      width: "4%",
      render: (text, record) => (
        <>
          <Popover
            placement="leftTop"
            content={
              <div className={styles.actionContainer}>
                <Tooltip title={t("Delete")} placement="top">
                  <Popconfirm
                    outlined
                    type="warning"
                    title={t("Are you sure to delete this Queue?")}
                    okText={t("Yes")}
                    cancelText={t("No")}
                    onConfirm={() => handleDeleteQueue(record)}
                  >
                    <div
                      className={styles.deleteActionIcon}
                      onClick={() => {
                        setShowAction(false);
                      }}
                    >
                      <FeatherIcon icon="trash-2" size={20} />
                    </div>
                  </Popconfirm>
                </Tooltip>

                <Tooltip title={t("Edit Interaction")} placement="top">
                  <div
                    className={styles.editActionIcon}
                    onClick={() => {
                      handleEditQueue(record);
                      setShowAction(false);
                    }}
                  >
                    <FeatherIcon icon="edit" size={20} />
                  </div>
                </Tooltip>
                <Tooltip title={t("View QR Code")} placement="top">
                  <div
                    className={styles.viewActionIcon}
                    onClick={() => {
                      setShowAction(false);
                      setQrModalOpen(true);
                      setPreviewContent(record?.short_url?.qr_code);
                    }}
                  >
                    <QrcodeOutlined />
                  </div>
                </Tooltip>
              </div>
            }
            arrow={true}
          >
            <FeatherIcon
              icon="more-vertical"
              onClick={() => {
                setShowAction(!showAction);
              }}
              style={{ cursor: "pointer" }}
              size={20}
            />
          </Popover>
        </>
      ),
    },
  ];

  // DEFINE ARRAY FOR QUEUE DETAILS TABLE FIELDS
  const QueueDetailscolumns = [
    {
      title: t("Token"),
      dataIndex: "userToken",
      key: "userToken",
    },
    {
      title: t("Comment"),
      dataIndex: "question",
      key: "question",
      render: (text) => (
        <Popover
          content={text}
          title={t("Full Comment")}
          trigger="hover"
          placement="top"
        >
          {text ? (text.length > 20 ? `${text.slice(0, 20)}...` : text) : "-"}
        </Popover>
      ),
    },
    {
      title: t("Alert Me"),
      dataIndex: "isAlertMe",
      key: "isAlertMe",
      render: (text) => (text === true ? "Yes" : "No"),
    },
    {
      title: t("mobile number"),
      dataIndex: "number",
      key: "number",
      render: (text, record) =>
        text ? <p>{`${record?.prefix} ${text}`}</p> : "-",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return index === 0 ? t("Running") : t("Pending");
      },
    },

    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <>
          {index === 0 && queueDetailsTableCurrentPage === 1 && (
            <Button
              className={styles.unselectedButton}
              onClick={() => handelQueueDone(record, index)}
            >
              {t("Done")}
            </Button>
          )}
        </>
      ),
    },
  ];

  // DEFINE ARRAY FOR QUEUE HISTORY TABLE FIELDS
  const QueueHistorycolumns = [
    {
      title: t("Token"),
      dataIndex: "userToken",
      key: "userToken",
    },
    {
      title: t("Rating"),
      dataIndex: "rating",
      key: "rating",
      render: (text) => (text ? text : "-"),
    },
    {
      title: t("Question"),
      dataIndex: "question",
      key: "question",
      render: (text) => (
        <Popover
          content={text}
          title={t("Full Comment")}
          trigger="hover"
          placement="top"
        >
          {text ? (text.length > 20 ? `${text.slice(0, 20)}...` : text) : "-"}
        </Popover>
      ),
    },
    {
      title: t("Comment"),
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <Popover
          content={text}
          title={t("Full Comment")}
          trigger="hover"
          placement="top"
        >
          {text ? (text.length > 20 ? `${text.slice(0, 20)}...` : text) : "-"}
        </Popover>
      ),
    },
    {
      title: t("mobile number"),
      dataIndex: "number",
      key: "number",
      render: (text, record) => (text ? `${record?.prefix} ${text}` : "-"),
    },
  ];

  // DEFINE FUNCTION FOR CREATE QR API CALLING
  const handleCreateQueue = async (values) => {
    if (
      validUrlStatus == true &&
      shortUrl !== "" &&
      queueNameLoading == false
    ) {
      if (!isUpdate) {
        if (values.type == "physical") {
          values.meetingURL = "";
        } else {
          values.address = "";
        }
        let newValues = { ...values, operator: getItem("userDetails")._id };
        let data = await dispatch(registerQueue(newValues, uploadPercentage));
        if (data) {
          getList();
          setShortUrl("");
          setValidUrlNameStatus(false);
          setSubmitClick(false);
          form.resetFields();
          handleModalCancel();
          setIsUpdate(false);
          setSelectedQueue([]);
          setModalTitle("");
          setProgressCount(0);
        }
      } else {
        if (values.type == "physical") {
          values.meetingURL = "";
        } else {
          values.address = "";
        }
        let data = await dispatch(
          updateQueue(selectedQueue._id, values, uploadPercentage)
        );
        if (data) {
          getList();
          setShortUrl("");
          setValidUrlNameStatus(false);
          setSubmitClick(false);
          form.resetFields();
          handleModalCancel();
          setIsUpdate(false);
          setSelectedQueue([]);
          setModalTitle("");
          setProgressCount(0);
        }
      }
    }
  };

  // DEFINE FUNCTION FOR MODEL CANCEL
  const handleModalCancel = () => {
    setCreateVisible(false);
    form.resetFields();
    setIsUpdate(false);
    setModalTitle(null);
    setProgressCount(0);
  };

  //  DEFINE FUNCTION FOR SHORT URL IS VALID OR NOT
  const onValidUrlCheck = async (value) => {
    setQueueNameLoading(true);
    if (isUpdate) {
      if (shortUrl !== selectedQueue?.short_url?.short_code) {
        if (value == "") {
          setQueueNameLoading(false);
        } else {
          const response = await dispatch(getCheckShortUrl(value));
          if (response == false) {
            setQueueNameLoading(false);
            setValidUrlNameStatus(false);
          } else {
            setValidUrlNameStatus(true);
            setQueueNameLoading(false);
          }
        }
      } else {
        setSubmitClick(true);
        setValidUrlNameStatus(true);
        setQueueNameLoading(false);
      }
    } else {
      if (value == "") {
        setQueueNameLoading(false);
      } else {
        const response = await dispatch(getCheckShortUrl(value));
        if (response == false) {
          setQueueNameLoading(false);
          setValidUrlNameStatus(false);
        } else {
          setQueueNameLoading(false);
          setValidUrlNameStatus(true);
        }
      }
    }
  };

  //  SHORT URL ON CHANGE FUNCTION DEFINE
  const onchangeShortUrl = (val) => {
    if (val == "") {
      setValidUrlNameStatus(false);
    }
    setQueueNameLoading(true);
    setShortUrl(val);
    setSubmitClick(true);
  };

  // MAIN FUNCTION RETURN
  return (
    <>
      {contextHolder}
      {/* DEFINE MAIN CONTAINER */}
      <div
        className={styles.subscriptionContainer}
        style={{ height: listLoading ? "calc(100% - 130px)" : "" }}
      >
        <div className={styles.interactionHeader}>
          {/* DEFINE PAGE HEADER NAME */}
          <h1>{t("Queue")}</h1>
          {/* DEFINE CREATE BUTTON */}
          <div className={styles.premiumBtnContainer}>
            <Button
              className={styles.premiumBtn}
              onClick={() => {
                setShortUrl("");
                setValidUrlNameStatus(false);
                setSubmitClick(false);
                setModalTitle(t("Create New Queue"));
                setCreateVisible(true);
                setProgressCount(0);
              }}
            >
              <div className={styles.buttonContent}>{t("Create Queue")}</div>
            </Button>
          </div>
        </div>

        <div
          className="subscriptionCardBox"
          showSelectTableRef={showSelectTableRef}
        >
          <Card>
            <Table
              loading={listLoading}
              dataSource={queues}
              pagination={false}
              columns={columns}
              scroll={{
                x: "500px",
              }}
            />
            <div className={styles.paginationComponent}>
              <Pagination
                className="interactionPagination"
                showSizeChanger
                current={currentPage}
                total={totalCounts}
                pageSizeOptions={["5", "10", "15", "20"]}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </div>
          </Card>
        </div>
      </div>

      {currentQueue.length !== 0 && currentQueue.queueName && (
        <>
          <div
            ref={showSelectTableRef}
            className={styles.subscriptionContainer}
            style={{ height: listLoading ? "calc(100% - 130px)" : "" }}
          >
            {/* DEFINE PAGE HEADER NAME */}
            <Card>
              <h2>{currentQueue.queueName}</h2>
              <Tabs
                defaultActiveKey="1"
                onChange={(value) => {
                  setSelecetdQueueTab(value);
                  if (value == 2) {
                    setQueueHistoryLoading(true);
                    getQueueHistory();
                  } else {
                    setQueueDetailsLoading(true);
                    getQueueDatails();
                  }
                }}
                activeKey={selecetdQueueTab}
                tabBarExtraContent={`${"Current Due"}: ${selecetdQueueTab == 1
                  ? queueDetailsTotalCounts
                    ? queueDetailsTotalCounts
                    : "0"
                  : queueHistoryTotalCounts
                    ? queueHistoryTotalCounts
                    : "0"
                  }`}
                items={[
                  {
                    label: t("View Current"),
                    key: "1",
                    children: (
                      <>
                        <div className="subscriptionCardBox">
                          <Table
                            loading={queueDetailsLoading}
                            dataSource={queueDetails}
                            pagination={false}
                            columns={QueueDetailscolumns}
                            scroll={{
                              x: "500px",
                            }}
                          />
                          <div className={styles.paginationComponent}>
                            <Pagination
                              className="custom-pagination"
                              showSizeChanger
                              current={queueDetailsTableCurrentPage}
                              total={queueDetailsTotalCounts}
                              pageSizeOptions={["5", "10", "15", "20"]}
                              pageSize={queueDetailsTablePageSize}
                              onChange={handleQueueDetailsPageChange}
                            />
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    label: t("View History / Feedbacks"),
                    key: "2",
                    children: (
                      <>
                        <div className="subscriptionCardBox">
                          <Table
                            loading={queueHistoryLoading}
                            dataSource={queueHistory}
                            pagination={false}
                            columns={QueueHistorycolumns}
                            scroll={{
                              x: "500px",
                            }}
                          />
                          <div className={styles.paginationComponent}>
                            <Pagination
                              className="custom-pagination"
                              showSizeChanger
                              current={queueHistoryTableCurrentPage}
                              total={queueHistoryTotalCounts}
                              pageSizeOptions={["5", "10", "15", "20"]}
                              pageSize={queueHistoryTablePageSize}
                              onChange={handleQueueHistoryPageChange}
                            />
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </Card>
          </div>
        </>
      )}

      {/* DEFINE CREATE QR MODEL */}
      <Modal
        title={modalTitle}
        open={createvisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="createQueueForm"
          onFinish={handleCreateQueue}
          className={styles.interactionForm}
        >
          <Row gutter={25}>
            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                name="queueName"
                label={t("Queue Name")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter a queue name"),
                  },
                ]}
              >
                <Input placeholder={t("enter a queue name")} />
              </Form.Item>
            </Col>
            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                name="short_url"
                label={
                  <span>
                    {shortUrl == "" && !validUrlStatus ? (
                      ""
                    ) : (
                      <span style={{ color: "red" }}>*</span>
                    )}{" "}
                    {t("Short URL")}
                  </span>
                }
                hasFeedback
                validateStatus={
                  submitClick
                    ? shortUrl == "" && !validUrlStatus
                      ? "error"
                      : queueNameLoading
                        ? "validating"
                        : validUrlStatus
                          ? "success"
                          : "error"
                    : ""
                }
                help={
                  submitClick
                    ? shortUrl == "" && !validUrlStatus
                      ? t("Please enter url")
                      : queueNameLoading
                        ? ""
                        : validUrlStatus
                          ? ""
                          : t("This short Url is not available.")
                    : ""
                }
                rules={[
                  {
                    required: shortUrl == "" && !validUrlStatus ? true : false,
                    message: t("Please enter url"),
                  },
                  {
                    pattern: new RegExp(
                      /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                    ),
                    message: t(
                      t("URL can not contain whitespace or upper case letters")
                    ),
                  },
                ]}
              >
                <Input
                  style={{ padding: "0px 8px" }}
                  prefix={
                    <>
                      <b className={styles.backslash}>{WEBSITE_URL}</b>
                    </>
                  }
                  onPaste={(e) => {
                    e.preventDefault()
                  }}
                  onChange={(e) => {
                    onchangeShortUrl(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.code === "Space") event.preventDefault();
                  }}
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toLocaleLowerCase())
                  }
                  placeholder={t("Enter url")}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
              <Form.Item
                name="maxLimit"
                label={
                  <span>
                    <span style={{ color: "red", marginRight: "3px" }}>*</span>
                    <span>{t("Limit")}</span>
                  </span>
                }
                rules={[
                  {
                    message: t("Please enter a queue limit"),
                  },
                  {
                    validator(_, value) {
                      if (value) {
                        if (value >= 1) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            t("value should be greater than 0")
                          );
                        }
                      } else {
                        return Promise.reject(t("Please enter a queue limit"));
                      }
                    },
                  },
                ]}
              >
                <Input
                  onKeyDown={(event) => {
                    if (
                      event.key == "." ||
                      event.key === "-" ||
                      event.key == "e"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  type="number"
                  min={1}
                  controls={false}
                  placeholder={t("enter a queue limit")}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} md={12} lg={12} sm={24} xs={24}>
              <Form.Item
                name="estimation_time"
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span>{" "}
                    {t("Estimated Time")}({t("Minutes")})
                  </span>
                }
                rules={[
                  {
                    message: t("Please enter a estimated time"),
                  },
                  {
                    validator(_, value) {
                      if (value) {
                        if (value >= 1) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            t("value should be greater than 0")
                          );
                        }
                      } else {
                        return Promise.reject(
                          t("Please enter a estimated time")
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  onKeyDown={(event) => {
                    if (
                      event.key == "." ||
                      event.key === "-" ||
                      event.key == "e"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  type="number"
                  min={1}
                  controls={false}
                  placeholder={t("enter a estimated time")}
                />
              </Form.Item>
            </Col>

            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                name="type"
                label={t("Type")}
                initialValue="physical"
                rules={[
                  {
                    required: true,
                    message: t("Please select type of queue"),
                  },
                ]}
              >
                <Select
                  placeholder={t("Select your type")}
                  required
                  style={{ height: "52px", textAlign: "center" }}
                  className="profileCountrySelectBox"
                >
                  <Select.Option value="physical">
                    {t("Physical")}
                  </Select.Option>
                  <Select.Option value="virtual">{t("Virtual")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.type !== currentValues.type
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("type") === "physical" ? (
                    <>
                      <Form.Item
                        name="address"
                        placeholder={t("Add address")}
                        label={t("Address")}
                        rules={[
                          {
                            required: true,
                            message: t("Please enter a address"),
                          },
                        ]}
                      >
                        <Input placeholder={t("enter a address")} />
                      </Form.Item>
                    </>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.type !== currentValues.type
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("type") === "virtual" ? (
                    <>
                      <Form.Item
                        name="meetingURL"
                        label={t("Meeting Url")}
                        rules={[
                          {
                            required: true,
                            message: t("Please enter metting URL"),
                          },
                          {
                            type: "url",
                            message: t("Please enter valid url"),
                          },
                        ]}
                      >
                        <Input placeholder={t("enter metting URL")} />
                      </Form.Item>
                    </>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col xxl={24} md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                name="description"
                placeholder={t("Enter description")}
                label={t("Description")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter a queue description"),
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={t("enter a queue description")}
                  rows={5}
                />
              </Form.Item>
            </Col>
          </Row>

          {progressCount > 0 && (
            <div className={styles.progressCircle}>
              <Progress type="circle" percent={50} strokeColor="#E60C8F" />
            </div>
          )}
          <div className={styles.createBtnContainer}>
            <Button className={styles.cancelBtn} onClick={handleModalCancel}>
              <div className={styles.buttonContent}>{t("Cancel")}</div>
            </Button>
            <Button
              className={styles.createBtn}
              disabled={progressCount > 0 ? true : false}
              loading={progressCount > 0 ? true : false}
              onClick={() => {
                if (progressCount == 0) {
                  form.submit();
                  setSubmitClick(true);
                }
              }}
            >
              <div className={styles.buttonContent}>{t("Submit")}</div>
            </Button>
          </div>
        </Form>
      </Modal>

      {/* DEFINE SHOW QR CODE MODEL */}
      <Modal
        open={qrModalOpen}
        footer={null}
        centered={true}
        onCancel={() => {
          setQrModalOpen(false);
          setPreviewContent("");
        }}
        width={400}
        className="interactionPreviewModal"
      >
        <div className={styles.modalQrPreviewContent}>
          <div className={styles.QrPreviewImg}>
            <img src={previewContent}></img>
          </div>
          <p>
            {t("Scan This QR Code & Join")} <br /> {t("With Queue")}
          </p>
        </div>
      </Modal>
    </>
  );
};

// DEFINE EXPORT DEFAULT FUNCTION
export default Queue;
