import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Components/dashboard/Dashboard";
import QRPayment from "../Dashboard/Components/QRPayment/QRPayment";
import Interaction from "../Dashboard/Components/Ineraction/Interaction";
import InteractionDetail from "../Dashboard/Components/Ineraction/InteractionDetail/InteractionDetail";
import AddInteraction from "../Dashboard/Components/Ineraction/createInteraction/AddInteraction";
import CreateContent from "../Dashboard/Components/Ineraction/createInteraction/CreateContent";
import Subscription from "../Dashboard/Components/subscription/Subscription";
import UserResponseList from "../Dashboard/Components/responseList/UserResponseList";
import MainLayout from "../Dashboard/layout/MainLayout";
import ProfileSetting from "../Dashboard/Components/ProfileSetting/ProfileSetting";
import ReactionsList from "../Dashboard/Components/reactionsList/ReactionsList";
import Calendar from "../Dashboard/Components/Calender/Calendar";
import CalendarDetail from "../Dashboard/Components/Calender/calendarDetail/CalendarDetail";
import QrPaymentDetail from "../Dashboard/Components/QRPayment/qrDetail/QRPaymentDetail";
import PaymentHistoryList from "../Dashboard/Components/paymentHIstory/PaymentHistoryList";
import Queue from "../Dashboard/Components/queue/Queue";
import BookingList from "../Dashboard/Components/BookingFlow/BookingList";
import BookingDetail from "../Dashboard/Components/BookingFlow/BookingDetail/BookingDetail";
import UsersBooking from "../Dashboard/Components/UserBooking/UsersBooking";

const DashboardRoutes = () => {
  return (
    <>
      <MainLayout>
        <Routes>
          <Route exact path="/*" element={<Navigate to="/" />} />
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/qrPayment" element={<QRPayment />} />
          <Route
            exact
            path="/qrPayment-detail/:id"
            element={<QrPaymentDetail />}
          />
          <Route exact path="/interaction" element={<Interaction />} />
          <Route
            path="/interaction-detail/:id"
            element={<InteractionDetail />}
          />
          <Route path="/add-interaction" element={<AddInteraction />} />
          <Route
            path="/add-interaction/create-video"
            element={<CreateContent />}
          />
          <Route
            exact
            path="/account-setting/profile"
            element={<ProfileSetting />}
          />
          <Route exact path="/subscription" element={<Subscription />} />
          <Route exact path="/queue" element={<Queue />} />
          <Route
            exact
            path="/interaction-responses"
            element={<UserResponseList />}
          />
          <Route exact path="/reactions" element={<ReactionsList />} />
          <Route exact path="/calendar" element={<Calendar />} />
          <Route
            exact
            path="/calendar-detail/:id"
            element={<CalendarDetail />}
          />
          <Route
            exact
            path="/payment-history"
            element={<PaymentHistoryList />}
          />
          <Route exact path="/booking-list" element={<BookingList />} />
          <Route exact path="/booking-detail/:id" element={<BookingDetail />} />

          <Route
            exact
            path="/users-booking-list/month"
            element={<UsersBooking />}
          />
          <Route
            exact
            path="/users-booking-list/week"
            element={<UsersBooking />}
          />
          <Route
            exact
            path="/users-booking-list/day"
            element={<UsersBooking />}
          />
          <Route
            exact
            path="/users-booking-list/today"
            element={<UsersBooking />}
          />
          <Route
            exact
            path="/users-booking-list/schedule"
            element={<UsersBooking />}
          />
          <Route
            exact
            path="/users-booking-list/*"
            element={<Navigate to="/users-booking-list/month" />}
          />
        </Routes>
      </MainLayout>
    </>
  );
};
export default DashboardRoutes;
