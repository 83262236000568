import React, { useState, useRef, useEffect } from "react";
import styles from "./businessDetail.module.css";
import {
  Form,
  Input,
  Button,
  Avatar,
  message,
  Row,
  Col,
  Spin,
  Modal,
  Divider, Space, Select
} from "antd";
import { useDispatch } from "react-redux";
import {
  updateBusinessAccountDetail,
  getBusinessDetails,
} from "../../../../redux/business/action";
import { getItem } from "../../../../utility/LocalStorageControl";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useTranslation } from "react-i18next";
import { getUserDetailData } from "../../../../redux/profileSetting/action";
import {
  countryList
} from "../../../../redux/authentication/action";
import Cookies from "js-cookie";

function BusinessDetail() {
  const { t } = useTranslation();
  const [businessDetail, setBusinessDetail] = useState({});
  const [businessFormSetValueDetail, setBusinessFormSetValueDetail] = useState(
    {}
  );

  // console.log(
  //   "🚀 ~ file: BusinessDetail.js:28 ~ BusinessDetail ~ businessDetail:",
  //   businessDetail
  // );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [stripeButtonLoading, setStripeButtonLoading] = useState(true);
  const [previewMedia, setPreviewMedia] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [img, setImg] = useState([]);
  const inputFile = useRef(null);
  const [vatId, setVatId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [orgNum, setOrgNum] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessImg, setBusinessImg] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [stripConnected, setStripConnected] = useState(false);
  const [isStripeUpdateModal, setIsStripeUpdateModal] = useState(false);
  const use_Detail = getItem("userDetails");

  const [countryIsoList, setCountryIsoList] = useState([]);
  const [countryListLoading, setCountryListLoading] = useState(true);
  const [countryValue, setCountryValue] = useState("");

  useEffect(() => {
    getCountryList()
    fetchBusinessDetail();
    getUserAllDetail();
  }, []);

  const fetchBusinessDetail = async () => {
    setLoading(true);
    let response = await dispatch(getBusinessDetails());
    if (!response?.error) {
      setLoading(false);
      setBusinessDetail(response?.data);
      setBusinessImg(
        response?.data?.business_image !== ""
          ? response?.data?.business_image
          : ""
      );

      setBusinessPhone(response?.data?.phone_number);
      setCompanyName(response?.data?.companyName);
      setOrgNum(response?.data?.organizationNumber);
      setVatId(response?.data?.VATId);

      setBusinessFormSetValueDetail({
        companyName: response?.data?.companyName,
        businessMobile: response?.data?.phone_number,
        organizationNumber: response?.data?.organizationNumber,
        VATId: response?.data?.VATId,
      });
      form.setFieldsValue({
        companyName: response?.data?.companyName,
        businessMobile: response?.data?.phone_number,
        organizationNumber: response?.data?.organizationNumber,
        VATId: response?.data?.VATId,
      });
    }
  };

  const getUserAllDetail = async () => {
    setStripeButtonLoading(true);

    let val = getItem("userDetails")?.username
    let username = val && val ? val : Cookies.get("username")

    let response = await dispatch(getUserDetailData(username))
    if (response.status) {
      setStripeButtonLoading(false);
      setStripConnected(response?.data?.flags?.is_stripe_connected);
      setUserDetail(response?.data);
    }
  };

  const handleSubmit = async (value) => {
    setLoading(true);
    let form_Data = new FormData();
    if (img !== "" && img.length !== 0) {
      form_Data.append("business_image", img);
    }
    if (businessPhone) {
      form_Data.append("phone_number", businessPhone.toString());
    }
    form_Data.append("email", businessDetail?.email);
    form_Data.append("companyName", value.companyName);
    form_Data.append("organizationNumber", value.organizationNumber);
    form_Data.append("VATId", value.VATId);
    form_Data.append("user_id", businessDetail?.user_id?._id);

    const response = await dispatch(updateBusinessAccountDetail(form_Data));
    if (!response?.data?.data?.error) {
      setLoading(false);
      setSubmitButtonDisabled(true);
      fetchBusinessDetail();
    }
  };

  const changeHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files[0]) {
      if (e.target.files[0]["type"]?.split("/")[0] == "image") {
        setImg(e.target.files[0]);
        setPreviewMedia(
          <div className={styles.previewImgContainer}>
            <img
              crossOrigin="anonymous"
              alt=""
              src={URL.createObjectURL(e.target.files[0])}
            />
          </div>
        );
      } else {
        message.error(t("please select image"));
      }
    }
  };

  const onCheckFormChangeValue = (value) => {
    setSubmitButtonDisabled(
      JSON.stringify(form.getFieldsValue()) ===
      JSON.stringify(businessFormSetValueDetail)
    );
  };


  const getCountryList = async () => {
    setCountryListLoading(true);
    let response = await dispatch(countryList());
    if (response.status) {
      if (response.data.length > 0) {
        setCountryListLoading(false);
        if (
          response.data.find(
            (item) =>
              item.country.name === use_Detail?.countryObject?.countryName
          )
        ) {
          setCountryValue(
            response.data.find(
              (item) =>
                item.country.name === use_Detail?.countryObject?.countryName
            )?.country?.name
          );
        } else {
          setCountryValue("");
        }
        setCountryIsoList(
          response.data.map(function (item) {
            return {
              label: item?.country?.alpha2Code,
              value: item?.country?.name,
            };
          })
        )

      }
    } else {
      setCountryListLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.loadArbOX}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            colon={true}
            form={form}
            onFinish={handleSubmit}
            onValuesChange={(value) => {
              onCheckFormChangeValue(value);
            }}
          >
            <Row>
              <Col xxl={6} xl={8} lg={24} md={24} sm={24} xs={24}>
                <div className={styles.userImg}>
                  {Object.keys(previewMedia).length !== 0 ? (
                    previewMedia
                  ) : businessImg !== "" &&
                    businessImg !== null &&
                    businessImg !== undefined ? (
                    <div className={styles.previewImgContainer}>
                      <img alt="" src={businessImg} />
                    </div>
                  ) : (
                    <Avatar
                      size={130}
                      icon={<FeatherIcon icon="user" size={40} />}
                    />
                  )}

                  <div
                    className={styles.editImg}
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <FeatherIcon icon="edit" size={18} />
                  </div>
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={changeHandler.bind(this)}
                />
                {!stripeButtonLoading ? (
                  !stripConnected ? (
                    <>
                      <div className={styles.stripeBtnContainer}>
                        <Button
                          className={styles.stripeAccountBtn}
                          onClick={() => {
                            window.open(userDetail?.flags?.stripe_connect_url);
                          }}
                        >
                          {t("Stripe Account")}
                        </Button>
                      </div>
                      {/* {userDetail?.is_subscription_active == true ? */}
                      <div className={styles.stripeDetail}>
                        <p>
                          {t("Commission Percentage")} :{" "}
                          <strong>
                            {userDetail?.commissionPercent !== null
                              ? `${userDetail?.commissionPercent}%`
                              : "-"}
                          </strong>
                        </p>
                      </div>
                      {/* : ''
                    } */}
                    </>
                  ) : (
                    <>
                      <div
                        className={styles.stripeBtnContainer}
                        onClick={() => { }}
                      >
                        <Button
                          className={styles.stripeAccountBtn}
                          onClick={() => {
                            setIsStripeUpdateModal(true);
                          }}
                        >
                          {t("Update Stripe Account")}
                        </Button>
                      </div>
                      {userDetail?.business_detail?.stripe_account_id !== "" &&
                        userDetail?.business_detail?.stripe_account_id !== null &&
                        userDetail?.business_detail?.stripe_account_id !==
                        undefined ? (
                        <div className={`${styles.stripeDetail} stripeDetail`}>
                          <Divider orientation="left">
                            {t("Account Info")}
                          </Divider>
                          <p>
                            {" "}
                            {t("Stripe Id")} :
                            <strong>
                              {userDetail?.business_detail?.stripe_account_id}
                            </strong>
                          </p>
                          <div className={styles.stripeDetail}>
                            <p>
                              Commission Percentage:{" "}
                              <strong>
                                {userDetail?.commissionPercent !== null
                                  ? `${userDetail?.commissionPercent}%`
                                  : "-"}
                              </strong>
                            </p>
                          </div>
                          <p>
                            {t("Email")} :
                            {userDetail?.stripe_account_detail?.email !== null
                              ? userDetail?.stripe_account_detail?.email
                              : " -"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={18} xl={16} lg={24} md={24} sm={24} xs={24}>
                <div className={styles.profileInfo}>
                  <Form.Item
                    label={t("Company Name")}
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: t("Please input your company name!"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        businessDetail?.companyName !== "" &&
                          businessDetail?.companyName !== undefined &&
                          businessDetail?.companyName !== null
                          ? t("Company Name")
                          : t("Add Company Name")
                      }
                      className={styles.profileInputFiled}
                      style={{ height: "50px" }}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      defaultValue={companyName}
                    />
                  </Form.Item>

                  <Row gutter={[15, 15]}>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Form.Item
                        label={t("Phone No")}
                        name="businessMobile"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "Please input your business mobile number!"
                            ),
                          },
                        ]}
                      >
                        <Space direction="vertical" size="middle">
                          <Space.Compact>
                            {/* <Select
                              className="profileCountrySelectBox"
                              defaultValue={userDetail?.countryObject?.countryName}
                              value={countryValue}
                              bordered="false"
                              size="large"
                              showSearch
                              style={{ width: '30%', height: "46px", textAlign: "center" }}
                              loading={countryListLoading}
                              placeholder={t("Select country code")}
                              optionFilterProp="children"
                              required
                              onChange={(value) => {
                                setCountryValue(value);
                              }}
                              filterOption={(input, option) =>
                                (option?.value ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={countryIsoList}
                            /> */}

                            <Input
                              // style={{
                              //   width: '70%',
                              // }}
                              className={styles.profileInputFiled}
                              disabled={false}
                              type="tel"
                              maxLength={10}
                              defaultValue={businessPhone}
                              placeholder={
                                businessDetail?.phone_number !== "" &&
                                  businessDetail?.phone_number !== undefined &&
                                  businessDetail?.phone_number !== null
                                  ? t("mobile number")
                                  : t("Add mobile number")
                              }
                              onChange={(e) => {
                                setBusinessPhone(e.target.value.slice(0, 10));
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                );
                              }}
                              onKeyDown={(event) => {
                                if (
                                  event.key == "." ||
                                  event.key === "-" ||
                                  event.key == "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Space.Compact>
                        </Space>


                      </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                      <Form.Item
                        label={t("organization number")}
                        name="organizationNumber"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "Please input your organization number!"
                            ),
                          },
                        ]}
                      >
                        <Input
                          className={styles.profileInputFiled}
                          type="tel"
                          placeholder={
                            businessDetail?.organizationNumber !== "" &&
                              businessDetail?.organizationNumber !== undefined &&
                              businessDetail?.organizationNumber !== null
                              ? t("organization number")
                              : t("Add organization number")
                          }
                          defaultValue={orgNum}
                          onChange={(e) => {
                            setOrgNum(e.target.value);
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^\d]/g,
                              ""
                            );
                          }}
                          onKeyDown={(event) => {
                            if (
                              event.key == "." ||
                              event.key === "-" ||
                              event.key == "e"
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label={t("VAT Id")}
                    name="VATId"
                    rules={[
                      {
                        required: true,
                        message: t("Please input your VAT ID") + '!',
                      },
                    ]}
                  >
                    <Input
                      placeholder={
                        businessDetail?.VATId !== "" &&
                          businessDetail?.VATId !== undefined &&
                          businessDetail?.VATId !== null
                          ? t("vat id")
                          : t("Add vat id")
                      }
                      className={styles.profileInputFiled}
                      style={{ height: "52px" }}
                      onChange={(e) => {
                        setVatId(e.target.value);
                      }}
                      defaultValue={vatId}
                    />
                  </Form.Item>
                  <div className={styles.createBtnContainer}>
                    <Button
                      disabled={submitButtonDisabled}
                      style={{
                        cursor: submitButtonDisabled ? "no-drop" : "pointer",
                        background: submitButtonDisabled
                          ? "#ff8ace"
                          : "rgb(223 71 153)",
                        color: !submitButtonDisabled
                          ? "white"
                          : "rgb(251 251 251 / 69%)",
                      }}
                      className={styles.createBtn}
                      onClick={form.submit}
                    >
                      <div className={styles.buttonContent}>{t("Submit")}</div>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <Modal
        open={isStripeUpdateModal}
        onCancel={() => {
          setIsStripeUpdateModal(false);
        }}
        centered={true}
        footer={false}
        width={600}
      >
        <div className={styles.signOutConfirm}>
          <h3>
            {t(
              "Are you sure you want to configure new stripe account? if you are configure new account the old account will get removed."
            )}
          </h3>
          <div className={styles.modalBtnContainer}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={24} xxl={12}>
                <Button
                  className={styles.premiumBtn}
                  onClick={() => {
                    setIsStripeUpdateModal(false);
                    window.open(userDetail?.flags?.stripe_connect_url);
                  }}
                >
                  <div className={styles.buttonContent}>
                    {t("Yes, Continue")}
                  </div>
                </Button>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} xxl={12}>
                <div className={styles.premiumBtnContainer}>
                  <Button
                    className={styles.premiumBtn}
                    onClick={() => {
                      setIsStripeUpdateModal(false);
                    }}
                  >
                    <div className={styles.buttonContent}>{t("Cancel")}</div>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BusinessDetail;
