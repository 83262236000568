import React, { useState, useEffect } from "react";
import style from "./QRPayment.module.css";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Row,
  QRCode,
  Divider,
  Select,
  Modal,
  Form,
  Input,
  Switch,
  Radio,
  Skeleton,
  Tooltip,
  message, Spin
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import { countryList } from "../../../redux/authentication/action";
import { getItem, setItem } from "../../../utility/LocalStorageControl";
import { getCheckShortUrl } from "../../../redux/Interaction/action";
import {
  createQrPaymentApi,
  getQrPaymentsList,
  getQrPaymentsDetail,
  resetQrPayment,
  editQrDetailApi,
  deleteQRPayment,
} from "../../../redux/qrPayment/action";
import { useNavigate } from "react-router-dom";
import { QrcodeOutlined, EyeOutlined } from "@ant-design/icons";
import stripeQrCodeImg from "../../../assets/Images/qr-code.png";
import rightMark from "../../../assets/Images/green-check-mark.jpg";
import WrongMark from "../../../assets/Images/red-cross.png";
import {
  updateProfile,
  getUserDetailData,
} from "../../../redux/profileSetting/action";
import { el } from "date-fns/locale";
import Cookies from "js-cookie";
import { WEBSITE_URL } from "../../../config/Constant";
const { TextArea } = Input;

const QRPayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [qrList, setQrList] = useState([]);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPaid, setIsPaid] = useState(false);
  const [splitEnable, setSplitEnable] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState(true);
  const [countryDataList, setCountryDataList] = useState([]);
  const [countryNameList, setCountryNameList] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const [shortUrl, setShortUrl] = useState("");
  const [validUrlStatus, setValidUrlNameStatus] = useState(true);
  const [urlLoading, setUrlLoading] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showResetQrModal, setShowResetQrMOdal] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [resetForm] = Form.useForm();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editForm] = Form.useForm();
  const [userDetail, setUserDetail] = useState(getItem("userDetails"));
  const [recursive, setRecursive] = useState(true);
  const [isStripeConnect, setIsStripeConnect] = useState(
    getItem("userDetails")?.flags?.is_stripe_connected
  );
  const [connectStripeBtnClick, setConnectStripeBtnClick] = useState(false);
  const [currency, setCurrency] = useState(
    getItem("userDetails")?.countryObject?.currency
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteForm] = Form.useForm();
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);

  const getUserDetail = async () => {
    let val = getItem("userDetails")?.username

    let username = val && val ? val : Cookies.get("username")

    let response = await dispatch(getUserDetailData(username));

    if (response) {
      setUserDetail(response?.data);
      setIsStripeConnect(response?.data?.flags?.is_stripe_connected);
      setItem("userDetails", response?.data);
      setConnectStripeBtnClick(false);
      window.location.reload();
    } else {
      setConnectStripeBtnClick(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    getCountryList();
    allQrPaymentList();
  }, []);

  const allQrPaymentList = async () => {
    setLoading(true);
    const dataList = await dispatch(getQrPaymentsList());
    if (dataList) {
      setQrList(dataList?.data);
      setLoading(false);
    }
  };

  const getCountryList = async () => {
    setCountryListLoading(true);
    let response = await dispatch(countryList());
    if (response.status) {
      if (response.data.length > 0) {
        setCountryListLoading(false);
        setCountryDataList(response.data);
        if (
          response.data.find(
            (item) =>
              item.country.name === getItem("country_details")?.country_name
          )
        ) {
          setCountryValue(
            response.data.find(
              (item) =>
                item.country.name === getItem("country_details")?.country_name
            )?.country?.name
          );
        } else {
          setCountryValue("");
        }
        setCountryNameList(
          response.data.map(function (item) {
            return {
              label: item?.country?.name,
              value: item?.country?.name,
            };
          })
        );
      }
    } else {
      setCountryListLoading(true);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setIsPaid(false);
    setShortUrl("");
    setIsExclusive(false);
    setValidUrlNameStatus(false);
    setRecursive(true);
    form.resetFields();
    setSubmitBtnClicked(false)
  };

  const handleSubmit = async (val) => {
    setSubmitBtnClicked(true)
    const payload = {};

    payload.title = val?.title;
    payload.description = val?.description;
    payload.amount = +convertComaInToDot(val.amount);
    payload.isPaid = isPaid;
    payload.short_url = val.short_url;
    payload.splitEnable = splitEnable;
    payload.isExclusive = isExclusive;
    payload.vat = +convertComaInToDot(val.vat);
    payload.is_recursive = recursive;
    payload.country_iso_code =
      countryValue == ""
        ? countryDataList.find(
          (item) =>
            item.country.name === getItem("country_details")?.country_name
        )?.country?.alpha2Code
        : countryDataList.find((item) => item.country.name === countryValue)
          ?.country?.alpha2Code;

    if (shortUrl !== "" && shortUrl !== null && shortUrl !== undefined) {
      const createResponse = await dispatch(createQrPaymentApi(payload));
      if (createResponse?.data) {
        setOpen(false);
        setIsPaid(false);
        setShortUrl("");
        setIsExclusive(false);
        form.resetFields();
        allQrPaymentList();
        setRecursive(true);
        setValidUrlNameStatus(false);
        setSubmitStatus(false);
        setSubmitBtnClicked(false)
      } else {
        setOpen(false);
        setIsPaid(false);
        setShortUrl("");
        setIsExclusive(false);
        form.resetFields();
        setRecursive(true);
        setValidUrlNameStatus(false);
        setSubmitStatus(false);
        setSubmitBtnClicked(false)
      }
    } else {
      setSubmitBtnClicked(false)
      if (shortUrl && !validUrlStatus) {
        message.warning("Enter Valid short URL");
      }
      message.warning("Enter short URL");
    }
  };

  // URL VALIDATION
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  const onchangeShortUrl = (val) => {
    setUrlLoading(true);
    setShortUrl(val.replace(/\s/g, "").toLowerCase());
    setSubmitStatus(val == "" ? true : false);
  };

  const onValidUrlCheck = async (value) => {
    if (value == "") {
      setUrlLoading(false);
    } else {
      const response = await dispatch(getCheckShortUrl(value));
      if (response) {
        setUrlLoading(false);
        setValidUrlNameStatus(true);
      } else {
        setUrlLoading(false);
        setValidUrlNameStatus(false);
      }
    }
  };

  // formate amount value
  const formatCurrency = (number, symbol) => {
    const formattedNumber = number
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedNumber}(${symbol})`;
  };

  // RESET QR

  const showResetQrPaymentModal = (data) => {
    setShowResetQrMOdal(true);
    resetForm.setFieldsValue({
      title: data?.title,
      amount: data?.amount,
      vat: data?.vat,
      isExclusive:
        data?.isExclusive == true
          ? setIsExclusive(true)
          : setIsExclusive(false),
    });
  };

  const handleResetSubmit = async (val) => {
    setSubmitBtnClicked(true)
    const Payload = {
      isExclusive: isExclusive,
      amount: +convertComaInToDot(val.amount),
      vat: +convertComaInToDot(val?.vat),
      title: val?.title,
    };
    const confirmResetQr = await dispatch(resetQrPayment(Payload, paymentId));
    // console.log("🚀 ~ file: QRPayment.js:216 ~ handleResetSubmit ~ confirmResetQr:", confirmResetQr)
    if (confirmResetQr) {
      setSubmitBtnClicked(false)
      setShowResetQrMOdal(false);
      setIsExclusive(false);
      allQrPaymentList();
      resetForm.resetFields();
    } else {
      setSubmitBtnClicked(false)
    }
  };

  const resetCancel = () => {
    setShowResetQrMOdal(false);
    setIsExclusive(false);
    resetForm.resetFields();
    setSubmitBtnClicked(false)
  };

  // edit details
  const showEditModal = (data) => {
    setEditModalOpen(true);
    editForm.setFieldsValue({
      title: data?.title,
      description: data?.description,
      amount: data?.amount,
      vat: data?.vat,
      isExclusive: setIsExclusive(data?.isExclusive == true ? true : false),
      splitEnable: setSplitEnable(data?.splitEnable == true ? true : false),
      short_url: data?.url_string,
      is_recursive: setRecursive(data?.is_recursive == true ? true : false),
    });
  };

  const handleEditCancel = (e) => {
    editForm.resetFields();
    setEditModalOpen(false);
    setShortUrl("");
    setIsExclusive(false);
    setSplitEnable(false);
    setRecursive(true);
    setValidUrlNameStatus(false);
    setSubmitBtnClicked(false)
  };

  const handleEditSubmit = async (data) => {
    setSubmitBtnClicked(true)
    const Payload = {
      title: data?.title,
      description: data?.description,
      amount: +convertComaInToDot(data?.amount),
      vat: +convertComaInToDot(data?.vat),
      isExclusive: isExclusive,
      splitEnable: splitEnable,
      short_url: data.short_url,
      is_recursive: recursive,
    };
    const confirmEditQr = await dispatch(editQrDetailApi(Payload, paymentId));
    if (confirmEditQr) {
      setEditModalOpen(false);
      setIsExclusive(false);
      setSplitEnable(false);
      allQrPaymentList();
      setRecursive(true);
      setShortUrl("");
      editForm.resetFields();
      setValidUrlNameStatus(false);
      setSubmitBtnClicked(false)
    } else {
      setSubmitBtnClicked(false)
    }
  };

  // formate currency number according user local
  function formatNumberToLocalCurrency(amount, currency) {
    const browserLang =
      window.navigator.language || window.navigator.userLanguage;
    const formatter = new Intl.NumberFormat(browserLang, {
      style: "currency",
      currency: currency,
    });
    return formatter.format(amount);
  }

  const convertComaInToDot = (num) => {
    if (num !== 0 && num !== undefined && num !== null) {
      const numberWithDots = num.toString()?.replace(/,/g, ".");
      // Convert the result back to a number
      const resultNumber = parseFloat(numberWithDots)?.toFixed(2);
      return resultNumber;
    }
  };

  const handleDeleteSubmit = async () => {
    const confirmDelete = await dispatch(deleteQRPayment(paymentId));
    if (confirmDelete) {
      setOpenDeleteModal(false);
      setPaymentId("");
      allQrPaymentList();
      deleteForm.resetFields();
    }
  };

  return (
    <>
      <div className={style.interactionContainer}>
        <div className={style.interactionHeader}>
          <h1>{t("QR Payment")}</h1>
          {isStripeConnect == true ? (
            <div className={style.premiumBtnContainer}>
              <Button className={style.premiumBtn} onClick={showModal}>
                <div className={style.buttonContent}>
                  {t("Create QR Payment")}
                </div>
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* {isStripeConnect == true ? (
                    <> */}
        {loading ? (
          <>
            <Row gutter={[20, 20]}>
              {new Array(4).fill("").map((_, i) => {
                return (
                  <Col xxl={6} xl={8} lg={8} md={12} sm={12} xs={24} key={i}>
                    <div className={style.QrPaymentCard}>
                      <div className={style.skeletonInputMain}>
                        <Skeleton.Input
                          active={true}
                          size={"large"}
                          block={false}
                          style={{ marginBottom: "20px" }}
                        />
                      </div>

                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                      <div className={style.skeletonImgBox}>
                        <Skeleton.Image
                          active={true}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                      <div className={style.skeletonInputMain}>
                        <Skeleton.Input
                          active={true}
                          size={"large"}
                          block={false}
                          style={{ marginBottom: "20px" }}
                        />
                      </div>
                      <div className={style.skeletonInputMain}>
                        <Skeleton.Input
                          active={true}
                          // block={true}
                          style={{ marginTop: "10px", height: "20px" }}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        ) : isStripeConnect == true ? (
          qrList && qrList.length > 0 ? (
            <Row gutter={[20, 20]}>
              {qrList &&
                qrList.map((data, i) => {
                  return (
                    <>
                      <Col
                        key={i}
                        xxl={6}
                        xl={8}
                        lg={8}
                        md={12}
                        sm={12}
                        xs={24}
                      >
                        <div className={style.QrPaymentCard}>
                          <div className={style.QrCardHeader}>
                            <p>{data?.title}</p>
                            <div
                              className={style.iconBox}
                              onClick={(e) => {
                                setShowAction(!showAction);
                                setPaymentId(data._id);
                                e.preventDefault();
                              }}
                            >
                              <FeatherIcon icon="more-vertical" size={20} />
                            </div>
                            {showAction == true && paymentId == data._id ? (
                              <div className={style.actionContainer}>
                                <Tooltip
                                  title={t("View Payment Detail")}
                                  placement="top"
                                >
                                  <div
                                    className={style.viewActionIcon}
                                    onClick={() => {
                                      navigate(`/qrPayment-detail/${data._id}`);
                                      setShowAction(false);
                                    }}
                                  >
                                    <EyeOutlined />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title={t("Edit details")}
                                  placement="top"
                                >
                                  <div
                                    className={style.viewActionIcon}
                                    onClick={() => {
                                      showEditModal(data);
                                      setShowAction(false);
                                    }}
                                  >
                                    <FeatherIcon icon="edit" size={20} />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title={t("Reset Qr Payment")}
                                  placement="top"
                                >
                                  <div
                                    className={style.viewActionIcon}
                                    onClick={() => {
                                      showResetQrPaymentModal(data);
                                      setShowAction(false);
                                    }}
                                  >
                                    <QrcodeOutlined />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title={t("Delete QR Payment")}
                                  placement="top"
                                >
                                  <div
                                    className={style.viewActionIcon}
                                    onClick={() => {
                                      setOpenDeleteModal(data);
                                      setPaymentId(data._id);
                                    }}
                                  >
                                    <FeatherIcon icon="trash" size={20} />
                                  </div>
                                </Tooltip>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            onClick={() =>
                              navigate(`/qrPayment-detail/${data._id}`)
                            }
                          >
                            <div className={style.QrCardAmountDetail}>
                              <div className={style.QrCardAmount}>
                                <p>Split Payment</p>
                                <div className={style.paymentStatus}>
                                  {data?.splitEnable == true ? (
                                    <FeatherIcon
                                      icon="check-circle"
                                      size={25}
                                      className={style.successStatusMark}
                                    />
                                  ) : (
                                    <FeatherIcon
                                      icon="x-circle"
                                      size={25}
                                      className={style.crossStatusMark}
                                    />
                                  )}
                                  {/* {data?.splitEnable == true ? "True" : "False"} */}
                                </div>
                              </div>
                              <div className={style.QrCardAmountStatus}>
                                <p>Is Paid</p>
                                <div className={style.paymentStatus}>
                                  {data?.isPaid == true ? (
                                    <FeatherIcon
                                      icon="check-circle"
                                      size={25}
                                      className={style.successStatusMark}
                                    />
                                  ) : (
                                    <FeatherIcon
                                      icon="x-circle"
                                      size={25}
                                      className={style.crossStatusMark}
                                    />
                                  )}
                                  {/* {data?.isPaid == true ? "True" : "False"} */}
                                </div>
                              </div>
                            </div>
                            <div className={style.chargeableAmountBox}>
                              <p>Price</p>
                              <div className={style.chargeableAmount}>
                                {formatNumberToLocalCurrency(
                                  data?.amount,
                                  data?.countryObject?.currency
                                )}
                              </div>
                            </div>

                            <div className={style.QrCodeContainer}>
                              <div className={style.QrCode}>
                                <img src={data?.qr_code} />
                              </div>
                              <p className={style.payAmount}>
                                {t("Chargeable Amount")}
                              </p>
                              <div className={style.amountNum}>
                                {/* {formatCurrency(
                                          (data?.chargeableAmount / 100)?.toFixed(2),
                                          data?.countryObject?.currency
                                        )} */}
                                {formatNumberToLocalCurrency(
                                  data?.chargeableAmount / 100,
                                  data?.countryObject?.currency
                                )}
                              </div>
                              <p>
                                {t("Reset Count.")}{" "}
                                <strong>{data?.paymentNumber}</strong>
                                <Tooltip
                                  title={
                                    <p>
                                      {t(
                                        "when full amount is paid counter will be reset and new number will allocated. or you manually reset the payment information counter number will increase by 1."
                                      )}
                                    </p>
                                  }
                                >
                                  <FeatherIcon
                                    icon="alert-circle"
                                    className={style.resetInfoIcon}
                                  />
                                </Tooltip>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
            </Row>
          ) : (
            <center>
              <h3>{t("No QR payment Created yet!")}</h3>
            </center>
          )
        ) : (
          <div className={style.QrPaymentCard}>
            <Row justify="center">
              <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24}>
                <div className={style.noDataContent}>
                  <div className={style.noDataText}>
                    <h3>{t("You Are not Connected to Stripe!")}</h3>
                    <p>
                      {t(
                        "First create account on stripe to create your QR payment."
                      )}
                    </p>
                    <p className={style.refreshMsg}>
                      {t("(Refresh the page after successfully creating your account on stripe)")}
                    </p>
                  </div>
                  <div className={style.premiumBtnContainer}>
                    {connectStripeBtnClick == false ? (
                      <Button
                        className={style.addStripeBtn}
                        onClick={() => {
                          // getUserDetail();
                          setConnectStripeBtnClick(true);
                          window.open(userDetail?.flags?.stripe_connect_url);
                        }}
                      >
                        <div className={style.buttonContent}>
                          {t("Add Stripe Account")}
                        </div>
                      </Button>
                    ) : (
                      <Button
                        className={style.addStripeBtn}
                        onClick={() => {
                          getUserDetail();
                        }}
                      >
                        <div className={style.buttonContent}>
                          {t("Refresh Page")}
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
                <div className={style.noDataIMG}>
                  <img src={stripeQrCodeImg} />
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          open={open}
          title={t("Create QR Payment")}
          footer={null}
          onCancel={handleCancel}
          maskClosable={false}
          width={600}
        >
          {isStripeConnect == true ? (
            <>
              <Form
                layout="vertical"
                name="basic"
                autoComplete="nope"
                onFinish={(value) => handleSubmit(value)}
                className={style.interactionForm}
                form={form}
              >
                <Form.Item
                  label={t("Title")}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t("Please enter payment title!"),
                    },
                  ]}
                >
                  <Input size="large" placeholder={t("Enter title")} />
                </Form.Item>
                <Form.Item label={t("Description")} name="description">
                  <TextArea placeholder={t("Enter description")} rows={3} />
                </Form.Item>
                <Row gutter={[20, 20]}>
                  <Col xxl={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={t("Amount") + " " + `(${currency})`}
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: t("Please enter payment amount!"),
                        },
                        () => ({
                          validator(_, value) {
                            const sum =
                              value !== "" &&
                              value
                                .toString()
                                .split("")
                                .map(Number)
                                .reduce(function (a, b) {
                                  return a + b;
                                }, 0);
                            if (sum === 0 || value < 0) {
                              return Promise.reject(
                                t("Enter valid Amount, it can't be 0.")
                              );
                            }
                            if (convertComaInToDot(value) < 0.5) {
                              return Promise.reject(
                                `${t("Amount must be at least")} ${formatNumberToLocalCurrency(
                                  0.5,
                                  userDetail?.countryObject?.currency
                                )} .`
                              );
                            }
                            // shows error if try to use dot or comma together
                            if (
                              /^-?\d+(\.\d+)?$/.test(value) == false &&
                              /^-?\d+(\,\d+)?$/.test(value) == false
                            ) {
                              return Promise.reject(
                                t("Please enter valid amount.")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        pattern="[0-9]"
                        inputMode="decimal"
                        className={style.profileInputFiled}
                        placeholder={t("Add Amount")}
                        onKeyDown={(evt) => {
                          var theEvent = evt || window.event;
                          var key = theEvent.keyCode || theEvent.which;
                          var keyCode = key;
                          key = String.fromCharCode(key);
                          if (key.length == 0) return;
                          var regex = /^[0-9.,\b]+$/;
                          if (keyCode == 188 || keyCode == 190) {
                            return;
                          } else {
                            if (!regex.test(key)) {
                              theEvent.returnValue = false;
                              if (theEvent.preventDefault)
                                theEvent.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={t("VAT(%)")}
                      name="vat"
                      rules={[
                        {
                          required: true,
                          message: t("Please Enter vat"),
                        },
                        () => ({
                          validator(_, value) {
                            // shows error if try to use dot or comma together
                            if (
                              /^-?\d+(\.\d+)?$/.test(value) == false &&
                              /^-?\d+(\,\d+)?$/.test(value) == false
                            ) {
                              return Promise.reject(
                                t("Please enter valid amount.")
                              );
                            }
                            if (value > 100) {
                              return Promise.reject(
                                t("Vat can't be more than 100%")
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Add vat"
                        pattern="[0-9]"
                        inputMode="decimal"
                        className={style.profileInputFiled}
                        onKeyDown={(evt) => {
                          var theEvent = evt || window.event;
                          var key = theEvent.keyCode || theEvent.which;
                          var keyCode = key;
                          key = String.fromCharCode(key);
                          if (key.length == 0) return;
                          var regex = /^[0-9.,\b]+$/;
                          if (keyCode == 188 || keyCode == 190) {
                            return;
                          } else {
                            if (!regex.test(key)) {
                              theEvent.returnValue = false;
                              if (theEvent.preventDefault)
                                theEvent.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[20, 20]}>
                  <Col xxl={12} md={12} sm={24} xs={24}>
                    <Form.Item name="isExclusive" className="interactionSwitch">
                      <p className={style.switchLabelText}>
                        {t("Is VAT Exclusive")} ?
                      </p>
                      <Switch
                        checked={isExclusive}
                        onChange={(e) => {
                          setIsExclusive(e);
                        }}
                        className="interactionSwitch"
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                    <Form.Item name="splitEnable">
                      <p className={style.switchLabelText}>
                        {t("Split Enable")} ?
                      </p>
                      <Radio.Group
                        onChange={(e) => {
                          setSplitEnable(e.target.value);
                        }}
                        value={splitEnable}
                        className="customRadioBtn"
                      >
                        <Radio value={true}>{t("Yes")}</Radio>
                        <Radio value={false}>{t("No")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="is_recursive" className="interactionSwitch">
                  <p className={style.switchLabelText}>
                    {t("Is QR Payment Recursive")} ?
                    <Tooltip
                      title={
                        <p>
                          {t(
                            "When user will pay full amount on QR code, it will be auto reset and no need to reset from admin panel."
                          )}
                        </p>
                      }
                    >
                      <FeatherIcon
                        icon="alert-circle"
                        className={style.resetInfoIcon}
                      />
                    </Tooltip>
                  </p>
                  <Switch
                    checked={recursive}
                    onChange={(e) => {
                      setRecursive(e);
                    }}
                    className="interactionSwitch"
                  />
                </Form.Item>

                <Form.Item
                  name="short_url"
                  label={t("Short URL")}
                  hasFeedback
                  validateStatus={
                    submitStatus
                      ? shortUrl == "" && validUrlStatus
                        ? "error"
                        : ""
                      : shortUrl == ""
                        ? ""
                        : urlLoading
                          ? "validating"
                          : validUrlStatus
                            ? "success"
                            : "error"
                  }
                  help={
                    submitStatus
                      ? shortUrl == "" && validUrlStatus
                        ? t("Please enter url!")
                        : ""
                      : shortUrl == ""
                        ? ""
                        : urlLoading
                          ? ""
                          : validUrlStatus
                            ? ""
                            : t("This short Url is not available.")
                  }
                  rules={[
                    {
                      required: true,
                      message: t("Please Enter short url"),
                    },
                    {
                      pattern: new RegExp(
                        /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                      ),
                      message: t(
                        t("URL can not contain whitespace or upper case letters")
                      ),
                    },
                  ]}
                >
                  <Input
                    className={style.profileInputFiled}
                    style={{ padding: "0px 8px" }}
                    prefix={
                      <>
                        <b className={style.backslash}>{WEBSITE_URL}</b>
                      </>
                    }
                    defaultValue={shortUrl}
                    onChange={(e) => {
                      onchangeShortUrl(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.code === "Space") {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault()
                    }}
                    onInput={(e) =>
                    (e.target.value = (
                      "" + e.target.value
                    ).toLocaleLowerCase())
                    }
                    placeholder={t("Enter url")}
                    disabled={false}
                    autoFocus={true}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Country")}
                  name="country_iso_code"
                  help={
                    submitStatus && countryValue == ""
                      ? t("Please select your country!")
                      : ""
                  }
                  rules={[
                    {
                      required: countryValue == "" ? true : false,
                      message: t("Please select your country!"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ height: "50px", textAlign: "center" }}
                    loading={countryListLoading}
                    placeholder={t("Select your country")}
                    optionFilterProp="children"
                    required
                    onChange={(value) => {
                      setCountryValue(value);
                    }}
                    defaultValue={getItem("country_details")?.country_name}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countryNameList}
                  />
                </Form.Item>
                <center>
                  <i>
                    {t("Please fill all mandatory fields")}
                    <span style={{ color: "red" }}>(*)</span> {t("before submit.")}
                  </i>
                </center>
                <div className={style.createBtnContainer}>
                  <Button className={style.cancelBtn} onClick={handleCancel}>
                    <div className={style.buttonContent}>{t("Cancel")}</div>
                  </Button>
                  <Button className={style.createBtn}
                    onClick={(e) => {
                      if (submitBtnClicked == false) {
                        form.submit()
                      } else {
                        e.preventDefault()
                      }
                    }}
                  >
                    <div className={style.buttonContent}>
                      {submitBtnClicked ?
                        <Spin size="default" className="whiteSpin" />
                        :
                        <div className={style.buttonContent}>{t("Submit")}</div>
                      }
                    </div>
                  </Button>
                </div>
              </Form>
            </>
          ) : (
            <>
              <h3 className={style.stripeConnectWarning}>
                {t("You Do not have account on Stripe.")}
                <br />
                {t("First create account on stripe to create your QR payment.")}
              </h3>
              <div className={style.createBtnContainer}>
                <Button className={style.cancelBtn} onClick={handleCancel}>
                  <div className={style.buttonContent}>{t("Cancel")}</div>
                </Button>
                <Button
                  className={style.createBtn}
                  onClick={() => {
                    window.open(userDetail?.flags?.stripe_connect_url);
                    handleCancel();
                  }}
                >
                  <div className={style.buttonContent}>{t("Add Stripe")}</div>
                </Button>
              </div>
            </>
          )}
        </Modal>

        <Modal
          footer={null}
          title={t("Reset Qr Payment")}
          open={showResetQrModal}
          onCancel={resetCancel}
          width={500}
        >
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            onFinish={(value) => handleResetSubmit(value)}
            className={style.interactionForm}
            form={resetForm}
          >
            <Form.Item label={t("Title")} name="title">
              <Input size="large" placeholder={t("Enter title")} />
            </Form.Item>
            <Form.Item
              label={t("Amount") + " " + `(${currency})`}
              name="amount"
              rules={[
                {
                  required: true,
                  message: t("Please enter payment amount!"),
                },
                () => ({
                  validator(_, value) {
                    const sum =
                      value !== "" &&
                      value
                        .toString()
                        .split("")
                        .map(Number)
                        .reduce(function (a, b) {
                          return a + b;
                        }, 0);
                    if (sum === 0 || value < 0) {
                      return Promise.reject(
                        t("Enter valid Amount, it can't be 0.")
                      );
                    }
                    if (convertComaInToDot(value) < 0.5) {
                      return Promise.reject(
                        `${t("Amount must be at least")} ${formatNumberToLocalCurrency(
                          0.5,
                          userDetail?.countryObject?.currency
                        )} .`
                      );
                    }
                    // shows error if try to use dot or comma together
                    if (
                      /^-?\d+(\.\d+)?$/.test(value) == false &&
                      /^-?\d+(\,\d+)?$/.test(value) == false
                    ) {
                      return Promise.reject(t("Please enter valid amount."));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                pattern="[0-9]"
                inputMode="decimal"
                className={style.profileInputFiled}
                placeholder={t("Add Amount")}
                defaultValue={qrList?.amount}
                onKeyDown={(evt) => {
                  var theEvent = evt || window.event;
                  var key = theEvent.keyCode || theEvent.which;
                  var keyCode = key;
                  key = String.fromCharCode(key);
                  if (key.length == 0) return;
                  var regex = /^[0-9.,\b]+$/;
                  if (keyCode == 188 || keyCode == 190) {
                    return;
                  } else {
                    if (!regex.test(key)) {
                      theEvent.returnValue = false;
                      if (theEvent.preventDefault) theEvent.preventDefault();
                    }
                  }
                }}
              />
            </Form.Item>

            <Form.Item name="isExclusive" className="interactionSwitch">
              <p className={style.switchLabelText}>{t("Is Exclusive")} ?</p>
              <Switch
                checked={isExclusive}
                onChange={(e) => {
                  setIsExclusive(e);
                }}
                className="interactionSwitch"
              />
            </Form.Item>

            <Form.Item
              label={t("VAT(%)")}
              name="vat"
              rules={[
                // {
                //   pattern: new RegExp(/^([1-9]?\d|100)$/),
                //   message: "Vat can't be more than 100% !!",
                // },
                () => ({
                  validator(_, value) {
                    // shows error if try to use dot or comma together
                    if (
                      /^-?\d+(\.\d+)?$/.test(value) == false &&
                      /^-?\d+(\,\d+)?$/.test(value) == false
                    ) {
                      return Promise.reject(t("Please enter valid amount."));
                    }
                    if (value > 100) {
                      return Promise.reject(t("Vat can't be more than 100%"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder={t("Add vat")}
                pattern="[0-9]"
                inputMode="decimal"
                defaultValue={qrList?.vat}
                className={style.profileInputFiled}
                onKeyDown={(evt) => {
                  var theEvent = evt || window.event;
                  var key = theEvent.keyCode || theEvent.which;
                  var keyCode = key;
                  key = String.fromCharCode(key);
                  if (key.length == 0) return;
                  var regex = /^[0-9.,\b]+$/;
                  if (keyCode == 188 || keyCode == 190) {
                    return;
                  } else {
                    if (!regex.test(key)) {
                      theEvent.returnValue = false;
                      if (theEvent.preventDefault) theEvent.preventDefault();
                    }
                  }
                }}
              />
            </Form.Item>

            <div className={style.createBtnContainer}>
              <Button className={style.cancelBtn} onClick={resetCancel}>
                <div className={style.buttonContent}>{t("Cancel")}</div>
              </Button>
              <Button className={style.createBtn}
                onClick={(e) => {
                  if (submitBtnClicked == false) {
                    resetForm.submit()
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <div className={style.buttonContent}>
                  {submitBtnClicked ?
                    <Spin size="default" className="whiteSpin" />
                    :
                    <div className={style.buttonContent}>{t("Submit")}</div>
                  }
                </div>
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          width={600}
          title={t("Edit QR Payment")}
          open={editModalOpen}
          onCancel={handleEditCancel}
          footer={null}
          maskClosable={false}
        >
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            onFinish={(value) => handleEditSubmit(value)}
            className={style.interactionForm}
            form={editForm}
          >
            <Form.Item label={t("Title")} name="title">
              <Input size="large" placeholder={t("Enter title")} />
            </Form.Item>
            <Form.Item label={t("Description")} name="description">
              <TextArea placeholder={t("Enter description")} rows={3} />
              {/* <Input size="large" placeholder="Enter description" /> */}
            </Form.Item>
            <Row gutter={[20, 20]}>
              <Col xxl={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={t("Amount") + " " + `(${currency})`}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: t("Please enter payment amount!"),
                    },
                    () => ({
                      validator(_, value) {
                        const sum =
                          value !== "" &&
                          value
                            .toString()
                            .split("")
                            .map(Number)
                            .reduce(function (a, b) {
                              return a + b;
                            }, 0);
                        if (sum === 0 || value < 0) {
                          return Promise.reject(
                            t("Enter valid Amount, it can't be 0.")
                          );
                        }
                        if (convertComaInToDot(value) < 0.5) {
                          return Promise.reject(
                            `${t("Amount must be at least")} ${formatNumberToLocalCurrency(
                              0.5,
                              userDetail?.countryObject?.currency
                            )} .`
                          );
                        }
                        // shows error if try to use dot or comma together
                        if (
                          /^-?\d+(\.\d+)?$/.test(value) == false &&
                          /^-?\d+(\,\d+)?$/.test(value) == false
                        ) {
                          return Promise.reject(t("Please enter valid amount."));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    pattern="[0-9]"
                    inputMode="decimal"
                    className={style.profileInputFiled}
                    placeholder={t("Add Amount")}
                    onKeyDown={(evt) => {
                      var theEvent = evt || window.event;
                      var key = theEvent.keyCode || theEvent.which;
                      var keyCode = key;
                      key = String.fromCharCode(key);
                      if (key.length == 0) return;
                      var regex = /^[0-9.,\b]+$/;
                      if (keyCode == 188 || keyCode == 190) {
                        return;
                      } else {
                        if (!regex.test(key)) {
                          theEvent.returnValue = false;
                          if (theEvent.preventDefault)
                            theEvent.preventDefault();
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label={t("VAT(%)")}
                  name="vat"
                  rules={[
                    {
                      required: true,
                      message: t("Please Enter vat"),
                    },
                    // {
                    //   pattern: new RegExp(/^([1-9]?\d|100)$/),
                    //   message: "Vat can't be more than 100% !!",
                    // },
                    () => ({
                      validator(_, value) {
                        // shows error if try to use dot or comma together
                        if (
                          /^-?\d+(\.\d+)?$/.test(value) == false &&
                          /^-?\d+(\,\d+)?$/.test(value) == false
                        ) {
                          return Promise.reject(t("Please enter valid amount."));
                        }
                        if (value > 100) {
                          return Promise.reject(t("Vat can't be more than 100%"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={t("Add vat")}
                    pattern="[0-9]"
                    inputMode="decimal"
                    className={style.profileInputFiled}
                    onKeyDown={(evt) => {
                      var theEvent = evt || window.event;
                      var key = theEvent.keyCode || theEvent.which;
                      var keyCode = key;
                      key = String.fromCharCode(key);
                      if (key.length == 0) return;
                      var regex = /^[0-9.,\b]+$/;
                      if (keyCode == 188 || keyCode == 190) {
                        return;
                      } else {
                        if (!regex.test(key)) {
                          theEvent.returnValue = false;
                          if (theEvent.preventDefault)
                            theEvent.preventDefault();
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 20]}>
              <Col xxl={12} md={12} sm={24} xs={24}>
                <Form.Item name="isExclusive" className="interactionSwitch">
                  <p className={style.switchLabelText}>{t("Is Exclusive")} ?</p>
                  <Switch
                    checked={isExclusive}
                    onChange={(e) => {
                      setIsExclusive(e);
                    }}
                    className="interactionSwitch"
                  />
                </Form.Item>
              </Col>
              <Col xxl={12} md={12} sm={24} xs={24} span={24}>
                <Form.Item name="splitEnable">
                  <p className={style.switchLabelText}>{t("Split Enable")} ?</p>
                  <Radio.Group
                    onChange={(e) => {
                      setSplitEnable(e.target.value);
                    }}
                    value={splitEnable}
                    className="customRadioBtn"
                  >
                    <Radio value={true}>{t("Yes")}</Radio>
                    <Radio value={false}>{t("No")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="is_recursive" className="interactionSwitch">
              <p className={style.switchLabelText}>
                {t("Is QR Payment Recursive")} ?
                <Tooltip
                  title={
                    <p>
                      {t(
                        "When user will pay full amount on QR code, it will be auto reset and no need to reset from admin panel."
                      )}
                    </p>
                  }
                >
                  <FeatherIcon
                    icon="alert-circle"
                    className={style.resetInfoIcon}
                  />
                </Tooltip>
              </p>
              <Switch
                checked={recursive}
                onChange={(e) => {
                  setRecursive(e);
                }}
                className="interactionSwitch"
              />
            </Form.Item>

            <Form.Item
              name="short_url"
              label={t("Short URL")}
              hasFeedback
              validateStatus={
                submitStatus
                  ? shortUrl == "" && validUrlStatus
                    ? "error"
                    : ""
                  : shortUrl == ""
                    ? ""
                    : urlLoading
                      ? "validating"
                      : validUrlStatus
                        ? "success"
                        : "error"
              }
              help={
                submitStatus
                  ? shortUrl == "" && validUrlStatus
                    ? t("Please enter url!")
                    : ""
                  : shortUrl == ""
                    ? ""
                    : urlLoading
                      ? ""
                      : validUrlStatus
                        ? ""
                        : t("This short Url is not available.")
              }
              rules={[
                {
                  required: true,
                  message: t("Please Enter short url"),
                },
                {
                  pattern: new RegExp(/^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/),
                  message: t(
                    "URL can not contain whitespace or upper case letters"
                  ),
                },
              ]}
            >
              <Input
                className={style.profileInputFiled}
                style={{ padding: "0px 8px" }}
                prefix={
                  <>
                    <b className={style.backslash}>{WEBSITE_URL}</b>
                  </>
                }
                onPaste={(e) => {
                  e.preventDefault()
                }}
                defaultValue={shortUrl}
                onChange={(e) => {
                  onchangeShortUrl(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.code === "Space") event.preventDefault();
                }}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value).toLocaleLowerCase())
                }
                placeholder={t("Enter url...")}
                disabled={false}
                autoFocus={true}
              />
            </Form.Item>
            <center>
              <i>
                {t("Please fill all mandatory fields")}
                <span style={{ color: "red" }}>(*)</span> {t("before submit.")}
              </i>
            </center>
            <div className={style.createBtnContainer}>
              <Button className={style.cancelBtn} onClick={handleEditCancel}>
                <div className={style.buttonContent}>{t("Cancel")}</div>
              </Button>
              <Button className={style.createBtn}
                onClick={(e) => {
                  if (submitBtnClicked == false) {
                    editForm.submit()
                  } else {
                    e.preventDefault()
                  }
                }}
              >
                <div className={style.buttonContent}>
                  {submitBtnClicked ?
                    <Spin size="default" className="whiteSpin" />
                    :
                    <div className={style.buttonContent}>{t("Submit")}</div>
                  }
                </div>
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          width={500}
          title={t("Delete QR Payment")}
          open={openDeleteModal}
          onCancel={() => {
            setOpenDeleteModal(false);
            setPaymentId("");
          }}
          centered={true}
          footer={null}
        >
          <h3 className={style.deleteSlotText}>
            {t("Are you sure you wants to delete this QR Payment?")}
          </h3>
          <div className={style.createBtnContainer}>
            <Button
              className={style.cancelBtn}
              onClick={() => {
                setOpenDeleteModal(false);
                setPaymentId("");
              }}
            >
              <div className={style.buttonContent}>{t("Cancel")}</div>
            </Button>
            <Button className={style.createBtn} onClick={handleDeleteSubmit}>
              <div className={style.buttonContent}>{t("Confirm")}</div>
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default QRPayment;
