import { useState, useRef, useEffect } from "react";
import Styles from "./AddInteraction.module.css";
import recordBtn from "../../../../assets/Images/logo-audacity-svgrepo-com.svg";
import recordActiveBtn from "../../../../assets/Images/white-audio-icon.svg";
import stopBtn from "../../../../assets/Images/stop-circle-svgrepo-com.svg";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { setItem } from "../../../../utility/LocalStorageControl";
import { useTranslation } from "react-i18next";
import { message, Modal, Row, Col, Button, Progress } from "antd";
import audioMicrophone from "../../../../assets/Images/microphone-audio-recording.svg";
const mimeType = "audio/mpeg-3";
const videoColor = {
  "0%": "#E60C8F",
  "100%": "#E60C8F",
};

function AudioRecord(props) {
  const { t } = useTranslation();
  let { removeAudio, audioFile, timerCount } = props;
  const [permission, setPermission] = useState(true);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [disabledStop, setDisabledStop] = useState(true);
  const [disabledRecord, setDisabledRecord] = useState(false);
  const [count, setCount] = useState();
  const [time, setTime] = useState({ minutes: 2, seconds: "00" });
  const [timer, setTimer] = useState();
  const [isAnimating, setIsAnimating] = useState(true);
  const [selectedCommentType, setSelectedCommentType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countPercentage, setCountPercentage] = useState(0);

  useEffect(() => {
    if (count > -1) {
      const countdownInterval = setInterval(() => {
        setCount(count - 1);
      }, 1500);
      if (count == 4) {
        setCountPercentage(0);
      } else if (count == 3) {
        setCountPercentage(33);
      } else if (count == 2) {
        setCountPercentage(66);
      } else if (count == 1) {
        setCountPercentage(100);
      }
      if (count == 0) {
        setTime({ minutes: 2, seconds: "00" });
        startCountdown();
      }
      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setIsAnimating(false);
    }
  }, [count]);

  const startCountdown = () => {
    setTimeout(() => {
      startRecording();
    }, 1500);
  };

  useEffect(() => {
    if (timer) {
      setItem("timer", timer.toString());
      const interval = setInterval(() => {
        if (timer > -1) {
          setTimer(timer - 1);
        }
      }, 1000);
    }
  }, [timer]);

  const getVideoPermission = async () => {
    setCountPercentage(0);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setStream(stream);
      setSelectedCommentType("audio");
      setCount(3);
    } catch (err) {
      if (err == "NotAllowedError: Permission denied") {
        setPermission(false);
        setSelectedCommentType("PermissionBlocked");
        message.warning("Please enable microphone permissions");
      } else {
        message.error("Requested device not found");
      }
    }
  };

  const startRecording = async (e) => {
    try {
      const media = new MediaRecorder(stream, { type: mimeType });
      mediaRecorder.current = media;
      mediaRecorder.current.start();
      let localAudioChunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };
      setAudioChunks(localAudioChunks);
      setDisabledRecord(true);
      setDisabledStop(false);
      setPermission(true);
      setRecordingStatus("recording");
    } catch (error) {
      message.warning("Please enable microphone permissions");
    }
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      audioFile(audioBlob);
      setAudioChunks([]);
      setDisabledStop(true);
      setDisabledRecord(false);
      setCount();
    };
  };

  const removeAudioFile = () => {
    setPermission(false);
    setStream(null);
    setRecordingStatus("inactive");
    setAudio(null);
    setDisabledStop(true);
    setDisabledRecord(false);
    removeAudio();
    audioFile(null);
    setIsModalOpen(false);
    setCount();
    setCountPercentage(0);
  };

  // delete file modal
  const handleCancel = () => {
    setIsModalOpen(false);
    setCountPercentage(0);
  };

  return (
    <div className={Styles.audionMainContainer}>
      {/* <h2>{t("your_audio_interaction")}</h2> */}
      <main>
        <div className={Styles.audioControls}>
          {/* {!permission ? (
                        <div className={Styles.recordingGroupBtn}>
                            <button
                                // onClick={getMicrophonePermission}
                                type="button"
                                className={Styles.PermissionBtn}
                            >
                                <div className={Styles.btnIcon}>
                                    <img src={microphoneBtn} />
                                </div>
                                <span>{t('Get_microphone')}</span>
                            </button>
                        </div>
                    ) : null} */}
          {selectedCommentType == "PermissionBlocked" && !permission ? (
            <div className={Styles.permissionBlockedContainer}>
              <div className={Styles.HeaderInteractionContainer}>
                <div
                  className={Styles.cancelTextDiv}
                  onClick={() => {
                    setSelectedCommentType("");
                  }}
                >
                  <FeatherIcon
                    icon="x-circle"
                    style={{ color: "#631deb" }}
                    size={20}
                  />
                </div>
              </div>
              <div className={Styles.blockedPermissionInfo}>
                <h3>
                  {t("Please enable camera")} &amp;{" "}
                  {t("microphone permissions")}
                </h3>
                <p className={Styles.message_one}>
                  {t("(You may have accidentally said no)")}
                </p>
                <h4>{t("How to fix?")}</h4>
                <p className={Styles.fixedMessage}>
                  {t(
                    "Click the 🔒lock icon in your browser's address bar, set the correct permissions and then refresh this page."
                  )}
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className={Styles.audioRecordingMainContainer}>
                {audio == null && recordingStatus == "recording" ? (
                  <div className={Styles.interactionAudioWavesContainer}></div>
                ) : (
                  <>
                    <div className={Styles.videoRecordImg}>
                      <img src={audioMicrophone}></img>
                    </div>
                    {count > 0 && recordingStatus == "inactive" ? (
                      <div className={Styles.videoCountDownContainer}>
                        <Progress
                          type="circle"
                          size={150}
                          className={Styles.recordingCountDownProgress}
                          percent={countPercentage}
                          strokeColor={videoColor}
                          format={() => {
                            return countPercentage <= 33 ? (
                              <p className={"videoCountNum"}>3</p>
                            ) : countPercentage <= 66 ? (
                              <p className={"videoCountNum"}>2</p>
                            ) : countPercentage <= 99 ? (
                              <p className={"videoCountNum"}>1</p>
                            ) : (
                              <p className={"videoCountNum"}>1</p>
                            );
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {audio && (
                      <>
                        <div className={Styles.audioView}>
                          <audio
                            id="recordedAudio"
                            src={audio}
                            controls
                            autoPlay
                          ></audio>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className={Styles.recordingGroupBtn}>
                {recordingStatus == "recording" ? (
                  <>
                    <button
                      id="record"
                      onClick={startRecording}
                      disabled={disabledRecord}
                      className={
                        recordingStatus == "recording"
                          ? `${Styles.activeBtn}`
                          : `${Styles.PermissionBtn}`
                      }
                    >
                      <div className={Styles.btnIcon}>
                        <img src={recordActiveBtn} />
                      </div>
                      <span>{t("Recording")}</span>
                    </button>
                    <button
                      id="stopRecord"
                      onClick={stopRecording}
                      disabled={disabledStop}
                      className={
                        recordingStatus == "inactive" && disabledRecord == true
                          ? `${Styles.activeBtn}`
                          : `${Styles.PermissionBtn}`
                      }
                    >
                      <div className={Styles.btnIcon}>
                        <img src={stopBtn} />
                      </div>
                      <span>{t("Stop")}</span>
                    </button>
                  </>
                ) : recordingStatus == "inactive" && audio == null ? (
                  <button
                    id="record"
                    onClick={getVideoPermission}
                    disabled={disabledRecord}
                    className={
                      recordingStatus == "recording"
                        ? `${Styles.activeBtn}`
                        : `${Styles.PermissionBtn}`
                    }
                  >
                    <div className={Styles.btnIcon}>
                      <img src={recordBtn} />
                    </div>
                    <span>{t("Start Recording")}</span>
                  </button>
                ) : (
                  audio && (
                    <div className={Styles.removeRecording}>
                      <FeatherIcon
                        icon="trash"
                        size={20}
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>

        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered={true}
          footer={false}
          width={600}
        >
          <div className={Styles.signOutConfirm}>
            <h3>{t("Are your sure you wants to delete this recording")}?</h3>
            <div className={Styles.modalBtnContainer}>
              <Row>
                <Col span={12}>
                  <Button className={Styles.cancelBtn} onClick={handleCancel}>
                    <div className={Styles.buttonContent}>{t("Cancel")}</div>
                  </Button>
                </Col>
                <Col span={12}>
                  <div className={Styles.premiumBtnContainer}>
                    <Button
                      className={Styles.premiumBtn}
                      onClick={removeAudioFile}
                    >
                      <div className={Styles.buttonContent}>{t("Confirm")}</div>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </main>
    </div>
  );
}

export default AudioRecord;
