import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";
import { getItem } from "../../utility/LocalStorageControl";

// SUBSCRIPTION LIST API CALL
export const getSubScriptionList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.subscription.list + getItem("userDetails")?.countryObject?.iso
      );
      if (!response.data.error) {
        dispatch({
          type: API.subscription.list,
          subScriptionList: response?.data?.data,
          loading: true,
        });
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// SUBSCRIPTION LIST API CALL
export const purchaseSubScription = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.subscription.purchaseForIndia,
        payload
      );
      if (!response.data.error) {
        if (response?.data?.data?.statusCode == 400) {
          message.error(response?.data?.data?.raw?.message);
          return false;
        } else {
          window.location.replace(response.data.data?.url);
          return true;
        }
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
