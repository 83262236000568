import React, { useEffect, useState } from "react";
import styles from "./CalendarDetail.module.css";
import {
  Row, Col, Button, Modal, Form,
  Input, Radio,
  Divider,
  Spin, Tooltip
} from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMyBookingDetail, checkInBookingApi, refundRequestApi } from "../../../../redux/myBooking/action";
import { useDispatch } from "react-redux";
// import moment from "moment";
import {
  getUserDetailData,
} from "../../../../redux/profileSetting/action";
import { getItem } from "../../../../utility/LocalStorageControl";
import { useForm } from "antd/es/form/Form";
import { CancelMyBookingAdminSideApi, EndPurchasePackage, RefundPurchaseAmount, CancelBookingUserSideApi } from "../../../../redux/booking/action";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration'
import moment from 'moment-timezone';
import { QrcodeOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";

function CalendarDetail() {
  var today = new Date();
  dayjs.extend(utc)
  dayjs.extend(tz)
  dayjs.extend(duration);
  var timeZone = dayjs.tz.guess()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState({});
  const [openCancelBooking, setOpenCancelBooking] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const { TextArea } = Input;
  const [cancelForm] = Form.useForm();
  const [userDetail, setUserDetail] = useState(getItem("userDetails"));
  const [refundRequestForm] = Form.useForm()
  const [refundRequestModal, setRefundRequestModal] = useState(false)
  const [confirmRefundForm] = Form.useForm()
  const [confirmRefundModal, setConfirmRefundModal] = useState(false)
  const [refundFullAmount, setRefundFullAmount] = useState(false)
  const [userLocal, setUserLocal] = useState("en-US");
  const [loading, setLoading] = useState(false);
  const [endPackModal, setEndPackModal] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState("");


  useEffect(() => {
    const browserLang =
      window.navigator.language || window.navigator.userLanguage || "en-US";
    setUserLocal(browserLang);
    getUserDetail()
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    let result = await dispatch(
      getMyBookingDetail(window.location.pathname?.split("/").pop())
    );
    if (result) {
      setLoading(false);
      setBookingDetails(result);
    } else {
      setBookingDetails({});
      setLoading(false);
    }
  };

  const getUserDetail = async () => {

    let val = getItem("userDetails")?.username
    let username = val && val ? val : Cookies.get("username")

    let response = await dispatch(
      getUserDetailData(username));
    if (response) {
      setUserDetail(response?.data);
    }
  };

  // cancel package (admin side)
  const handleCancelBooking = async (val) => {
    const payload = {
      cancel_reason: val?.cancel_reason
    }
    // admin side cancel
    if (bookingDetails?.user_id?._id !== userDetail?._id) {
      const confirmCancel = await dispatch(CancelMyBookingAdminSideApi(bookingDetails?._id, payload))
      if (confirmCancel?.data) {
        getDetails()
        setOpenCancelBooking(false)
        if (bookingDetails?.is_paid == true) {
          setConfirmRefundModal(true)
        } else { }
        cancelForm.resetFields()
      } else {
        setOpenCancelBooking(false)
        cancelForm.resetFields()
      }
    } else {
      // user side cancel
      const confirmCancel = await dispatch(CancelBookingUserSideApi(bookingDetails?._id, payload))
      if (confirmCancel?.data) {
        getDetails()
        setOpenCancelBooking(false)
        cancelForm.resetFields()
      } else {
        setOpenCancelBooking(false)
        cancelForm.resetFields()
      }
    }

  }


  // checkIn booking package (user side)
  const checkInBookingFunction = async () => {
    const payload = {
      booking_purchase_id: bookingDetails?._id
    }
    const confirmCheckIn = await dispatch(checkInBookingApi(payload))
    if (confirmCheckIn?.data) {
      getDetails()
    }
  }

  // end pack (admin side)
  const EndBookingPurchasePackage = async () => {
    const payload = {
      booking_purchase_id: bookingDetails?._id
    }
    const confirmCheckIn = await dispatch(EndPurchasePackage(payload))
    if (confirmCheckIn?.data) {
      getDetails()
      setEndPackModal(false)
    }
  }

  // request refund reason (user side)
  const submitRefundRequest = async (val) => {
    const payload = {
      refund_reason: val?.refund_reason
    }
    const submitRefundReason = await dispatch(refundRequestApi(bookingDetails?._id, payload))
    if (submitRefundReason) {
      getDetails()
      setRefundRequestModal(false)
      refundRequestForm.resetFields()
    } else {
      setRefundRequestModal(false)
      refundRequestForm.resetFields()
    }
  }

  // return refund amount 
  const handleRefundAmount = async (val) => {
    if (bookingDetails?.status == 'refund_request') {
      const payload = {
        booking_purchase_id: bookingDetails?._id,
        is_refund_full_amount: refundFullAmount
      }
      if (refundFullAmount == false) {
        payload.refund_amount = + convertComaInToDot(val.refund_amount)
      }
      const submitRefundReason = await dispatch(RefundPurchaseAmount(payload))
      if (submitRefundReason?.data) {
        getDetails()
        setConfirmRefundModal(false);
        refundRequestForm.resetFields();
        setRefundFullAmount(false)
      } else {
        setConfirmRefundModal(false);
        refundRequestForm.resetFields();
        setRefundFullAmount(false)
      }
    } else {
      const payload = {
        booking_purchase_id: bookingDetails?._id,
        is_charge_cancellation_fee: refundFullAmount
      }
      if (refundFullAmount == true) {
        payload.cancellation_fee = + convertComaInToDot(val.cancellation_fee)
      }
      const submitRefundReason = await dispatch(RefundPurchaseAmount(payload))
      if (submitRefundReason?.data) {
        getDetails()
        setConfirmRefundModal(false);
        refundRequestForm.resetFields();
        setRefundFullAmount(false)
      } else {
        setConfirmRefundModal(false);
        refundRequestForm.resetFields();
        setRefundFullAmount(false)
      }
    }
  }

  function formatNumberToLocalCurrency(amount, currency) {
    const formatter = new Intl.NumberFormat(userLocal.toString(), {
      style: "currency",
      currency: currency || "USD",
    });
    return formatter.format(amount);
  }

  const convertComaInToDot = (num) => {
    if (num !== 0 && num !== undefined && num !== null) {
      const numberWithDots = num.toString()?.replace(/,/g, ".");
      // Convert the result back to a number
      const resultNumber = parseFloat(numberWithDots)?.toFixed(2);
      return resultNumber;
    }
  };

  return (
    <div className={styles.calendarDetailContainer}>
      <div
        className={styles.backBtn}
        onClick={() =>
          navigate(location?.state ? location?.state?.pathname : "/calendar")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="rgb(206 19 119 / 54%)"
          height="inherit"
          width="inherit"
          style={{
            objectFit: "cover",
          }}
          version="1.1"
          id="Layer_1"
          viewBox="0 0 512 512"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0" />

          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />

          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <g>
                {" "}
                <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
      <div>
        <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className={styles.calenderDetailCard}>{
              loading ?
                (
                  <div className={styles.loaderBox}>
                    <Spin size='large' />
                  </div>
                )
                :
                (<>
                  <div className={styles.imageTitleContent}>
                    <div className={styles.interactionThumbnail}>
                      <img
                        src={
                          bookingDetails?.booking_id?.poster_image == ""
                            ? "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
                            : bookingDetails?.booking_id?.poster_image
                        }
                        className={styles.headPhoneIcon}
                      />
                    </div>
                    <h3 >
                      {bookingDetails?.booking_id?.name}
                    </h3>
                    {

                      bookingDetails?.status == "upcoming" ?
                        <div className={styles?.bookingStatusTag}
                          style={{
                            background: "#b3b3b3",
                            color: "black",
                          }}
                        >
                          Upcoming
                        </div>
                        : bookingDetails?.status == "expired" ?
                          <div className={styles?.bookingStatusTag}
                            style={{
                              background: "#ffdddd",
                              color: "red",
                            }}
                          >
                            Expired
                          </div>
                          : bookingDetails?.status == "active" ?
                            <div className={styles?.bookingStatusTag}
                              style={{
                                background: "#d4ffdb",
                                color: "#009c15",
                              }}
                            >
                              Active
                            </div>
                            : bookingDetails?.status == "refund_request" ?
                              <div className={styles?.bookingStatusTag}
                                style={{
                                  background: "#ffddc7",
                                  color: "#ff3c00",
                                }}
                              >
                                Refund Request
                              </div>
                              : bookingDetails?.status == "cancelled" || bookingDetails?.status == "cancel_request" ?
                                <div className={styles?.bookingStatusTag}
                                  style={{
                                    background: "#ffdddd",
                                    color: "#ff0000",
                                  }}
                                >
                                  {bookingDetails?.status == "cancelled" ? "Cancelled" : "Cancelled Request"}
                                </div>
                                :
                                bookingDetails?.status == "refunded" ?
                                  <div className={styles?.bookingStatusTag}
                                    style={{
                                      background: "#ffddc7",
                                      color: "#ff3c00",
                                    }}
                                  >
                                    Refunded
                                  </div>
                                  : bookingDetails?.status == "completed" ?
                                    <div className={styles?.bookingStatusTag}
                                      style={{
                                        background: "#ffdddd",
                                        color: "#ff0000",
                                      }}
                                    >
                                      Completed
                                    </div>
                                    :
                                    bookingDetails?.status
                    }

                    <Tooltip
                      title={t("Scan QR Code to see Booking Details")}
                      placement="top"
                    >
                      <div
                        className={styles.interactionDetailIconeDiv}
                        onClick={() => {
                          setQrModalOpen(true);
                          setPreviewContent(
                            bookingDetails?.purchase_qr_code
                          );
                        }}
                      >
                        <QrcodeOutlined
                          style={{ fontSize: "24px" }}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div className={styles.dividerBox}></div>
                  <div className={styles.cardInfoContainer}>
                    <div className={styles.bookingDetails}>
                      <h4>Description</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {bookingDetails?.booking_id?.pkg_desc}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Is Booking Paid/Free ?")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {bookingDetails?.is_paid
                            ? t("Paid")
                            : t("Free")}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Price")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {formatNumberToLocalCurrency(
                            bookingDetails?.booking_id?.price,
                            bookingDetails?.booking_id?.countryObject?.currency
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Chargeable Amount")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {formatNumberToLocalCurrency(
                            bookingDetails?.booking_id?.chargeableAmount / 100,
                            bookingDetails?.booking_id?.countryObject?.currency
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Total Paid Amount")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {formatNumberToLocalCurrency(
                            bookingDetails?.chargeableAmount / 100,
                            bookingDetails?.booking_id?.countryObject?.currency
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Total Users")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {bookingDetails?.total_user_allow_in_pack}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Start Date")}</h4>
                      <div className={styles.bookingDateContainer}>
                        <div className={styles.bookingDateDetail}>
                          <p>
                            {moment(bookingDetails.start_date).format('MMM DD, YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("End Date")}</h4>
                      <div className={styles.bookingDateContainer}>
                        <div className={styles.bookingDateDetail}>
                          <p>
                            {moment(bookingDetails.end_date).format('MMM DD, YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Slot Time")}</h4>
                      <div className={styles.bookingDateContainer}>
                        <div className={styles.bookingDateDetail}>
                          <p>
                            {`${moment(bookingDetails.start_date).format("HH:mm")} - ${moment(bookingDetails.end_date).format("HH:mm")}`} ({moment.tz(moment.tz.guess()).zoneAbbr()})
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Is Booking Online/Offline ?")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>
                          {bookingDetails?.booking_id?.is_online ? "Online" : "Offline"}
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Meeting Link")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p className={styles.linkText}>
                          {bookingDetails?.booking_id?.meeting_link !== '' ?
                            (
                              (bookingDetails?.status == "upcoming" || bookingDetails?.status == "active") ?
                                <a target="_blank"
                                  href={`${bookingDetails?.booking_id?.meeting_link}`}
                                >
                                  {bookingDetails?.booking_id?.meeting_link}
                                </a>
                                :
                                bookingDetails?.booking_id?.meeting_link
                            )

                            : '-'
                          }
                        </p>
                      </div>
                    </div>
                    <div className={styles.bookingDetails}>
                      <h4>{t("Address")}</h4>
                      <div className={styles.bookingDateDetail}>
                        <p>{bookingDetails?.booking_id?.address !== '' ? bookingDetails?.booking_id?.address : '-'}</p>
                      </div>
                    </div>
                    {
                      bookingDetails?.status == "refund_request" ?
                        (<>
                          <div className={styles.refundRequestBox}>
                            <Divider
                              orientation='left'
                              className={styles.dividerText}>
                              Refund Reason
                            </Divider>
                            <p>{bookingDetails?.refund_reason}</p>
                          </div>
                        </>)
                        :
                        bookingDetails?.status == "cancel_request" ?
                          (<>
                            <div className={styles.refundRequestBox}>
                              <Divider
                                orientation='left'
                                className={styles.dividerText}>
                                Cancel Booking Reason
                              </Divider>
                              <p>{bookingDetails?.cancel_reason}</p>
                            </div>
                          </>)
                          :
                          bookingDetails?.status == "cancelled" ?
                            (<>
                              <div className={styles.refundRequestBox}>
                                <Divider
                                  orientation='left'
                                  className={styles.dividerText}>
                                  Cancel Booking Reason
                                </Divider>
                                <p>{bookingDetails?.cancel_reason}</p>
                              </div>
                            </>)
                            : ''
                    }

                    {bookingDetails?.user_id?._id !== userDetail?._id ?
                      (<>
                        <div className={styles.BookingUserInfo}>
                          <Divider
                            orientation='left'
                            className={styles.dividerText}
                          >
                            User Info
                          </Divider>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("User Name")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.user_id?.username}
                              </p>
                            </div>
                          </div>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("Email")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.user_id?.email}
                              </p>
                            </div>
                          </div>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("Country")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.user_id?.countryObject?.countryName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>) :
                      (<>
                        <div className={styles.BookingUserInfo}>
                          <Divider
                            orientation='left'
                            className={styles.dividerText}>
                            Business User Info
                          </Divider>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("Business User Name")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.business_id?.username}
                              </p>
                            </div>
                          </div>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("Email")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.business_id?.email}
                              </p>
                            </div>
                          </div>
                          <div className={styles.bookingUserDetails}>
                            <h4>{t("Country")} :</h4>
                            <div className={styles.bookingDateDetail}>
                              <p>
                                {bookingDetails?.business_id?.countryObject?.countryName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>)
                    }
                    <div className={styles.actionBtnContainer}>
                      {bookingDetails?.user_id?._id !== userDetail?._id ?
                        (<>
                          {/* admin side cancel/refund flow flow action btn */}
                          {
                            bookingDetails?.status == 'upcoming' ?
                              (<>
                                <Button className={styles.CancelBookingBtn}
                                  onClick={() => {
                                    setOpenCancelBooking(true)
                                  }}
                                >
                                  Cancel Booking
                                </Button>
                                <Button className={styles.CancelBookingBtn}
                                  onClick={checkInBookingFunction}
                                >
                                  Check In
                                </Button>
                              </>)
                              : bookingDetails?.status == 'active' ?
                                (<>
                                  <Button className={styles.CancelBookingBtn}
                                    onClick={() => {
                                      setEndPackModal(true)
                                    }}
                                  >
                                    End Package
                                  </Button>
                                </>)
                                : (bookingDetails?.status == 'refund_request' && bookingDetails?.is_paid == true) ||
                                  (bookingDetails?.status == 'cancel_request' && bookingDetails?.is_paid == true) ?
                                  (<>
                                    <Button className={styles.CancelBookingBtn}
                                      onClick={() => {
                                        setConfirmRefundModal(true)
                                      }}
                                    >
                                      Refund
                                    </Button>
                                  </>) :
                                  ''
                          }
                        </>)
                        :
                        (<>
                          {/* user side check in , refund request flow action btn */}
                          {
                            bookingDetails?.status == 'upcoming' ?
                              (<>
                                {
                                  bookingDetails?.is_paid == true &&
                                  (<Button className={styles.CancelBookingBtn}
                                    onClick={() => {
                                      setRefundRequestModal(true)
                                    }}
                                  >
                                    Refund Request
                                  </Button>)
                                }
                                <Button className={styles.CancelBookingBtn}
                                  onClick={() => {
                                    setOpenCancelBooking(true)
                                  }}
                                >
                                  Cancel Booking
                                </Button>
                                <Button className={styles.CancelBookingBtn}
                                  onClick={checkInBookingFunction}
                                >
                                  Check In
                                </Button>
                              </>)
                              : bookingDetails?.status == 'active' ?
                                (<>
                                  <Button className={styles.CancelBookingBtn}
                                    onClick={checkInBookingFunction}
                                  >
                                    Check In
                                  </Button>
                                </>)
                                : bookingDetails?.status == "expired" && bookingDetails?.is_paid == true ?
                                  (<>
                                    <Button className={styles.CancelBookingBtn}
                                      onClick={() => {
                                        setRefundRequestModal(true)
                                      }}
                                    >
                                      Refund Request
                                    </Button>
                                  </>)
                                  : ''
                          }
                        </>)
                      }
                    </div>
                  </div>
                </>)
            }
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        width={450}
        title={bookingDetails?.status == 'refund_request' ?
          'Refund Amount'
          : "Cancelled Booking Refund"
        }
        open={confirmRefundModal}
        footer={null}
        centered={true}
        onCancel={() => {
          setConfirmRefundModal(false);
          refundRequestForm.resetFields();
          setRefundFullAmount(false)
        }}
      >
        <div className={styles.showCancelDescription}>
          <h3 className={styles.confirmationMsg}>
            {bookingDetails?.status == 'refund_request' ?
              "Would you like to refund full amount?"
              : "Would you like to charge some Cancellation Fees?"
            }
          </h3>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            onFinish={handleRefundAmount}
            className={styles.interactionForm}
            form={confirmRefundForm}
          >
            <Form.Item
              name="is_refund_full_amount"
            >
              <Radio.Group
                onChange={(e) => {
                  setRefundFullAmount(e.target.value);
                  confirmRefundForm.resetFields(["refund_amount"]);
                }}
                defaultValue={refundFullAmount}
                className="customRadioBtn"
              >
                <Radio value={true}>{t("Yes")}</Radio>
                <Radio value={false}>{t("No")}</Radio>
              </Radio.Group>
            </Form.Item>
            {
              bookingDetails?.status == 'refund_request' && refundFullAmount == false ?
                (
                  <Form.Item
                    label={`Refund Amount (${bookingDetails?.booking_id?.countryObject?.currency})`}
                    name="refund_amount"
                    rules={[
                      () => ({
                        validator(_, value) {
                          const sum =
                            value !== "" &&
                            value
                              .toString()
                              .split("")
                              .map(Number)
                              .reduce(function (a, b) {
                                return a + b;
                              }, 0);
                          if (sum === 0 || value < 0) {
                            return Promise.reject(t("Enter valid Amount, it can't be 0."));
                          }
                          if (
                            /^-?\d+(\.\d+)?$/.test(value) == false &&
                            /^-?\d+(\,\d+)?$/.test(value) == false
                          ) {
                            return Promise.reject(t("Please enter valid amount."));
                          }
                          if (convertComaInToDot(value) > bookingDetails?.chargeableAmount / 100) {
                            return Promise.reject(
                              t(
                                "Refund amount can't be More than chargeable amount."
                              )
                            );
                          }
                          if (convertComaInToDot(value) == bookingDetails?.chargeableAmount / 100) {
                            return Promise.reject(
                              t(
                                "If you wants to return full amount then choose yes option"
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      pattern="[0-9]"
                      inputMode="decimal"
                      className={styles.profileInputFiled}
                      placeholder={t("Enter refund amount")}
                      onKeyDown={(evt) => {
                        var theEvent = evt || window.event;
                        var key = theEvent.keyCode || theEvent.which;
                        var keyCode = key;
                        key = String.fromCharCode(key);
                        if (key.length == 0) return;
                        var regex = /^[0-9.,\b]+$/;
                        if (keyCode == 188 || keyCode == 190) {
                          return;
                        } else {
                          if (!regex.test(key)) {
                            theEvent.returnValue = false;
                            if (theEvent.preventDefault)
                              theEvent.preventDefault();
                          }
                        }
                      }}
                    />
                  </Form.Item>
                )
                : bookingDetails?.status == "cancel_request" && refundFullAmount == true ?
                  (
                    <Form.Item
                      label={`Cancellation fee (${bookingDetails?.booking_id?.countryObject?.currency})`}
                      name="cancellation_fee"
                      rules={[
                        () => ({
                          validator(_, value) {
                            const sum =
                              value !== "" &&
                              value
                                .toString()
                                .split("")
                                .map(Number)
                                .reduce(function (a, b) {
                                  return a + b;
                                }, 0);
                            if (sum === 0 || value < 0) {
                              return Promise.reject(t("Enter valid Amount, it can't be 0."));
                            }
                            if (
                              /^-?\d+(\.\d+)?$/.test(value) == false &&
                              /^-?\d+(\,\d+)?$/.test(value) == false
                            ) {
                              return Promise.reject(t("Please enter valid amount."));
                            }
                            if (convertComaInToDot(value) > bookingDetails?.chargeableAmount / 100) {
                              return Promise.reject(
                                t(
                                  "Cancellation fees can't be More than chargeable amount."
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        pattern="[0-9]"
                        inputMode="decimal"
                        className={styles.profileInputFiled}
                        placeholder={t("Enter cancellation fees")}
                        onKeyDown={(evt) => {
                          var theEvent = evt || window.event;
                          var key = theEvent.keyCode || theEvent.which;
                          var keyCode = key;
                          key = String.fromCharCode(key);
                          if (key.length == 0) return;
                          var regex = /^[0-9.,\b]+$/;
                          if (keyCode == 188 || keyCode == 190) {
                            return;
                          } else {
                            if (!regex.test(key)) {
                              theEvent.returnValue = false;
                              if (theEvent.preventDefault)
                                theEvent.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  )
                  : ''
            }
          </Form>
        </div>
        <div className={styles.createBtnContainer}>
          <Button
            className={styles.cancelBtn}
            onClick={() => {
              setConfirmRefundModal(false);
              refundRequestForm.resetFields();
              setRefundFullAmount(false)
            }}
          >
            <div className={styles.buttonContent}>{t("Cancel")}</div>
          </Button>
          <Button
            className={styles.createBtn}
            onClick={confirmRefundForm.submit}
          >
            <div className={styles.buttonContent}>{t("Submit")}</div>
          </Button>
        </div>
      </Modal>

      <Modal
        width={450}
        title='Cancel Booking'
        open={openCancelBooking}
        footer={null}
        centered={true}
        onCancel={() => {
          setOpenCancelBooking(false);
          cancelForm.resetFields()
        }}
      >
        <div className={styles.showCancelDescription}>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            onFinish={handleCancelBooking}
            className={styles.interactionForm}
            form={cancelForm}
          >
            <Form.Item
              label='Cancel Package Reason'
              name="cancel_reason"
              rules={[
                {
                  required: true,
                  message: "Please enter reason for cancel booking!",
                },
              ]}
            >
              <TextArea
                placeholder="Enter reason"
                rows={3}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.createBtnContainer}>
          <Button
            className={styles.cancelBtn}
            onClick={() => {
              setOpenCancelBooking(false);
              cancelForm.resetFields()
            }}
          >
            <div className={styles.buttonContent}>{t("No")}</div>
          </Button>
          <Button
            className={styles.createBtn}
            onClick={cancelForm.submit}
          >
            <div className={styles.buttonContent}>{t("Yes")}</div>
          </Button>
        </div>
      </Modal>

      <Modal
        width={450}
        title='Refund Request'
        open={refundRequestModal}
        footer={null}
        centered={true}
        onCancel={() => {
          setRefundRequestModal(false);
          refundRequestForm.resetFields()
        }}
      >
        <div className={styles.showCancelDescription}>
          <Form
            layout="vertical"
            name="basic"
            autoComplete="nope"
            onFinish={submitRefundRequest}
            className={styles.interactionForm}
            form={refundRequestForm}
          >
            <Form.Item
              label='Add Reason for refund request'
              name="refund_reason"
              rules={[
                {
                  required: true,
                  message: "Please enter reason to get refund!",
                },
              ]}
            >
              <TextArea
                placeholder="Enter refund request reason"
                rows={3}
              />
            </Form.Item>

            <div className={styles.createBtnContainer}>
              <Button
                className={styles.cancelBtn}
                onClick={() => {
                  setRefundRequestModal(false);
                  refundRequestForm.resetFields()
                }}
              >
                <div className={styles.buttonContent}>{t("Cancel")}</div>
              </Button>
              <Button
                className={styles.createBtn}
                onClick={refundRequestForm.submit}
              >
                <div className={styles.buttonContent}>{t("Submit")}</div>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        width={450}
        title='End Booking Package'
        open={endPackModal}
        footer={null}
        centered={true}
        onCancel={() => {
          setEndPackModal(false);
        }}
      >
        <div className={styles.showCancelDescription}>
          <h3>
            This booking package will be close for this User.<br />
            Are you sure you want to do this?
          </h3>

          <p><strong>Note :</strong>user will not be able to continue using this package after end of this package.</p>

          <div className={styles.createBtnContainer}>
            <Button
              className={styles.cancelBtn}
              onClick={() => {
                setEndPackModal(false);
              }}
            >
              <div className={styles.buttonContent}>{t("No")}</div>
            </Button>
            <Button
              className={styles.createBtn}
              onClick={EndBookingPurchasePackage}
            >
              <div className={styles.buttonContent}>{t("Yes")}</div>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={qrModalOpen}
        footer={null}
        centered={true}
        onCancel={() => {
          setQrModalOpen(false);
          setPreviewContent("");
        }}
        width={400}
        className="interactionPreviewModal"
      >
        <div className={styles.modalQrPreviewContent}>
          <div className={styles.QrPreviewImg}>
            <img src={previewContent}></img>
          </div>
          <p>
            {t("Scan this qr code to View booking purchase details.")}
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default CalendarDetail;
