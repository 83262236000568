import React, { useEffect, useState } from "react";
import styles from "./SignUp.module.css";
import { Row, Col, Form, Input, Button, Select, Space } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AccountVarificationUserName,
  checkValidUserName,
  countryList,
} from "../../redux/authentication/action";
import { getItem, setItem } from "../../utility/LocalStorageControl";
import BusinessDetailForm from "./BusinessDetailForm";
import { useTranslation } from "react-i18next";
import { API_ENDPOINT } from "../../config/dataService/DataService";
import { getCheckShortUrl } from "../../redux/Interaction/action";
import { WEBSITE_URL } from "../../config/Constant";

//  country_iso_code
const SignUpComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [countryListLoading, setCountryListLoading] = useState(true);
  const [countryNameList, setCountryNameList] = useState([]);
  const [countryDataList, setCountryDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [validUserNameStatus, setValidUserNameStatus] = useState(true);
  const [showAccountOptionPage, setShowAccountOptionPage] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);
  const [shortUrl, setShortUrl] = useState("");
  const [validUrlStatus, setValidUrlNameStatus] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  // DEFINE USEEFFECT FOR SEARCH CHANNEL DEBOUNCE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUserNameCheck(userName);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [userName]);


  useEffect(() => {
    getCountryList();
    if (getItem("AccountDetailsFilled")) {
      setShowAccountOptionPage(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onValidUrlCheck(shortUrl);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [shortUrl]);

  const onchangeShortUrl = (val) => {
    setUrlLoading(true);
    setShortUrl(val.replace(/\s/g, "").toLowerCase());
  };

  const onValidUrlCheck = async (value) => {
    if (value == "") {
      setUrlLoading(false);
      setValidUrlNameStatus(false);
    } else {
      const response = await dispatch(getCheckShortUrl(value));
      if (response) {
        setUrlLoading(false);
        setValidUrlNameStatus(true);
      } else {
        setUrlLoading(false);
        setValidUrlNameStatus(false);
      }
    }
  };

  const getCountryList = async () => {
    setCountryListLoading(true);
    let response = await dispatch(countryList());
    if (response.status) {
      if (response.data.length > 0) {
        setCountryListLoading(false);
        setCountryDataList(response.data);
        if (
          response.data.find(
            (item) =>
              item.country.name === getItem("country_details")?.country_name
          )
        ) {
          setCountryValue(
            response.data.find(
              (item) =>
                item.country.name === getItem("country_details")?.country_name
            )?.country?.name
          );
        } else {
          setCountryValue("");
        }
        setCountryNameList(
          response.data.map(function (item) {
            return {
              label: item?.country?.name,
              value: item?.country?.name,
            };
          })
        );
      }
    } else {
      setCountryListLoading(true);
    }
  };

  const handleSubmit = async (value) => {
    if (validUrlStatus == false || validUserNameStatus == false) {
      setSubmitStatus(false);
      return;
    } else {
      setSubmitStatus(true);
      if (userName == "") {
        setSubmitStatus(true);
      } else {
        if (countryValue == "") {
        } else {
          setLoading(false);
          setSubmitStatus(false);
          if (validUserNameStatus) {
            let formData = new FormData();
            if (mobileNumber) {
              formData.append("phone_number", mobileNumber.toString());
            }
            formData.append("new_username", userName);
            formData.append("name", name);
            formData.append("short_url", shortUrl);
            formData.append(
              "country_iso_code",
              countryValue == ""
                ? countryDataList.find(
                  (item) =>
                    item.country.name ===
                    getItem("country_details")?.country_name
                )?.country?.alpha2Code
                : countryDataList.find(
                  (item) => item.country.name === countryValue
                )?.country?.alpha2Code
            );
            const response = await dispatch(
              AccountVarificationUserName(
                getItem("userDetails")?._id ? getItem("userDetails")?._id : "",
                formData
              )
            );
            if (response) {
              setShowAccountOptionPage(true);
              setItem("AccountDetailsFilled", true);
              form.resetFields();
              setShortUrl('')
              setValidUserNameStatus(false);
              setValidUrlNameStatus(false);
              setUserName('')
              setName('')
              setMobileNumber(null)
              setSubmitClicked(false);
            } else {
              setSubmitClicked(false);
            }
          }
        }
      }
    }
  };

  const onValidUserNameCheck = async (value) => {
    if (value == "") {
      setLoading(false);
    } else {
      const response = await dispatch(checkValidUserName(value));
      if (response) {
        setLoading(false);
        setValidUserNameStatus(true);
      } else {
        setLoading(false);
        setValidUserNameStatus(false);
      }
    }
  };

  return (
    <Row justify={"center"}>
      <Col xxl={7} xl={9} lg={10} md={12} sm={14} xs={24}>
        <div className={styles.signUpContainer}>
          <div className={styles.logoHeader}>
            <div className={styles.LinkText}>
              <h2>{t("Claim your Link in Bio!")}</h2>
            </div>
          </div>
          <div className={styles.signUpFormContainer}>
            {showAccountOptionPage == true ? (
              <BusinessDetailForm />
            ) : (
              <>
                <Form
                  layout="vertical"
                  name="basic"
                  autoComplete="nope"
                  colon={true}
                  className={styles.loginForm}
                  form={form}
                >
                  <Form.Item
                    name="short_url"
                    hasFeedback
                    validateStatus={
                      submitStatus
                        ? shortUrl == "" && validUrlStatus
                          ? "error"
                          : ""
                        : shortUrl == ""
                          ? ""
                          : urlLoading
                            ? "validating"
                            : validUrlStatus
                              ? "success"
                              : "error"
                    }
                    help={
                      submitStatus
                        ? shortUrl == "" && validUrlStatus
                          ? "Please enter short name!"
                          : ""
                        : shortUrl == ""
                          ? ""
                          : urlLoading
                            ? ""
                            : validUrlStatus
                              ? ""
                              : "This short name is not available."
                    }
                    rules={[
                      {
                        required:
                          submitStatus && shortUrl == "" && !validUrlStatus
                            ? true
                            : false,
                        message: t("Please Enter short name"),
                      },
                      {
                        pattern: new RegExp(
                          /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                        ),
                        message: t(
                          "short name URL can not contain whitespace or upper case letters"
                        ),
                      },
                    ]}
                    label={t("ShortName")}
                    className={styles.inputMain}
                  >
                    <div className={styles.inputContainer}>
                      <Input
                        className={styles.profileInputFiled}
                        placeholder={t("Add short name")}
                        onChange={(e) => {
                          onchangeShortUrl(e.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.code === "Space") event.preventDefault();
                        }}
                        onInput={(e) =>
                        (e.target.value = (
                          "" + e.target.value
                        ).toLocaleLowerCase())
                        }
                        onPaste={(e) => {
                          e.preventDefault()
                        }}
                        disabled={false}
                        style={{ padding: "0px 10px" }}
                        prefix={
                          <>
                            <div className={styles.logoBox}>
                              <svg
                                width="83"
                                height="95"
                                viewBox="0 0 83 95"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_183_2)">
                                  <path
                                    d="M43.6788 43.5901L48.1882 35.19L54.1571 46.4411C51.6966 47.4389 48.6373 47.7749 45.9241 47.8258C44.2401 47.8564 42.6871 47.7749 41.4802 47.6833L43.6788 43.5901Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M38.4958 31.0765L42.2568 24L46.7195 32.4205C43.6508 30.9136 40.788 30.7608 38.4958 31.0765Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M58.3485 54.3423C55.7009 54.6681 52.838 54.943 49.7974 55.1161C45.157 55.391 40.0862 55.442 34.7067 55.1365C31.9374 54.9736 29.0746 54.7292 26.1556 54.3525L36.3252 35.1798C38.028 34.5282 41.0686 33.8256 44.5208 35.1696L36.2972 50.4833L37.1111 50.6055L38.6454 50.8397C38.8793 50.8804 41.5925 51.2775 45.0728 51.2775C45.9242 51.2775 46.813 51.2572 47.7298 51.1961C50.443 51.023 53.3339 50.5342 55.7757 49.455L58.3485 54.3423Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M67.1898 71H58.2177L54.5316 64.0457C45.3723 65.8479 37.0271 65.4407 29.9542 64.0966L26.2867 71H17.3146L22.0393 62.0908L23.7701 58.8224C26.6516 59.2704 29.4677 59.586 32.2182 59.81C39.5157 60.4006 46.2892 60.2784 52.2581 59.7897C55.2894 59.5453 58.1241 59.1991 60.7063 58.8122L62.3997 61.9992L67.1898 71Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M74.3763 55.0977C74.8094 54.9606 75.1501 55.6618 74.762 55.8979C70.5505 58.4597 66.4202 60.4199 62.4091 61.888C59.724 62.8641 57.0951 63.6247 54.5316 64.1809C45.3724 66.1786 37.0271 65.7246 29.9542 64.2263C27.1007 63.6247 24.4531 62.8414 22.0487 61.9901C15.515 59.6694 10.7319 56.7699 8.31176 55.1293C7.96154 54.8919 8.21235 54.4232 8.61397 54.5563C13.801 56.2758 18.8776 57.5054 23.7795 58.3465C26.661 58.8459 29.4771 59.1978 32.2277 59.4475C39.5251 60.1059 46.2986 59.9696 52.2675 59.4248C55.2988 59.1524 58.1335 58.7664 60.7157 58.3351C66.7044 57.3295 71.4035 56.0385 74.3763 55.0977Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M41.4802 47.6833L43.6788 43.5901L45.9241 47.8156C44.2401 47.8564 42.6777 47.7749 41.4802 47.6833Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M49.7972 55.1161C45.1568 55.391 40.086 55.4419 34.7065 55.1365L37.1015 50.6157L38.6359 50.8499C38.8698 50.8906 41.5829 51.2877 45.0632 51.2877C45.9146 51.2877 46.8034 51.2673 47.7202 51.2062L49.7972 55.1161Z"
                                    fill="#E60C8F"
                                  />
                                  <path
                                    d="M54.5315 64.0457C45.3723 65.8479 37.0271 65.4407 29.9542 64.0966L32.2276 59.81C39.525 60.4006 46.2985 60.2784 52.2675 59.7897L54.5315 64.0457Z"
                                    fill="#E60C8F"
                                  />
                                </g>
                                <path
                                  d="M73.6035 52.36C74.2752 52.1632 74.9562 52.2478 75.5882 52.5026C76.2202 52.7574 76.8481 53.2151 77.261 53.7907C77.712 54.3598 78.0311 55.0728 78.1082 55.7468C78.1691 56.1002 78.1472 56.4274 78.1252 56.7546C78.0652 57.0884 78.0052 57.4222 77.9003 57.7233C77.8513 57.8934 77.6677 57.9655 77.5019 57.9133C77.419 57.8872 77.3742 57.8545 77.3606 57.776L77.3538 57.7367C77.1854 57.4423 77.0999 57.1741 76.9764 56.9124C76.8528 56.6507 76.7673 56.3825 76.6125 56.1666C76.3789 55.7218 76.114 55.3228 75.856 54.963C75.5979 54.6033 75.2636 54.2566 74.86 53.9624C74.4877 53.6224 74.0461 53.3347 73.5528 52.9751L73.508 52.9424C73.3734 52.8444 73.3396 52.6481 73.4334 52.5106C73.458 52.4255 73.5341 52.4123 73.6035 52.36Z"
                                  fill="#E60C8F"
                                />
                                <path
                                  d="M9.59441 51.8442C8.914 51.6801 8.23782 51.7974 7.61883 52.0824C6.99984 52.3674 6.39483 52.8548 6.01012 53.4497C5.58707 54.0398 5.30277 54.7674 5.25826 55.4444C5.2144 55.8002 5.2521 56.126 5.2898 56.4518C5.36584 56.7823 5.44188 57.1128 5.56113 57.4085C5.61833 57.5761 5.80516 57.6393 5.96828 57.5791C6.04983 57.549 6.09305 57.5142 6.1028 57.4351L6.10767 57.3956C6.26168 57.0934 6.33414 56.8213 6.44493 56.554C6.55573 56.2867 6.62818 56.0146 6.77245 55.7915C6.9843 55.336 7.22961 54.9246 7.47006 54.5529C7.7105 54.1811 8.02763 53.8188 8.41656 53.5054C8.77203 53.1478 9.19931 52.8392 9.67468 52.4562L9.71789 52.4214C9.84754 52.317 9.87191 52.1193 9.7715 51.9865C9.7429 51.9027 9.66622 51.8932 9.59441 51.8442Z"
                                  fill="#E60C8F"
                                />
                                <defs>
                                  <clipPath id="clip0_183_2">
                                    <rect
                                      width="71.44"
                                      height="47"
                                      fill="white"
                                      transform="translate(6.52768 24)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>

                            <b className={styles.backslash}>
                              {WEBSITE_URL}
                            </b>
                          </>
                        }
                      />
                    </div>
                  </Form.Item>
                  <Row gutter={[18, 18]}>
                    <Col xxl={12} sm={12} xs={24}>
                      <Form.Item
                        name="new_username"
                        label={t("username") + " :"}
                        validateStatus={
                          submitStatus
                            ? userName == "" && validUserNameStatus
                              ? "error"
                              : ""
                            : userName == ""
                              ? ""
                              : loading
                                ? "validating"
                                : validUserNameStatus
                                  ? "success"
                                  : "error"
                        }
                        help={
                          submitStatus
                            ? userName == "" && validUserNameStatus
                              ? t("Please enter Username!")
                              : ""
                            : userName == ""
                              ? ""
                              : loading
                                ? ""
                                : validUserNameStatus
                                  ? ""
                                  : t("Username is not available.")
                        }
                        rules={[
                          {
                            required: userName == "" && validUserNameStatus ? true : false,
                            message: t("Please Enter Username"),
                          },
                          {
                            pattern: new RegExp(
                              /^[a-z0-9!@#$%\?:;,/|<>~'^&*)(+=._-]*$/
                            ),
                            message: t("Username can only contain lowercase letters and numbers"),
                          },
                        ]}
                        hasFeedback
                      >
                        <Input
                          placeholder={t("Enter Username")}
                          className={styles.userNameInput}
                          style={{ height: "50px" }}
                          value={userName}
                          onChange={(e) => {
                            setLoading(true);
                            setUserName(e.target.value);
                            // setSubmitStatus(e.target.value == "" ? true : false);
                          }}
                          onKeyDown={(event) => {
                            if (event.code === "Space") { event.preventDefault() };
                          }}
                          onInput={(e) =>
                          (e.target.value = (
                            "" + e.target.value
                          ).toLocaleLowerCase())
                          }
                          onPaste={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xxl={12} sm={12} xs={24}>
                      <Form.Item
                        name="name"
                        label={t("Name") + " :"}
                        className={styles.inputMain}>
                        <Input
                          className={styles.userNameInput}
                          placeholder={t("Enter name")}
                          style={{ height: "50px" }}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[18, 18]}>
                    <Col xxl={12} sm={12} xs={24}>
                      <Form.Item
                        label={t("mobile number") + " :"}
                        name="mobile"
                      >
                        <Input
                          type="tel"
                          style={{ height: "50px" }}
                          maxLength={10}
                          className={styles.userNameInput}
                          placeholder={t("mobile number")}
                          value={mobileNumber}
                          onChange={(e) => {
                            setMobileNumber(e.target.value.slice(0, 10));
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^\d]/g,
                              ""
                            );
                          }}
                          onKeyDown={(event) => {
                            if (
                              event.key == "." ||
                              event.key === "-" ||
                              event.key == "e"
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xxl={12} sm={12} xs={24}>
                      <Form.Item
                        label={t("Country") + " :"}
                        name="country_iso_code"
                        help={
                          submitStatus && countryValue == ""
                            ? t("Please select your country!")
                            : ""
                        }
                        rules={[
                          {
                            required: countryValue == "" ? true : false,
                            message: t("Please select your country!"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          className="profileCountrySelectBox"
                          style={{ height: "50px", textAlign: "center", width: '100%' }}
                          loading={countryListLoading}
                          placeholder={t("Select your country")}
                          optionFilterProp="children"
                          required
                          onChange={(value) => {
                            setCountryValue(value);
                          }}
                          defaultValue={
                            getItem("country_details")?.country_name
                          }
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={countryNameList}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    type="primary"
                    disabled={!validUserNameStatus && !validUrlStatus}
                    htmlType="submit"
                    className={styles.formSubmitBtn}
                    onClick={() => {
                      handleSubmit();
                    }}
                    block
                  >
                    {t("Next")}
                  </Button>
                </Form>
              </>
            )}
          </div>
          <p className={styles.conditionInfo}>
            {t("By signing up, you agree to our")}{" "}
            <NavLink to="/terms-of-service">{t("Terms Of service")}</NavLink>{" "}
            and <NavLink to="/privacy-policy">{t("Privacy Policy.")}</NavLink>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default SignUpComponent;
