import React, { useState, useEffect, useRef } from "react";
import style from "../../reactionsList/chatModule/ChatModule.module.css";
import InputEmoji from "react-input-emoji";
import Avatar from "antd/es/avatar/avatar";
import { UserOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  sendReplyToReaction,
  responseDetailApi,
} from "../../../../redux/Interaction/action";
import sendImg from "../../../../assets/Images/send.svg";
import {
  message,
  Skeleton,
  Modal,
  Spin,
  Popover,
  Progress,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import moment from "moment";
import VideoPlayer from "../../Ineraction/createInteraction/VideoPlayer";

const ChatModule = (props) => {
  const { t } = useTranslation();
  let { showChatModule, pageNum, limit, fetchList, responseId } = props;
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const chatBodyRef = useRef(null);
  const dispatch = useDispatch();
  const [progressCount, setProgressCount] = useState(0);
  const [responseData, setResponseData] = useState({});
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputFile = useRef(null);
  const audioStreamRecorder = useRef(null);
  const videoRefone = useRef(null);
  const audioRecorder = useRef(null);
  const videoRecorder = useRef(null);
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [audioRecording, setAudioRecording] = useState(null);
  const [videoRecording, setVideoRecording] = useState(null);
  const [time, setTime] = useState({ minutes: 2, seconds: "00" });
  const [streams, setStreams] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [audioFileData, setAudioFileData] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [videoFileData, setVideoFileData] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [messageArr, setMessageArr] = useState([]);

  useEffect(() => {
    if (responseId) {
      setLoading(true);
      fetchDetailData(false);
    }
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
    setProgressCount(0);
  }, [responseId]);

  const fetchDetailData = async (status) => {
    const resp = await dispatch(responseDetailApi(responseId));
    if (resp && resp?.data) {
      setResponseData(resp?.data);
      setMessageArr(resp?.data?.message);
      setTimeout(() => {
        if (chatBodyRef.current) {
          chatBodyRef.current.style.scrollBehavior = status ? "smooth" : "auto";
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
      }, 10);
      setProgressCount(0);
      setLoading(false);
    }
  };

  const uploadPercentage = (val) => {
    setProgressCount(val);
  };

  const handleSubmit = async (val) => {
    if (selectedMediaType == "file") {
      if (fileData) {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append(
          "content_type",
          fileData?.["type"]?.split("/")[0] + "_upload"
        );
        formData.append("user_content", fileData);
        formData.append("business_id", responseData?.business_id);
        const replay = await dispatch(
          sendReplyToReaction(
            formData,
            responseData?._id,
            "media",
            uploadPercentage
          )
        );
        if (replay) {
          setProgressCount(0);
          setUploadLoading(false);
          setSelectedMediaType("");
          setFileData(null);
          fetchDetailData(true);
        }
      }
    } else if (selectedMediaType == "audio") {
      if (audioFileData) {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("content_type", "audio_record");
        formData.append("user_content", audioFileData);
        formData.append("business_id", responseData?.business_id);
        const replay = await dispatch(
          sendReplyToReaction(
            formData,
            responseData?._id,
            "media",
            uploadPercentage
          )
        );
        if (replay) {
          setProgressCount(0);
          setAudioBlob(null);
          setAudioFileData(null);
          setAudioRecording(false);
          setUploadLoading(false);
          setSelectedMediaType("");
          fetchDetailData(true);
        }
      }
    } else if (selectedMediaType == "video") {
      if (videoFileData) {
        setUploadLoading(true);
        const formData = new FormData();
        formData.append("content_type", "video_record");
        formData.append("user_content", videoFileData);
        formData.append("business_id", responseData?.business_id);
        const replay = await dispatch(
          sendReplyToReaction(
            formData,
            responseData?._id,
            "media",
            uploadPercentage
          )
        );
        if (replay) {
          setProgressCount(0);
          setVideoBlob(null);
          setVideoFileData(null);
          setVideoRecording(false);
          setUploadLoading(false);
          setSelectedMediaType("");
          fetchDetailData(true);
        }
      }
    } else {
      setBtnDisable(true);
      setText("");
      const obj = {};
      obj.business_id = responseData?.business_id;
      obj.user_content = text;
      obj.content_type = "text";
      if (text !== "" && text.trim() !== "") {
        const replay = await dispatch(
          sendReplyToReaction(obj, responseData?._id, "text")
        );
        if (replay) {
          setProgressCount(0);
          fetchDetailData(true);
        }
      }
    }
  };

  // interaction Preview modal

  const showModal = (val) => {
    if (
      val.user_content !== "" &&
      val.user_content !== null &&
      val.user_content !== undefined
    ) {
      setIsModalOpen(true);
      setPreviewContent(val);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onOpenMedia = async (val) => {
    setProgressCount(0);
    if (val == "file") {
      setSelectedMediaType(val);
    } else if (val == "audio") {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        setSelectedMediaType(val);
      } catch (error) {
        if (error == "NotAllowedError: Permission denied") {
          message.warning(t("Please enable microphone permissions"));
        }
      }
    } else if (val == "video") {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        videoRefone.current = null;
        setSelectedMediaType(val);
      } catch (error) {
        if (error == "NotAllowedError: Permission denied") {
          message.warning(t("Please enable video & microphone permissions"));
        }
      }
    }
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files[0]) {
      if (
        event.target.files[0]["type"]?.split("/")[0] == "image" ||
        event.target.files[0]["type"]?.split("/")[0] == "audio" ||
        event.target.files[0]["type"]?.split("/")[0] == "video"
      ) {
        setFileData(event.target.files[0]);
      } else {
        message.error(t("please select image or video"));
      }
    }
  };

  const startCountdown = () => {
    clearInterval(intervalId);
    setIntervalId(
      setInterval(() => {
        setTime((prevTime) => {
          let { minutes, seconds } = prevTime;
          if (minutes === 0 && seconds === 0) {
            clearInterval(intervalId);
            return prevTime;
          }
          seconds -= 1;
          if (seconds < 0) {
            if (minutes > 0) {
              minutes -= 1;
              seconds = 59;
            } else {
              seconds = 0;
            }
          } else if (seconds < 10) {
            seconds = "0" + seconds;
            if (seconds == 0 && minutes == 0) {
              if (selectedMediaType == "audio") {
                if (
                  audioRecorder.current &&
                  audioRecorder.current.state === "recording"
                ) {
                  audioRecorder.current.stop();
                  if (audioStreamRecorder.current) {
                    audioStreamRecorder.current
                      .getTracks()
                      .forEach((track) => track.stop());
                  }
                }
                setAudioRecording(false);
              } else {
                if (
                  videoRecorder.current &&
                  videoRecorder.current.state === "recording"
                ) {
                  videoRecorder.current.stop();
                  if (videoRefone.current) {
                    streams?.getTracks().forEach((track) => track.stop());
                    videoRefone.current = null;
                    setTime({ minutes: 0, seconds: "00" });
                  }
                }
                setVideoRecording(false);
              }
            }
          }
          return { minutes, seconds };
        });
      }, 1000)
    );
  };

  const onRecordAudioButtonClick = async (value) => {
    if (!audioRecording) {
      try {
        setTime({ minutes: 2, seconds: "00" });
        startCountdown();
        setAudioRecording(true);
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioStreamRecorder.current = stream;
        audioRecorder.current = new MediaRecorder(stream);
        const chunks = [];
        audioRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
        audioRecorder.current.onstop = () => {
          const blob = new Blob(chunks, { type: "audio" });
          setAudioFileData(blob);
          setAudioBlob(URL.createObjectURL(blob));
        };
        audioRecorder.current.start();
        setAudioRecording(true);
      } catch (error) {
        setAudioRecording(false);
        if (error == "NotAllowedError: Permission denied") {
          setSelectedMediaType("PermissionBlocked");
          message.warning(t("Please enable microphone permissions"));
        }
      }
    } else {
      if (
        audioRecorder.current &&
        audioRecorder.current.state === "recording"
      ) {
        audioRecorder.current.stop();
        if (audioStreamRecorder.current) {
          audioStreamRecorder.current
            .getTracks()
            .forEach((track) => track.stop());
        }
      }
      setAudioRecording(false);
    }
  };

  const onRecordVideoButtonClick = async (value) => {
    if (!videoRecording) {
      try {
        setTime({ minutes: 2, seconds: "00" });
        startCountdown();
        setVideoRecording(true);
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        videoRefone.current.srcObject = stream;
        videoRecorder.current = new MediaRecorder(stream);
        const chunks = [];
        videoRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
        videoRecorder.current.onstop = () => {
          const blob = new Blob(chunks, { type: "video/webm" });
          setVideoBlob(URL.createObjectURL(blob));
          setVideoFileData(blob);
          videoRefone.current = null;
          setTime({ minutes: 0, seconds: "00" });
        };
        videoRecorder.current.start();
        setVideoRecording(true);
        setStreams(stream);
      } catch (error) {
        setVideoRecording(false);
        if (error == "NotAllowedError: Permission denied") {
          setSelectedMediaType("");
          message.warning(t("Please enable camera & microphone permissions"));
        }
      }
    } else {
      if (
        videoRecorder.current &&
        videoRecorder.current.state === "recording"
      ) {
        videoRecorder.current.stop();
        if (videoRefone.current) {
          streams.getTracks().forEach((track) => track.stop());
          videoRefone.current = null;
          setTime({ minutes: 0, seconds: "00" });
        }
      }
      setVideoRecording(false);
    }
  };

  // group messages by day
  const GroupedDayMessagesArr = groupMessagesByDay(messageArr);
  function groupMessagesByDay(messages) {
    const grouped = [];
    messages.forEach((message) => {
      const day = new Date(message.created_at).toLocaleDateString();
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(message);
    });
    return grouped;
  }

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  return (
    <div
      className={style.ChatModuleDiv}
      style={{ width: props.width == true ? "100%" : "50%" }}
    >
      <div className={style.HeaderProfileDiv}>
        <div className={style.leftHeader}>
          <div className={style.ProfileImageDiv}>
            {responseData?.user_id?.user_image !== "" ? (
              <Avatar size={45} src={responseData?.user_id?.user_image} />
            ) : (
              <Avatar size={45} icon={<UserOutlined />} />
            )}
          </div>
          <div className={style.ChatPersonNameDiv}>
            <p className={style.ReceiverTxt}>
              {responseData?.user_id?.username !== ""
                ? responseData?.user_id?.username
                : responseData?.user_id?.email}
            </p>
          </div>
        </div>
        <div className={style.rightHeader}>
          <CloseOutlined
            className={style.LeftIcon}
            onClick={() => {
              showChatModule(false, {});
              setSelectedMediaType("");
              setProgressCount(0);
            }}
          />
        </div>
      </div>

      {selectedMediaType !== "" ? (
        <>
          {progressCount > 0 ? (
            <div className={style.progressCircle}>
              <Progress
                type="circle"
                percent={progressCount}
                strokeColor={{
                  "0%": "#E60C8F",
                  "100%": "#E60C8F",
                }}
              />
            </div>
          ) : (
            <>
              <div className={style.backButtonDiv}>
                <div className={style.backButton}>
                  <FeatherIcon
                    onClick={() => {
                      setSelectedMediaType("");
                      setFileData(null);
                      if (chatBodyRef.current) {
                        chatBodyRef.current.scrollTop =
                          chatBodyRef.current.scrollHeight;
                      }
                    }}
                    icon="arrow-left"
                    size={26}
                  />
                </div>
              </div>
              {selectedMediaType == "file" ? (
                <>
                  <div className={style.uplaodIconeDiv}>
                    <div>
                      {fileData ? (
                        <div className={style.imagePreviewContainer}>
                          {fileData?.["type"]?.split("/")[0] == "image" ? (
                            <img src={URL.createObjectURL(fileData)} />
                          ) : fileData?.["type"]?.split("/")[0] == "video" ? (
                            <video
                              controls
                              autoPlay
                              src={URL.createObjectURL(fileData)}
                            />
                          ) : (
                            <audio
                              controls
                              autoPlay
                              src={URL.createObjectURL(fileData)}
                            />
                          )}
                          <div className={style.removeUploadFile}>
                            <FeatherIcon
                              onClick={() => {
                                setFileData(null);
                              }}
                              icon="trash"
                              className={style.fileCancelSVG}
                              size={20}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={style.iconeDiv}
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          >
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onChangeFile.bind(this)}
                            />
                            <FeatherIcon icon="upload" size={20} />
                          </div>
                          <p>{t("Select your file")}</p>{" "}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : selectedMediaType == "audio" ? (
                <div className={style.uplaodIconeDiv}>
                  <div>
                    {audioBlob ? (
                      <div className={style.imagePreviewContainer}>
                        <audio controls autoPlay src={audioBlob} />
                        <div className={style.removeUploadFile}>
                          <FeatherIcon
                            onClick={() => {
                              setAudioBlob(null);
                              setAudioFileData(null);
                              setAudioRecording(false);
                            }}
                            icon="trash"
                            className={style.fileCancelSVG}
                            size={20}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={style.iconeDiv}>
                          <FeatherIcon icon="mic" size={20} />
                        </div>
                        <div
                          className={style.audioRecordingBtn}
                          onClick={() => {
                            onRecordAudioButtonClick(audioRecording);
                          }}
                        >
                          <div className={style.recordingBtnContainer}>
                            {" "}
                            {audioRecording ? time.minutes : ""}
                            {audioRecording ? ":" : ""}
                            {audioRecording ? time.seconds : ""}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className={style.uplaodIconeDiv}>
                  <div>
                    {videoBlob && !videoRecording ? (
                      <div className={style.imagePreviewContainer}>
                        <iframe src={videoBlob} controls autoPlay></iframe>
                        <div className={style.removeUploadFile}>
                          <FeatherIcon
                            onClick={() => {
                              setVideoBlob(null);
                              setVideoFileData(null);
                              setVideoRecording(false);
                              videoRefone.current = null;
                            }}
                            icon="trash"
                            className={style.fileCancelSVG}
                            size={20}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: videoRefone?.current ? "block" : "none",
                          }}
                          className={style.imagePreviewContainer}
                        >
                          <video
                            style={{
                              display: videoRefone?.current ? "block" : "none",
                            }}
                            ref={videoRefone}
                            autoPlay
                          ></video>
                        </div>
                        <div
                          style={{
                            display: videoRefone?.current ? "none" : "flex",
                          }}
                          className={style.iconeDiv}
                          onClick={() => { }}
                        >
                          <FeatherIcon icon="video" size={20} />
                        </div>
                        <div
                          className={style.audioRecordingBtn}
                          style={{
                            height: videoRefone?.current ? "90px" : "120px",
                          }}
                          onClick={() => {
                            onRecordVideoButtonClick(videoRecording);
                          }}
                        >
                          <div className={style.recordingBtnContainer}>
                            {" "}
                            {videoRecording ? time.minutes : ""}
                            {videoRecording ? ":" : ""}
                            {videoRecording ? time.seconds : ""}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className={style.sendButtonDiv}>
                {fileData || audioBlob || videoBlob ? (
                  <div
                    className={`${style.sendButton} sendUploadFileButton`}
                    onClick={() => {
                      if (uploadLoading == false) {
                        handleSubmit();
                      }
                    }}
                  >
                    {uploadLoading ? (
                      <Spin />
                    ) : (
                      <FeatherIcon icon="send" size={20} />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={style.chatMsgBody} ref={chatBodyRef}>
            {loading ? (
              <>
                <div className={style.userChatSkeleton}>
                  <Skeleton
                    paragraph={{
                      rows: 1,
                    }}
                    style={{ marginTop: "10px", height: "20px" }}
                  />
                </div>
                <div className={style.adminChatSkeleton}>
                  <Skeleton.Input
                    block={true}
                    style={{ marginTop: "10px", height: "30px" }}
                  />
                  <Skeleton.Input
                    block={true}
                    style={{ marginTop: "10px", height: "20px" }}
                  />
                </div>
                <div className={style.userChatSkeleton}>
                  <Skeleton
                    paragraph={{
                      rows: 1,
                    }}
                    style={{ marginTop: "10px", height: "20px" }}
                  />
                  <Skeleton
                    paragraph={{
                      rows: 1,
                    }}
                    style={{ marginTop: "10px", height: "20px" }}
                  />
                </div>
                <div className={style.adminChatSkeleton}>
                  <Skeleton.Input
                    block={true}
                    style={{ marginTop: "10px", height: "80px" }}
                  />
                  <Skeleton.Input
                    block={true}
                    style={{ marginTop: "10px", height: "20px" }}
                  />
                </div>
              </>
            ) : (
              <>
                {Object.keys(GroupedDayMessagesArr).map((day) => (
                  <div key={day}>
                    <Divider style={{ margin: "5px 0" }}>
                      <p className={style.chatDividerDayText}>
                        {moment(day, 'DD/MM/YYYY').format("MMM DD, YYYY")}
                      </p>
                    </Divider>
                    {messageArr && messageArr.length > 0
                      ? GroupedDayMessagesArr[day].map((data, i) => {
                        return (
                          <>
                            {data?.content_type == "video_upload" ||
                              data?.content_type == "video_record" ? (
                              <>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseTime}`
                                      : `${style.userResponseTime}`
                                  }
                                  key={i}
                                >
                                  {moment(data.created_at).format("LT")}{" "}
                                </div>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseText}`
                                      : `${style.reactionThumbnailBox}`
                                  }
                                  key={i}
                                >
                                  <video src={data?.user_content}></video>
                                  {data?.user_id ==
                                    responseData?.business_id ? (
                                    <div className={style.rightChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="rgb(227 99 168 / 33%)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(-1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="rgb(227 99 168 / 33%)"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <div className={style.leftChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{
                                          objectFit: "object-fit: cover;",
                                        }}
                                        viewBox="0 0 24 24"
                                        fill="#ffffff8a"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="#ffffff8a"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  )}
                                  <div
                                    className={style.viewPreviewIcon}
                                    style={{
                                      left:
                                        data?.user_id ==
                                          responseData?.business_id
                                          ? "-30px"
                                          : "0px",
                                      right:
                                        data?.user_id ==
                                          responseData?.business_id
                                          ? "auto"
                                          : "-30px",
                                    }}
                                    onClick={() => showModal(data)}
                                  >
                                    <FeatherIcon icon="eye" size={16} />
                                  </div>
                                </div>
                              </>
                            ) : data?.content_type == "image_upload" ? (
                              <>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseTime}`
                                      : `${style.userResponseTime}`
                                  }
                                  key={i}
                                >
                                  {moment(data.created_at).format("LT")}{" "}
                                </div>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseText}`
                                      : `${style.reactionThumbnailBox}`
                                  }
                                  key={i}
                                >
                                  <img src={data?.user_content}></img>
                                  {data?.user_id ==
                                    responseData?.business_id ? (
                                    <div className={style.rightChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="rgb(227 99 168 / 33%)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(-1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="rgb(227 99 168 / 33%)"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <div className={style.leftChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="#ffffff8a"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="#ffffff8a"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  )}
                                  <div
                                    className={style.viewPreviewIcon}
                                    onClick={() => showModal(data)}
                                    style={{
                                      left:
                                        data?.user_id ==
                                          responseData?.business_id
                                          ? "-30px"
                                          : "0px",
                                      right:
                                        data?.user_id ==
                                          responseData?.business_id
                                          ? "auto"
                                          : "-30px",
                                    }}
                                  >
                                    <FeatherIcon icon="eye" size={16} />
                                  </div>
                                </div>
                              </>
                            ) : data?.content_type == "audio_upload" ||
                              data?.content_type == "audio_record" ? (
                              <>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseTime}`
                                      : `${style.userResponseTime}`
                                  }
                                  key={i}
                                >
                                  {moment(data.created_at).format("LT")}{" "}
                                </div>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminAudioResponseText}`
                                      : `${style.audioResponseBox}`
                                  }
                                  key={i}
                                >
                                  <audio controls>
                                    <source
                                      src={data?.user_content}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                  {data?.user_id ==
                                    responseData?.business_id ? (
                                    <div className={style.rightChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="rgb(227 99 168 / 33%)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(-1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="rgb(227 99 168 / 33%)"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <div className={style.leftChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="#ffffff8a"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="#ffffff8a"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : data?.content_type == "text" ? (
                              <>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseTime}`
                                      : `${style.userResponseTime}`
                                  }
                                  key={i}
                                >
                                  {moment(data.created_at).format("LT")}{" "}
                                </div>
                                <div
                                  className={
                                    data?.user_id == responseData?.business_id
                                      ? `${style.adminResponseSendText}`
                                      : `${style.textContentBox}`
                                  }
                                  key={i}
                                >
                                  <p
                                    style={{
                                      cursor: isValidUrl(data?.user_content)
                                        ? "pointer"
                                        : "",
                                    }}
                                    onClick={() => {
                                      if (isValidUrl(data?.user_content)) {
                                        window.open(data?.user_content);
                                      }
                                    }}
                                  >
                                    {data?.user_content}
                                  </p>
                                  {data?.user_id ==
                                    responseData?.business_id ? (
                                    <div className={style.rightChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="rgb(227 99 168 / 33%)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(-1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="rgb(227 99 168 / 33%)"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <div className={style.leftChatArrow}>
                                      <svg
                                        width="inherit"
                                        height="inherit"
                                        style={{ objectFit: "cover" }}
                                        viewBox="0 0 24 24"
                                        fill="#ffffff8a"
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(1, 0, 0, -1, 0, 0)"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth="0"
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M18 7.2071C18 6.09347 16.6536 5.53575 15.8661 6.32321L6.32321 15.8661C5.53576 16.6536 6.09346 18 7.2071 18H16.25C17.2165 18 18 17.2165 18 16.25V7.2071Z"
                                            fill="#ffffff8a"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <Avatar
                                className={style.textAvatar}
                                shape="square"
                              >
                                !
                              </Avatar>
                            )}
                          </>
                        );
                      })
                      : ""}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={style.chatFooter}>
            <InputEmoji
              value={text}
              onChange={(e) => {
                setText(e);
                setBtnDisable(false);
              }}
              cleanOnEnter
              onEnter={handleSubmit}
              placeholder={t("type reply")}
            />
            <div className={style.sendImgBOx} onClick={() => { }}>
              <Popover
                placement="top"
                content={
                  <div className={style.uploadIconesDiv}>
                    <p>
                      <FeatherIcon
                        icon="upload"
                        size={20}
                        onClick={() => {
                          onOpenMedia("file");
                        }}
                      />
                    </p>
                    <p>
                      <FeatherIcon
                        icon="mic"
                        size={20}
                        onClick={() => {
                          onOpenMedia("audio");
                        }}
                      />
                    </p>
                    <p>
                      {" "}
                      <FeatherIcon
                        icon="video"
                        size={20}
                        onClick={() => {
                          onOpenMedia("video");
                        }}
                      />
                    </p>
                  </div>
                }
                trigger="click"
              >
                <FeatherIcon style={{ color: "white" }} icon="link" size={20} />
              </Popover>
            </div>
            <div
              className={style.sendImgBOx}
              onClick={() => {
                if (btnDisable == false) {
                  handleSubmit();
                }
              }}
            >
              <img src={sendImg} />
            </div>
          </div>
        </>
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        centered={true}
        onCancel={handleCancel}
        className="interactionPreviewModal"
      >
        <div className={style.modalPreviewContent}>
          {previewContent?.content_type == "image_upload" ? (
            <img src={previewContent?.user_content}></img>
          ) : previewContent?.content_type == "video_upload" ||
            previewContent?.content_type == "video_record" ? (
            // <video
            //   autoPlay
            //   controls
            //   playsInline
            //   src={previewContent?.user_content}
            // ></video>
            <VideoPlayer videoUrl={previewContent?.user_content} />

          ) : (
            ""
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ChatModule;
