import React from "react";
import { Row, Col } from "antd";
import { CalendarWrapper, Main } from "./Style";
import "react-calendar/dist/Calendar.css";
import MonthCalendar from "./overview/Month";
import WeekCalendar from "./overview/Week";
import DayCalendar from "./overview/Day";
import TodayCalendar from "./overview/Today";
import ScheduleCalendar from "./overview/Schedule";
import { useLocation } from "react-router-dom";

const Calendars = () => {
  const location = useLocation();

  return (
    <>
      <Main>
        <CalendarWrapper>
          <Row>
            <Col span={24}>
              {location?.pathname?.includes("month") ? (
                <MonthCalendar />
              ) : location?.pathname?.includes("week") ? (
                <WeekCalendar />
              ) : location?.pathname?.includes("/users-booking-list/day") ? (
                <DayCalendar />
              ) : location?.pathname?.includes("/users-booking-list/today") ? (
                <TodayCalendar />
              ) : location?.pathname?.includes("schedule") ? (
                <ScheduleCalendar />
              ) : (
                <MonthCalendar />
              )}
            </Col>
          </Row>
        </CalendarWrapper>
      </Main>
    </>
  );
};

export default Calendars;
