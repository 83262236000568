import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import Cookies from "js-cookie";
import { message } from "antd";
import { getItem, setItem } from "../../utility/LocalStorageControl";

// LOGIN API CALL
export const loginEmail = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        `${API.auth.login}?country_iso_code=${payloads?.country_iso_code}`,
        payloads
      );
      if (!loggedIn.data.error) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// OTP VERIFICATION API CALL
export const otpVarification = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.auth.verifyOtp, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        setItem("access_token", response?.data?.data?.token);
        setItem("userDetails", response?.data?.data);
        if (response?.data?.data?.is_information_submitted) {
          setTimeout(() => {
            dispatch({
              type: API.auth.verifyOtp,
              userDetails: response?.data?.data,
              login: true,
              loading: true,
            });
          }, 2000);

          Cookies.set("logedIn", true);
          Cookies.set("username", response?.data?.data?.username);
        }
        return {
          status: true,
          data: response?.data?.data,
          submitted_status: response?.data?.data?.is_information_submitted,
        };
      } else {
        message.error(response.data.message);
        if (response.data.statusCode == 400) {
          return { status: false, optFalse: true };
        } else {
          return { status: false, optFalse: false };
        }
      }
    } catch (err) {
      return { status: false };
    }
  };
};

// DEFINE FUNCTION FOR UPDATE REDUX USER DETAILS
export const updateReduxUserNavigateDetails = (payload) => {
  return async (dispatch) => {
    try {
      Cookies.set("logedIn", true);
      await dispatch({
        type: API.auth.verifyOtp,
        userDetails: payload,
        login: true,
        loading: true,
      });
      window.location.replace("/");
    } catch (err) {
      return false;
    }
  };
};

// DEFINE FUNCTION FOR UPDATE REDUX USER DETAILS
export const updateReduxUserDetails = (payload, isLoginStatus) => {
  return async (dispatch) => {
    try {
      if (payload) {
        await dispatch({
          type: API.auth.verifyOtp,
          userDetails: payload,
          login: true,
          loading: true,
        });
        Cookies.set("logedIn", true);
      } else {
        await dispatch({
          type: API.auth.verifyOtp,
          userDetails: getItem("userDetails"),
          login: true,
          loading: true,
        });
      }
      return { status: true };
    } catch (err) {
      return { status: false };
    }
  };
};

// CHECK VALIDATION USER NAME API CALL
export const checkValidUserName = (useName) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.auth.validUserName + useName);
      if (!response.data.error) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// ACCOUNT VARIFICATION USER NAME API CALL
export const AccountVarificationUserName = (id, payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.auth.accountVarificationUserName + id,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (!response.data.error) {
        message.success(response.data.message);
        let object = {
          ...response?.data?.data,
          stripe_connect_url: getItem("userDetails").stripe_connect_url,
        };
        setItem("userDetails", object);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// CHECK VALIDATION USER NAME API CALL
export const otpResend = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.auth.resendOTP, payload);
      if (!response.data.error) {
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// COUNTRY LIST API CALL
export const countryList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.auth.countryList);
      if (!response.data.error) {
        dispatch({
          type: API.auth.countryList,
          countryList: response?.data?.data,
        });
        return { status: true, data: response?.data?.data };
      } else {
        return { status: false, data: [] };
      }
    } catch (err) {
      return { status: false, data: [] };
    }
  };
};

// LOG OUT FUNCTION
export const logOut = () => {
  return async (dispatch) => {
    try {
      Cookies.remove("logedIn");
      Cookies.remove("username")
      localStorage.clear();
      dispatch({
        type: API.auth.verifyOtp,
        userDetails: {},
        login: false,
        loading: false,
      });
      window.location.replace("/login");
      message.success("Logout Successfully");
      return true;
    } catch (err) {
      return false;
    }
  };
};
