import { combineReducers } from "redux";
import authReducer from "./authentication/reducers";
import SubScriptionReducer from "./subscription/reducers";
import CalenderReducer from "./calendar/reducers";
const rootReducers = combineReducers({
  auth: authReducer,
  subscription: SubScriptionReducer,
  Calender: CalenderReducer,
});

export default rootReducers;
