import { DataService } from "../../config/dataService/DataService";
import { API } from "../../config/api/Index";
import { message } from "antd";

// all user's booking List API
export const getUsersBookingList = (start_date, end_date, status) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.usersBooking.usersBookingList}?start_date=${start_date}&end_date=${end_date}&status=${status}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// users booking detail
export const getUsersBookingDetail = (id) => {
  return async (dispatch) => {
    try {
      const listResp = await DataService.get(
        `${API.usersBooking.usersBookingDetail}${id}`
      );
      if (!listResp.data.error) {
        return listResp?.data;
      } else {
        message.error(listResp.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
