import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./RootReducers";

const store = createStore(
  rootReducers,
  compose(applyMiddleware(thunk.withExtraArgument()))
);

export default store;
