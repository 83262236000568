import React, { useState, useEffect } from "react";
import style from "./QRDetail.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Col,
  Row,
  Spin,
  Popover,
  Descriptions,
  Table,
  Pagination,
  Badge,
  Tooltip,
} from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch } from "react-redux";
import {
  getQrPaymentsDetail,
  getQrPaymentsHistory,
  viewReceiptApi,
} from "../../../../redux/qrPayment/action";
import moment from "moment";
import { WEBSITE_URL } from "../../../../config/Constant";

function QrPaymentDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [qrDetail, setQrDetail] = useState();
  const { id } = useParams();
  const [paymentHistory, showPaymentHistory] = useState(false);
  const [paymentHistoryList, showPaymentHistoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [paginationCount, setPaginationCount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [userLocal, setUserLocal] = useState("en-US");

  useEffect(() => {
    allQrPaymentList();
    const browserLang =
      window.navigator.language || window.navigator.userLanguage || "en-US";
    setUserLocal(browserLang);
  }, []);

  const allQrPaymentList = async () => {
    setLoading(true);
    const dataList = await dispatch(getQrPaymentsDetail(id));
    if (dataList) {
      setQrDetail(dataList?.data);
      setLoading(false);
      setCurrency(dataList?.data?.countryObject?.currency);
    }
  };

  const qrPaymentHistoryList = async (page, pageNum) => {
    const param = {
      business_id: qrDetail?.business_id?._id,
      qr_payment_id: qrDetail?._id,
      page: page,
      limit: pageNum,
    };
    const dataList = await dispatch(getQrPaymentsHistory(param));
    if (dataList) {
      showPaymentHistoryList(dataList?.data);
      setPaginationCount(dataList?.pagination?.total_counts);
    } else {
      showPaymentHistoryList([]);
    }
  };

  const columns = [
    {
      title: t("Total User"),
      dataIndex: "totalUser",
      key: "totalUser",
      render: (icon, value) => {
        return value?.paidByUsers.length;
      },
    },
    {
      title: t("Paid By Users"),
      dataIndex: "paidByUsers",
      key: "paidByUsers",
      render: (icon, value) => {
        const user = value?.paidByUsers.map((val, i) => {
          return val?.userId?.username;
        });
        return user.map((data, i) => {
          return (
            <>
              <div className={style.userNameCell} key={i}>
                <Badge status="default" />
                <p>{data}</p>
              </div>
            </>
          );
        });
      },
    },
    {
      title: t(`Paid Amount`) + `(${currency})`,
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (icon, value) => {
        const amount = value?.paidByUsers.map((val, i) => {
          return val?.paidAmount;
        });
        return amount.map((data, i) => {
          return (
            <>
              <div className={style.userNameCell} key={i}>
                <Badge status="default" />
                <h3>+{data?.toFixed(2)}</h3>
              </div>
            </>
          );
        });
      },
    },
    {
      title: t("Transaction Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (icon, value) => {
        // return moment(
        //   value?.created_at,
        //   "YYYY-MM-DDTHH:mm:ss.SSSSZ"
        // ).format("MMM DD, YYYY HH:mm a")

        const createDate = value?.paidByUsers.map((val, i) => {
          return val?.created_date;
        });
        return createDate.map((data, i) => {
          return (
            <>
              <div className={style.userNameCell} key={i}>
                <Badge status="default" />
                <p>
                  {moment(data, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format(
                    "MMM DD, YYYY HH:mm"
                  )}
                </p>
              </div>
            </>
          );
        });
      },
    },
    {
      title: t(`Total Amount`) + `(${currency})`,
      dataIndex: "total_amount",
      key: "amount",
      render: (icon, value) => {
        return value?.qrPaymentObject?.amount?.toFixed(2);
      },
    },
    {
      title: t("VAT(%)"),
      dataIndex: "vat",
      key: "vat",
      render: (icon, value) => {
        return value?.qrPaymentObject?.vat;
      },
    },
    {
      title: t(`Chargeable Amount`) + `(${currency})`,
      dataIndex: "chargeable_amount",
      key: "chargeable_amount",
      render: (icon, value) => {
        return (value?.qrPaymentObject?.chargeableAmount / 100)?.toFixed(2);
      },
    },
    {
      title: t(`Remaining Amount`) + `(${currency})`,
      dataIndex: "remaining_amount",
      key: "remaining_amount",
      render: (icon, value) => {
        const remaining =
          value?.qrPaymentObject?.chargeableAmount >
            value?.qrPaymentObject?.amountPaid
            ? value?.qrPaymentObject?.chargeableAmount -
            value?.qrPaymentObject?.amountPaid
            : 0;
        return (remaining / 100)?.toFixed(2);
      },
    },
    {
      title: t(`Extra Amount`) + `(${currency})`,
      dataIndex: "extra_amount",
      key: "extra_amount",
      render: (icon, value) => {
        const remaining =
          value?.qrPaymentObject?.chargeableAmount <
            value?.qrPaymentObject?.amountPaid
            ? value?.qrPaymentObject?.amountPaid -
            value?.qrPaymentObject?.chargeableAmount
            : 0;
        return (
          <div className={style.userNameCell}>
            {remaining > 0 ? (
              <h3>+{(remaining / 100)?.toFixed(2)}</h3>
            ) : (
              <p>{(remaining / 100)?.toFixed(2)}</p>
            )}
          </div>
        );
      },
    },
    // {
    //   title: 'Receipt',
    //   dataIndex: 'Receipt',
    //   key: 'Receipt',
    //   render: (icon, value) => {
    //     console.log("🚀 ~ file: QrPaymentDetail.js:215 ~ QrPaymentDetail ~ value:", value._id)

    //     return (<>
    //       <div className={style.bookingActionBox}>
    //         <Tooltip title="View Payment Receipt" placement="top">
    //           <FeatherIcon
    //             icon='file-text'
    //             size={18}
    //             className={style.actionIcons}
    //             onClick={() => {
    //               viewPaymentReceipt(value?._id)
    //             }}
    //           />
    //         </Tooltip>
    //       </div>

    //     </>)
    //   },
    // },
  ];

  const handlePagination = (page, pageNumber) => {
    setCurrentPage(page);
    setDataPerPage(pageNumber);
    qrPaymentHistoryList(page, pageNumber);
  };

  // formate currency number according user local
  function formatNumberToLocalCurrency(amount, currency) {
    const formatter = new Intl.NumberFormat(userLocal.toString(), {
      style: "currency",
      currency: currency || "USD",
    });
    return formatter.format(amount);
  }

  // view payment receipt
  const viewPaymentReceipt = async (id) => {
    // console.log(
    //   "🚀 ~ file: QrPaymentDetail.js:238 ~ viewPaymentReceipt ~ id:",
    //   id
    // );
    const data = await dispatch(viewReceiptApi(id));
    if (data) {
      if (!Array.isArray(data.data?.data)) {
        window.location.href = data.data?.data;
      } else {
        let aa = new Uint8Array(data.data?.data);
        let file = new Blob([aa], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.location.href = fileURL;
      }
    } else {
      console.log("Error:", data?.error);
    }
  };

  return (
    <div className={style.qrDetailContainer}>
      {loading ? (
        <div className={style.loadArbOX}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={style.backBtn} onClick={() => navigate("/qrPayment")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="rgb(206 19 119 / 54%)"
              // height="inherit"
              // width="inherit"
              style={{
                objectFit: "cover",
              }}
              version="1.1"
              id="Layer_1"
              viewBox="0 0 512 512"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />

              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />

              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M313.749,347.584 c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251L176.917,271.083 c-8.341-8.341-8.341-21.824,0-30.165l106.667-106.667c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165L222.165,256 L313.749,347.584z" />{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          </div>

          <Row gutter={[20, 20]}>
            <Col
              // xxl={paymentHistory == true ? 12 : 24}
              // xl={paymentHistory == true ? 12 : 24}
              // lg={paymentHistory == true ? 12 : 24}
              // md={paymentHistory == true ? 10 : 24}
              // sm={paymentHistory == true ? 10 : 24}
              // xs={paymentHistory == true ? 24 : 24}
              span={24}
            >
              <div className={style.qrDetailCard}>
                <div className={style.QrCodeContainer}>
                  <div className={style.QrCode}>
                    <img src={qrDetail?.qr_code} />
                  </div>

                  <p className={style.payAmount}>{t("Chargeable Amount")}</p>
                  <div className={style.amountNum}>
                    {formatNumberToLocalCurrency(
                      qrDetail?.chargeableAmount / 100,
                      qrDetail?.countryObject?.currency
                    )}
                  </div>
                  <p>
                    {t("Reset Count.")}{" "}
                    <strong>{qrDetail?.paymentNumber}</strong>
                    <Tooltip
                      title={
                        <p>
                          {t(
                            "when full amount is paid counter will be reset and new number will allocated. or you manually reset the payment information counter number will increase by 1."
                          )}
                        </p>
                      }
                    >
                      <FeatherIcon
                        icon="alert-circle"
                        className={style.resetInfoIcon}
                      />
                    </Tooltip>
                  </p>
                </div>

                <div className={style.cardInfoContainer}>
                  <h4>{qrDetail?.title}</h4>
                  <p>
                    {t("Price")}{" "}
                    <strong className={style.priceText}>
                      {formatNumberToLocalCurrency(
                        qrDetail?.amount,
                        qrDetail?.countryObject?.currency
                      )}
                    </strong>
                  </p>
                  <p className={style.priceAmount}>
                    {t("Received Amount")}{" "}
                    <strong>
                      {formatNumberToLocalCurrency(
                        qrDetail?.amountPaid / 100,
                        qrDetail?.countryObject?.currency
                      )}
                    </strong>
                  </p>
                  <div className={style.userNameCell}>
                    {t("Extra Received Amount")}
                    {"  "}
                    {qrDetail?.amountPaid > qrDetail?.chargeableAmount ? (
                      <div className={style.userNameCell}>
                        <h3>
                          +
                          {formatNumberToLocalCurrency(
                            (qrDetail?.amountPaid -
                              qrDetail?.chargeableAmount) /
                            100,
                            qrDetail?.countryObject?.currency
                          )}
                        </h3>
                      </div>
                    ) : (
                      formatNumberToLocalCurrency(
                        0,
                        qrDetail?.countryObject?.currency
                      )
                    )}
                  </div>
                  <p>
                    {t("Remaining Amount")}
                    {"  "}
                    <span>
                      {qrDetail?.amountPaid < qrDetail?.chargeableAmount
                        ? formatNumberToLocalCurrency(
                          (qrDetail?.chargeableAmount -
                            qrDetail?.amountPaid) /
                          100,
                          qrDetail?.countryObject?.currency
                        )
                        : formatNumberToLocalCurrency(
                          0,
                          qrDetail?.countryObject?.currency
                        )}
                    </span>
                  </p>
                  <p className={style.descriptionText}>
                    {qrDetail?.description}
                  </p>
                  <div className={style.shortUrlContainer}>
                    <p className={style.interactionUrlTxt}>
                      <a
                        target="_blank"
                        href={`${WEBSITE_URL}${qrDetail?.url_string}`}
                      >
                        {`${WEBSITE_URL}${qrDetail?.url_string}`}
                      </a>
                    </p>
                    <Popover
                      content={<div>{t("Link copied")}!</div>}
                      trigger="click"
                    >
                      <div
                        className={style.interactionDetailIconeDiv}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${WEBSITE_URL}${qrDetail?.url_string}`
                          );
                        }}
                      >
                        {" "}
                        <FeatherIcon icon="copy" size={24} />
                      </div>
                    </Popover>
                  </div>

                  <div className={style.booleanValueBox}>
                    <Descriptions
                      column={1}
                      items={[
                        {
                          key: "VAT",
                          label: t("VAT"),
                          children: qrDetail?.vat + "%",
                        },
                        {
                          key: "isExclusive",
                          label: t("Is VAT Exclusive/Inclusive ?"),
                          children:
                            qrDetail?.isExclusive == true
                              ? "Exclusive"
                              : "Inclusive",
                        },
                        {
                          key: "isRecursive",
                          label: t("Is QR Recursive ?"),
                          children:
                            qrDetail?.is_recursive == true ? "True" : "False",
                        },
                        {
                          key: "Split_Payment",
                          label: t("Split Payment"),
                          children:
                            qrDetail?.splitEnable == true ? "True" : "False",
                        },
                        {
                          key: "Is_Paid",
                          label: t("Is Paid ?"),
                          children: qrDetail?.isPaid == true ? "True" : "False",
                        },
                      ]}
                    />
                  </div>
                  <p className={style.dateInfoText}>
                    {moment(
                      qrDetail?.created_at,
                      "YYYY-MM-DDTHH:mm:ss.SSSSZ"
                    ).format("MMM DD, YYYY HH:mm")}
                    .
                  </p>

                  <div
                    onClick={() => {
                      if (paymentHistory == false) {
                        showPaymentHistory(true);
                        qrPaymentHistoryList(currentPage, dataPerPage);
                      } else {
                        showPaymentHistory(false);
                        setCurrentPage(1);
                        setDataPerPage(10);
                      }
                    }}
                    className={style.showHistory}
                  >
                    {paymentHistory == false ? (
                      <>
                        <FeatherIcon icon="chevron-down" />{" "}
                        {t("View all Payments")}
                      </>
                    ) : (
                      <>
                        <FeatherIcon icon="chevron-up" /> {t("Show Less")}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={paymentHistory == true ? 24 : 0}>
              {paymentHistoryList && paymentHistoryList.length !== 0 ? (
                <>
                  <h3>{t("Payment History")}</h3>
                  <Table
                    showSorterTooltip={false}
                    columns={columns}
                    dataSource={paymentHistoryList}
                    pagination={false}
                    scroll={{
                      x: "500px",
                    }}
                  />
                  <Row justify="center">
                    <Col className={style.paginationBox}>
                      {paginationCount !== "" &&
                        paginationCount !== undefined &&
                        paginationCount !== 0 ? (
                        <Pagination
                          className="interactionPagination"
                          total={paginationCount}
                          currentPage={currentPage}
                          onChange={handlePagination}
                          pageSizeOptions={[10, 20, 50, 100]}
                          showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                          }
                          pageSize={dataPerPage}
                          showSizeChanger
                        />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <div className={style.qrDetailCard}>
                  <h3> {t("No Payment Data.")}</h3>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default QrPaymentDetail;
